import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress} from '@mui/material';
import ModalCustom from './ModalCustom';
import { useDataProvider, useRefresh, useUpdate } from 'react-admin';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { InputAdornment, TextField } from '@mui/material';
import PercentIcon from '@mui/icons-material/Percent';
import { useQuery } from 'react-query';

const ModalSocio = ({open, setOpen, record, discounts, setDiscounts}) => {
  const defaultDiscount = {
    id: Date.now(),
    concepto: '',
    aplicaciones_restantes: 1,
    monto_absoluto: 0,
    porcentaje: 0,
    date_created: new Date().toISOString().slice(0,10),
    cantidad_de_aplicaciones: 0

  };
  const [newDiscount, setNewDiscount] = useState(defaultDiscount);
  const [update, {loaded, loading, error}] = useUpdate();
  const [errorField, setErrorField] = useState({date: false, concept: false});
  const [pricePlan, setPricePlan] = useState(null);
  const dataProvider = useDataProvider();

  useQuery(
    ['planes', {id: record?.active_plan_id}], 
    () => dataProvider.getOne('planes',  {id: record?.active_plan_id}),
    {
      onSuccess: ({data}) => {
        setPricePlan(data.price);
      },
      enabled: (record?.active_plan_id && open) ? true : false
    }
  );

  const handlerClick = () => {
    if(newDiscount?.concepto){
      if(validate()){
        if(validateDiscount()){
          setErrorField({date: false, concept: false});
          if(discounts){
            setDiscounts([...discounts, newDiscount]);
            setOpen(false);
          }else{
            let discountsSend = record?.discounts;
            let editedNewDiscount = {...newDiscount, date_created: new Date().toISOString().slice(0,10)};
            discountsSend.push(editedNewDiscount);
            console.log('entro');
            update('socios',  {id: record?.id, data: {'discounts': discountsSend}}, {
              onSuccess: (data) => {
                createNotification('success', 'Descuento creado con éxito');
                setNewDiscount(defaultDiscount);
                setOpen(false);
              },
            });
          
          }
        }
      }else{
        createNotification('error', 'Por favor revisa los campos antes de guardar, completa los campos requeridos');
      }
    }else{
      if(!newDiscount?.concepto){
        setErrorField({date: false, concept: true});
      }else{
        setErrorField({date: true, concept: false});
      }
      createNotification('error', 'Por favor completa los campos requeridos');
    }
  };

  const validateDiscount = () => {
    let disc = pricePlan;
    if(newDiscount?.monto_absoluto == 0 && newDiscount?.porcentaje == 0) return true;
    if(pricePlan && (newDiscount?.monto_absoluto || newDiscount?.porcentaje)){
      if(newDiscount?.porcentaje > 0){
        disc = ((disc * newDiscount?.porcentaje) / 100);
        disc = pricePlan - disc - newDiscount?.monto_absoluto;
      }else{
        disc = disc - newDiscount?.monto_absoluto;
      }
    }
    if(disc < 0){
      createNotification('error', 'El descuento y/o el monto absoluto no puede superar el precio del plan');
      return false;
    }
    return true;
  };

  const validate = () => {
    let error = false;

    if(newDiscount?.aplicaciones_restantes <= 0 || newDiscount?.aplicaciones_restantes.length == 0 || isNaN(newDiscount?.aplicaciones_restantes) ) {
      setErrorField({...errorField, aplicaciones_restantes: true})
      error = true;
    };
    if((!newDiscount?.monto_absoluto && newDiscount?.monto_absoluto !== 0) || newDiscount?.monto_absoluto < 0) {
      console.log(!newDiscount?.monto_absoluto );
      setErrorField({...errorField, monto_absoluto: true});
      error = true;
    }
    if( (newDiscount?.porcentaje !== 0 && !newDiscount?.porcentaje) || newDiscount?.porcentaje < 0 || newDiscount?.discount > 100) {
      setErrorField({...errorField, porcentaje: true});
      error = true;
    }
    return !error;
  };

  useEffect(()=> {
    if(error){
      createNotification('error', 'No se pudo crear el descuento', ()=> handleClickCopy(newDiscount));
    }
  }, [loaded, error, discounts]);

  const handleClose = () => {
    if(!loading) {
      setOpen(false);
      setNewDiscount(defaultDiscount);
    }
  };

  const handleDiscount = (e) => {
    let value = parseInt(e.target.value);
    setNewDiscount(
      {
        ...newDiscount,
        porcentaje: value > 100 ? 100 : value < 0 ? 0 : value
      }
    );
  };

  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      showButtons
      onClickAction={handlerClick}
      textAction='Crear'
      startIcon={loading && <CircularProgress style={{width: '18px', height: '18px'}} />}
      disabledAction={loading}
      title='Crear Descuento'
    >
      <Box 
        component="form" 
        noValidate 
        autoComplete="off"
        style={{display: 'flex', flexDirection: 'column',gap: '15px'}}
      >

        <TextField 
          variant='outlined'
          label='Concepto' 
          required 
          error={errorField.concept}
          onChange={(e)=> setNewDiscount({...newDiscount, concepto: e.target.value})} 
          size='small'
          helperText="Este campo es requerido"
        />

        <TextField 
          variant='outlined'
          label='Restantes'
          type='number'
          required
          error={newDiscount?.aplicaciones_restantes < 1}
          value={newDiscount?.aplicaciones_restantes}
          InputProps={{ inputProps: { min: 1 } }}
          onChange={(e) => setNewDiscount((prevState) => ({...prevState, aplicaciones_restantes: parseInt(e.target.value), cantidad_de_aplicaciones: parseInt(e.target.value)}))}
          size='small'
        />
            
        <TextField 
          variant='outlined' 
          label='Monto absoluto'
          required
          type='number'
          defaultValue={0} 
          error={errorField?.monto_absoluto}
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setNewDiscount((prevState) => ({...prevState, monto_absoluto: parseInt(e.target.value)}))}
          size='small'
        />
  
        <TextField 
          variant='outlined' 
          label='Descuento' 
          type='number' 
          min={0} max={100} 
          required
          error={errorField?.porcentaje}
          defaultValue={0} 
          InputProps={{ 
            inputProps: { min: 0, max: 100 },
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon style={{fontSize: '14px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={handleDiscount} 
          value={newDiscount?.porcentaje} 
          size='small'
        />
      </Box>
    </ModalCustom>
  );
};

export default ModalSocio;
