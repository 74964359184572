import { Box, CircularProgress, Divider, Typography, TextField, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Show, useUpdate, useRedirect, useDataProvider, NumberInput, useRecordContext, useResourceContext, usePermissions } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import {  bannerStyle } from '../../Theme/useStyle';
import DescriptionCard from './SectionsShow/DescriptionCard';
import NegociosHabilitados from './SectionsShow/NegociosHabilitados';
import ImageCard from './SectionsShow/ImageCard';
import PlanCard from './SectionsShow/PlanCard';
import { FiExternalLink } from 'react-icons/fi';
import PrestacionesCard from './SectionsShow/PrestacionesCard';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import VersionCard from '../../Components/UI/Show/VersionCard';
import TyCCard from './SectionsShow/TyCCard';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import EditIcon from '@mui/icons-material/Edit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import CardHeader from './SectionsShow/HeaderCard';
import { createNotification, getPermissions, handleClickCopy } from '../helpers/helpFunctions';
import { useContext } from 'react';
import { merchantCadena, merchantContext } from '../../DataProvider';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { ModalDiscount } from './SectionsEdit/DescuentoMasivoEdit';
import CorporativosCard from './SectionsShow/CorporativosCard';

const PlanesShow = (props) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const permis = usePermissions().permissions;
  const {getMerchant} = useContext(merchantContext);
  const canSeeCard = (getMerchant()?.id === '' || getMerchant()?.id === merchantCadena) ? true :
    !getMerchant()?.central && resource === 'planes' ? true :
      !getMerchant()?.central && resource !== 'planes' ? false :
        true;

 
  const isMultinegocio = resource == 'multinegocio';
  const editMultinegocio = (isMultinegocio && (permis == 'admin' || permis == 'operador'));
  const noOperadorPlanes = (resource == 'planes' && permis == 'operador');
  const isAbleToEdit = (noOperadorPlanes || editMultinegocio) ? false : true;
  const notOwner = permis === 'owner' || permis === 'superadmin';

  return (
    <Show {...props} s
      title={<TitleEdit title='Plan: ' source='name'/>}
      aside={<AsideShow permis={permis}/>} 
      actions={null} 
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <CardHeader permis={props.permissions} isAbleToEdit={isAbleToEdit}/>
        <DescriptionCard isAbleToEdit={isAbleToEdit} />
        <PlanCard isAbleToEdit={isAbleToEdit}/>
        {canSeeCard && <CorporativosCard />}
        {notOwner && <NegociosHabilitados isAbleToEdit={isAbleToEdit} />}
        <PrestacionesCard isAbleToEdit={isAbleToEdit}/>
        <ImageCard isAbleToEdit={isAbleToEdit}/>
        <TyCCard isAbleToEdit={isAbleToEdit}/>
        <VersionCard isAbleToEdit={isAbleToEdit} />
      </ShowTransparent>
    </Show>
  );
};

export default PlanesShow;

const AsideShow = ({...rest}) => {
  const record = useRecordContext();
  const [activos, setActivos] = useState(true);
  const [numeroDeActivos, setNumeroDeActivos] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const resource = useResourceContext();
  const permis = getPermissions();
  const dataProvider = useDataProvider();
  const [update, {loaded, loading, error}] = useUpdate();
  const { getMerchant } = useContext(merchantContext);

  // const isMultinegocio = ['multinegocio', 'centrales'].includes(resource);
  const isMultinegocio = record?.merchant_id === merchantCadena;
  const editMultinegocio = (isMultinegocio && permis !== 'admin' && permis !== 'operador');
  const noOperadorPlanes = (resource == 'planes' && permis !== 'operador');

  const permisAumentoSegmentado = ['superadmin', 'owner'].includes(permis);
  const baseUrl = `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`
  
  const canDiscount = isMultinegocio && permisAumentoSegmentado ?
    true :
    !isMultinegocio && (permis === 'superadmin' || permis === 'owner' || permis === 'admin') ?
      true :
      false;
  const handleArchived = () => {
    const diff = {archivado: !record?.archivado};
    update('planes', {id: record?.id, data: diff, previousData: record});
  };

  useEffect(() => {
    let filter = {'_id': record?.id};
    record && dataProvider.getList(`${resource}`, {filter: filter, pagination:{page: 1, perPage: 5}, sort:{field:'id', order:'ASC'}}).then((res) => { setActivos(res.data[0].socios > 0), setNumeroDeActivos(res.data[0].socios);});
  }, [record]);

  useEffect(()=> {
    if(loaded){
      record?.archivado ? 
        createNotification('success', 'Plan Archivado con éxito') : 
        createNotification('success', 'Plan Desarchivado con éxito'); 
    }else if(error)(
      record?.archivado ? 
        createNotification('error', 'Error al Desarchivar el Plan', ()=> handleClickCopy(record.id)) :
        createNotification('error', 'Error al Archivar el Plan', ()=> handleClickCopy(record.id))
    );
    
  }, [loaded, error, record]);
  const desarchivadoLegacy = record?.archivado && record?.nivel_de_acceso !== 'Local' && record?.['tipo-de-plan'] !== 'cadena' && permis !== 'superadmin' && permis !== 'owner' ? true : false;
  const data = record ? [
    // (noOperadorPlanes || editMultinegocio) && 
    // {
    //   text: 'Editar Plan',
    //   link: resource + '/' + record?.id,
    //   icon: <EditIcon/>

    // },
    !record?.archivado && canDiscount &&
    {
      text: 'Aumento Segmentado',
      icon: <MdOutlineAttachMoney/>,
      onClick: () => {setOpenModal(true);},
    },
    openModal && permis !== 'operador' && permis !== 'accesos' &&  {
      component: <ModalDiscount numeroDeActivos={numeroDeActivos} activos={activos} openModal={openModal} setOpenModal={setOpenModal} record={record}/>,
      custom: true
    },
    permis !== 'operador' && { divider: true },
    !record?.archivado && {
      text: 'Ver Checkout',
      href: baseUrl + record?.slug + (isMultinegocio ? ((getMerchant().id !== '' && getMerchant()?.id !== merchantCadena) ? '?seller_merchant=' + getMerchant().id : '' ): ''),
      icon: <FiExternalLink style={{fontSize: '16px', marginRight: '2px'}}/>
    },
    (noOperadorPlanes || editMultinegocio) && 
      {
        text: record?.archivado ? 'Desarchivar Plan' : 'Archivar Plan',
        icon:  loading ? <CircularProgress style={{width: '18px', height: '18px'}}/> :
          record?.archivado ? 
            <UnarchiveOutlinedIcon style={{fontSize: '16px', marginRight: '2px'}}/> :
            <ArchiveOutlinedIcon  style={{fontSize: '16px', marginRight: '2px'}}/>,
        onClick: handleArchived,
        disabled: loading || desarchivadoLegacy
      },
    (noOperadorPlanes || editMultinegocio) && { divider: true },
    (noOperadorPlanes || editMultinegocio) && {
      rest: rest,
      record: record,
      disabled: activos,
      label: 'Eliminar Plan',
      confirmTitle: `¿Eliminar ${record?.name}?`,
      confirmContent: '¿Seguro quieres eliminar este plan?',
      basePath: resource,
      tooltip: activos ? 'No se puede eliminar un plan con socios activos' : null,
    }
  ] : [];

  return(
    <AsideActionsShow data={data}/>
  );
};

