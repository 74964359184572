import { Box, Typography } from '@mui/material';
import React from 'react';
import { usePermissions, useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';

const AccessCard = () => {
  const record = useRecordContext();
  const permissions = usePermissions().permissions;
  const notAccess = permissions === 'owner' || permissions === 'superadmin';
  console.log(record);
  const dataArray = [
    {key: 'Sede Ingreso', value: record['sede_name'] },
    {key: 'Hora', value: record['date'].slice(11,16) },
    {key: 'Sede Socio', value: record['sede_socio'] ?? record?.['sede_name'] },
    {key: 'Nivel de acceso', value: record?.['categoria_socio'] },
    notAccess && {key: 'Plan', value: record['plan']}
  ];

  return (
    <CardBasic title='Acceso'>
      <TableMinimal data={dataArray} noBorderBottom/>
    </CardBasic>
  );
};

export default AccessCard;