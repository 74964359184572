import { Box } from '@mui/material';
import VentasCard from '../../../Components/UI/Dashboard/VentasCard';
import AltasSmallCard from '../../../Components/UI/Dashboard/AltasSmallCard';
import MediosDePagoCard from '../../../Components/UI/Dashboard/MediosDePagoCard';
import PlanesSmallCard from '../../../Components/UI/Dashboard/PlanesSmallCard';


const CobrosSection = ({
  isSuccess,
  errorVentas,
  isFetchedVentas,
  isFetchingMensuales,
  isFetchingVentas,
  ventasMensuales,
  date,
  cobrosMensuales
}) => {

  return (
    <>
      <VentasCard 
        isSuccess={isSuccess} 
        errorVentas={errorVentas}
        isFetchedVentas={isFetchedVentas}
        isFetchingVentas={isFetchingVentas}
        ventasMensuales={ventasMensuales} 
        date={date} 
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <AltasSmallCard 
          isFetchingMensuales={isFetchingMensuales}
          date={date}
          noPad 
          cobrosMensuales={cobrosMensuales} 
        />
        <MediosDePagoCard 
          isFetchingVentas={isFetchingVentas}
          date={date} 
          noPad 
          ventasMensuales={ventasMensuales} 
        />
        <PlanesSmallCard 
          isFetchingVentas={isFetchingVentas}
          noPad 
          ventasMensuales={ventasMensuales}
          date={date} 
        />
      </Box>
    </>
  );
};

export default CobrosSection;