import { Box } from '@mui/material';
import React from 'react';
import { EditButton } from 'react-admin';
import MercadopagoField from './MercadopagoField';
import WhatsappField from './WhatsappField';

const ActionsField = (props) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
      <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '180px'}}>
        <WhatsappField source="celular" label="Whatsapp" isSocio/>
        <MercadopagoField source="email" label="Cuenta Corriente" />
        {/* <EditButton label={null} {...props}/> */}
      </Box>
    </Box>
  );
};

export default ActionsField;