
import React, { useEffect } from 'react';
import { useSidebarState } from 'react-admin';
import { StyledBody, StyledCell, StyledHead, StyledRow, StyledTable } from './StyledTable';
import { Tooltip, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useAbsolute } from '../../../../Hooks/usePosition';
import './styledTable.css';
import { FaFileExport } from 'react-icons/fa';

export const CustomMerchList = (props) => {
  const {
    data,
    sortedPlanes,
    setSelectedRow,
    setOpen,
    fixedPosition
    // findValue
  } = props;

  const sideBarOpen = useSidebarState();
  const tipRef = React.useRef(null);
  const [inView, setInView] = React.useState(false);
  
  const cb = (entries) => {
    const [entry] = entries;
    entry.isIntersecting ? setInView(true) : setInView(false);
  };

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px'
    };
    const ref = tipRef.current;
    const observer = new IntersectionObserver(cb, options);
  
    if (ref) observer.observe(ref);
  
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [tipRef]);

  const merchName = (str) => /sportclub/i.test(str) ? str.split(/sportclub/i)[1] : str;
  const handleOpen = (row) => { setSelectedRow(row); setOpen(true);};
  const findValue = (merch, data) => {
    return merch?.planes_multinegocio?.find((item) => {
      if (item?.plan_id === data?.id) {
        return item;
      }
    });
  };
  const scrollbarClass = 'scrollbar-style';


  useEffect(() => {
    const relativeContainer = document.getElementById('merch-div-table');
    const fixedDiv = document.getElementById('fixed-div');
 
    function handleScroll() {
      fixedDiv.scrollLeft = relativeContainer.scrollLeft;
    }

    function handleTopScroll() {
      relativeContainer.scrollLeft = fixedDiv.scrollLeft;
    }

    relativeContainer.addEventListener('scroll',handleScroll);
    fixedDiv.addEventListener('scroll',handleTopScroll);
    return () => {
      relativeContainer.removeEventListener('scroll', handleScroll);
      fixedDiv.removeEventListener('scroll', handleTopScroll);
    };
  },[]);

  return (
    <>
      <div
        id="merch-div-table"
        style={{
          border: '0px solid black', 
          transform: !fixedPosition ? 'rotateX(180deg)' : null, 
          boxShadow: 'none', 
          width: '100%', 
          overflowX:'scroll', 
          whiteSpace: 'nowrap',
          maxWidth: sideBarOpen[0] ? '69vw' : '70vw' ,
          backgroundColor: '#f1f1f1',
          paddingTop: fixedPosition ? 8 : 0,
          position:'relative',
        }}
        className={scrollbarClass}
      >
        <StyledTable 

          style={{ 
            transform: !fixedPosition ? 'rotateX(180deg)' : null,  
            border: '0px solid black', 
            margin: 0,
            width: '100%', 
            whiteSpace: 'nowrap',
            backgroundColor: '#fff',

          }}
        >
          <StyledHead 
            id="fixed-div"
            valign="bottom"
            style={{
              display: !fixedPosition ? 'table-header-group' : 'flex',
              marginRight: fixedPosition ? '100px' : null,
              backgroundColor: '#f1f1f1',
              overflowX:'scroll',
              width: !fixedPosition ? 'inherit' : '70vw',
              height: !fixedPosition ? '100%' : 80,
              maxWidth: '1400px',
              position: fixedPosition ? 'fixed' : 'sticky',
              top: fixedPosition ? 40 : null,
              verticalAlign: 'bottom',
              textAlign: 'bottom',
              alignItems: 'end',
            }}
            className={scrollbarClass}
          >
            <StyledRow 
              valign="bottom"
              style={{
                width: '100%',
                height: '100%',
                verticalAlign: 'bottom',
                textAlign: 'bottom',
                alignItems: 'flex-start',
                whiteSpace: 'nowrap',
            
              }}>
              {
                data && data?.map((item, index) => (
                  <StyledCell >
                    <Tooltip 
                      ref={tipRef} 
                      title={item?.name}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#a3a3a3',
                            '& .MuiTooltip-arrow': {
                              color: '#a3a3a3'
                            }
                          }
                        }
                      }}
                      PopperProps={{
                        sx: { display: !inView ? 'block' : 'none' },
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -8]
                            }
                          }
                        ]
                      }}
                    >
                      <div style={{display: 'flex', alignItems: 'flex-start'}}>
                        {merchName(item?.name)}
                      </div>
                    </Tooltip>
                  </StyledCell>
                ))
              }
            </StyledRow>
          </StyledHead>
          {
            sortedPlanes && sortedPlanes?.map((plan, index) => {
              return (
                <StyledBody
                >
                  <StyledRow
                    style={{
                      cursor: 'pointer',
                      border: '0px solid black',
                      padding: '15px',
                      height: '20px',
                    }} 
                    selectable
                    onClick={() => handleOpen(plan)} key={index}>
                    {
                      data && data?.map((item, i) => {
                        let value = findValue(item, plan);
                        return (
                          <StyledCell
                            sx={{ 
                              height: '20px' ,
                              padding: '16px',
                              border: '0px solid black',
                            }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', border: '0px solid black'  }}>
                              <Typography sx={{ fontSize: '1em', color: !value?.enabled ? grey[500] : green[600], fontWeight: '600', border: '0px solid black' }}>$ {value?.application_fee ?? '0'}</Typography>
                            </div>
                          </StyledCell>
                        );
                          
                      })
                    }
                  </StyledRow>
                </StyledBody>
              );
            })
          }
        </StyledTable>
      </div>
    </>
  );
};