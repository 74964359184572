import { useEffect, useState } from 'react';
import { useDebounce } from '../../../Hooks/useDebounce';
import { Box, Checkbox, CircularProgress, TextField, Button, Skeleton } from '@mui/material';
import SplitComponent from '../../../Components/CustomFields/SplitComponent';
import { Spinner } from '../../../Components/UI/Dashboard/Spinner/Spinner';
import { DataGrid } from '@mui/x-data-grid';
import { theme } from '../../../Theme/theme';
import { FaSave } from 'react-icons/fa';

export const MultinegocioGrid = ({putLoading, loading, postSave, multimerch, setMultimerch, selectedRow}) => {

  const [datagrid, setDatagrid] = useState([]);
  const [search, setSearch] = useState('');
  
  const debouncedSearch = useDebounce(search, 500);
  
  const handleHabilitado = (clickedRow) => {
    console.log(clickedRow);
    const updatedData = multimerch.map((item) => {
      if (item?.id === clickedRow?.id) {
      
        return {
          ...item,
          habilitado: !item.habilitado,
          enabled: !item.habilitado,
          edit: true
        };
      }
      return item;
    });
    if(search !== '' ){
      setDatagrid(datagrid.map(d => {
        if(d?.id === clickedRow?.id){
          return({
            ...d,
            habilitado: !d.habilitado,
            enabled: !d.habilitado,
            edit: true
          });
        }else{
          return d;
        }
      }));
    }
    setMultimerch(updatedData);
  };
  useEffect(() => {
    search === '' && setDatagrid(multimerch ?? []);
  }, [multimerch]);
  
  
  useEffect(() => {
    if(multimerch && search !== ''){
      setDatagrid(multimerch.filter(
        (p) => ((p.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).includes(search))
      ));
    }else{
      setDatagrid(multimerch);
    }
  }, [debouncedSearch]);
  
  const cols = [
    {
      field: 'name',
      headerName: 'Negocio',
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.name}
        </>
      ),
    },
    {
      field: 'habilitado', 
      headerName: 'Habilitado',
      renderCell: (params, i) => (
        <Checkbox
          key={i}
          checked={params.row.habilitado}
          onChange={() => handleHabilitado(params.row)}
        />
      ),
      flex: 1,
      
    },
    {
      field: 'split', 
      headerName: 'Comisión de Cadena',
      flex: 2,
      renderCell: (params) => <SplitComponent params={params} multimerch={multimerch} setMultimerch={setMultimerch}/>,
    }
  ];
  
  const handleSearch = (e) => setSearch(e.target.value);
  
  return (
    <Box sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', justifyContent: 'flex-end'}}>
      {
        loading && multimerch && datagrid ? (
          <Spinner />
        ) : (
          <>
            <TextField 
              label="Buscar" 
              variant="outlined" 
              size='small' 
              sx={{alignSelf: 'flex-start'}}
              onChange={handleSearch}
            />
            <Box style={{ minWidth: '600px', width: '100%', height: `calc(${37*(datagrid.length > 9 ? 9 : datagrid.length)}px + 111px)`, overflowX: 'hidden'}}>
              <DataGrid 
                columns={cols} 
                rows={datagrid} 
                experimentalFeatures={{ newEditingApi: true }} 
                rowHeight={37}
                rowsPerPageOptions={[25]}
                disableColumnMenu={true}
                hideFooterSelectedRowCount
                hideFooterRowCount
                pageSize={25}
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-columnHeaders': {
                    border: 'none',
                    color: theme.palette.primary.main
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: '1px solid #EEEEEF'
                  },
                  '& .MuiDataGrid-columnSeparator--sideRight': {
                    display: 'none'
                  },
                  '& .MuiDataGrid-footerContainer': {
                    borderTop: 'none'
                  }
                }}
              />
            </Box>
            <Button 
              disabled={putLoading}
              startIcon={
                putLoading ? <CircularProgress size="15px" />
                  : <FaSave size="15px" />
              } 
              variant="contained" 
              sx={{
                justifySelf: 'flex-end',
                marginBottom: '20px', 
                maxWidth: '120px'
              }}
              onClick={() => postSave(selectedRow)}
            >GUARDAR</Button>
     
          </>
        )
      }
    </Box>
  );
  
};