import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const ChartLine = ({datasets, date, accesos, hideValue}) => {
  const [daysArr, setDaysArr] = useState([]);
  const {chart, chartLabel} = datasets;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const accesosOptions = {
    responsive: true,
    maintainAspectRatio: false,
   
    layout: {
      padding: 0,
      
    },
    elements: {
      padding: 5,
      margin: 10,
      bar: {
        borderWidth: 7,
        padding: 5,
        margin: 10,
      },
      
      point:{
        pointRadius: 6,
        backgroundColor: '#fff'
      }
    },
    scales: {
      x:  {
        min:0,
        max: 31,
        suggestedMin: 0,
        suggestedMax: 31
      },
     
    },
   
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(value, data) {
            if(value?.dataset?.label === 'Accesos') {
              return 'Accesos: ' + value.raw;
              
            }
          },
         
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          padding: 10,
          font: { 
            family: 'Arial'
          }
        }
      },
      title: {
        display: false,
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
   
    layout: {
      padding: 0,
      
    },
    elements: {
      padding: 5,
      margin: 10,
      bar: {
        borderWidth: 7,
        padding: 5,
        margin: 10,
      },
      
      point:{
        pointRadius: 6,
        backgroundColor: '#fff'
      }
    },
    scales: {
      x:  {
        min:0,
        max: 31,
        suggestedMin: 0,
        suggestedMax: 31
      },
      y: {
        alignToPixels: true,
        min: 0,
        ticks: {
          display: true,
          callback: function(value) {
            return hideValue ? '$ ***,***' : value.toLocaleString('es-ar', {
              style: 'currency',
              maximumFractionDigits: 0,
              currency: 'ARS'
            });
          }
        },
      },
    },
   
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(value, data) {
            if(value?.dataset?.label === 'Mostrador') {
              return hideValue ? '$ ***,***' : [
                'Monto: ' + value.raw.toLocaleString('es-ar', {
                  style: 'currency',
                  currency: 'ARS'
                }),
                'Boletas: ' + chart?.altas?.[value.dataIndex].boletas
              ];
            }
            if(value?.dataset?.label === 'Recurrentes'){
              return hideValue ? '$ ***,***' : [
                'Monto: ' + value.raw.toLocaleString('es-ar', {
                  style: 'currency',
                  currency: 'ARS'
                }),
                'Boletas: ' + chart?.recurrentes?.[value.dataIndex].boletas
              ];
            }
          },
         
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          padding: 10,
          font: { 
            family: 'Arial'
          }
        }
      },
      title: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setDaysArr([]);
    const numberOfDays = new Date((new Date(date.until).getFullYear()), (new Date(date.until).getMonth() + 1), 0).getDate();
    for (let i = 0; i < numberOfDays; i++) {
      setDaysArr((f) => [...f, i + 1]);
    }
  },[]);
  const dataChart = chart && {
    // labels: chart?.altas?.length === 0 ? ['1'] : chart?.altas?.map(item => item.key),
    labels: daysArr,
    datasets: [
      {
        label: 'Mostrador',
        data: chart?.altas?.map(item => item.value),
        borderColor: [
          'rgba(85, 132, 172, .8)',
        ],
        tension: 0.2,
        borderWidth: 3,
        hoverBorderWidth: 3,
        hoverOffset: 4
      },
      {
        label: 'Recurrentes',
        data: chart?.recurrentes?.map(item => item.value),
        borderColor: [
          'rgba(55, 32, 22, .8)',
        ],
        tension: 0.2,
        borderWidth: 3,
      },
    ]
  };
  const accesosChart = chart && {
    // labels: chart?.altas?.length === 0 ? ['1'] : chart?.altas?.map(item => item.key),
    labels: daysArr,
    datasets: [
      {
        label: 'Accesos',
        data: chart?.accesos?.map(item => item),
        borderColor: [
          'rgba(85, 132, 172, .8)',
        ],
        tension: 0.2,
        borderWidth: 3,
        hoverBorderWidth: 3,
        hoverOffset: 4
      },
    ]
  };

  return (
    <Box sx={{height: '270px', width: '100%'}}>
      {
        accesos ? (
          <Line data={accesosChart} options={accesosOptions} />
        ) : (
          <Line data={dataChart} options={options}/>
        )
      }
    </Box>
  );
};

export default ChartLine;