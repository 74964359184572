import { Box } from '@mui/material';
import AccesosCard from '../../../Components/UI/Dashboard/AccesosCard';
import IngresosCard from '../../../Components/UI/Dashboard/IngresosCard';
import EgresosCard from '../../../Components/UI/Dashboard/EgresosCard';
import { useContext, useEffect } from 'react';
import { merchantCadena, merchantContext } from '../../../DataProvider';


const AccesosSection = ({
  accesosMns,
  date,
  errorAccesos,
  isFetchingAccesos,
  isSuccess
}) => {
  const {getMerchant} = useContext(merchantContext);


  return (
    <>
      <AccesosCard 
        accesosMns={accesosMns}
        accessCard 
        date={date}
        isSuccess={isSuccess}
        errorVentas={errorAccesos} 
        isFetching={isFetchingAccesos} 
        isError={errorAccesos}
      />
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <IngresosCard 
          isFetching={isFetchingAccesos}
          isError={errorAccesos}
          accesosMns={accesosMns} 
          date={date}/>
        <EgresosCard 
          accesosMns={accesosMns}
          isError={errorAccesos} 
          isFetching={isFetchingAccesos} 
          date={date}
        />
      </Box>
    </>
  );
};

export default AccesosSection;