
import React, { useContext, useEffect, useState } from 'react';
import { DateInput, required, useDataProvider, useLocale, useRecordContext } from 'react-admin';
import useCheckField from '../../../Hooks/useCheckField';
import { documentTypes } from '../../helpers/choicesSelects';

const informacionSocio = () => {
  const record = useRecordContext();
  const [email, setEmail] = useState(record?.email ? record?.email : undefined);
  const [dni, setDni] = useState(record?.documento ? record?.documento : undefined);
  const [tipoDni, setTipoDni]= useState(record?.documento_tipo ? record?.documento_tipo : undefined);
  const [nacimiento, setNacimiento] = useState(undefined);
 
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleDateParser = (value) => {
    const date = value.split('-');
    const formattedDate = `${date[2]}/${date[1]}/${date[0]}`;
    setNacimiento(formattedDate);
    return formattedDate;
  };
  
  const handleDateFormatter = (value) => {
    const date = value.split('/');
    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;
    return formattedDate;
  };
  const handleTipoDocumento = (value) => {
    console.log(value);
    setTipoDni(value);
    return value;
  };
  
  const onlyActive = record?.status ? {} : {'status' : ['activo', 'baja']};
  const refetch = useCheckField('socios', 'documento', dni, onlyActive);
  // const refetchEmail = useCheckField('socios', 'email', email);
  const data = [
    {
      source: 'nombre',
      label: 'Nombre',
      validate: {
        required: 'El campo es obligatorio',
      },
      xs: 6
    },
    {
      source: 'apellido',
      label: 'Apellido',
      validate: {
        required: 'El campo es obligatorio',
      },
      xs: 6
    },
    {
      source: 'documento_tipo',
      label: 'Tipo de Documento',
      optionLabel: 'name',
      type: 'select',
      checkOnblur: refetch,
      choices: documentTypes,
      validate: {required: 'Falta el campo Tipo de Documento'},
      defaultValue: record?.documento_tipo,
      onChange: (e) => {
        handleTipoDocumento(e.target.value);},
      xs:  6,
    },
    {
      source: 'documento',
      label: tipoDni === 'dni'? 'Numero de DNI' : tipoDni === 'pasaporte' ? 'Pasaporte' : 'Documento',
      type: tipoDni === 'dni' ?'number': 'text',
      checkOnblur: refetch,
      onChange: (e) => setDni(e.target.value),
      xs:  6,
      validate: {
        min: {
          value: tipoDni === 'dni' && 1,
          message: 'No se admiten números negativos.'
        },
        required: 'El campo es obligatorio',
        pattern: {
          value: tipoDni === 'dni' ? /^[0-9]*$/ : /^[a-zA-Z0-9]*$/,
          message: tipoDni === 'dni' ? 'Debe contener solo números' : 'Debe contener sólo números y/o letras.',
        },
        minLength: {
          value: 7, 
          message: 'El campo debe tener al menos 7 caracteres'
        },
        maxLength: {
          value: tipoDni === 'dni' ? 8: 12,
          message: `El campo debe tener como máximo ${tipoDni === 'dni'? '8': '12'} caracteres`
        },
      },
      // value: dni,
    },
    {
      source: 'nacimiento',
      label: 'Nacimiento',
      type: 'date',
      variant: 'outlined',
      // format: dateFormatter,
      defaultValue: record?.nacimiento ? handleDateFormatter(record?.nacimiento) : '',
      onChange: (e) => handleDateParser(e.target.value),
      validate: {
        required: 'El campo es obligatorio',
        validate: {
          isGreaterThan: e => new Date(handleDateFormatter(e)) > new Date() ? 'La fecha no puede ser mayor a hoy' : true
        }
      },
      xs: 6
    },
    {
      source: 'celular',
      label: 'Celular',
      validate: {
        required: 'El campo es obligatorio',
      },
      xs: 6
    },
    {
      source: 'email',
      textField: true,
      label: 'Email',
      // checkOnblur: refetchEmail,
      onChange: (e) => {setEmail(e.target.value);},
      xs:  6,
      validate: {
        required: 'El campo es obligatorio',
        pattern: {
          value: emailRegex,
          message: 'El email no corresponde a un formato correcto'
        },
        minLength: {
          value: 4,
          message: 'minimo de 4 caracteres'
        }
      },
      
    },
   
  ];

  // return (
  //   <SectionForm data={data} />
  // );

  return data;
};

export default informacionSocio;