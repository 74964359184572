import React, { useState, useEffect, useContext, useMemo } from 'react';
import { BooleanInput, maxLength, NumberInput, regex, required, useDataProvider, useGetOne, useRecordContext } from 'react-admin';
import { createNotification, getListAxios, getMerchantId, removeAccents } from '../../helpers/helpFunctions';
import { verticalChoices } from '../../helpers/choicesSelects';
import useCheckField from '../../../Hooks/useCheckField';
import { FormControlLabel, FormGroup, Switch, Typography, Tooltip } from '@mui/material';
import { brandSportClub, merchantCadena, merchantContext, merchantsInToken } from '../../../DataProvider';
import { useForm, useFormContext, useWatch } from 'react-hook-form';

const informationEdit = (setSlugValue, slugValue, setEditPrice, editHaberes, setSelectType, selectType, setSelectedMerch, selectedMerch) => {
  
  const { getMerchant } = useContext(merchantContext);
  const record = useRecordContext();
  const isEdit = record?.length > 0 ? true : false;
  const [hasDad, setHasDad] = useState(record?.tiene_padre ? record.tiene_padre : false);
  const [descHab, setDescHab] = useState((record?.['descuento-haberes'] && record['descuento-haberes'] > 0) ? true : false);
  const [valueDesc, setValueDesc] = useState(record?.['descuento-haberes'] ? record['descuento-haberes'] : 0);
  const [name, setName] = useState('');
  const [merchant, setMerchant] = useState(
    getMerchant()?.id !== '' &&
     !getMerchant()?.negocio_central ?
      getMerchant() :
      null
  );
  const { data: merch } = useGetOne('merchants', {id: record?.merchant_id}, {enabled: Boolean(record), onSuccess: (data) => setMerchant(data)});
  const isOwner = getMerchant()?.id === '';

  const isAdmin = !getMerchant()?.negocio_central && Boolean(getMerchant()?.sede_principal);

  const isSede = isOwner ? (!merch?.negocio_central && Boolean(merch?.sede_principal)) : isAdmin;

  const [vertical, setVertical] = useState(record?.vertical ? verticalChoices.find(item => item.id === record?.vertical) : isSede ? { id: 'Sedes', name: 'Sedes'}: null);
  
  const [error, setError] = useState(false);
  const nameRegex = /^(?! )[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/;
  const spaceRegex = /^(?!\s).+(?<!\s)$/gm;  
  const handlerName = (e) => {
    let value = e.target.value;
    setName(value);
    if(value.length > 49 || !value.match(/^[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/) || value.length == 0){
      setError(true);
    }else{
      setError(false);
    }
    if(isEdit) return;
    const noAccents = removeAccents(value.toLowerCase().replace(/ /g, '-'));
    const slugsend = noAccents.replace('&', 'y');
    
    !record?.slug && setSlugValue('promocion-'+slugsend);
  };
  
  useEffect(() => {
    if(record){
      setDescHab((record?.['descuento-haberes'] && record['descuento-haberes'] > 0) ? true : false);
      setValueDesc(record?.['descuento-haberes'] ? record['descuento-haberes'] : 0);
    }
  },[record]);

  const handleDesc = () => {
    if(descHab){
      setValueDesc(0);
    }
    setDescHab(!descHab);
  };


  useEffect(() => {
    if(descHab) {
      setEditPrice(true);
    } else {
      setEditPrice(false);
    }
  },[descHab]);

  const tipoDeCampanas = useMemo(() => {
    switch (true) {
    case getMerchant()?.id === '' :
      return [
        { key: 'central', name: 'Central'},
        { key: 'cadena-sede', name: 'Cadena Sede' },
        { key: 'cadena-habilitadas', name: 'Cadena Sedes habilitadas' },
        { key: 'local', name: 'Local'}
      ];
    case getMerchant()?.negocio_central :
      return [
        { key: 'central', name: 'Central'  },
      ];
    default:
      return [
        {key: 'local', name: 'Local'},
        {key: 'cadena-habilitadas', name: 'Cadena Sedes habilitadas',disabled: true },

      ];
    }
  },[getMerchant()?.id]);


  const campanasMerchs = useMemo(() => {
    let arr = merchantsInToken.filter(item => item.id !== '').reduce((a, b, index) => {
      switch(true) {
      case ['local', 'cadena-sede'].includes(selectType):
        if(!b.negocio_central) return [...a, b];
        return [...a];
      case ['cadena-habilitadas', 'central'].includes(selectType):
        setSelectedMerch && setSelectedMerch(null);
        return [];
      default:
        return [];
      }
    },[]);
    return arr;
  },[selectType]);

  const typeHandler = (e) => {
    let value = e.target.value;
    setSelectType(value);
    if(!getMerchant()?.negocio_central && getMerchant()?.id !== '') {
      setSelectedMerch(getMerchant()?.id);
    }
    if(value !== 'central') return setVertical({id: 'Sedes', name: 'Sedes'});
    return setVertical(null);
  };

  const merchHandler = (e,v) => {
    setSelectedMerch(e);
    setMerchant(campanasMerchs?.find(item => e === item.id));
  };

  const data = [
    {
      source: 'name',
      label: 'Nombre', 
      onChange: handlerName,
      // checkOnblur: handleSlugValidation,
      validate: {
        required: 'Falta el campo Nombre en Información', 
        maxLength: {value: 50, message: 'El nombre no puede superar los 50 caracteres.'},
        pattern: {
          value:  nameRegex && spaceRegex,
          message: 'No puede contener caracteres especiales, ni empezar ni terminar con espacios.'
        }, 
      },
      // style: !isEdit ? {display: 'none'}: '',
      initialValue: name,
      xs: 12
    },
    {
      source: 'slug', 
      label: 'Slug',
      // checkOnblur: handleSlugValidation,
      initialValue: slugValue,
      defaultValue: slugValue,
      value: slugValue,
      dependantValue: slugValue,
      disabled: true, 
      xs: isSede ? 12 : 6
    },
    !isSede && {source: 'email-contacto', 
      label: 'Email', 
      validate: {
        pattern: { 
          value: /\S+@\S+\.\S+/, 
          message: 'No es un email valido'
        }}, 
      xs: 6},
    {
      source: 'tipo',
      label: 'Tipo de campaña',
      type: 'select',
      optionValue: 'key',
      optionLabel: 'name',
      disabled: record?.tipo || isSede,
      choices: tipoDeCampanas,
      defaultValue: record?.tipo ? record?.tipo : isSede ? {key: 'local', name: 'local'} : null,
      onChange: typeHandler,
      xs: 12
    },
    {
      source: 'merchant_id',
      label: 'Negocio',
      value: merchant,
      type: 'autocomplete',
      saveValue: 'id',
      displayNone: !['cadena-sede', 'local'].includes(selectType),
      optionValue: 'id',
      optionLabel: 'name',
      disabled: record?.merchant_id,
      choices: campanasMerchs,
      initialValue: isSede ? getMerchant()?.id : null,
      validate: {
        required: (['cadena-sede', 'local'].includes(selectType) && !merchant) ? 'El negocio es requerido' : undefined
      },
      onChange: merchHandler,
      xs: 12
    },
    {
      source: 'vertical',
      value: vertical,
      style: {
        display: ([merchantCadena, ''].includes(getMerchant()?.id) || getMerchant()?.negocio_central) ? 'block' : 'none'
      },
      label: 'Vertical',
      type: 'autocomplete',
      defaultValue: (![merchantCadena, ''].includes(getMerchant()?.id) && !getMerchant()?.negocio_central) ? verticalChoices.find(item => item.name === 'Sedes').id : null,
      choices: verticalChoices,
      optionLabel: 'name',
      optionValue: 'id',
      onChange: (e, v) => setVertical(verticalChoices.find(item => item.id === e)),
      initialValue: record?.vertical ? verticalChoices.find(item => item.id === record?.vertical ? item : '') : isSede ? {id: 'Sedes', name: 'Sedes'} : null,
      saveValue: 'id',
      disabled: selectType !== 'central',
      validate: {
        required: 'Falta el campo Vertical en Información',
        validate: () => !vertical ? 'Falta el campo Vertical en Información' : undefined
      }, 
      xs: 12
    },
    {
      source: 'tiene_padre',
      label: 'Es parte de una agrupación de Campaña',
      type: 'boolean',
      labelPlacement: 'start',
      onChange: () => setHasDad(!hasDad),
      value: hasDad,
      style: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      xs: 6,
    },
    {
      source: 'campana_padre',
      label: 'Agrupacion',
      style: hasDad ? {display:'block'} : {visibility: 'hidden'},
      xs: 4
    },
    !isSede && {
      custom: true,
      component:   
      <Tooltip placement={'right'} title={`${!editHaberes ? 'No se puede editar el descuento por haberes a menos que el descuento por empleado sea del 100%' : ''}`}>
        <FormGroup>
          <FormControlLabel
            labelPlacement='start'
            control={<Switch checked={descHab} onChange={handleDesc}/>} 
            label="Descontado por haberes"
            style={{marginLeft: '0px', display: 'flex', justifyContent: 'flex-end'}}
            disabled={!editHaberes}
          />
        </FormGroup>
      </Tooltip>,
      xs: 6
    },
    {
      source: 'descuento-haberes',
      label: 'Descuento por haberes',
      type: 'number',
      variant: 'outlined',
      defaultValue: 0,
      onChange: e => {setValueDesc(e.target.value !== '' ? e.target.value : 0);},
      value: valueDesc,
      toNumber: true,
      style: !descHab ? {display:'none'} : {}
    }
  ];

  return data;
};

export default informationEdit;