import { Box } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { BsCardImage } from 'react-icons/bs';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import ImageComponent from '../../../Components/UI/Show/ImageComponent';
import imagesEdit from '../SectionsEdit/ImagesEdit';

const ImagesCard = () => {

  const record = useRecordContext()

  const editComponent = {
    component: imagesEdit(),
    label: 'Imagenes',
    icon: <BsCardImage/>
  }

  return (
    <CardBasic title='Imagenes' record={record} editComponent={editComponent} route={'corporativo'}>
      <Box style={{display: 'flex', gap: '20px'}}>
        <ImageComponent small item={record.hero}/>
        <ImageComponent small item={record['hero-1000x700']}/>
        <ImageComponent small item={record['hero-1280x523']}/>
      </Box>
    </CardBasic>
  );
};

export default ImagesCard;