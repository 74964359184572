import { Box, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import DotDivider from '../../../Components/UI/DotDivider';
import { fontTextInfoKey, fontTitleHeader, iconsChips, styleChip, styleSpanPlan } from '../../../Theme/useStyle';

import CardBasic from '../../../Components/UI/Show/CardBasic';
import { BsClockHistory } from 'react-icons/bs';
import { dataHorarios } from '../SedeCreateEdit';
import { getPermissions } from '../../helpers/helpFunctions';



const HorariosCard = () => {
  const record = useRecordContext();
  const permis = getPermissions();
    
  const editComponent = {
    component: dataHorarios,
    label: 'Horarios',
    icon: <BsClockHistory/>
  };

  return (
    <>
      <CardBasic title="Horarios" record={record} editComponent={permis !== 'operador' && editComponent} route="sedes">
        <Box style={{display: 'flex', gap: '10px', marginTop: '8px'}}>
          <Typography style={fontTextInfoKey}>
              De Lunes a Viernes: <span style={styleSpanPlan}>{record['horario-l-a-v']}</span>
          </Typography>
          <DotDivider margin='0 5px'/>
          <Typography style={fontTextInfoKey}>
              Sabados: <span style={styleSpanPlan}>{record['horario-sabado']}</span>
          </Typography>
          <DotDivider margin='0 5px'/>
          <Typography style={fontTextInfoKey}>
              Domingos y Feriados: <span style={styleSpanPlan}>{record['horario-domingo-y-feriados']}</span>
          </Typography>
        </Box>
      </CardBasic>
    </>
  );
};

export default HorariosCard;