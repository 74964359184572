import { PlanList } from './PlanList';
import StoreIcon from '@mui/icons-material/Store';
import PlanEdit from './PlanEdit';
import ShowWrapper from './ShowWrapper';
import PlanCreate from './PlanCreate';
import PlanesShow from './PlanesShow';
import { CgGym } from 'react-icons/cg';

export default{
  icon: CgGym,
  list: PlanList,
  show: ShowWrapper,
  create: PlanCreate
};