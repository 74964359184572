import { Datagrid, TextField, NumberField, List, TextInput, ReferenceField, downloadCSV, SelectInput, EditButton, ReferenceInput, AutocompleteInput, useDataProvider, useGetList, useRedirect, SingleFieldList, TopToolbar, ExportButton, FilterButton, CreateButton, BooleanInput, useListController, usePermissions } from 'react-admin';
import OpenLink from '../../Components/CustomFields/OpenLink';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import rowStylePlans from '../planes/rowStylePlans';
import { useContext, useEffect, useMemo, useState } from 'react';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import { createNotification, getToken, roles } from '../helpers/helpFunctions';
import { apiUrl, merchantCadena, merchantCentral, merchantContext, merchantSelected } from '../../DataProvider';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { Box, Typography } from '@mui/material';
import { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import axios from 'axios';
import { PlanesNavBar } from '../planes/PlanesNavBar';

export const CorporativoList = props => {
  const dataProvider = useDataProvider();
  const [verticales, setVerticales] = useState([]);
  const [campanas, setCampanas] = useState([]);
  const canView = roles.slice(0,2).includes(props.permissions);
  const redirect = useRedirect();
  const { getMerchant } = useContext(merchantContext);
  const [tab, setTab] = useState('locales');
  const { permissions: permis } = usePermissions();

  const { setFilters } = useListController();

  const handleClickCreate = () => {
    redirect('/corporativo/create');
  };
  
  useEffect(() => {
    const params = {
      pagination: {page: 1, perPage: 9999},
      sort: {field: 'name', order: 'ASC'},
      filter: {}
    };
    dataProvider.getList('distinct/corporativo/vertical', params).then(e => {
      setVerticales(e.data.map((i) => ({'id': i.id, 'value': i.name, name: i.name.split('_').join(' ')})));
    });
    dataProvider.getList('/distinct/corporativo/campana_padre', params).then(e => {
      e.data.shift();
      setCampanas(e.data.map((i) => ({'id':i.id, 'value':i.name, name: i.name.split('_').join(' ')})));
    });
  }, []);

  useEffect(() => {
    if(getMerchant()?.id === merchantCadena) return setTab('multinegocio');
    if(merchantCentral.find(merch => merch?.id === getMerchant()?.id)) return setTab('centrales');
    if(!merchantCentral.find(merch => merch?.id === getMerchant()?.id)) return setTab('locales');
  }, [getMerchant()?.id]);

  useEffect(() => {
    tab === 'centrales' ?
      setFilters({['tipo']: 'central', archivado: false}) :
      tab === 'multinegocio' ?
        setFilters({['tipo']: 'cadena', archivado: false}) :
        tab === 'locales' ? 
          setFilters({['tipo']: 'local', archivado: false}) : 
          setFilters({archivado: false});
  },[tab]);

  
  const corpFilters = [
    <TextInputIcon
      source="q" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
    <AutocompleteInput 
      source="vertical" 
      resettable 
      emptyText="Todos" 
      optionValue='value' 
      optionText="name" 
      label="Vertical" 
      variant='outlined' 
      choices={verticales}
      matchSuggestion={(filter, choices) => {
        const normalize = choices.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (normalize.toLowerCase().includes(filter.toLowerCase()));
      }}
    />,
    <AutocompleteInput 
      source="campana_padre" 
      emptyText="Todos" 
      label="Agrupación" 
      resettable 
      variant="outlined"
      optionValue='value' 
      optionText="name" 
      suggestionLimit={50} 
      choices={campanas}
      matchSuggestion={(filter, choices) => {
        const normalize = choices.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (normalize.toLowerCase().includes(filter.toLowerCase()));
      }}
    />,
    <BooleanInput 
      source='archivado' 
      alwaysOn 
      label={'Campañas Archivadas'} 
      defaultValue={false}
    />
  ];

  const isDevelop = process.env.REACT_APP_TEST === 'true';
  const linksPrepend = useMemo(() => {
    switch(true){
    case ['locales', 'multinegocio'].includes(tab):
      return [
        {
          label: 'Ir al checkout',
          link: isDevelop ? `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/` : `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`,
          viewContext: true
        },
        {
          label: 'Ver Socios',
          redirect: true,
          filter: 'plan_corporativo'
        }
      ];
    default:
      return [
        {
          label: 'Ir al checkout',
          link: `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`,
          viewContext: true
        },
        {
          label: 'Ir a la landing',
          link: `${process.env.REACT_APP_SPORTCLUB_LANDING}/corporativo/`,
        },
        {
          label: 'Ver Socios',
          redirect: true,
          filter: 'plan_corporativo'
        }
      ];
    }
  });

  const isSede = getMerchant()?.id !== '' && !getMerchant()?.negocio_central;

  return (
    <>
      {((getToken()?.brand_id === '6328afb359d052e6817c2200') || (permis === 'superadmin')) ? (
        <PlanesNavBar setFilters={setFilters} selected={tab} setSelected={setTab}/>
      ) : null }
      <List 
        {...props}
        bulkActionButtons={false} 
        filters={corpFilters} 
        // exporter={exporter} 
        actions={<ListActions/>}
        perPage={25}
        filterDefaultValues={{ archivado : false}}
        empty={false}
      >
        <DatagridCustom
          emptyComponent={
            <Box textAlign="start" height="auto" m={3}>
              <Typography variant="h7" paragraph>
               No existen campañas
              </Typography>
            </Box>
          } rowClick='show' rowStyle={rowStylePlans(props.selectedRow)}>
          <TextField source="name" label='Nombre'/>
          {getMerchant()?.id === '' && <TextField source="merchant"/>}
          <NumberField source="porcentaje-descuento-empleado" label='Porcentaje de Descuento'/>
          {!isSede && <TextField source="vertical" />}
          <TextField source="campana_padre" label='Agrupacion'/>
          {tab !== 'locales' && <TextField source="merchant" label='Negocio'/>}
          <NumberField source="socios" label="Socios Activos"/>
          <OpenLink 
            source="slug" 
            label='' 
            cta="Checkout" 
            prepend={linksPrepend}
          />
        </DatagridCustom>
      </List>
    </>

  );
};


export const ListActions = () => {
  const [open, setOpen] = useState(false);
  const { getMerchant } = useContext(merchantContext);

  return(

    <TopToolbar style={{minWidth:'324px', paddingTop: '10px'}}>
      <Box style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        <FilterButton disableSaveQuery/>
        <CreateButton className='create'/>
        <Button startIcon={<DownloadIcon />} style={{textTransform: 'uppercase', padding: '10px'}} onClick={() => setOpen(true)}>
        exportar
        </Button>
        {open && (
          <CustomReportFilters
            style={{width: '200px'}}
            text={'Campañas'}
            open={open}
            setOpen={setOpen}
            viewVerticales
            viewMerchants
            variant={'outlined'}
            title={'Reporte de Campañas'} 
            noFilter downloadText={'Campañas'}
            url={'corporativo_pro'} 
          />
        )
        }
      </Box>
    </TopToolbar>
  );
};
