import React, { useContext, useMemo, useState }from 'react';
import { BooleanInput, TextInput, useRecordContext } from 'react-admin';
import { getPermissions } from '../helpers/helpFunctions';
import InformationEdit from './SectionsEdit/InformationEdit';
import PlanEdit from './SectionsEdit/PlanEdit';
import DiscountsEdit from './SectionsEdit/DiscountsEdit';
import imagesEdit from './SectionsEdit/ImagesEdit';
import SectionForm from '../../Components/Form/SectionForm';
import { BsCardText } from 'react-icons/bs';
import { CgGym } from 'react-icons/cg';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { BsQuestionCircle } from 'react-icons/bs';
import { BsCardImage } from 'react-icons/bs';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import CreateForm from '../../Components/Form/CreateForm';
import informationEdit from './SectionsEdit/InformationEdit';
import planEdit from './SectionsEdit/PlanEdit';
import { merchantContext, merchantsInToken } from '../../DataProvider';


const CorporativoCreateEdit = ({isEdit, ...props}) => {
  const record = useRecordContext();
  const [slugValue, setSlugValue] = useState('');
  const { getMerchant } = useContext(merchantContext);
  const [selectedMerch, setSelectedMerch] = useState(
    merchantsInToken.length === 1 ?
      merchantsInToken[0].id :
      getMerchant()?.id !== '' && !getMerchant()?.negocio_central ?
        getMerchant()?.id : null
  );
  const [editHaberes, setEditHaberes] = useState(isEdit && (record?.['porcentaje-descuento-empleado'] === 100) ? true : false);
  const [editPrice, setEditPrice] = useState(null);
  const [selectType, setSelectType] = useState(record?.tipo ?? null);

  const negocioSede = useMemo(() => {
    return !getMerchant()?.negocio_central && Boolean(getMerchant()?.sede_principal);
  }, [getMerchant, selectType]);
  console.log(selectedMerch);
  const tabs = !negocioSede ? [
    {
      component: informationEdit(
        setSlugValue, 
        slugValue, 
        setEditPrice, 
        editHaberes, 
        setSelectType, 
        selectType, 
        setSelectedMerch,
        selectedMerch
      ),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: planEdit(
        slugValue, 
        editPrice, 
        setEditHaberes,
        null,
        selectType,
        selectedMerch
      ),
      label: 'Plan',
      icon: <CgGym/>
    },
    {
      component: dataQuestions(),
      label: 'Aclaraciones',
      icon: <BsQuestionCircle/>
    },
    {
      component: imagesEdit(),
      label: 'Imagenes',
      icon: <BsCardImage/>
    }
  ] : [
    {
      component: informationEdit(
        setSlugValue, 
        slugValue, 
        setEditPrice, 
        editHaberes, 
        setSelectType, 
        selectType, 
        setSelectedMerch
      ),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: planEdit(
        slugValue, 
        editPrice, 
        setEditHaberes,
        null,
        selectType
      ),
      label: 'Plan',
      icon: <CgGym/>
    },
  ];

  return (
    <CreateForm tabs={tabs} route={'corporativo'} />
  );
};

export const dataQuestions = ()  => {
  const record = useRecordContext();
  
  const data = [
    {
      source: 'pregunta-sobre-debito',
      label: 'Aclaracion principal',
      multiline: true,
      resettable: true,
      xs: 12
    },
    {
      source: 'pregunta-sobre-pago',
      label: 'Aclaración sobre el precio',
      multiline: true,
      resettable: true,
      xs: 12
    },
    {
      source: 'descripcion',
      label: 'Aclaración bajo el botón “Asociate”',
      multiline: true,
      resettable: true,
      xs: 12
    },
    {
      source: 'aclaraciones',
      title: 'Activar / Desactivar aclaraciones',
      defaultChecked: record?.aclaraciones,
      type: 'boolean'
    }
  ];
  
  return data;
}; 



export default CorporativoCreateEdit;