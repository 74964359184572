import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import useCorpContext from './useCorpContext';
import { CorpContext } from './ContextCorp';
import { useQuery } from 'react-query';
import { createNotification } from '../../helpers/helpFunctions';
import { Autocomplete, Box, FormControl, IconButton, Popover, Skeleton, Switch, TextField, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { grey } from '@mui/material/colors';
import { DispatchErrorContext } from '../../../Context/menuContext';


export const PlanCorporativo = ({
  setCorporativo, 
  merchantId,
  corporativo,
  bool, 
  setBool, 
  selectedPlan
}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [corps, setCorps] = useState([]);
  const  { setDisableSaveButton } = useContext(DispatchErrorContext);
  const refetchContext = useCorpContext(corporativo?.plan_id, () => setDisableSaveButton(false));
  const corpContext = useContext(CorpContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const openMenu = Boolean(anchorEl);
  
  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
    
  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {merchant_id: merchantId}
  };
  
  const { refetch: refetchCorp } = useQuery(
    ['corporativos'],
    () => dataProvider.getList('corporativo', params),
    {
      onSuccess: ({data}) => {
        setCorps(data);
        let corp = data?.find(item => item.id === record?.plan_corporativo);
        corp && setCorporativo(corp);
      },
      enabled: Boolean(merchantId)
    },
  );
  
  useEffect(() => {
    merchantId && refetchCorp();
  },[merchantId]);
  
  
  const handleChange = (e) => {
    setDisableSaveButton(true);

    setCorporativo(e);
    // corps?.map((item) => {
    //   if(item.plan_id !== record?.active_plan_id){
    //     console.log(item);
    //   }
    // });
  };
  
  useEffect(() => {
    corporativo && refetchContext();
  },[corporativo]);
  
  useEffect(() => {
    if(!corpContext.back) {
      let corp = corps?.find(item => item.id === record?.plan_corporativo);
      setCorporativo(corp);
      createNotification('warning', 'El plan seleccionado no acepta el método de pago del socio.');
    }
    return () => {
      corpContext.getBack(true);
    };
  },[corpContext.back]);
  
  
  const handleSetCorp = () => {
    setBool(!bool);
  };
  
  return (
    <>
      <Box 
        style={{
          margin: '10px 0',
          borderBottom: '1px solid #cfd8dc',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <Typography 
          style={{ 
            color: '#9ea7aa',
            fontWeight: 'normal', 
            display: 'flex'
          }}>Campaña</Typography>
        <FormControl>
          <Switch
            onChange={handleSetCorp}
            disabled={record?.active_plan_id !== selectedPlan}
            defaultChecked={record?.plan_corporativo}
          />
        </FormControl>
        <>
          <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
            <HelpOutlineIcon sx={{fontSize: '21px'}} />
          </IconButton>
          <Popover
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <Box sx={{
              maxWidth: '320px',
              backgroundColor: '#fff',
              padding: '15px 18px'
            }}>
              <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}> Si se desea cambiar el plan corporativo, el plan del socio se ajustara al plan nativo de la campaña. <br /> Al deshabilitar el plan corporativo, podras seleccionar el plan nativo del socio.</Typography>
            </Box>
          </Popover>
        </>
      </Box>
  
      {
        bool && (
            
          corps?.length > 0 ? (
            <>
              <Autocomplete 
                source='plan_corporativo' 
                label='Plan'
                onChange={(e,v) => handleChange(v)}
                value={corporativo}
                options={corps}
                getOptionLabel={(option) => option.name}
                variant='outlined'
                fullWidth
                renderInput={(params, i) => <TextField variant={'outlined'} {...params} key={i} />}
              />
            </>
          ) : (
            <Skeleton height="50px" />
          )
            
        )
      }
    </>
  );
};
  