import React from 'react';
import { Create } from 'react-admin';
import ActividadesCreateEdit from './ActividadesCreateEdit';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';

const ActividadesCreate = (props) => {
  return (
    <Create 
      {...props} 
      title={<TitleEdit title='Crear Actividad '/>} 
    >
      <ActividadesCreateEdit/>
    </Create>
  );
};

export default ActividadesCreate;
