import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Datagrid, List, EmailField, TextField, DateField, TextInput, SelectInput, FunctionField, ReferenceArrayInput, useDataProvider, TopToolbar, FilterButton, Link, useRecordContext } from 'react-admin';
import { AutocompleteArrayInputSmall } from '../../Components/CustomFields/InputSmall';
import OpenLink from '../../Components/CustomFields/OpenLink';
import WhatsappField from '../../Components/CustomFields/WhatsappField';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import DoubleField from '../../Components/ListComponents/DoubleField';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { STATUS } from '../../Models/Boletas';
import CustomReport, { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import { isUserSC } from '../helpers/helpFunctions';
import DownloadIcon from '@mui/icons-material/Download';
import { MdEmail } from 'react-icons/md';
import rowStyle from './rowStyle';
import { Box, Button, IconButton } from '@mui/material';
import { theme } from '../../Theme/theme';

const useStyles = makeStyles(theme => ({
  cellWidthSmall: {
    width: '165px',
  }
}));

export const LeadList = props => {
  const [verticales, setVerticales] = useState([]);
  const [corps, setCorps] = useState([]);
  const dataProvider = useDataProvider();
  const permis = localStorage.getItem('permissions');
  const noOpeAcc = permis !== 'operador' && permis !== 'accesos';
  
  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'ASC'},
    filter: {}
  };  
  useEffect(() => {
    noOpeAcc && dataProvider.getList('distinct/corporativo/vertical', params).then(e => {
      setVerticales(e?.data);
    });
    dataProvider.getList('corporativo', params).then(({data}) => setCorps(data));
  }, []);
  
  const defaultSort = { field: 'date_last_abandoned', order: 'DESC' };

  const clientFilters = [
    <TextInputIcon
      source="q" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
    <SelectInput source="payment_status" label="Estado de pago" emptyText='Todos' allowEmpty   variant='outlined'
      choices={[
        { id: 'approved', name: 'Aprobado' },
        { id: 'in_process', name: 'En Proceso' },
        { id: 'abandoned', name: 'Abandono' },
        { id: 'rejected', name: 'Rechazado' }
      ]} />,
    <SelectInput optionValue={'name'} source="vertical" emptyText='Todos' allowEmpty  variant='outlined'
      choices={verticales} />,
    <AutocompleteArrayInputSmall
      label="Empresas" 
      variant='outlined'
      source="corporativo"
      size='small'
      suggestionLimit={55}
      resettable
      choices={corps}
      matchSuggestion={(filter, choices) => {
        const normalize = choices.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (normalize.toLowerCase().includes(filter.toLowerCase()));
      }}
    /> 
  ];

  const defaultFilters =[
    <TextInputIcon
      source="q" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
    <SelectInput source="payment_status" label="Estado de pago" emptyText='Todos' allowEmpty  variant='outlined'
      choices={[
        { id: 'approved', name: 'Aprobado' },
        { id: 'in_process', name: 'En Proceso' },
        { id: 'abandoned', name: 'Abandono' },
        { id: 'rejected', name: 'Rechazado' }
      ]} />,
    <SelectInput source="vertical" emptyText='Todos' allowEmpty  variant='outlined'
      choices={verticales} />,
  ];

  const classes = useStyles();

  return(
    <List 
      bulkActionButtons={false} 
      filters={isUserSC(permis) ? clientFilters : defaultFilters} 
      perPage={25} 
      sort={defaultSort}
      exporter={false}
      actions={<ListActions />}
      {...props}
      empty={false}
    >
      <DatagridCustom rowStyle={rowStyle(props.selectedRow)} optimized>
        <DoubleField label='Nombre' title={['first_name', 'last_name']} subtitle='documento'/>
        <DateField source="date_last_abandoned" label="Intento de Compra" headerClassName={classes.cellWidthSmall}/>
        <TextField label="Plan Activo" source="plan" />
        <TextField 
          source="corporativo"
          label='Campaña' 
          style={{ 
            display: 'inline-flex',
            height: '40px', 
            maxWidth: '20em',
            overflowY: 'hidden', 
            textOverflow: 'ellipsis',
          }}
        />
        <TextField source="vertical" label='Vertical'/>

        {/* <FunctionField label="Detalle" render={(record) => record?.instances?.[record?.instances?.length - 1]?.status_detail} /> */}
        
        <FunctionField label='Estado' render={(record) => STATUS[record.payment_status]} />
        <FunctionField label='Contacto' render={(record) => (
          <Box style={{width: '100%', display: 'flex', textAlign: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              
            <Link to={`mailto:${record.email}`}>
              <IconButton style={{borderRadius: '50%'}}>
                <MdEmail color={theme.palette.primary.main} fontSize={'20px'} />
              </IconButton>
            </Link>
            <WhatsappField source="celular" label="Whatsapp"/>
          </Box>
        )} />
        <OpenLink 
          source={null} 
          label=""
          prepend={[
            {label: 'Ir al checkout', link: `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`, viewContext: true}
          ]} 
        />
      </DatagridCustom>
    </List>
  );
};

export const ListActions = () => {

  const permis = localStorage.getItem('permissions');
  return(

    <TopToolbar style={{minWidth:'324px', paddingTop: '10px'}}>
      <Box style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        {/* <ShowButton onClick={() => handleBajasFilter()} label="Bajas con vigencia" /> */}
        {(permis !== 'operador' || permis !== 'accesos') && <FilterButton disableSaveQuery/>}
        <CustomFilter />
      </Box>
    </TopToolbar>
  );
};

export const CustomFilter = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button startIcon={<DownloadIcon />} style={{textTransform: 'uppercase', padding: '10px'}} onClick={() => setOpen(true)}>
  exportar
      </Button>
      <CustomReportFilters style={{width: '200px'}} text={'Prospectos'} open={open} setOpen={setOpen} variant={'outlined'} showDate viewMerchants viewProspects title={'Reporte de Prospectos'} downloadText={'prospectos'} url={'abandoned_pro'} />
    </>
  );
};