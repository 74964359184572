import React, { useEffect, useState } from 'react';
import { CardMedia, Box , ImageField, TextField, Typography, Button} from '@mui/material';
import { TextInput, useRecordContext } from 'react-admin';
import ButtonAws from './ButtonAws';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddToDriveOutlined } from '@mui/icons-material';
// import { useForm } from 'react-final-form';


// const CardAws = ({text, disabledImage, sx, small, defaultUrl, source }) => {
const cardAwsArray = (text, source, disabledImage, sx, small, defaultUrl) => {
  const record = useRecordContext();
  const hasRecord = record?.[source.slice(0,-4)];
  const [plus, setPlus] = useState(0);
  const [heroUrl, setHeroUrl] = useState(record?.['hero-image']?.url ?? '');
  const [index, setIndex] = useState(record?.['multi-imagen'] ? record['multi-imagen'].length : 0);
  const initalUrls = record?.['multi-imagen'] ? record?.['multi-imagen'] : defaultUrl ? [defaultUrl] : [];
  const [count, setCount] = useState([]);
  const [urls, setUrls] = useState(record?.['multi-imagen'] ?? []);

  const removeItem = (num) => {
    const newCountArr = [...count];
    const newUrlsArr = [...urls];

    newCountArr.splice(num, 1);
    newUrlsArr.map((url, i) => {
      if(i === num) {
        newUrlsArr.splice(i, 1);
      }
    });
    setCount(newCountArr);
    setUrls(newUrlsArr);
    setPlus(plus-1);

  };
  useEffect(() => {
    if(record?.['multi-imagen']){

      for(let i = 0; i < record?.['multi-imagen'].length; i++){
        let addedItem =  (
          <Box style={{margin: '10px'}}>
            <AwsButton index={i} removeItem={removeItem} setIndex={setIndex} count={count} setCount={setCount} setUrls={setUrls} sx={sx} disabledImage={disabledImage} text={'Subir imagen'} small={small} urls={urls} />
          </Box>
        );
        let obj = {key: i, value: addedItem, url: record['multi-imagen'][i].url};
        setCount((f) => ([...f, obj]));
      }
    }
    return() => {
      setCount([]);
    };
  },[]);
  const handleAddItems = (i) => {
    const addedItem =  (
      <Box style={{margin: '10px'}}>

        <AwsButton index={i} removeItem={removeItem} setIndex={setIndex} count={count} setCount={setCount} setUrls={setUrls} sx={sx} disabledImage={disabledImage} text={'Subir imagen'} small={small} urls={urls} />
      </Box>
    );
    setCount([...count, {key: i, value: addedItem}]);
    setPlus(i+1);
  };

  const handleHeroUrl = (url) => {
    setHeroUrl(url);
  };

  const data = [
    {
      custom: true,
      component: 
      <>
        <Box style={{width: '100%'}}>
          <Box sx={sx}>
            <ButtonAws setUrl={handleHeroUrl} text={text} small={small} />
            <Box style={{width: '100%', minWidth: '300px', display: 'flex', flexDirection: 'row',  alignItems: 'center', justifyContent: 'space-between'}}>
              <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start'}}>
                {!disabledImage &&
            <Box sx={{ padding: '20px 20px 20px 0', width: '350px' }}>
              { 
                heroUrl?.length > 0 ? 
                  <CardMedia component='img' height='190' image={heroUrl} /> : null
                // <ImageField source={`${source}`} title="title"/> 
              
              }
          
            </Box>
                }

                { heroUrl?.length > 0 &&
          <>
            <TextField 
              variant='outlined' disabled initialValue={heroUrl}
              value={heroUrl}
            />
          </>
                }
              </Box>
              {heroUrl !== '' && 
                <Button onClick={() => setHeroUrl('')}>remove</Button>
              }
            </Box>
          </Box>
          <Box style={{borderBottom:'1px solid grey', width: '100%', padding: '10px 0'}} />
          {count !== 0 && count.map((item, i) => (
            <>
              <Box style={{width: '100%', minWidth: '300px', display: 'flex', flexDirection: 'row',  alignItems: 'center', justifyContent: 'space-between'}}>
                <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start'}}>
                  {item.value}
                  {!disabledImage && 
                    <Box sx={{ padding: '20px 20px 20px 0', width: '350px' }}>
                      { 
                        count[i]?.url?.length > 0 ? (
                          <CardMedia component='img' height='190' image={count[i]?.url} /> 
                        ) : null
                        // <ImageField source={`${source}`} title="title"/> 
                      }
                    </Box>
                  }
                  { count[i]?.url?.length > 0 ? (
                    <TextField variant='outlined' disabled initialValue={count[i]?.url}
                      value={count[i].url} /> 
                
                  ) : null
                  }
                </Box>
                <Button onClick={() => removeItem(i)}>remove</Button>
              </Box>
            </>
          ))}
        
          <Button
            onClick={() => handleAddItems(plus)}
            startIcon={<AddCircleOutlineIcon />}
          >
        Añadir
          </Button >
        </Box>
        {/* <AwsButton sx={sx} handleUrl={handleUrl} disabledImage={disabledImage} text={text} small={small} url={url} /> */}
      </>
    },
    {
      source: 'multi-imagen',
      displayNone: true,
      value: urls
    },
    {
      source: 'hero-image.url',
      displayNone: true,
      value: heroUrl
    }
  ];

  return data;
};

export default cardAwsArray;

export const AwsButton = ({sx, setIndex, setUrls, count, index, setCount, disabledImage, text, small, urls, removeItem}) => {

  const [url, setUrl] = useState('');

  const handleUrl = (url) => {
    setUrl(url);
    setIndex(index);
    setUrls([...urls, {url: url}]);
    setCount([...count, {...count[index], url: url}]);
    // form.change(source, url);
  };
  return (
    <>
      <ButtonAws setUrl={handleUrl} text={text} small={small ?? 'small'} />
     
    </>
  );
};