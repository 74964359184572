import ModalCustom from '../../../Components/UI/ModalCustom';
import { useContext, useEffect, useState } from 'react';
import CustomReport from './CustomReport';
import { usePermissions } from 'react-admin';
import { ButtonReport, CobrosCustomReport } from './ButtonsReport';
import { merchantCadena, merchantCentral, merchantContext, merchantSelected } from '../../../DataProvider';
import { Box } from '@mui/material';
import StatusSocioModal from './StatusSocioModal';


const ModalReportes = ({open, setOpen, selectedDate, onClose, notDisabledMerchs}) => {
  const [openStatus, setOpenStatus] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  const [date, setDate] = useState(
    {
      from: selectedDate.from,
      until: selectedDate.until
    }
  );

  const [newFilter, setNewFilter] = useState('');
  useEffect(() => {
    setDate({from: selectedDate.from, until: selectedDate.until});
  },[selectedDate]);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  // Eliminar toda esta logica que involucra hardcodear ids de cadena
  const isCadena = getMerchant().id === merchantCadena;

  return(
    <>
      <ModalCustom
        open={open}
        setOpen={setOpen}
        onClose={() => handleClose()}
        title={'Reportes'}
      >
        <Box style={{width: '500px', height: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '20px', gap: '10px'}}>
          <StatusSocioModal open={openStatus} setOpen={setOpenStatus} />
          <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Planes'} variant={'outlined'} viewMerchants disabledMerchs={notDisabledMerchs ?? true} viewPlanes viewPlanAccess title={'Reporte de Planes'} downloadText={'planes'} url={'planes_pro'} />
          <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Prospectos'} variant={'outlined'} viewMerchants disabledMerchs={notDisabledMerchs ?? true} selectedDate={date} showDate viewProspects title={'Reporte de Prospectos'} downloadText={'prospectos'} url={'abandoned_pro'} />
          <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Accesos'} variant={'outlined'} viewDocumento selectedDate={date} showDate viewSedes viewPlanAccess title={'Reporte de Accesos'} downloadText={'accesos'} url={'accesos_metric_pro'} />
          <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Socios'} variant={'outlined'} viewMerchants disabledMerchs={notDisabledMerchs ?? true} viewCobros viewPlanes viewPlanAccess viewCorporativos viewSocios title={'Reporte de Socios'} downloadText={'socios'} url={'socios_activos_pro'} />
          {/* <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Cobros Adicionales'} showDate selectedDate={date} variant={'outlined'} viewMerchants disabledMerchs={notDisabledMerchs ?? true} title={'Reporte de Cobros Adicionales'} url={'reporte_cobros_adicionales'} /> */}

          {
            (getMerchant()?.id === '' || merchantSelected?.negocio_central) && (
              <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Sedes'} variant={'outlined'} viewSedes viewCategoria title={'Reporte de Sedes'} downloadText={'Reporte de Sedes'} url={'sedes_pro'} />
            )
          }
          {isCadena && <CustomReport style={{width: '200px', justifyContent: 'center'}} text={'Campañas'} viewVerticales variant={'outlined'} title={'Reporte de Campañas'} downloadText={'Reporte de Campañas'} url={'corporativo_pro'} />}
          <CobrosCustomReport
            url='pagos_reporte' 
            text='Reporte Cobros'
            openModal
            viewMerch
            date={date}
            setDate={setDate}
            filter={newFilter}
            variant={'outlined'}
            setFilter={setNewFilter}
            viewDate
            style={{width: '200px', justifyContent: 'center'}}
            msgError='No existen pagos para este negocio en estas fechas'
          />
          <ButtonReport
            url={'facturacion_reporte'} 
            text='Facturas'
            filter={{'from': date.from, 'until': date.until, 'merchant': [getMerchant().id]}}
            openModal
            date={date}
            setDate={setDate}
            variant={'outlined'}
            viewDate
            style={{width: '200px', justifyContent: 'center'}}
            msgError='No existen facturas para este negocio en estas fechas'
          />
          {
            isCadena && (
              <ButtonReport
                url={'conciliacion_facturacion'} 
                text='Conciliación de Facturación'
                filter={{'from': date.from, 'until': date.until, 'merchant': [getMerchant().id]}}
                openModal
                date={date}
                setDate={setDate}
                variant={'outlined'}
                viewDate
                style={{width: '200px', justifyContent: 'center'}}
                msgError='No existen facturas para este negocio en estas fechas'
              />
            )
          }
        </Box>
      </ModalCustom>

    </>
  );
};

export default ModalReportes;