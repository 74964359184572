import { Box } from '@mui/material';
import { CardAws } from '../../../Components/CustomFields/CardAws';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';


export const imagesCardEdit = () => {
  const record = useRecordContext();
  const [horizontal, setHorizontal] = useState(record?.images.horizontal_white ? record.images.horizontal_white : 'https://muvimages.s3-us-east-2.amazonaws.com/placeholderpng.png');
  const [dark, setDark] = useState(record?.images.image_dark ? record.images.image_dark : 'https://muvimages.s3-us-east-2.amazonaws.com/placeholderpng.png');
  const [light, setLight] = useState(record?.images.image_light ? record.images.image_light : 'https://muvimages.s3-us-east-2.amazonaws.com/placeholderpng.png');
  const [mailLogo, setMailLogo] = useState(record?.images.mail_logo ? record.images.mail_logo : 'https://muvimages.s3-us-east-2.amazonaws.com/placeholderpng.png');

  const data = [
    {
      source: 'images.horizontal_white',
      custom: true,
      xs: 12,
      component: 
      <Box style={{display: 'flex', flexWrap: 'wrap'}}>
        <Box display='flex' justifyContent='flex-start'>
          <Box marginTop='10px' marginRight='30px'>
            <CardAws setUrl={(e) => setHorizontal(e)} source='images.horizontal_white' text='Horizontal white'
              url={horizontal}
            />
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-start'>
          <Box marginTop='10px' marginRight='30px' >
            <CardAws setUrl={(e) => setDark(e)} source='images.image_dark' text='Image Dark'
              url={dark}
            />
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-start'>
          <Box marginTop='10px' marginRight='30px' >
            <CardAws setUrl={(e) => setLight(e)} source='images.image_light' text='Image Light'
              url={light}
            />
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-start'>
          <Box marginTop='10px' marginRight='30px'>
            <CardAws setUrl={(e) => setMailLogo(e)} source='images.mail_logo' text='Email Logo'
              url={mailLogo}
            />
          </Box>
        </Box>
      </Box>
      
    },
    {
      source: 'images.horizontal_white',
      displayNone: true,
      value: horizontal,
      xs: 12,
    },
    {
      source: 'images.image_dark',
      displayNone: true,
      value: dark,
      xs: 12,
    },
    {
      source: 'images.image_light',
      displayNone: true,
      value: light,
      xs: 12,
    },
    {
      source: 'images.mail_logo',
      displayNone: true,
      value: mailLogo,
      xs: 12,
    }
  ];

  return data;
};