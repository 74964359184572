import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import AddIcon from '@mui/icons-material/Add';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import { useDataProvider, useRecordContext } from 'react-admin';
import { getListProvider } from '../../helpers/helpFunctions';
import { useRedirect } from 'react-admin';
import { useQuery } from 'react-query';

const cols = [
  {name: 'Nombre', key: 'socio_nombre'},
  {name: 'Sede', key: 'sede_name'},
  {name: 'Fecha', key: 'date'},
  {name: null, key: '#/accesos/'}
];

const AccessCard = () => {
  const record = useRecordContext();
  const [page, setPage] = useState(1);
  const [dataAccess, setDataAccess] = useState(null);
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const formatDataAccess = (d,t) => {
    setDataAccess(d);
    setTotal(t);
  };

  const params = {
    pagination: {page: page, perPage: 5},
    sort: {field: 'date', order: 'DESC'},
    filter: {}
  };

  const { isFetching } = useQuery(
    ['socios/accesos', {id: record?.id}, page], 
    () =>  dataProvider.getList(`socios/${record.id}/accesos`, params),
    {
      onSuccess: (e) => formatDataAccess(e.data, e.total),
      keepPreviousData: true
    }
  );

  return (
    <CardBasic 
      title='Accesos' 
      asideAction={
        <Button variant='text' size='small' style={{fontWeight: '600', fontSize: '12px'}}
          startIcon={<AddIcon style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
          onClick={()=> redirect('/accesos/create?dni='+record?.documento)}
        >
          Agregar Acceso
        </Button>
      }
    >
      <TableBasic 
        cols={cols} 
        rows={dataAccess} 
        page={page} 
        setPage={setPage} 
        total={total} 
        action={'Ver Acceso'} 
        actionShow
        loading={isFetching}
        emptyText='No hay registros de accesos para este socio'
      />
    </CardBasic>
  );
};

export default AccessCard;
