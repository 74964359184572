import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { List, Datagrid, TextField, BooleanField , TextInput, EditButton, useRedirect, ListContextProvider,
  useList, useDataProvider, useListContext, Loading, Pagination, useQuery, ListToolbar, TopToolbar, FilterButton, CreateButton, BooleanInput} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';
import OpenLink from '../../Components/CustomFields/OpenLink';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { exporter } from './exporter';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { getMerchantId } from '../helpers/helpFunctions';
import { merchantContext } from '../../DataProvider';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Box, Button } from '@mui/material';
import { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import { ReportButton } from '../../Components/ListComponents/ReportButton';

const sedeFilters = [
  <TextInputIcon
    source="q" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
  <BooleanInput 
    source='archivado' 
    alwaysOn 
    label={'Sedes Archivadas'} 
    defaultValue={false}
  />
];



export const SedeList = (props) => {
  const permis = props.permissions;
  const canCreate = (permis !== 'operador') ? true : false;
  const canEdit = (permis !== 'operador') ? 'edit' : '';
  const redirect = useRedirect();

  const handleClickCreate = () => {
    redirect('/sedes/create');
  };
  const { getMerchant } = useContext(merchantContext);

  return(
    <List
      {...props} 
      bulkActionButtons={false} 
      filters={sedeFilters} 
      hasCreate={canCreate} 
      canCreate={true}
      perPage={25}
      exporter={false}
      filterDefaultValues={{archivado: false}}
      actions={<ListActions />}
      empty={false}
    >
      <DatagridCustom defaultFilter={{archivado: false}} rowClick="show">
        <TextField source="name" />
        <TextField source="categoria" />
        <TextField source="direccion" />
        <TextField source="provincia" />
        <TextField source="zona" />
        <OpenLink source="slug" label="" prepend={[{label: 'Landing',link:`${process.env.REACT_APP_SPORTCLUB_LANDING}/sedes/`}]} />
      </DatagridCustom>
    </List>
  );
};

export const ListActions = () => (
  <TopToolbar style={{minWidth:'324px', paddingTop: '10px'}}>
    <Box style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      {/* <ShowButton onClick={() => handleBajasFilter()} label="Bajas con vigencia" /> */}
      {/* <FilterButton/> */}
      <CreateButton />
      <ReportButton
        text={'Sedes'} 
        viewSedes 
        viewCategoria
        title={'Reporte de Sedes'}
        downloadText={'sedes'}
        url={'sedes_pro'} 
      />
    </Box>
  </TopToolbar>
);