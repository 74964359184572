import { useEffect } from 'react';
import { List, Datagrid, TextField, CreateButton, TopToolbar, ExportButton, useRedirect} from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import { getPermissions, getToken } from '../helpers/helpFunctions';

const BrandsListActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <ExportButton />
  </TopToolbar>
);

const defaultFilter = [
  <TextInputIcon
    source="name" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

const BrandsList = props => {

  const redirect = useRedirect();
  const permis = getPermissions();

  useEffect(() => {
    if(permis !== 'superadmin') {
      redirect(`/brands/${getToken()?.brand_id}/show`);
    }
  },[]);

  return(
    <List 
      bulkActionButtons={false} 
      actions={<BrandsListActions/>} 
      perPage={25} 
      filters={defaultFilter}
      {...props}
    >
      <DatagridCustom rowClick='show' >
        <TextField source="name" label='Nombre'/>
        <TextField source='email_contacto' label={'Email'}/>
      </DatagridCustom>
    </List>
     
  );
};

export default BrandsList;