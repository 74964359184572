import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDataProvider, useRedirect, useRefresh } from 'react-admin';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { DispatchErrorContext } from '../../Context/menuContext';
import { merchantContext } from '../../DataProvider';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import ModalCustom from '../UI/ModalCustom';
import ConfirmModal from './ConfirmModal';
import SectionTabForm from './SectionTabForm';
import { BoxTab, TabbedForm, TabForm } from './StyledComponets.js/FormComponents';
import { ROUTE_NAMES } from './RouteNames';
import { dirtyValues } from './editSubmitFunction';


/* Este array existe para setear valores a null luego de que pasaron por el register de react hook form.
Esto ocurre porque react-hook-form no permite setear valores a null para mandarlos, solo los remueve del form, por lo que hay que crear una manera 
de hacer override al null del form */

const NULLABLE_FIELDS = ['application_fee'];

// Esta funcion basicamente encuentra alguna key guardada en el array de Nullable_Fields matchea con alguna key de form, y a su vez este valor es un string === "null", lo pasa a null.
// Estaría bueno encontrar una mejor manera, pero React-hook-form no permite esta lógica.
const convertToNull = (data, nullableFields) => {
  return Object.fromEntries(Object.keys(data).map(item => {
    const found = nullableFields.includes(item);
    if (found && data[item] === 'null') {
      return [item, null];
    }
    return [item, data[item]];
  }));

};

const FormTab = ({ 
  validateSubmit, 
  scrollable, 
  onSuccess, 
  route, 
  isEdit, 
  setOpen, 
  tabs, 
  open, 
  currentTab, 
  handleTabValid, 
  defaultValues, 
  modalConfirm, 
  transform, 
  handleCancelar,
  handleDelete ,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const methods  = useForm({
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const { getIsDisabled, dispatchField, errors: fieldErrors, getIsLoading, clearAllDispatchFields, disabledSave } = useContext(DispatchErrorContext);
  const maxTab = tabs?.length - 1;
  const isLastTab = currentTab == maxTab;
  let touchedFields = methods.formState.touchedFields;

  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const handleDateFormatter = (value) => {

    if (value?.includes('-')) {
      const date = value.split('-');
      const formattedDate = `${date[2]}/${date[1]}/${date[0]}`;
      return formattedDate;
    }
    return value;
  };

  const handleSetValues = () => {
    if (isEdit) {
      tabs[0].component.map(e => {
        if (e?.displayNone) {
          methods.setValue(e.source, e.value, {shouldTouch: true});
        }
        if (e?.type === 'date') {
          let editValue = methods.getValues(e.source);
          let customDateValue = handleDateFormatter(editValue);
          methods.setValue(e.source, customDateValue);
        }
        if (e?.dependantValue) {
          methods.setValue(e.source, e?.dependantValue);
        }
        if (e?.value) {
          if(e.saveValue) {
            methods.setValue(e.source, e?.value[e.saveValue], {shouldTouch: true});
          } else {
            methods.setValue(e.source, e?.value, {shouldTouch: true});
          }
        }
        if(e?.type === 'multiple_autocomplete'){
          let editedValue = [];
          e?.value?.map(item => {
            editedValue.push(item.id);
          });
          methods.setValue(e.source, editedValue);
        }
        if(e?.toNumber){
          let regexNumber = /^\d+(\.\d+)?$/;
          let value = methods.getValues(e?.source) ?? e?.value;
          
          if(e.value === '' || value === '' || !value) return methods.setValue(e  .source, 0);
          if(regexNumber.test(value)) {
            return methods.setValue(e.source, parseFloat(value) ?? parseFloat(e?.defaultValue));
          }
          if(regexNumber.test(e?.value)) {
            return methods.setValue(e.source, parseFloat(e?.value) ?? parseFloat(e?.defaultValue));
          }
          
        }
        if(e?.erase){
          methods.unregister(e?.source, {keepValue: false});
        }
      });
      return;
    } else {
      tabs[currentTab].component.map(e => {
        if (e?.displayNone) {
          methods.setValue(e.source, e.value);
        }
        if (e?.type === 'date') {
          let editValue = methods.getValues(e.source);
          let customDateValue = handleDateFormatter(editValue);
          console.log('customdatevalue', customDateValue);

          methods.setValue(e.source, customDateValue);
        }
        if (e?.dependantValue) {
          methods.setValue(e.source, e?.dependantValue);
        }
        if (e?.value) {
          if(e.saveValue) {
            methods.setValue(e.source, e?.value[e.saveValue], {shouldTouch: true});
          } else {
            methods.setValue(e.source, e?.value, {shouldTouch: true});
          }
        }
        if(e?.type === 'multiple_autocomplete'){
          let editedValue = [];
          e?.value?.map(item => {
            editedValue.push(item.id);
          });
          methods.setValue(e.source, editedValue);
        }
        if(e?.toNumber){
          let regexNumber = /^\d+(\.\d+)?$/;
          let value = methods.getValues(e?.source) ?? e?.value;
          if(e.value === '' || value === '' || !value) return methods.setValue(e.source, 0);
          if(regexNumber.test(value)) {
            if(!isNaN) return methods.setValue(e.source, value);
            return methods.setValue(e.source, parseFloat(value) ?? parseFloat(e?.initialValue));
          }
          if(regexNumber.test(e?.value)) {
            if(!isNaN) return methods.setValue(e.source, e?.value);
            return methods.setValue(e.source, parseFloat(e?.value) ?? parseFloat(e?.initialValue));
          }
          
        }
        if(e?.nullable) {
          methods.setValue(e?.source, 'null');
        }
        if(e?.erase){
          methods.unregister(e?.source, {keepValue: false});
        }
      });
      return;
    }
  };

  useEffect(() => {
    handleSetValues();
  }, [currentTab, tabs]);

  const callDirtyFields = (data) => {
    return dirtyValues(touchedFields, data);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    validateSubmit && validateSubmit();
    console.log(data, convertToNull(data, ['application_fee']));
    if(isEdit) {
      if(await handleValidateEdit()) {
        if(handleConfirmation(data)) {
          const socioRoute = route === 'socios';
          let newData = (socioRoute && transform) ?
            callDirtyFields(transform(data)) :
            (!socioRoute && transform) ?
              transform(data) :
              (socioRoute && !transform) ?
                callDirtyFields(data) :
                data;

          //Conver to null fields
          dataProvider.update(`${route}`, { data: convertToNull(newData, NULLABLE_FIELDS), id: defaultValues.id }, ).then(res => {

            ROUTE_NAMES.find(item => {
              if(item.key === route){
                createNotification('success', `${item.value} editado con éxito`);
              }
            });
            // createNotification('success', `${ROUTE_NAMES.find(item => item.key === route && item.value)} editado con éxito`);
            setOpen && setOpen(false);
            setLoading(false);
            let data = {data: {...defaultValues}};
            onSuccess && onSuccess(route === 'socios' ? dirtyValues(methods.formState.touchedFields, data) : data);
            refresh();
            return;
          }).catch((e) => {
            setLoading(false);
            if(e?.body?.msg) {
              createNotification('error', e.body.msg);
              return;
            }
            if(e?.body?.error?.includes('webflow')) {
              createNotification('error', 'Error, se perdio la referencia con la pagina web.');
              return;
            }
            createNotification('error', 'Error al editar, por favor intente más tarde');
            throw new Error(e);
          });
        }
      } else {
        setLoading(false);
      }
    } else {

      const flexAllowed = Boolean(localStorage.getItem('newFlex'))

      const flex = {
        "gasto_mensual": 0,
        "nivel_acceso_actual": "",
        "miembro_nuevo": true
      }

      if (flexAllowed) {
        data.flex = flex
      }

      dataProvider.create(`${route}`, { data: transform ? transform(data) : data }).then(e => {
        redirect(`/${route}`);
        methods.reset();
        ROUTE_NAMES.find(item => {
          if(item.key === route){
            if(item.key === 'sedes') {
              createNotification('success', `${item.value} creada con éxito`);
              return;
            }
            createNotification('success', `${item.value} creado con éxito`);
          }
        });
        setLoading(false);
        setTimeout(() => {
          onSuccess && onSuccess(e);
        },1000);
      }).catch(e => {
        createNotification('error', 'Error al crear, por favor intente más tarde', () => handleClickCopy(data));
        setLoading(false);

      });
    }


  };

  const onError = (data,e) => {
    Object.values(data).map(item => {

      item && createNotification('warning', item.message);
    });
    !data && createNotification('error', 'Existen algunos errores en los campos, Por favor verificar');
  };
  const handleValidateEdit = async () => {
    if (!getIsDisabled()) {
      return true;
    } else {
      return false;
    }
  };

  const checkValidTab = async () => {
    if (tabs.length > 1) {
      
      const ActualTabs = tabs[currentTab]?.component?.map(item => item !== null && item?.source);
      const isValid = await methods.trigger(ActualTabs, { shouldFocus: true });
      return isValid;
    }
    const ActualTabs = tabs[0]?.component?.map(item => item !== null && item.source);
    const isValid = await methods.trigger(ActualTabs, { shouldFocus: true });
    return isValid;
  };

  const handlePrevTab = () => {
    const check = checkValidTab();
    if (check) {
      const tab = currentTab > 0 ? currentTab - 1 : 0;
      handleTabValid(tab);
    }
  };

  const handleNextTab = () => {
    if (!getIsDisabled()) {
     
      checkValidTab().then((data) => {
       
        if (data) {
          const tab = isLastTab ? maxTab : currentTab + 1;
          handleTabValid(tab);
        }
        if(isEdit) {
          return data;
        }
      });
    }
  };
  
  useEffect(() => {
    fieldErrors.map(item => {
      methods.setError(item, { message: `El ${item} ingresado ya existe` }, {shouldFocus: false});
    });
    if ((fieldErrors.length === 0) && !getIsDisabled()) {
      methods.clearErrors();
    }
  }, [fieldErrors]);

  const handleConfirmation = () => {
    let confirmation = tabs[0]?.component?.find(item => item?.confirmation && item);
    if(confirmation?.confirmation) {
      setLoading(false);
      setConfirmModal(true);
      if(confirmModal){
        setLoading(true);
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const handleCancelarEdit = () => {
    if(handleCancelar) return handleCancelar();
    redirect(`/${route}/${defaultValues?.id}/show`);
    methods.reset();
    refresh();
    setOpen && setOpen(false);
  };
  
  const handleCancelarCreate = () => {
    redirect(`/${route}`);
    clearAllDispatchFields();

  };

  useEffect(() => {
    !open && methods.reset();
    clearAllDispatchFields();
  },[open, setOpen]);

  return (
    <>
      <Box sx={{
        width: '100%',
        maxWidth: '1000px',
      }}>
        <FormProvider {...methods} >
          
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <BoxTab padding={tabs.length > 1 ? null : '16px'}>
              {
                tabs?.map((item, index) => {
                  return (
                    !item.disabled ? (
                      <TabForm active={currentTab == index}>
                        <SectionTabForm isEdit={isEdit} errors={methods.formStateerrors} data={item.component} control={methods.control} />
                      </TabForm>
                    ) : (
                      <Box disabled>
                        <TabForm active={currentTab == index}>
                          <SectionTabForm isEdit={isEdit} errors={methods.formState.errors} data={item.component} control={methods.control} />
                        </TabForm>
                      </Box>
                    )
                  );
                })
              }
            </BoxTab>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '12px' }}>
              {tabs.length > 1 ? (
                <>
                  <Button color='error' variant='contained' onClick={() => handleCancelarCreate()}>Cancelar</Button>
                  <Box sx={{ display: 'flex', gap: '12px' }}>
                    { !tabs[isLastTab + 1]?.disabled  && <Button variant='contained' onClick={handlePrevTab}>Anterior</Button> }
                    {isLastTab || tabs[isLastTab + 1].disabled ?
                      <Button variant='contained'
                        disabled={loading || getIsLoading()}
                        endIcon={loading && <CircularProgress size='20px' />}
                        onClick={methods.handleSubmit(onSubmit, onError)}>Guardar</Button> :
                      <Button variant='contained' disabled={loading || getIsLoading()} onClick={handleNextTab}>Siguiente</Button>
                    }
                  </Box>
                </>
              ) : (
                <>
                  <Button color='error' variant='contained' onClick={handleCancelarEdit}>Cancelar</Button>
                  {handleDelete && (
                    <Button color='error' variant='contained' onClick={handleDelete}>Borrar</Button>
                  )}
                  <Button variant='contained'
                    disabled={loading  || getIsLoading() || disabledSave}
                    endIcon={loading && <CircularProgress size='20px' />}
                    onClick={methods.handleSubmit(onSubmit, onError)}>Guardar</Button>
                </>
              )}
       
            </Box>
          </form>
        </FormProvider>
      </Box>
      <ConfirmModal
        setOpen={setConfirmModal}
        open={confirmModal}
        onClickAction={methods.handleSubmit(onSubmit, onError)}
        loading={loading}
      >
        {
          tabs[0]?.component?.map(item => {
            if(item?.confirmation && item?.confirmationMessage) {
              return (
                <>
                  <Typography>{item?.confirmationMessage}</Typography>
                </>
              );
            }
          })
        }
      </ConfirmModal>
    </>
  );
};

export default FormTab;