import React, { useContext, useState } from 'react';
import { InputSmall } from '../../../Components/CustomFields/InputSmall';
import { Box, Button, IconButton, TextField } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useForm } from 'react-hook-form';
import SectionForm from '../../../Components/Form/SectionForm';
import {TextField as TextFieldMui} from '@mui/material';
import { getPermissions } from '../../helpers/helpFunctions';
import { merchantCentral, merchantContext } from '../../../DataProvider';

const prestacionesEdit = (record) => {
  const [newPres, setNewPres] = useState('');
  // const form = useForm();

  const presToArray = () => {
    const presFormat = record?.prestaciones?.slice(4, -5).split('<li>');
    const ret = [];
    if(presFormat){
      for(let pres of presFormat){
        if(pres?.length > 0){
          ret.push(pres.slice(0, -5));
        }
      }
    }
    return ret;
  };

  const arrayToPrest = (items) => {
    let ret = '<ul>';
    for(let pres of items){
      ret += '<li>'+pres+'</li>';
    }
    ret += '</ul>';
    return ret;
  };

  const addPrest = () => {
    if(newPres !== '') {

      setPrestaciones([...prestaciones, newPres]);
      setNewPres('');
      let ret = sendPrest.slice(0, -5) + '<li>' + newPres + '</li></ul>';
      // form.change('prestaciones', ret);
      setSendPrest(ret);
    }
  };

  const deletAllPrest = () => {
    setPrestaciones([]);
    setSendPrest('<ul></ul>');
    // form.change('prestaciones', '<ul></ul>');
  };

  const [prestaciones, setPrestaciones] = useState(record ? presToArray() : []);
  const [sendPrest, setSendPrest] = useState(arrayToPrest(prestaciones));


  const permis = getPermissions();
  const {getMerchant} = useContext(merchantContext);
  const isCentral = merchantCentral.find(item => item.id === getMerchant()?.id) ? true : false;
  const disabledApplication = (permis === 'superadmin' || permis === 'owner') && !isCentral;
  const legacyEdit = record && record?.['tipo-de-plan'] !== 'cadena' && record?.nivel_de_acceso !== 'Local' && !disabledApplication;


  const deleteHandler = (index) => {
    const r = prestaciones.filter((item, i) => i != index);
    setPrestaciones(r);
    const prestSend = arrayToPrest(r);
    setSendPrest(prestSend);
    // form.change('prestaciones', prestSend);
  };
  
  const data = [
    {
      custom: true,
      component: 
      <>
       
        <Box style={{margin: '16px'}}>
          {/* <InputSmall source='prestaciones' initialValue={sendPrest} style={{display: 'none'}}/> */}
          <Box>
            <Box style={{display: 'flex', gap: '20px'}}>
              <TextFieldMui
                variant='outlined' 
                size='small' 
                label='Prestaciones' 
                disabled={legacyEdit}
                value={newPres} 
                onChange={(e) => setNewPres(e.target.value)}
              />
              <Button variant='outlined' disabled={legacyEdit} onClick={addPrest} size='small' color='primary'>Agregar</Button>
            </Box>
            <ul style={{padding: '0'}}>
              {
                prestaciones?.map((item, index) => (
                  <Box key={item} style={{display: 'flex', alignItems: 'center'}}>
                    <IconButton aria-label="delete" onClick={() => deleteHandler(index)} style={{marginRight: '20px'}}>
                      <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                    <li>
                      {item}
                    </li>
                  </Box>
                ))
              }
            </ul>
            {
              prestaciones.length > 0 && 
              <Button variant='contained' color='error' onClick={deletAllPrest} size='small'>Eliminar Todos</Button>
            }
          </Box>
        </Box>
      </>
    },
    {
      source: 'prestaciones',
      displayNone: true,
      value: prestaciones?.map(item => `<ul><li>${item}</li></ul`).join('')
    }
  ];

  return data;
};

export default prestacionesEdit;