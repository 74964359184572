import { CircularProgress } from '@mui/material';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CRUD_SET_FILTER } from 'react-admin';
import { ModalDateCustom, ModalReporteCustom } from '../../../Components/UI/ExportCustomButton';
import { createNotification } from '../../helpers/helpFunctions';
import { apiUrl, merchantSelected } from '../../../DataProvider';
import ModalCustom from '../../../Components/UI/ModalCustom';
import { theme } from '../../../Theme/theme';

const ButtonsReport = () => {
  const [filter, setFilter] = useState([]);
  const [newFilter, setNewFilter] = useState([]);
  const [filterSedes, setFilterSedes] = useState([]);
  const [date, setDate] = useState(
    {
      from: new Date(Date.now()).toISOString().slice(0,10),
      until: new Date(Date.now()).toISOString().slice(0,10)
    }
  );
  
  const handleFilter = (val, value) => {
    if(value === 'planes') {
      let idsPlanes = val.map((item) => item.id);
      setFilter((f) => ({...f, 'plan': idsPlanes})); 
    } else if(value === 'merchant') {
      let idsmerch = val.map((item) => item.id);
      setFilter((f) => ({...f, 'merchant': idsmerch}));
    }

  };

  const handleFilterPlanes = (planes) => {
    let idsPlanes = planes.map((item) => item.id);
    setFilter((f) => ({...f, 'plan': idsPlanes}));
  };

  const handleFilterSedes = (sedes) => {
    let sedesid = sedes.map((item) => item.id);
    setFilterSedes(sedesid);
  };


  return (
    <Box style={{display: 'flex',justifyContent: 'space-around', flexWrap: 'wrap', width: '100%', marginTop: '120px'}}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '40px', minWidth: '250px'}}>
        <ButtonReport 
          url='socios_reporte' 
          text='Socios'
          filter={{'merchant': filter.merchant ?? []}}
          openModal
          viewMerch
          setFilter={handleFilter}
          noPlanes
          // viewSede
          msgError='No existen reportes para este negocio en estas fechas'
        />
        <ButtonReport 
          url='cobros_reporte'
          text='Cobros' 
          filter={{'from': date.from, 'until': date.until,'merchant': filter.merchant ?? [] , 'plan': filter.plan, download: 'true'}}
          openModal
          viewMerch
          handleFilterPlanes={handleFilterPlanes}
          setFilter={handleFilter}
          date={date}
          setDate={setDate}
          viewDate
          viewPlanes
          msgError='No existen cobros para este negocio en estas fechas'
        />
        <CobrosCustomReport 
          url='pagos_reporte' 
          text='Detalle de intentos de cobro'
          openModal
          viewMerch
          date={date}
          setDate={setDate}
          filter={newFilter}
          setFilter={setNewFilter}
          viewDate
          msgError='No existen pagos para este negocio en estas fechas'
        />
      </Box>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '40px', minWidth: '250px'}}>
        <ButtonReport 
          url='socios_bajas_altas' 
          text='Altas de Socios' 
          filter={{'from': date.from, 'until': date.until, 'data':'altas', 'merchant': filter.merchant ?? []}}
          openModal
          setFilter={handleFilter}
          date={date}
          viewMerch
          setDate={setDate}
          viewDate
          noPlanes
          msgError='No existen altas para este negocio en estas fechas'
        />
        <ButtonReport 
          url='socios_bajas_altas' 
          text='Bajas de Socios'
          filter={{'from': date.from, 'until': date.until, 'data': 'bajas', 'merchant': filter.merchant ?? []}}
          openModal
          date={date}
          viewMerch
          setDate={setDate}
          setFilter={handleFilter}
          viewDate
          noPlanes
          msgError='No existen bajas para este negocio en estas fechas'
        />
        <ButtonReport 
          url='facturacion_reporte' 
          text='Facturas'
          filter={{'from': date.from, 'until': date.until, 'merchant': filter.merchant ?? []}}
          openModal
          viewMerch
          date={date}
          setDate={setDate}
          setFilter={handleFilter}
          viewDate
          msgError='No existen facturas para este negocio en estas fechas'
        />
        
      </Box>
    </Box>
  );
};

export const CobrosCustomReport = ({text, url, filter = '', rechazos, viewPlanes, viewDate, date, setDate, openModal, viewMerch,viewSede, msgError, setFilter, setFilterSedes, variant, ...props}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  
  const download = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    
    axios({
      url: `${uri}/${url}?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'access-control-expose-headers' : '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        const filename = response?.headers?.['content-disposition']?.split('filename=')[1];
        const url = window.URL
          .createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename ? `${filename}` : `${text}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        document.body.removeChild(link);
        setOpen(false);
        setLoading(false);
      }).catch(err => {
        if(err.response.status === 400) {
          createNotification('error', msgError);
        }
        setOpen(false);
        setLoading(false);
      });
  };

  const mailSend = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    console.log('filter en mailsend', filter);
    axios({
      url: `${uri}/${url}?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*',
        'access-control-expose-headers': '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        createNotification('success', 'Recibirás el reporte solicitado en tu correo electrónico.'); 
        setOpen(false);
        setLoading(false);
      }).catch(err => {
        // createNotification("error", err.response?.data.msg);
        createNotification('error', msgError);
        setOpen(false);
        setLoading(false);
      });
  };
  
  return (
    <Box>
      <Box>
        <Button
          onClick={openModal ? () => setOpen(true) : download} 
          startIcon={loading && <CircularProgress style={{color: '#fff', width: '1rem', height: '1rem'}}/>}
          disabled={loading}
          fullWidth
          color={'secondary'}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          variant={variant ?? 'outlined'}
          {...props}
        >
          {text}
        </Button>
      </Box>
      {open && (
        <ModalReporteCustom
          open={open}
          rechazos={rechazos}
          setOpen={setOpen} 
          loading={loading}
          setLoading={setLoading}
          onClick={mailSend}
          date={date}
          filter={filter}
          setDate={setDate}
          viewMerch={viewMerch}
          viewDate={viewDate}
          viewSede={viewSede}
          setFilter={setFilter}
          setFilterSedes={setFilterSedes}
          viewPlanes={viewPlanes}
        />
      )}
    </Box>
  );
};



export const ButtonReport = ({text, url, filter, variant = 'contained',icon, viewPlanes, viewDate, date, setDate, openModal, viewMerch,viewSede, msgError, setFilter, setFilterSedes, ...props}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [filterAux, setFilterAux] = useState({'merchant': []});

  const handleFilter = (val, value) => {
    if(value === 'merchant') {
      let idsmerch = val.map((item) => item.id);
      setFilterAux((f) => ({...f, 'merchant': idsmerch}));
    }

  };

  
  const download = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    axios({
      url: `${uri}/${url}?filter=${JSON.stringify(filter ? filter : filterAux)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*',
        'access-control-expose-headers' : '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        const filename = response?.headers?.['content-disposition']?.split('filename=')[1];

        const url = window.URL
          .createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename ? `${filename}` : `${text}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        document.body.removeChild(link);
        setOpen(false);
        setLoading(false);
      }).catch(err => {
        // createNotification("error", err.response?.data.msg);
        createNotification('error', msgError);
        setOpen(false);
        setLoading(false);
      });
  };

  const mailSend = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    axios({
      url: `${uri}/${url}?filter=${JSON.stringify(filter ? filter : filterAux)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*',
        'access-control-expose-headers': '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        createNotification('success', 'Recibirás el reporte solicitado en tu correo electrónico.'); 
        setOpen(false);
        setLoading(false);
      }).catch(err => {
        // createNotification("error", err.response?.data.msg);
        createNotification('error', msgError);
        setOpen(false);
        setLoading(false);
        setFilter('');
      });
  };
  
  return (
    <Box>
      <Box>
        <Button
          variant={variant} 
          onClick={openModal ? () => setOpen(true) : download} 
          startIcon={loading ? <CircularProgress style={{color: '#fff', width: '1rem', height: '1rem'}}/> : icon}
          disabled={loading}
          fullWidth
          color={'secondary'}
          style={{display: 'flex', justifyContent: 'center'}}
          {...props}
        >
          {text}
        </Button>
      </Box>
      <ModalDateCustom
        open={open} 
        setOpen={setOpen} 
        loading={loading}
        onClick={mailSend}
        date={date}
        setDate={setDate}
        viewMerch={viewMerch}
        viewDate={viewDate}
        viewSede={viewSede}
        setFilter={setFilter ? setFilter : handleFilter}
        setFilterSedes={setFilterSedes}
        viewPlanes={viewPlanes}
      />
    </Box>
  );
};

export default ButtonsReport;