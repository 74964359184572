import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardContent from './Card/CardContent';
import CardDashboard from './Card/CardDashboard';

const VentasCard = ({
  ventasMensuales, 
  date, 
  loading, 
  isFetchingVentas, 
  isFetchedVentas, 
  errorVentas, 
  isSuccess
}) => {
  const [datasets, setDatasets] = useState({});
  const [ventasData, setVentasData] = useState({});
  const [chipData, setChipData] = useState(null);
  const [hideValue, setHideValue] = useState(true);
  useEffect(() => {
    let data = ventasMensuales?.data?.json?.data;
    let today = new Date();
    let count = 1;
    let count2 = 1;
    let totalAltas = 0;
    let totalRecurrentes = 0;
    const newAltasArray = [];
    const newRecurringArray = [];
    data?.checkout?.monto_cobrado?.map((r, i) => {
      totalAltas = totalAltas + r;
      newAltasArray.push({key: `${count}`, value: r, boletas: data?.checkout?.nro_boletas[i]});
      count++;
    });
    data?.recurring?.monto_cobrado?.map((e, i) => {
      totalRecurrentes = totalRecurrentes + e;
      newRecurringArray.push({key: `${count2}/${today.getMonth() + 1}`, value: e, boletas: data?.recurring?.nro_boletas[i]});
      count2++;
    });
    setVentasData({...ventasData, totalAltas: data?.total_mostrador, totalRecurrentes: data?.total_recurring});
    setDatasets({chart: {altas: newAltasArray, recurrentes: newRecurringArray}});
    setChipData({...chipData,
      total:  data?.variacion_total?.toFixed(2),
      recurrentes: data?.variacion_recurring?.toFixed(2),
      altas: data?.variacion_mostrador?.toFixed(2),
    });

  },[ventasMensuales]);

  const helpText = 'Cobros por mostrador y por recurrencias del mes seleccionado. Los porcentajes de variación comparan el período actual con el del mes anterior. Si el mes corriente aún no terminó, se comparan los cobros de la misma cantidad de días del periodo anterior. (Ej. Si se observa el mes corriente al día 15, se compara con los primeros 15 días del mes anterior).';
  return (
    <Box sx={{ width: '100%'}}>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          width: {xs: '100%'},
          height: 'auto',
          justifyContent: 'space-between'
        }}
      >
        <CardDashboard noPad>
          <CardContent
            hideValue={hideValue}
            setHideValue={setHideValue}
            isSuccess={isSuccess}
            errorVentas={errorVentas} 
            isFetchedVentas={isFetchedVentas}
            isFetching={isFetchingVentas} 
            loading={loading}
            datasets={datasets}
            ventasData={ventasData} 
            chartLine name={'Cobros'} 
            helpText={helpText} 
            title={'Diferencia'} 
            chipData={chipData}
            // data={data} 
            url='cobros_reporte'
            date={date}/>
        </CardDashboard>
      </Box>
    </Box>
  );
};

export default VentasCard;