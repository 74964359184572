import React from 'react';
import { Create } from 'react-admin';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';
import { createNotification } from '../helpers/helpFunctions';
import UsersCreateedit from './UsersCreateedit';


const UsersCreate = (props) => {

  const onFailure = () => {
    createNotification('warning', 'Ya existe una cuenta asociada a este email');
  };

  return (
    <Create 
      {...props} 
      title={<TitleEdit title='Crear Usuario'/>} 
      onFailure={onFailure}
    >
      <UsersCreateedit/>
    </Create>
  );
};

export default UsersCreate;
