import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Show, useDataProvider, useRecordContext } from 'react-admin';
import s from 'react-aws-s3';
import { BsPinMapFill } from 'react-icons/bs';
import { MdOutlineQrCode } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import CardBasic from '../../Components/UI/Show/CardBasic';
import HeaderCardShow from '../../Components/UI/Show/HeaderCardShow';
import TableMinimal from '../../Components/UI/Show/TableMinimal';
import { paCustom } from './PuntoAccesoEdit';
import axios from 'axios';
import { apiUrl } from '../../DataProvider';



const PuntoAccesoShow = (props) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [sede, setSede] = useState(null);

  useEffect(() => {
    if(record) {
      dataProvider.getOne('sedes', {id: record?.sede_id}).then(({data}) => {
        setSede(data);
      });
    }
  },[]);

  return(
    <Show
      {...props}
      title={<TitleEdit title='Punto de Acceso: ' source='name'/>} 
      aside={<AsideShow />} 
      actions={null}
    >
      <ShowTransparent>
        <PuntoAccesoInfo sede={sede} />
        <PuntoAccessQr sede={sede} />
      </ShowTransparent>
    </Show>
  );
};

export default PuntoAccesoShow;

export const PuntoAccesoInfo = () => {

  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [sede, setSede] = useState(null);
  
  useEffect(() => {
    if(record) {
      dataProvider.getOne('sedes', {id: record?.sede_id}).then(({data}) => {
        setSede(data);
      });
    }
  },[]);

  const editComponent = {
    component: paCustom(),
    label: 'Información',
    icon: <BsPinMapFill/>
  }; 

  const dataArr = [
    {key: 'Descripcion', value: record?.description},
    {key: 'Mac Placa', value: record?.mac_placa},
    {key: 'Comando', value: record?.comando},
    {key: 'Sede', value: sede?.name},
    {key: 'IP', value: record?.server_ip}
  ];

  return (
    <CardBasic record={record} editComponent={editComponent} route={'punto_acceso'} >
      <HeaderCardShow title={'Molinete'} />
      <TableMinimal data={dataArr} />
    </CardBasic>
  );
};

const AsideShow = ({basePath, resource, permis, ...rest}) => {
  const record = useRecordContext();
  
  const data = record ? [
     
    {
      rest: rest,
      record: record,
      disabled: false,
      label: 'Eliminar Punto de Acceso',
      confirmTitle: `¿Eliminar ${record?.name}?`,
      confirmContent: '¿Seguro quieres eliminar este Punto de Acceso?',
      basePath: basePath,
    },
    { divider: true },
  
  ] : [];
  
  return(
    <AsideActionsShow data={data}/>
  );
};


const PuntoAccessQr = () => {

  const record = useRecordContext();
  const [codigo, setCodigo] = useState('');
  const dataProvider = useDataProvider();
  const [sede, setSede] = useState(null);
  
  useEffect(() => {
    if(record) {
      dataProvider.getOne('sedes', {id: record?.sede_id}).then(({data}) => {
        setSede(data);
      });
    }
  },[]);

  useEffect(() => {
    setCodigo(`{
            "funcion": "acceso_simple",
            "tipo":"punto_de_acceso",
            "punto_de_acceso_id" : "${record?.id}",
            "prestador": "${sede?.slug}"
          }`);
  },[sede]);

  const triggerDownload = (imgURI) => {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });
      
    const a = document.createElement('a');
    a.setAttribute('download', `${sede?.name + sede?.slug}.png`);
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');
    a.dispatchEvent(evt);
  };
      
  const download = async () => {
         
    try {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', 150);
      canvas.setAttribute('height', 150);
      const ctx = canvas.getContext('2d');
      
      ctx.fillStyle = 'white';
      ctx.fillRect(0,0,canvas.width, canvas.height);
      const DOMURL = window.URL || window.webkitURL || window;
      const image = document.getElementById('codigoQr.png');
      
      const imageString = new XMLSerializer().serializeToString(image);
      const img = new Image();
      const imageBlob = new Blob([imageString], {type:  'image/svg+xml;charset=utf-8'});
      const bURL = URL.createObjectURL(imageBlob);
      img.onload = function () {
        ctx.drawImage(img, 11, 11);
        DOMURL.revokeObjectURL(bURL);
      
        const imgUri = canvas.toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        triggerDownload(imgUri);
      };
      img.src = bURL;
              
    } catch (err) {
      console.log(err);
    }
  };

  // const download = async () => {
  //   const token = localStorage.getItem('auth');
  //   const tokenSend = await JSON.parse(token)?.token;

  //   axios({
  //     url: `${apiUrl}/qrcode/${sede.id}`,
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-Access-Tokens': tokenSend,
  //       'access-control-allow-origin': '*',
  //       'access-control-expose-headers' : '*'
  //     },
  //     responseType: 'blob'
  //   }).then(res => {
  //     const filename = res?.headers?.['content-disposition']?.split('filename=')[1];
  //     const url = window.URL
  //       .createObjectURL(new Blob([res?.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', filename ? `${filename}` : `${sede.name}.png`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
    
  // };
    

  return (
    <CardBasic>
      <Box sx={{margin: '10px 0', display: 'flex', flexDirection: 'column'}}>
        <QRCode id="codigoQr.png" size={128} value={codigo} style={{display: 'none'}} />
        <Button disabled={!record?.molinete} onClick={() => download()} style={{backgroundColor: `${record?.molinete && sede?.id ? '#5584AC' : '#d7ccc8'}`, fontWeight: 400, width: '50%'}}> 
          <MdOutlineQrCode size={20} style={{margin: '0 0 0 10px', color: '#FFFFFF'}} />
          <Typography variant="h7" style={{color: '#FFFFFF', fontWeight: 600, margin: '0 10px'}}>Descargar Código Qr</Typography>
        </Button>
  
      </Box>
    </CardBasic>
  );
};