import { GetObjectCommand,S3Client, ListObjectsV2Command, PutBucketCorsCommand } from "@aws-sdk/client-s3";

const AWS_BUCKET_NAME=process.env.REACT_APP_AWS_BUCKET_NAME

const AWS_REGION= process.env.REACT_APP_AWS_REGION
const AWS_ACCESS_KEY_ID=process.env.REACT_APP_AWS_ACCESS_KEY_ID
const AWS_SECRET_ACCESS_KEY=process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

const awsConfig = {
    region: AWS_REGION,
    credentials: {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
    }
};

const s3 = new S3Client(awsConfig);

const bucketDownloader = async (imageNameUrl) => {

    function extractimageName(url) {
    const indexCom = url.indexOf('.com/');
    if (indexCom === -1) {

        return url;
    }
    const imageName = url.substring(indexCom + 5);
    return imageName;
}

    const imageName = extractimageName(imageNameUrl)
    const params = new GetObjectCommand({
        Bucket: AWS_BUCKET_NAME,
        Key: imageName,
    });

    try {
        const response = await s3.send(params);
        // Leer los datos del ReadableStream
        const reader = response.Body.getReader();
        let chunks = [];
        let done = false;
        while (!done) {
            const { value, done: isDone } = await reader.read();
            if (value) {
                chunks.push(value);
            }
            done = isDone;
        }

        // Conectar chunks en Uint8Array
        const imageData = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
        let offset = 0;
        for (const chunk of chunks) {
            imageData.set(chunk, offset);
            offset += chunk.length;
        }

        // Blob a partir de binarios.
        const blob = new Blob([imageData], { type: response.ContentType });
        const imageUrl = URL.createObjectURL(blob);
        window.open(imageUrl, '_blank');
        URL.revokeObjectURL(imageUrl)
    } catch (err) {
        console.error(err);
    }
}

export default bucketDownloader;