import { Box, Pagination } from '@mui/material';
import { Button } from '@mui/material';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useState } from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import PaginationTable from '../../../Components/ListComponents/PaginationTable';
import Skeleton from '@mui/material/Skeleton';
import { useDataProvider, useListContext, useRecordContext, useShowContext } from 'react-admin';
import ModalEvent from '../ModalEvent';

const cols = [
  {name: 'Fecha', key: 'date_created'},
  {name: 'Plan', key: 'plan_name'},
  {name: 'Estado', key: 'event'},
  {name: 'Source', key: 'source'},
  {name: 'Razón', key: 'reason'},
];


const HistoryCard = () => {
  const record = useRecordContext();
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const dataProvider = useDataProvider();

  const { loading } = useShowContext();
  const history = record.history.reverse();

  const getName = async (item) => {
    let obj = item;
    try {
      
      if(!obj?.plan) return {...obj, plan_name: 'Plan Eliminado'};
      let response = await dataProvider.getOne('planes', {id: obj?.plan});
      let data = await response.data;
      obj.plan_name = data.name;
      return obj;
    } catch (error) {
      
      if(error?.status === 404){
        obj.plan_name = 'Plan Eliminado';
        return {...obj};
      } else {

        obj.plan_name = '';
        return {...obj};
      }
    }
   
  };

  const getNameMemoized = useCallback(async (historyItem, ids) => {
    const newRow = await getName(historyItem);
    ids.push(newRow);
    return newRow;
  }, []);
  
  useEffect(async () => {
    let ids = [];
    let rowsPlanes = [];
  
    if (!loading && record?.history?.length === 0) {
      setRows([]);
    } else if (!loading && record?.history?.length > 0) {
      const historySet = new Set(record.history);
      for (const historyItem of historySet) {
        const existingRow = rowsPlanes.find(
          (row) => row?.date_created === historyItem?.date_created
        );
        if (existingRow) {
          continue;
        }
        const matchingId = ids.find((id) => id.plan === historyItem.plan);
        if (matchingId) {
          const newRow = { ...historyItem, plan_name: matchingId.plan_name };
          rowsPlanes.push(newRow);
        } else {
          const newRow = await getNameMemoized(historyItem, ids);
          rowsPlanes.push(newRow);
        }
      }
      setRows(rowsPlanes);
    }
  
    return () => {
      setRows([]);
    };
  }, [record, loading, getNameMemoized]);
  

  return (
    <CardBasic 
      title='Historial'
    // asideAction={<AgregarEvento setOpenModal={setOpenModal} />}
    >
      {
        !loading && rows? (
          <>
            {/* <TableBasic cols={cols} rows={rows} emptyText='No hay registros de historial para este socio'/> */}
            <PaginationTable sortedField={'date_created'} cols={cols} rows={rows}/>
          </>
        ) : (
          <Box>
            <Skeleton />
            <Skeleton />
          </Box>
        )
      }
      <ModalEvent open={openModal} setOpen={setOpenModal} />

    </CardBasic>
  );
};

export default HistoryCard;

export const AgregarEvento = ({setOpenModal}) => {
  return (
    <>
      <Button 
        variant='text' onClick={() => setOpenModal(true)}
        size='small' style={{fontWeight: '600', fontSize: '12px'}}
        startIcon={<AddIcon style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
      >
    Agregar Evento
      </Button>
    </>
  );

};