import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import ModalImage from '../../../Components/UI/ModalImage';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import ImageComponent from '../../../Components/UI/Show/ImageComponent';
import cardAws from '../../../Components/CustomFields/CardAws';
import { BsCardImage } from 'react-icons/bs';
import { useRecordContext } from 'react-admin';

const styleImage= {
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center',
  cursor: 'pointer'
};

const ImageCard = ({isAbleToEdit}) => {

  const record = useRecordContext();
  const isMulti = record?.['tipo-de-plan'] === 'cadena';
  const editComponent = {
    component: cardAws('Subir Foto', 'foto'),
    label: 'Imagen',
    icon: <BsCardImage/>
  };

  const [open, setOpen] = useState(false);

  return (
    <CardBasic title='Imagen' route={isMulti ? 'multinegocio' : 'planes'} record={record} editComponent={isAbleToEdit && editComponent}>
      <ImageComponent item={{url: record?.foto}}/>
    </CardBasic>
  );
};

export default ImageCard;