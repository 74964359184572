import { grey } from '@mui/material/colors';
import { StyledBody, StyledCell, StyledHead, StyledRow, StyledTable } from './StyledTable';
import { Typography } from '@mui/material';
import { SortByAlphaOutlined } from '@mui/icons-material';
import { useAbsolute } from '../../../../Hooks/usePosition';


export const CustomPlanList = (props) => {
  const {
    sortedPlanes,
    setSort,
    sort,
    fixedPosition
  } = props;
  
  return (
    <div
      style={{ 
        backgroundColor: '#f1f1f1', 
        boxShadow: 'none', 
        overflowX: 'hidden', 
        width: '300px', 
        paddingTop: '8px',
        position: 'relative',
      }}>
      <StyledTable>
        <StyledHead style={{ 
          backgroundColor: '#f1f1f1',
          height: !fixedPosition ? '100%' : 80,
          top: fixedPosition ? 40 : null,
          border: '0px solid black',
          position: fixedPosition ? 'fixed' : 'sticky',
        }}>
          <StyledRow
            style={{
              verticalAlign: 'start',
              height: '20px',
              textAlign: 'center',
              alignItems: 'center',
              top: fixedPosition && '50%',
              left: fixedPosition&& '50%',
              transform: fixedPosition &&'translate(50%, 0%)'
            }}
          >
            <StyledCell
              style={{
                verticalAlign: 'start',
                cursor: 'pointer',
                textAlign: 'start'
              }}
              onClick={() => setSort(!sort)}
            >
                      Nombre del plan
            </StyledCell>
          </StyledRow>
        </StyledHead>
        <StyledBody style={{ borderRight: `3px solid ${grey[200]}`, backgroundColor: '#fff', }}>
          {
            sortedPlanes && sortedPlanes?.map((item) => (
              <StyledRow
                style={{
                  padding: '15px',
                  height: '20px',
  
                }}
              >
                <StyledCell>
                  <div style={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    width: '100%',
                    border: '0px solid black',
                  }}>
                    <Typography sx={{ fontSize: '1em', color: 'black', fontWeight: '500', border: '0px solid black' }}>{item.name}</Typography>
                  </div>
                </StyledCell>
              </StyledRow>
            ))
          }
        </StyledBody>
      </StyledTable>
    </div>
  );
};