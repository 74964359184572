import { Box, Button, Icon, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { TiTick } from 'react-icons/ti';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AiOutlineWarning } from 'react-icons/ai';
import { useRecordContext, useRedirect } from 'react-admin';
import { Tooltip } from '@mui/material';
import { theme } from '../../../Theme/theme';

const Habilitado = ({ boolean }) => (
  <>
    <Box sx={{display: 'flex', alignItems: 'center', gap: '6px'}}>
      {boolean ? <TiTick size="25px" color="green" /> : <RxCross2 size="20px" color="red" />}
      <Typography color={theme.palette.primary.main} fontSize=".8em">
        {boolean ? 'Ya estás habilitado para la venta de planes' : 'No estás habilitado para la venta de planes.'}
      </Typography>
    </Box>
  </>
);


const VentaPlanesCadena = ({record}) => {
 
  const hasKeys = (record?.credentials?.mercadopago?.access_token);
  let clientId = process.env.REACT_APP_TEST === 'true' ? '1871745014499305' : '2391665143782594';
  const url = `https://auth.mercadopago.com.ar/authorization?client_id=${clientId}&response_type=code&platform_id=mp`;
  const handleStorage = () => {
    localStorage.setItem('merchant', `${record.id}-.-${record.name}`);
    console.log(localStorage.getItem('merchant'));
  };

  return (

    <>
      <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' ,gap: '20px'}}>
        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Box style={{backgroundColor: '#fff59d', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', width: '100%', margin: '10px', padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <AiOutlineWarning size={30} />
            <Typography style={{marginLeft: '10px', fontSize: '12px', fontWeight: '300', width: '100%'}}>
      Al hacer click en ‘Habilitar Venta de Planes Cadena’, se te redirigirá a una página de mercadopago donde se deberá autorizar al negocio de SportClub Cadena a gestionar pagos vinculados a este negocio. Es necesario que la cuenta de mercadopago a la que te encontrás autenticado en este explorador se corresponda con la cuenta asociada a este negocio. Caso contrario será necesario que ingreses a mercadopago en este explorador y hagas click “Tu cuenta” →  “Salir de la cuenta”.
            </Typography>
          </Box>
        </Box>
        <Tooltip placement='top' title={!hasKeys ? 'Tenés que guardar las keys de mercadopago para acceder a esta función' : ''}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Button 
              variant='contained' 
              color='primary' 
              href={`${url}`}
              onClick={() => handleStorage()}
              disabled={(!record?.credentials?.mercadopago?.access_token && !record?.credentials?.mercadopago?.access_token)|| record?.credentials?.mp_marketplace.active}
            >
              {record?.credentials?.mp_marketplace?.active ? 'Ya estás habilitado para la venta de planes cadena' : 'Habilitar venta Planes Cadena'}
            </Button>
            <div style={{alignItems: 'center', display: 'flex', gap: '5px'}}>
              <Typography sx={{textTransform: 'uppercase', fontSize: '.8em', fontWeight:'600', color: theme.palette.primary.main}} variant="none"><a style={{color: 'inherit', textDecoration: 'none'}} href="https://docs.google.com/document/d/1cEPtmKK4zrr8YxB7QM-WQhsEe9sGipJHSOI_V1SbXRQ/edit#heading=h.ob0ekd9ry40l" target="_blank" rel="noreferrer">Instructivo</a></Typography>
              <OpenInNewIcon sx={{width: '15px', color: theme.palette.primary.main}} />
            </div>
          </div>
        </Tooltip>
      </Box>
      <div style={{borderBottom: '1px solid #9ea7aa', margin: '20px 0'}}></div>
      
    </>
  );

};


const planesCadena = () => {
  const record = useRecordContext();
 
  
  const hasKeys = (record?.credentials?.mercadopago?.access_token);
  const hasFacturacion = (record?.afip?.activate_facturacion);

  const tooltip = (!hasKeys && !hasFacturacion) ? 'Tenés que guardar las keys de mercadopago y habilitar el proceso de facturación para acceder a esta función' : !hasFacturacion ? 'Tenés que habilitar el proceso de facturación para acceder a esta función' : !hasKeys ? 'Tenés que guardar las keys de mercadopago para acceder a esta función' : '';


  const data = [
    
    {
      custom: true,
      component: <VentaPlanesCadena record={record} />
    },
    record?.credentials?.mp_marketplace?.access_token ? {
      custom: true,
      component: <Habilitado />
    } : null,

  ];

  return data;
};

export default {
  planesCadena: planesCadena,
  Habilitado: Habilitado,
  VentaPlanesCadena: VentaPlanesCadena
};