import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Checkbox} from '@mui/material';
import {  maxLength, regex, required, useDataProvider, usePermissions, useRecordContext } from 'react-admin';
import { createNotification, getListAxios, getListProvider, getPermissions, removeAccents } from '../../helpers/helpFunctions';
import SectionForm from '../../../Components/Form/SectionForm';
import axios from 'axios';
import DataProvider, { apiUrl, brandSportClub, merchantCadena, merchantCentral, merchantContext } from '../../../DataProvider';
import { useQuery } from 'react-query';
import useCheckField from '../../../Hooks/useCheckField';
import { DispatchErrorContext } from '../../../Context/menuContext';


const informacionEdit = (permission, setMerchant, isMulti, multimerch, setIsCentral, setIsMP) => {
  const record = useRecordContext();
  const isEdit = false;
  const [slugValue, setSlugValue] = useState('');
  const [name, setName] = useState(record ? record?.name : '');
  const [error, setError] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(isEdit ? record?.merchant_id : '6178652dcfb117ad58a2cd3d');
  const [merchants, setMerchants] = useState(null);
  const [recordMerchant, setRecordMerchant] = useState(null);
  const [checkoutLink, setCheckoutLink] = useState('');
  const dataProvider = useDataProvider();
  
  const noOperador = permission !== 'operador';
  const validateName = regex(/^[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/, 'No puede contener caracteres especiales');
  const nameRegex = /^(?! )[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/;
  const checkout = `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}`;

  const permis = getPermissions();
  const {getMerchant} = useContext(merchantContext);

  const isCentral = merchantCentral.find(item => item.id === getMerchant()?.id) ? true : false;
  const disabledApplication = (permis === 'superadmin' || permis === 'owner') && !isCentral;
  const legacyEdit = record && record?.['tipo-de-plan'] !== 'cadena' && record?.nivel_de_acceso !== 'Local' && !disabledApplication;

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: 
      record?.['tipo-de-plan'] === 'local' ?
        {negocio_central: false} :
        record?.['tipo-de-plan'] === 'central' ? 
          {negocio_central: true} :
          {}
  };
  
  const {data: merchantsData, isFetching } = useQuery(
    ['merchants'],
    () =>  dataProvider.getList('merchants', params), 
    { 
      onSuccess: ({data}) => {
        setMerchants(data);
        data?.map(item => {
          if(item.id === record?.merchant_id) {
            setRecordMerchant(item);
          }
        });
      }
    }
  );

  const handlerName = (e) => {
    const value = e.target.value;
    !record?.slug && setSlugValue(removeAccents(value.toLowerCase().replace(/ /g, '-')));
    setName(value);
    setCheckoutLink(checkout + removeAccents(value.toLowerCase().replace(/ /g, '-')));
    if(value.length > 49 || !value.match(/^[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/) || value.length == 0){
      setError(true);
    }else{
      setError(false);
    }
  };

  // Validacion onBlur para los campos que no se puedan repetir en la DB

  const basePath = isMulti ? 'multinegocio' : 'planes';
  // const refetchName = useCheckField(basePath, 'name', name); 
  // const refetchSlug = useCheckField(basePath, 'slug', slugValue);


  useEffect(() => {
    if(isMulti){
      setMerchant('6178652dcfb117ad58a2cd3d');
    }
  }, []);


  const handleMerchantValidation = (e) => {
    
    let isValid = multimerch?.find(item => {
      if(item?.enabled === true && e !== '6178652dcfb117ad58a2cd3d') {
        return item;
      }
    });
      
    if(isValid) {
      return 'No se puede cambiar el Negocio si existen negocios habilitados';
    }
     
  };

  const handleMerchant = (e,v) => {
    console.log(e);
    if(typeof e === 'string') setMerchant(e);
    for (const m of merchants) {
      if (m?.id === e) {
        setIsCentral(m?.negocio_central);
        if(m?.split){
          setIsMP(true);
          break;
        } else {
          setIsMP(false);
          break;
        }
      }
    }
  };


  const data = [
    {
      source: 'name',
      label: 'Nombre',
      onChange: handlerName,
      // checkOnblur: refetchSlug, // setTimeout(() => refetchSlug()), 1000
      value: name,
      defaultValue: '',
      disabled: legacyEdit,
      // style: isEdit ? {display: 'none'} : null,
      validate: {
        required: 'El campo es obligatorio',
        maxLength: 49,
        pattern: {
          value: nameRegex,
          message: 'El campo no puede tener caracteres especiales, ni empezar con espacios en blanco.'
        },

      },
      xs: 12,
    },
    {
      source: 'slug', 
      label: 'Slug',
      disabled: true,
      dependantValue: slugValue,
      
      tooltip: 'Parte final de la URL',
      placement: 'top',
      xs: 6
    },
    {
      source: 'contacto',
      label: 'Contacto',
      disabled: legacyEdit,
      initialValue: '',
      xs: 6
    },
    
    isFetching ? {
      type: 'skeleton',
      xs: 6
    } : !isMulti && noOperador ?
      {
        source:'merchant_id',
        label: 'Negocio',
        type: 'autocomplete',
        choices: merchants ?? [],
        onChange: handleMerchant,
        // initialValue: recordMerchant,
        optionLabel: 'name',
        disabled: legacyEdit,
        selectedValue: 'id',
        validate: {
          required: 'El campo es obligatorio',
          validate: {
            isEnabled: (e) => (
              e !== merchantCadena && 
            merchants?.find(item => {
              if (item?.id === e && (item.brand_id === '6328afb359d052e6817c2200')) {
                return !item?.split;
              }
            })) ?
              'El negocio no esta habilitado para venta de planes cadena, por lo que no puede vender planes.' :
              undefined
          }
        },
      
        xs: 6
      } :  isMulti ? {
        source: 'merchant_id',
        type: 'autocomplete',
        label: 'Negocio',
        choices: merchants?.filter(item => item?.negocio_central),
        onChange: (e) => (d) => handleMerchant(d),
        optionLabel: 'name',
        optionValue: 'id',
        disabled: true,
        xs: 6
      } : null,
   
  ];

  return data;

};

export default informacionEdit;