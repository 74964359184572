import UserIcon from '@mui/icons-material/Group';
import SocioCreate from './SocioCreate';
import { SocioEdit } from './SocioEdit';
import { SocioList } from './SocioList';
import SocioShow from './SocioShow';
import ShowWrapper from './ShowWrapper';
import { HiUsers } from 'react-icons/hi';

export default {
  icon: HiUsers,
  create: SocioCreate,
  list: SocioList,
  show: <ShowWrapper><SocioShow /></ShowWrapper>,
};