import { Autocomplete, Box, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Skeleton, TableCell, TextField, Tooltip, Typography } from '@mui/material';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import Close  from '../../Assets/Images/close.png';
import Tick  from '../../Assets/Images/tick.png';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ModalCustom from '../../Components/UI/ModalCustom';
import { useContext, useRef, useState } from 'react';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { useDataProvider, useRefresh } from 'react-admin';
import { useQuery } from 'react-query';
import { createNotification, getMerchantId, isUserCentral } from '../helpers/helpFunctions';
import { ActionsDiv, MenuActionsDiv } from '../../Components/UI/Options/ActionsOptions';
import { styleTabbleData } from '../../Theme/useStyle';
import axios from 'axios';
import { apiUrl, merchantContext } from '../../DataProvider';


export const AsideMenuActions = ({
  refetchCobros,
  refetchAuth,
  socio,
  isFetching,
  err,
  permis,
  handleDispositivo,
  handleRedirect,
  dni,
  showLink,
  handleLink,
  setOpenAM
}) => {

  const [openAdicional, setOpenAdicional] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  return(
    <>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'start', marginLeft: '20px', marginTop: '50px'}}>
        {
          (socio && !isFetching && !err && permis !== 'accesos') &&
              <Tooltip placement={'right'} title={socio?.externo === true ? 'El socio no esta en este sistema.' : !showLink ? 'El socio no pertenece a tu red de negocios' : ''}>
                <Box >
                  <Button style={{textTransform: 'none'}} startIcon={<VisibilityIcon />} disabled={!showLink} onClick={() => handleLink()}>Ver Socio</Button>
                </Box>
              </Tooltip>
        }
        {(socio && !isFetching && !err) && 
                  <Tooltip title={socio.externo ? 'El socio no puede ser editado porque no pertenece a buenclub.' : null} placement="bottom">
                    <Box>
                      <Button startIcon={<DriveFileRenameOutlineIcon />} style={{textTransform: 'none'}} disabled={!socio?.['_id'] || socio.externo} onClick={() => setOpenAM(true)}>
                        Editar Apto Medico
                      </Button>
                    </Box>
                  </Tooltip>
        }
        {(socio && !isFetching && !err) && <Button startIcon={<TabletAndroidIcon />} style={{textTransform: 'none'}} onClick={() => handleDispositivo()}>Cambio de dispositivo</Button>}
        {(socio && !isFetching && !err) && <Button startIcon={<AddIcon />} style={{textTransform: 'none'}} onClick={() => handleRedirect(dni)}>Crear Acceso Manual</Button>}
        {(socio && !isFetching && !err) && <Button startIcon={<LoginIcon />} style={{textTransform: 'none'}} onClick={() => refetchAuth(dni)}>Autenticación Manual</Button>}
        {(socio && !isFetching && !err && permis !== 'accesos') && 
          <Box>
            <Button disabled={getMerchant()?.id === ''} startIcon={<PointOfSaleIcon />} style={{textTransform: 'none'}} onClick={() => setOpenAdicional(true)}>Cobro Adicional</Button>
          </Box>
        }
        {openAdicional && <ModalCobroAdicional refetchCobros={refetchCobros} dni={dni} open={openAdicional} setOpen={setOpenAdicional} />}
      </Box>
    </>
  );
};

export const greaterThan = (e) => {
  let today = new Date();
  let date = new Date(e);
  if(date > today) return true;
  return false;
};

export const AptoMedicoComponent = ({aptoMedico}) => (
  <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'right'}}>
    {!greaterThan(aptoMedico) ? 
      <img src={Close} style={{width: '13px', marginRight: '15px'}} /> : <img src={Tick} style={{width: '13px', marginRight: '15px'}} /> }
    <Typography fontSize={'0.875rem'} textAlign={'right'}>{new Date(aptoMedico).toLocaleString().split(',')[0] ?? 'N/A'}</Typography>
  </Box>
);

export const FechaDeVigenciaComponent = ({fecha}) => (
  <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'right'}}>
    {!greaterThan(fecha) ? 
      <img src={Close} style={{width: '13px', marginRight: '15px'}} /> : <img src={Tick} style={{width: '13px', marginRight: '15px'}} /> }
    <Typography fontSize={'0.875rem'} textAlign={'right'}>{new Date(fecha).toLocaleString().split(',')[0]}</Typography>
  </Box>
);

export const ModalCobroAdicional = ({refetchCobros, open, setOpen, dni}) => {
  const [cobroAdicional, setCobroAdicional] = useState(false);
  const dataProvider = useDataProvider();
  const ref = useRef(false);
  useOnClickOutside(ref, () => setOpen(false));
  const handleClose = () => {
    setOpen(false);
  };

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {}
  };

  const {data: {data: adicionales} = {}} = useQuery(['adicionales'], () => dataProvider.getList('adicionales', params), {
    onError: (err) => { throw new Error();}
  });
  
  const { refetch, isFetching } = 
  useQuery(['cobro_adicional'],
    () => dataProvider.create('cobros_adicionales', {
      data: {
        documento: Number.isInteger(dni) ? JSON.stringify(dni) : dni,
        adicional: cobroAdicional.id, 
        payment_type: cobroAdicional.metodo 
      }}), {
      onSuccess: (res) => {
        refetchCobros();
        createNotification('success', 'Cobro cargado con éxito'); 
        setOpen(false);
      },
      enabled: false,
      onError: (err) => { 
        createNotification('error', 'Hubo un error, por favor intente mas tarde.'); 
        throw new Error();
      }
    });

  return(
    <ModalCustom
      ref={ref}
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title="Cobro Adicional"
      textAction="Cobrar"
      showButtons
      disabledAction={isFetching}
      startIcon={isFetching ? <CircularProgress size="25px" /> : null}
      onClickAction={() => refetch()}
    >
      <Box 
        sx={{ padding: '50px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', minWidth: '500px', height: 'auto'}}
      >
        {
          adicionales ? (
            <Box sx={{width: '100%', display: 'flex',flexDirection:'column', gap: '10px'}}>
              <Autocomplete 
                fullWidth
                sx={{height: '50px'}}
                options={adicionales}
                onChange={(e,v) => setCobroAdicional((f) => ({...f, id: v.id}))}
                getOptionLabel={(option) => { return `${option.name} - $ ${option.price} - ${option.duration} días`;}}
                renderInput={
                  (params) => <TextField sx={{height: '50px'}} label="Adicional" variant="outlined" {...params} />}
              />
              <FormControl size="small" fullWidth>
                <TextField
                  select
                  variant="outlined"
                  // labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  label="Método de pago"
                  value={cobroAdicional.metodo}
                  size="small"
                  onChange={(e) => setCobroAdicional((f) => ({...f, ['metodo']: e.target.value}))}
                >
                  <MenuItem value="efectivo">Efectivo</MenuItem>
                  <MenuItem value="link-de-pago">Link de pago</MenuItem>
                  <MenuItem value="terminal">Terminal</MenuItem>
                </TextField>
              </FormControl>
            </Box>
          ) : (
            <>
              <Skeleton sx={{height: '50px', width: '100%'}} />
            </>
          )
        }
      </Box>
    </ModalCustom>
  );
};