import { Edit, useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import CreateForm from '../../Components/Form/CreateForm';
import { BsCardText } from 'react-icons/bs';
import { adicionalesInformation } from './AdicionalesCreate';
import { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import ModalCustom from '../../Components/UI/ModalCustom';
import { createNotification } from '../helpers/helpFunctions';

const AdicionalesEdit = () => {

  const tabs = [
    {
      component: adicionalesInformation(true),
      label: 'Información',
      icon: <BsCardText/>,

    }
  ];

  return (
    <Edit redirect={'list'} mutationMode='pessimistic'>
      <EditProvider tabs={tabs} />
    </Edit>
  );
};

export default AdicionalesEdit;

export const EditProvider = ({tabs}) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const transform = (data) => {
    redirect('list', 'adicionales');
    return data;
  };
  const handleCancelar = () => redirect('list', 'adicionales');
  const handleDelete = () => dataProvider.delete('adicionales', {id: record?.id}).then(res => {
    createNotification('success', 'Adicional Borrado con éxito');
    redirect('list', 'adicionales');
  }).catch(err => {
    createNotification('error', 'Hubo un error, por favor intente mas tarde.');
  });

  return (
    <>
      <CreateForm handleDelete={() => setOpen(true)} handleCancelar={handleCancelar} transform={transform} isEdit defaultValues={record} tabs={tabs} route={'adicionales'} />
      <ModalCustom
        open={open}   
        onClose={() => setOpen(false)}
        title="Borrar"
      >
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '50px'
          }}
        >
          <Typography>Estás seguro de eliminar este adicional?</Typography>
          <Button variant="contained" onClick={handleDelete}>Confirmar</Button>
        </Box>
      </ModalCustom>
    </>
  );
};