import React from 'react';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import { BsPinMapFill } from 'react-icons/bs';
import { paCustom} from './PuntoAccesoEdit';
import CreateForm from '../../Components/Form/CreateForm';



const CreatePAEdit = ({isEdit, record, ...props}) => {
  const tabs = [
    {
      component: paCustom(),
      label: 'Información',
      icon: <BsPinMapFill/>
    }
  ];
  
  return (
    <CreateForm tabs={tabs} route={'punto_acceso'} />
  );
  // return (
  //   <CustomTabbedForm tabs={tabs} deleteEnabled={isEdit} title='Punto de Acceso' noShow isEdit={isEdit} {...props}/>
  // );
};



export default CreatePAEdit;
