import { useState } from 'react';
import { Show, useDataProvider, usePermissions, useRecordContext } from 'react-admin';
import { BsCardText } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';

import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import CardBasic from '../../Components/UI/Show/CardBasic';
import HeaderCardShow from '../../Components/UI/Show/HeaderCardShow';
import TableMinimal from '../../Components/UI/Show/TableMinimal';
import { userCustom } from './UsersCreateedit';


const UsersShow = (props) => {

  return (
    <>
      <Show {...props}
        title={<TitleEdit title='Socio: ' source='nombre'/>} 
        aside={<AsideShow permis={props.permissions}/>} 
        actions={null}>
        <ShowTransparent>
          <InfoUser />
        </ShowTransparent>
      </Show>
    </>
  );
};

export default UsersShow;

const AsideShow = ({basePath, resource, permis, ...rest}) => {
  const record = useRecordContext();
  
  const data = record ? [
     
    {
      rest: rest,
      record: record,
      disabled: false,
      label: 'Eliminar Usuario',
      confirmTitle: `¿Eliminar ${record?.email}?`,
      confirmContent: '¿Seguro quieres eliminar este Usuario?',
      basePath: basePath,
    },
    { divider: true },
  
  ] : [];
  
  return(
    <AsideActionsShow data={data}/>
  );
};


export const InfoUser = () => {

  const [brand, setBrand] = useState([]);
  const [imagePermission, setImagePermission] = useState(''); // Desaprece con Brands
  const permis = usePermissions().permissions;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const dataArr = [
    {key: 'Role', value: record?.role},
    record?.role !== 'accesos' && {key: 'Merchants', value: `${record?.merchant?.length}`},
    record?.role === 'accesos' && {key: 'Sedes', value: `${record?.sede?.length}`},
    permis === 'superadmin' && {key: 'Brand', value: brand.name},
  ];

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'id', order: 'ASC'},
    filter: {}
  };  
      
  useQuery(
    ['brands', permis === 'superadmin'],
    () => dataProvider.getOne('brands', {id: record?.brand_id}),
    {
      onSuccess: ({data}) => setBrand(data),
      enabled: Boolean(permis === 'superadmin') && Boolean(record?.brand_id)
    }
  );
      
  const transform = (v) => {
    if(v.role === 'owner') {
      return {...v,
        merchant: [],
        spice: ''
      };
    }
    return v;
  };

  const editComponent = {
    component: userCustom(setImagePermission),
    label: 'Información',
    icon: <BsCardText/>
  };
  return (
    <CardBasic transform={transform} route={'users'} record={record} editComponent={editComponent}>
      <HeaderCardShow title={record?.email} />
      <TableMinimal data={dataArr} />
    </CardBasic>
  );
};

