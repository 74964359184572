import { useState } from "react"
import { Show, useRecordContext } from "react-admin"
import { BsCardText } from "react-icons/bs"
import { ShowTransparent } from "../../Components/CustomFields/FormTransparent"
import { TitleEdit } from "../../Components/CustomFields/TitleEdit"
import AsideActionsShow from "../../Components/UI/Show/AsideActionsShow"
import CardBasic from "../../Components/UI/Show/CardBasic"
import HeaderCardShow from "../../Components/UI/Show/HeaderCardShow"
import { removeAccents } from "../helpers/helpFunctions"


const ActividadesShow = (props) => {

    return(
        <Show
        {...props} 
        title={<TitleEdit title='Actividad: ' source='name'/>} 
        actions={null}
        aside={<AsideShow />}
        >
            <ShowTransparent>
                <ActividadesInfo />
            </ShowTransparent>
        </Show>
    )
}

export default ActividadesShow

const AsideShow = ({basePath, resource, permis, ...rest}) => {
    const record = useRecordContext()
  
    const data = record ? [
     
    {
        rest: rest,
        record: record,
        disabled: false,
        label: 'Eliminar Servicio',
        confirmTitle: `¿Eliminar ${record?.name}?`,
        confirmContent: '¿Seguro quieres eliminar este Servicio?',
        basePath: basePath,
      },
    { divider: true },
  
    ] : [];
  
    return(
      <AsideActionsShow data={data}/>
    );
  };

  export const ActividadesInfo = () => {
    const record = useRecordContext()
    const [slug, setSlug] = useState(record ? record.slug : '')
    const handlerName = (e) => {
      setSlug(removeAccents(e.target.value.toLowerCase().replace(/ /g, '-')));
    };
    const editComponent = {
      component: [
        {source: 'name', label:'Nombre', onChange: handlerName, xs: 12}, 
        {source: 'slug', label:'Slug', disabled: true, initialValue: slug, value: slug, xs:12},],
      label: 'Informacion',
      icon: <BsCardText />
    }
      return (
    <CardBasic route={'actividades'} record={record} editComponent={editComponent}>
        <HeaderCardShow
        title={record.name}
        subtitle={`Slug: ${record.slug}`}
        />
      </CardBasic>
      )
  }