import { CorporativoList } from './CorporativoList';
import { CorporativoEdit } from './CorporativoEdit';
import { CorporativoCreate } from './CorporativoCreate';
import CorporativoShow from './CorporativoShow';
import { IoBusiness } from 'react-icons/io5';


export default{
  icon: IoBusiness,
  list: CorporativoList,
  create: CorporativoCreate,
  show: CorporativoShow,
};