import React, { useEffect } from 'react';
import { useRedirect } from 'react-admin';

const RedirectResource = ({ sectorName }) => {
  const redirect = useRedirect();
  let token = localStorage.getItem('auth');
  token = token.slice(10, -2);
  let permissions = localStorage.getItem('permissions');
  const urlBaseMF = process.env.REACT_APP_MICROFRONT_SELECTED;
  
  const url = `${urlBaseMF}${sectorName}?token=${token}&permissions=${permissions}&merchant=${'undefined'}`;

  useEffect(() => {
    redirect(url);
  }, [redirect, url]);

  return <div />;
};

export default RedirectResource;