import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CardDashboard = ({children, onClick, sx, noPad}) => {
  return (
    <Box sx={{
      position: 'relative',
      width: 'auto',
      flex: '1 1 1',
      backgroundColor: '#fff',
      borderRadius: '6px',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      margin: '15px 5px',
      ...sx
    }}
    >
      <Box sx={!noPad && {padding: '15px 18px'}}>
        {children}
      </Box>
      {
        onClick && (
          <Box sx={{
            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', 
            padding: '12px 18px',
            cursor: 'pointer',
            borderBottomLeftRadius: '9px',
            borderBottomRightRadius: '9px',
            position: 'relative',
            bottom: '0',
            '&:hover':{
              backgroundColor: 'rgba(34, 87, 126, 0.1)',
              overflow:'hidden',
            }
          }}>
            <Typography variant='subtitle2' style={{color: 'rgba(0,0,0,0.75)'}}>Ver detalles</Typography>
            <NavigateNextIcon style={{color: 'rgba(0,0,0,0.75)'}}/>
          </Box>
        )
      }
    </Box>
  );
};

export default CardDashboard;