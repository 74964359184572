import { useContext, useEffect, useState, useCallback, useReducer, useMemo } from 'react';
import { apiUrl, merchantCadena, merchantContext } from '../../../DataProvider';
import axios from 'axios';
import { Box, Button, OutlinedInput, MenuItem, Skeleton, FormHelperText } from '@mui/material';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Input, InputLabel, Modal, Select, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createNotification, getMerchantId, getPermissions, getToken } from '../../helpers/helpFunctions';
import { useQuery } from 'react-query';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Filter, regex, useDataProvider, useResourceContext } from 'react-admin';
import { theme } from '../../../Theme/theme';
import { merchantCentral } from '../../../DataProvider';
import { DispatchErrorContext } from '../../../Context/menuContext';
import { MdClear } from 'react-icons/md';
import { RadioSelect } from '../../../Components/TextInputIcon/TextInputIcon';


// Estaria bueno cambiar todos los estados del filtro por un reducer.
const reducer = (state, action) => {
  if(action.choice === 'change') {
    if(state.plan === 'Cadena') {
      return {
        plan: 'Cadena'
      };
    } else if (state.plan === 'Locales') {
      return {
        plan: 'Local'
      };
    } else {
      return {
        plan: 'Central'
      };
    }
  } 
};

const planesReducer = (state, action) => {

  switch(true) {
  case action.set === 'locales': 
    return {
      ...state,
      locales: action.value
    };
  case action.set === 'centrales': 
    return {
      ...state,
      centrales: action.value
    };
  case action.set === 'multinegocio': 
    return {
      ...state,
      multinegocio: action.value
    };
  }
};

const CustomReport = ({ 
  filter, 
  msgError, 
  url, 
  disabledMerchs, 
  showDate, 
  selectedDate, 
  icon, 
  text, 
  variant,
  viewArchivados,
  viewDocumento,
  viewCategoria, 
  fullWidth, 
  viewCorporativos,
  viewVerticales,
  downloadText, 
  title, 
  viewSedes, 
  viewCobros, 
  viewPlanes, 
  viewSocios, 
  viewMerchants, 
  viewProspects, 
  viewPlanAccess, 
  ...props }) => {


  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          variant={variant}
          onClick={() => setOpen(true)}
          startIcon={loading ? <CircularProgress style={{ color: '#fff', width: '1rem', height: '1rem' }} /> : icon}
          disabled={loading}
          fullWidth={fullWidth}
          color='secondary'
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
          {...props}
        >
          {text}
        </Button>
      </Box>
      {
        open && (
          <CustomReportFilters 
            showDate={showDate} 
            disabledMerchs={disabledMerchs} 
            selectedDate={selectedDate} 
            viewCorporativos={viewCorporativos}
            text={text} 
            downloadText={downloadText} 
            viewCategoria={viewCategoria} 
            viewArchivados={viewArchivados} 
            url={url} 
            open={open} 
            viewVerticales={viewVerticales}
            viewDocumento={viewDocumento}
            setOpen={setOpen} 
            title={title} 
            viewCobros={viewCobros} 
            viewSocios={viewSocios} 
            viewPlanAccess={viewPlanAccess}
            viewMerchants={viewMerchants} 
            viewProspects={viewProspects} 
            viewSedes={viewSedes} 
            viewPlanes={viewPlanes}
          />
        )
      }
    </>
  );
};

export default CustomReport;

export const CustomReportFilters = ({ open,
  noFilter,
  setOpen,
  text,
  disabledMerchs,
  selectedDate, 
  showDate, 
  title, 
  viewArchivados, 
  viewCategoria, 
  titleAction, 
  url, 
  viewCorporativos,
  downloadText, 
  msgError, 
  viewSocios, 
  viewCobros,
  viewVerticales,
  viewPlanAccess,
  viewSedes, 
  viewPlanes, 
  viewMerchants, 
  viewDocumento,
  viewProspects }) => {
  const { getMerchant } = useContext(merchantContext);

  const today = new Date();
  const [filterAux, setFilterAux] = useState({ 'merchant': [] });
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [corporativos, setCorporativos] = useState([]);
  const [error, setError] = useState(false);
  const [verticales, setVerticales] = useState([]);
  const [sedeError, setSedeError] = useState(false);
  const [sedeSelected, setSedeSelected] = useState(null);
  const [disableAction, setDisableAction] = useState(false);
  const [accessTypeError, setAccessTypeError] = useState(false);
  const [nivelDeAcceso, setNivelDeAcceso] = useState([]);
  const [accesoDisabled, setAccesoDisabled] = useState(false);
  const [choice, dispatchChoice] = useReducer(reducer, 
    {
      plan: !getMerchant()?.negocio_central ? 'Locales' : 
        getMerchant()?.id === merchantCadena ? 'Cadena' : 
          'Central'
    });
  const [newPlanes, dispatch] = useReducer(planesReducer, 
    {
      locales: [],
      centrales: [],
      multinegocio: []
    });
  const permis = getPermissions();
  const resource = useResourceContext();
  const [date, setDate] = useState(
    selectedDate ?
      {
        from: selectedDate.from,
        until: selectedDate.until
      } : {
        from: new Date(today.getFullYear(), today.getMonth(), 1).toISOString().slice(0, 10),
        until: new Date(Date.now()).toISOString().slice(0, 10)
      }
  );

      
  const defaultFilter = text === 'Planes' || text === 'Sedes' ? {
    'archived': false
  } : showDate ? {
    'from': date.from,
    'until': date.until,
  } : {};

  // Logica para opcion de 'Todos' en input de sedes
  const isCadena = getMerchant()?.id === merchantCadena;
  const isSede = !getMerchant()?.negocio_central && getMerchant()?.id !== '' && permis !== 'accesos';
  const isCentral = getMerchant()?.negocio_central && getMerchant()?.id !== merchantCadena;
  const sedesTotal = (resource === 'sedes') && getMerchant()?.id === '' && (permis === 'superadmin' || permis === 'owner');
  const isCadenaSedesDashboard = isCadena && !viewDocumento;
  const superOwner = getMerchant()?.id === '';

  const [filter, setFilter] = useState(defaultFilter);

  const dataProvider = useDataProvider();

  const params = {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: 'id', order: 'ASC' },
    filter: {}
  };

  const {isFetching: isLoadingPlanes, refetch: refetchPlanes} = useQuery(
    ['planes'],
    () => dataProvider.getList('planes', {...params, filter:{ ['tipo-de-plan']: 'local'}}), { onSuccess: (d) => dispatch({set: 'locales', value: d.data}), enabled: false }
  );
  const {isFetching: isLoadingMultinegocio, refetch: refetchMultis} = useQuery(
    ['multinegocio'],
    () => dataProvider.getList('multinegocio', {...params, filter: {...params.filter, ['tipo-de-plan']:'cadena'}}), { onSuccess: (d) => dispatch({set: 'multinegocio', value: d.data}), enabled: false }
  );
  const {isFetching: isLoadingCentrales, refetch: refetchCentrales} = useQuery(
    ['multinegocio'],
    () => dataProvider.getList('multinegocio', {...params, filter: {...params.filter, ['tipo-de-plan']:'central'}}), { onSuccess: (d) => dispatch({set: 'centrales', value: d.data}), enabled: false }
  );
  const { isLoading: isLoadingMerchants, refetch} =  useQuery(
    ['merchants'],
    () => dataProvider.getList('merchants', params), { onSuccess: (d) => setMerchants(d.data), enabled: viewMerchants}
  );
  const { isLoading: isLoadingVertical, refetch: refetchVerticales} = useQuery(
    ['verticales'],
    async () => {
      const response = await dataProvider.getList('distinct/corporativo/vertical', params);
      return response.data;
    },
    {
      onSuccess: (data) => setVerticales(data),
      enabled: false
    }
  );
  const { isLoading: isLoadingCorporativos, refetch: refetchCorporativos} =  useQuery(
    ['corporativo'],
    () => dataProvider.getList('corporativo', params), { onSuccess: (d) => setCorporativos(d.data), enabled: false}
  );
  
  useEffect(() => {
    if(viewPlanes) {
      refetchMultis();
      refetchPlanes();
      refetchCentrales();
    }
  },[viewPlanes]);

  useEffect(() => {
    if(viewCorporativos) {
      refetchCorporativos();
    }
  },[viewCorporativos]);

  useEffect(() => {
    if(viewVerticales) {
      refetchVerticales();
    }
  },[viewVerticales]);

  // Empezar a setear filtros defualt en base al reporte seleccionado

  useEffect(() => {
    switch(true){
    case text=== 'Campañas':
      return setFilter({...filter, ['merchant']: [], ['vertical']: []});
    }
  },[text]);

  viewSedes && useQuery(
    ['sedes'],
    () => dataProvider.getList('sedes', params), { onSuccess: (d) => {
      let sedesArr = d.data;
      // if (sedesTotal || isCadenaSedesDashboard) sedesArr.unshift({id: '', name: 'Todos'});
      setSedes(sedesArr);
    }}
  );

  useEffect(() => {
    if (sedes.length > 0){
      let sedesArr = sedes;
      let index = sedes.indexOf({id: '', name: 'Todos'});
      if ((sedesTotal || isCadenaSedesDashboard) && index === -1) {
        sedesArr.unshift({id: '', name: 'Todos'});
        return setSedes(sedesArr);
      };
      return() => {
        if(index > -1) {
          let pop = sedes.splice(index, 1);
          setSedes(pop);
        };
      };
    }
  },[open, sedes]);

  const accesoArr = ['Flex', 'Plus', 'Local', 'Elite', 'Total'];
  const sociosStatusArr = ['activo', 'baja', 'inactivo'];
  const prospectosArr = [
    {
      key: 'abandoned',
      label: 'Abandonado'
    },
    {
      key: 'approved',
      label: 'Aprobado'
    },
    {
      key: 'rejected',
      label: 'Rechazado'
    },
    {
      key: 'error',
      label: 'Error'
    }
  ];
  const cobrosArr = ['Anual', 'Mensual'];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    minWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
  };


  useEffect(() => {
    showDate && setFilter((f) => ({ ...f, 'from': date.from, 'until': date.until }));
  }, [showDate, date, open]);


  //  INGRESOS Y EGRESOS
  useEffect(() => {
    (viewPlanAccess && text === 'Accesos' && !filter?.socio_documento || filter?.socio_documento === '') && setFilter((f) => ({ ...f, 'ingresos': true, 'egresos': false }));
    if (viewSedes && sedes?.[0]?.id && isSede) {
      setFilter((f) => ({ ...f, 'sede': sedes[0]?.id }));
      return;
    }

  }, [viewPlanAccess, open, viewSedes, sedes]);
  useEffect(() => {
    (getMerchant()?.id.length > 0 && merchants.length > 0 && viewMerchants) && setFilter((f) => ({ ...f, 'merchant': [merchants[0]?.id] }));
  }, [open, getMerchant, merchants]);
  
  const mailSend = async () => {
    //Revisar toda esta lógica para filtrar mejor sedes
    const alteredFilter = {...filter};
    if (viewSedes && permis === 'accesos' && Array.isArray(alteredFilter.sede)) {
      if(alteredFilter?.sede?.some(value => value === '')) {
        alteredFilter.sede = [];
      };
    } else if (viewSedes && Array.isArray(alteredFilter?.sede) && alteredFilter?.sede?.some(value => value === '')) {
      alteredFilter.sede = [];
    } else if(viewSedes && !Array.isArray(alteredFilter?.sede)) {
      alteredFilter.sede = new Array(alteredFilter.sede);
    }

    if(text === 'Planes' && superOwner) {
      if (!alteredFilter?.merchant) {
        alteredFilter.merchant = [];
      }
    }
    
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    axios({
      url: `${uri}/${url}?filter=${JSON.stringify(alteredFilter ? alteredFilter : filterAux)}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': getMerchant()?.id,
        'access-control-allow-origin': '*',
        'access-control-expose-headers': '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        createNotification('success', 'Recibirás el reporte solicitado en tu correo electrónico.');
        setOpen(false);
        setLoading(false);
        setFilter('');
      }).catch(err => {
        if (err?.response?.status === 404) {
          setLoading(false);
          createNotification('error', msgError ?? 'No existen datos para los filtros seleccionados');
          return;
        }
        createNotification('error', err.response?.data.msg ?? 'Hubo un error, por favor intentar más tarde.');
        setOpen(false);
        setLoading(false);
        setFilter('');
      });
  };

  const handleDocumento = (e) => {
    let regex = !/^[0-9/ -()]+$/.test(e.target.value);
    setFilter((f) => ({
      ...f, 
      'socio_documento': e.target.value,
      'sede': [],
      'nivel_de_acceso': [],
      'egresos': e.target.value !== '' ? true : false,
      'ingresos': true
    }));
    setAccesoDisabled(e.target.value !== '');
    setNivelDeAcceso(null);
    setSedeSelected(null);
    setDisableSede(e.target.value !== '');
    disableCheck(e.target.value !== '');
    e.target.value !== '' ? setDisableAction(regex) : setDisableAction(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFilter('');
  };

  useEffect(() => {
    isSede && setSedeSelected(getMerchant());
    return() => {
      setFilter(defaultFilter);
    };
  },[open]);

  useEffect(() => {
    if(text === 'Planes' && !superOwner && (filter?.['merchant'] || filter?.['merchant']?.length !== 0)) return setError(false);
    if(viewMerchants && !['Planes', 'Campañas'].includes(text) && (filter?.['merchant'] || filter?.['merchant']?.length !== 0)) return setError(false);
    if(viewSedes && filter?.sede) return setSedeError(false);
    if(viewSedes && filter?.['egresos']) return setSedeError(false);
  },[filter]);

  const handleValidations = () => {

    if((viewPlanAccess && text === 'Accesos' && (!superOwner || isCentral)) && (!filter.egresos && !filter.ingresos && (!filter?.socio_documento || filter?.socio_documento === ''))) return setAccessTypeError(true);
    if((viewPlanAccess && text === 'Accesos' && superOwner && filter?.sede?.length > 0 && (!filter.egresos && !filter.ingresos))) return setAccessTypeError(true);
    if(viewMerchants && !['Planes', 'Campañas'].includes(text) && (!filter?.['merchant'] || filter?.['merchant']?.length === 0 || filter?.['merchant'].length === 0)) return setError(true);
    if(viewMerchants && text === 'Planes' && !superOwner && (!filter?.['merchant'] || filter?.['merchant']?.length === 0 || filter?.['merchant'].length === 0)) return setError(true);
    if(viewSedes && (!filter?.sede || filter?.sede?.[0] === null) && !filter?.['egresos']) return setSedeError(true);
    if(viewSedes && (isCentral || superOwner) && filter?.['ingresos'] && (!filter?.sede || filter?.sede?.[0] === null || filter?.sede?.length === 0)) return setSedeError(true);
    if(viewSedes && superOwner && filter?.['egresos'] && (!filter?.sede || filter?.sede?.[0] === null || filter?.sede?.length === 0)) return setSedeError(true);
    setError(false);
    setSedeError(false);
    return mailSend();
  };
  // Lógica para blokear inputs en reporte de accesos

  const [disableSede, setDisableSede] = useState(false);
  const [disableCheckbox ,setDisableCheckbox] = useState(false);
  const disableCheck = (boolean) => {
    if(!superOwner || !isCentral && boolean){
      setFilter((f) => ({ ...f, 'ingresos': false, 'egresos': false }));
    }
    setDisableCheckbox(boolean);
  };

  const accesoHandler = (v) => {
    setFilter((f) => ({...f, 'nivel_de_acceso': v}));
    setNivelDeAcceso(v);
  };

  const planesFilter = useMemo(() => {
    switch (true) {
    case getMerchant()?.id === '' :
      return [{ name: 'local' }, { name: 'central' }, { name: 'cadena' }];
    case getMerchant()?.id === merchantCadena:
      return [{ name: 'cadena' }];
    case getMerchant()?.negocio_central && getMerchant()?.id !== merchantCadena:
      return [{ name: 'central' }];
    default:
      return [{ name: 'local' }, { name: 'cadena' }];
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2" margin='20px'>
              {title}
            </Typography>

            <IconButton style={{ width: '40px', height: '40px' }} aria-label="delete" onClick={handleClose} >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          {
            showDate && (
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-around', margin: '15px 15px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%', }}>
                  <Typography  style={theme.typography.subtitle2}>Desde</Typography>
                  <Input
                    
                  
                    type='date' defaultValue={date?.from} style={{ width: 'auto' }}
                    onChange={(e) => setDate({ ...date, from: e.target.value })}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%', }}>
                  <Typography style={theme.typography.subtitle2}>Hasta</Typography>
                  <Input type='date' style={{ width: 'auto' }}
                    onChange={(e) => setDate({ ...date, until: e.target.value })}
                    defaultValue={date?.until} />
                </Box>
              </Box>
            )
          }
          <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginBottom: '20px', alignItems: 'center' }}>
            {viewPlanAccess &&
              <FormControl style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}>
                {(!filter?.['nivel_de_acceso'] || filter?.['nivel_de_acceso']?.length <= 0 || !filter?.['nivel_de_acceso']) ?
                  <InputLabel style={{margin: '0.5vh 0 0 1.7vw', alignSelf: 'center'}}>Todos</InputLabel> : null}
                <Autocomplete
                  options={accesoArr}
                  onChange={(e,v) => accesoHandler(v)}
                  getOptionLabel={(option) => option}
                  multiple
                  disabled={accesoDisabled}
                  value={nivelDeAcceso ?? []}
                  // clearIcon={<MdClear />}
                  renderInput={(params) => <TextField {...params} label="Nivel de acceso" InputLabelProps={{shrink:true}} variant="outlined" />}
                />
              </FormControl>
            }
            {viewSocios &&
              <FormControl style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                <TextField
                  select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Estado del Socio"
                  variant="outlined"
                  SelectProps={{
                    multiple: true,
                    onChange: (e) => setFilter((f) => ({ ...f, 'status': e.target.value }))
                  }}
                  defaultValue={[]}
                  value={filter?.status}
                  style={{ width: '100%' }}
                >
                  <MenuItem variant="outlined" value={sociosStatusArr[0]}>{sociosStatusArr[0].charAt(0).toUpperCase() + sociosStatusArr[0].slice(1)}</MenuItem>
                  <MenuItem variant="outlined" value={sociosStatusArr[1]}>{sociosStatusArr[1].charAt(0).toUpperCase() + sociosStatusArr[1].slice(1)}</MenuItem>
                  <MenuItem variant="outlined" value={sociosStatusArr[2]}>{sociosStatusArr[2].charAt(0).toUpperCase() + sociosStatusArr[2].slice(1)}</MenuItem>
                </TextField>
              </FormControl>
            }
            {viewCobros &&
              <FormControl style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px', }}>
                <TextField
                  select
                  label="Tipo de cobro del plan"
                  variant="outlined"
                  onChange={(e) => setFilter((f) => ({ ...f, 'cobro': [e.target.value] }))}
                >
                  <MenuItem variant="outlined" value={cobrosArr[0]}>{cobrosArr[0]}</MenuItem>
                  <MenuItem variant="outlined" value={cobrosArr[1]}>{cobrosArr[1]}</MenuItem>
                </TextField>
              </FormControl>
            }
            {(viewPlanes || viewArchivados) && text !== 'Socios' &&
              <>
                <FormControl sx={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }} component="fieldset" variant="standard">
                  {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={(e) => setFilter((f) => ({ ...f, 'archived': e.target.checked }))} />
                      }
                      label="Sólo archivados"
                      slotProps={{ fontSize: '8px' }}
                    />
                  </FormGroup>
                </FormControl>
              </>
            }
            {viewVerticales && verticales?.length > 0 ? (
              <Autocomplete
                style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                variant={'outlined'}
                multiple
                disablePortal
                required
                disableCloseOnSelect
                limitTags={5}
                getOptionLabel={(option) => option.name}
                options={[...verticales]}
                onChange={(e, v) => setFilter((f) => ({ ...f, 'vertical': [v[0]?.name]}))}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Verticales" />}
                renderTags={(params) =>
                  params.map(e => <Typography variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e.name}</Typography>)}

              />) : viewVerticales && verticales?.length === 0 ? (
              <Skeleton style={{ width: '45%', margin: '10px 10px 15px 15px', padding: '0px 15px 15px 0px'}} />
            ) : null
            }
           
            {viewCorporativos && corporativos?.length > 0 ? (
              <Autocomplete
                style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                variant={'outlined'}
                multiple
                

                disablePortal
                required
                disableCloseOnSelect
                limitTags={5}
                getOptionLabel={(option) => option.name}
                options={[...corporativos]}
                onChange={(e, v) => setFilter((f) => ({ ...f, 'corporativo': v?.map(item => item?.id) }))}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Campaña" />}
                renderTags={(params) =>
                  params.map(e => <Typography variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e.name}</Typography>)}

              />) : viewCorporativos && corporativos?.length === 0 ? (
              <Skeleton style={{ width: '45%', margin: '10px 10px 15px 15px', padding: '0px 15px 15px 0px'}} />
            ) : null
            }
            {viewPlanes && (newPlanes?.locales?.length > 0 || newPlanes?.multinegocio?.length > 0 || newPlanes?.centrales?.length > 0)  ? (
              <Box sx={{width: '100%'}}>
                
                {planesFilter?.length > 1 && (
                  <RadioSelect
                    disabled={planesFilter?.length <= 1}
                    checked={choice === 'Locales'}
                    onClick={() => dispatchChoice({choice: 'change' })}
                    choices={planesFilter}
                    sx={{width: '100%'}}
                  />
                )}

                <Autocomplete
                  style={{ width: '100%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                  variant={'outlined'}
                  multiple
                  disablePortal
                  required
                  disableCloseOnSelect
                  limitTags={5}
                  getOptionLabel={(option) => option.name}
                  options={choice.plan === 'Locales' ? newPlanes?.locales : choice.plan === 'Central' ? newPlanes?.centrales : newPlanes?.multinegocio}
                  onChange={(e, v) => setFilter((f) => ({ ...f, 'planes': v?.map(item => item?.id) }))}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Planes" />}
                  renderTags={(params) =>
                    params.map(e => <Typography variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e.name}</Typography>)}

                />
              </Box>
            ) : viewPlanes && (isLoadingPlanes || isLoadingMultinegocio || isLoadingCentrales) ? (

              <Skeleton style={{ width: '100%', margin: '10px 10px 15px 15px', padding: '0px 15px 15px 0px'}} />
            ) : null
            }
            {
              viewDocumento && !isSede && permis !== 'accesos' && (
                <FormControl sx={{width: '50%'}} variant="standard">
                  <TextField 
                    type="tel"
                    pattern={/^[0-9/ -()]+$/}
                    disabled={sedeSelected}
                    sx={{ width: '100%', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                    label="Documento"
                    InputLabelProps={{
                      style: {width: '100%', padding: '0 15px 15px 15px', borderRadius: '5px'},
                      shrink: true
                    }}
                    error={filter.socio_documento && !/^[0-9/ -()]+$/.test(filter.socio_documento)}
                    variant="outlined"
                    onChange={handleDocumento}
                  />
                  <FormHelperText sx={{color: theme.palette.error.main, position: 'absolute', bottom: -10,  marginLeft: '15px' }}>{filter.socio_documento && !/^[0-9/ -()]+$/.test(filter?.socio_documento) ? 'Solo se admiten números' : ''}</FormHelperText>

                </FormControl>
              )
            }
            {
              viewSedes && (
                (sedes?.length > 0) ? (
                  <Autocomplete
                    sx={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                    variant={'outlined'}
                    disabled={disableSede || isSede}
                    disablePortal
                    required
                    value={sedeSelected}
                    disableCloseOnSelect
                    limitTags={5}
                    getOptionLabel={(option) => option.name}
                    // getOptionDisabled={getOptionDisabled}
                    defaultValue={isSede ? sedes?.find(item => item.id === getMerchant()?.name)?.id : sedes[0]?.name}
                    options={sedes ?? []}
                    onChange={(e, v) => {
                      setSedeSelected(v);
                      return setFilter((f) => ({ ...f, 'sede': [v?.id] }));
  
                    }}
                    renderInput={(params) =>
                      <>
                        <TextField error={sedeError} disabled={true} {...params} variant="outlined" label="Sedes"/>
                        <FormHelperText style={{color: theme.palette.error.main , position: 'absolute', top: '5', marginLeft: '5px'}}>{sedeError ? 'Falta el campo de sedes' : ''}</FormHelperText>
                      </> 
                    }
                    // renderTags={(params) => {
                    //   return params.map(e => <Typography  variant="outlined" style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e?.name}</Typography>);
                    // }}
                  />
                ) : (
                  <Skeleton style={{ width: '45%', margin: '10px 10px 15px 15px', padding: '0px 15px 15px 0px'}}/>
                )
              )

            }
            {viewProspects &&
              <FormControl style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}>
                <TextField
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Estado del Pago"
                  variant="outlined"
                  defaultValue={[]}
                  value={filter?.payment_status}
                  select
                  SelectProps={{
                    multiple: true,
                    onChange: (e) => setFilter((f) => ({ ...f, 'payment_status': e.target.value }))

                  }}
                >
                  <MenuItem variant="outlined" value={prospectosArr[0].key}>{prospectosArr[0].label}</MenuItem>
                  <MenuItem variant="outlined" value={prospectosArr[1].key}>{prospectosArr[1].label}</MenuItem>
                  <MenuItem variant="outlined" value={prospectosArr[2].key}>{prospectosArr[2].label}</MenuItem>
                  <MenuItem variant="outlined" value={prospectosArr[3].key}>{prospectosArr[3].label}</MenuItem>
                </TextField>
              </FormControl>
            }
            {viewCategoria &&
              <FormControl style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px', }}>
                <TextField
                  select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Categoria"
                  variant="outlined"
                  defaultValue={[]}
                  value={filter?.payment_status}
                  SelectProps={{
                    multiple: true,
                    onChange: (e) => setFilter((f) => ({ ...f, 'categoria': e.target.value }))
                  }}
                >
                  <MenuItem variant="outlined" value={'Total'}>Total</MenuItem>
                  <MenuItem variant="outlined" value={'Plus'}>Plus</MenuItem>
                  <MenuItem variant="outlined" value={'Elite'}>Elite</MenuItem>
                </TextField>
              </FormControl>

            }
            {
              (viewMerchants && merchants.length > 0 && !isLoadingMerchants) ?
                <Autocomplete
                  style={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                  variant={'outlined'}
                  multiple
                  disablePortal
                  required
                  disableCloseOnSelect
                  disabled={disabledMerchs}
                  limitTags={5}
                  getOptionLabel={(option) => option.name}
                  options={merchants}
                  FormHelperTextProps={{
                    position: 'absolute',
                    top: '5'
                  }}
                  defaultValue={getMerchant()?.id !== '' ? [merchants[0]] : []}
                  onChange={(e, v) => { setFilter((f) => ({ ...f, 'merchant': v?.map(item => item?.id)})); }}
                  renderInput={(params) => 
                    <>
                      <TextField  {...params} error={error} variant="outlined" label="Negocios" />
                      <FormHelperText style={{color: theme.palette.error.main , position: 'absolute', top: '5', marginLeft: '5px'}}>{error ? 'Falta el campo de negocios' : ''}</FormHelperText>
                    </>
                  }
                  renderTags={(params) =>
                    params.map(e => <Typography style={{ fontSize: '0.8em', borderRadius: '5px', fontWeight: 400, padding: '3px 8px', margin: '0 3px', backgroundColor: '#e2f1f8' }}>{e?.name}</Typography>)}

                /> : viewMerchants && merchants.length === 0 ? (
                  <Skeleton style={{ width: '40%', margin: '10px 10px 15px 15px', padding: '0px 15px 15px 0px'}} />
                ) : null
            }
            {(viewPlanAccess && text === 'Accesos')  &&
              <>
                <FormControl 
                  sx={{ width: '50%', margin: '10px 0', padding: '0 15px 15px 15px', borderRadius: '5px' }}
                  error={accessTypeError}
                  component="fieldset"
                  variant="standard"

                >
                  <FormLabel component="legend">Tipo de accesos</FormLabel>
                  <FormGroup
                  >
                    <FormControlLabel
                      checked={filter?.['egresos']}

                      control={
                        <Checkbox 
                          checked={filter?.['ingresos']}
                          onChange={(e) => {
                            setDisableSede(false);
                            isSede && !sedeSelected && setSedeSelected(getMerchant());
                            setFilter((f) => ({ ...f, 'ingresos': e.target.checked }));
                            setAccessTypeError(false);
                          }} />
                      }
                      disabled={disableCheckbox || (!superOwner && !isSede ? filter?.['egresos'] : false)}
                      label="Ingresos"
                      slotProps={{ fontSize: '8px' }}
                    />
                    
                    <FormControlLabel
                      checked={filter?.['egresos']}
                      
                      control={
                        <Checkbox 
                          checked={filter?.['egresos']}
                          onChange={(e) => {
                            !isSede && setSedeSelected(null);
                            isCentral && setDisableSede(e.target.checked);
                            setFilter((f) => ({ ...f, 'egresos': e.target.checked }));
                            setAccessTypeError(false);
                          }} />
                      }
                      label="Egresos"
                      disabled={disableCheckbox  || (!superOwner && !isSede ? filter?.['ingresos'] : false)}
                      slotProps={{ fontSize: '8px' }}
                    />
                    
                  </FormGroup>
                  <FormHelperText>{accessTypeError ? 'Es necesario seleccionar un tipo de acceso' : ''}</FormHelperText>
                </FormControl>
              </>
            }
          </Box>
          <Button 
            disabled={loading || disableAction}
            endIcon={loading && 
           <CircularProgress style={{ color: '#fff', width: '1rem', height: '1rem' }} />
            }
            style={{ borderRadius: '20px', color: 'white', }}
            onClick={handleValidations} 
            variant={'contained'}>{titleAction ?? 'Enviar'}</Button>
        </Box>
      </Modal>
    </>
  );
};