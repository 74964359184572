import React, { useState } from 'react';
import { CardMedia, Box , ImageField, TextField} from '@mui/material';
import { TextInput, useRecordContext } from 'react-admin';
import ButtonAws from './ButtonAws';


// const CardAws = ({text, disabledImage, sx, small, defaultUrl, source }) => {
const cardAws = (text, source, defaultUrl, disabledImage, sx, small) => {
  const record = useRecordContext();
  const hasRecord = record?.[source.slice(0,-4)];
  const initalUrl = hasRecord ? hasRecord.url : defaultUrl ? defaultUrl : '';
  const [url, setUrl] = useState(initalUrl);

  const handleUrl = (url) => {
    setUrl(url);
  };
  
  const data = [
    {
      custom: true,
      component: 
      <>
        <Box sx={sx}>
          <ButtonAws setUrl={handleUrl} text={text} small={small} />
          {!disabledImage &&
        <Box sx={{ padding: '20px 20px 20px 0', width: '350px' }}>
          { 
            url?.length > 0 ? 
              <CardMedia component='img' height='190' image={url} /> : null
              // <ImageField source={`${source}`} title="title"/> 
              
          }
        </Box>
          }
          { url?.length > 0 &&
        <TextField 
          variant='outlined' disabled initialValue={url}
          value={url}
        />
          }
        </Box>
      </>
    },
    {
      source: source,
      displayNone: true,
      value: url
    }
  ];

  return data;
};

export default cardAws;

export const CardAws = ({text, disabledImage, sx, small, defaultUrl, source, setUrl, url }) => {

  return (
    <Box sx={{width: '200px', ...sx}}>
      
      {!disabledImage &&
        <Box sx={{ padding: '20px 20px 20px 0', width: '150px', margin: '20px' }}>
          { 
            url?.length > 0 ? 
              <CardMedia component='img' height='130' image={url} /> : null
            // <ImageField source={`${source}`} title="title"/> 
            
          }
        </Box>
      }
      { url?.length > 0 &&
      <Box style={{dispaly: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <TextField 
          variant='outlined' disabled initialValue={url}
          value={url}
        />
        <ButtonAws setUrl={setUrl} text={text} small={small} />
      </Box>
      }
    </Box>
  );
};