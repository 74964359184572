import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { BooleanInput, required, TextInput } from 'react-admin';
import { removeAccents } from '../helpers/helpFunctions';
import SectionForm from '../../Components/Form/SectionForm';
import { BsCardImage, BsCardText } from 'react-icons/bs';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import CardAws from '../../Components/CustomFields/CardAws';
import { BsWifi } from 'react-icons/bs';
import ImagesEdit from './SectionsEdit/ImagesEdit';
import CreateForm from '../../Components/Form/CreateForm';
import cardAws from '../../Components/CustomFields/CardAws';
import { imagesCardEdit } from './SectionsEdit/ImagesCardEdit';


const BrandsCreateEdit = ({isEdit, ...props}) => {

  const tabs = [
    {
      component: brandsCustom(),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: imagesCardEdit(),
      label: 'Imagenes',
      icon: <BsCardImage/>
    }
  ];
  
  return (
    <CreateForm route="brands" tabs={tabs} />
  );
};

export const brandsCustom = () => {
  const [slug, setSlug] = useState('');

  const handlerName = (e) => {
    setSlug(removeAccents(e.target.value.toLowerCase().replace(/ /g, '-')));
  };

  const data = [
    {
      source: 'name',
      label: 'Nombre',
      onChange: (e)=> handlerName(e),
      validate: {
        required: 'El Nombre es requerido',
      },
      xs: 12
    },
    {
      source: 'email_contacto',
      label: 'Email de contacto',
      validate: {
        required: 'El email es requerido',
        pattern: {
          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          message: 'No es un email valido'
        }
      },
      xs: 12
    },
    {
      source: 'mail_sender_address',
      label: 'Email de respuestas',
      validate: {
        required: 'El email es requerido',
        pattern: {
          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          message: 'No es un email valido'
        }
      },
      xs: 12
    },
    {
      source: 'politica_privacidad',
      label: 'Politica de privacidad',
      xs: 12
    },
  ];
  return data;
};

const IconActividades = () => {

  const data = [
    {
      custom: true,
      component: <Box>
        <CardAws source='icon.url' text='Subir Icono'/>
      </Box>,
      xs: 12
    }
  ];
  return(
    <SectionForm data={data}/>
    
  );
};

export default BrandsCreateEdit;
