import React, { useEffect, useState } from 'react';
import {List, Datagrid, TextField, CreateButton, TopToolbar,
  ExportButton, TextInput, ReferenceInput, SelectInput, useRedirect, AutocompleteInput, useDataProvider, usePermissions, useResourceContext } from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';

const userFilter = [
  <TextInputIcon
    source="email" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];



const UsersList = (props) => {
  const [merchs, setMerchs] = useState([]);

  const permissions = usePermissions();
  const permis = permissions.permissions;
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  
  const handleClickCreate = () => {
    redirect('/users/create');
  };

  const userFilterSuperadmin = [
    <TextInputIcon
      source="email" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      style={{width: '100%'}}
    />,
    <AutocompleteInput source="merchant" label="Negocio" resettable alwaysOn variant='outlined' choices={merchs}/>
  ];

  useEffect(() => {
    const params = {
      pagination: {page: 1, perPage: 9999},
      sort: {field: 'name', order: 'ASC'},
      filter: {}
    };
    dataProvider.getList('merchants', params).then(({data}) => {
      setMerchs(data);
    });
  }, []);

  return (
    <List 
      bulkActionButtons={false} 
      actions={<MerchantListActions permis={permis}/>} 
      filters={permis == 'superadmin' ? userFilterSuperadmin : userFilter}
      perPage={25} 
      empty={
        <EmptyLayout
          handleClickCreate={(permis == 'superadmin' || permis == 'admin' || permis == 'owner') && handleClickCreate}
          title='No se encuentran usuarios cargados'
        />}
      {...props}
    >
      <DatagridCustom rowClick='show'>
        <TextField source="email" />
        <TextField source="role" />
      </DatagridCustom>
    </List>
  );
};

export default UsersList;

export const MerchantListActions = ({ permis}) => {

  const resource = useResourceContext();
  return (
    <TopToolbar>
      {
        (permis == 'superadmin' || permis == 'admin' || permis == 'owner') &&
        <CreateButton resource={resource}/>
      }
      <ExportButton />
    </TopToolbar>
  );
};
