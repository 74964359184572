import React from 'react';
import { Box } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import ubicationEdit from '../SectionsEdit/UbicationEdit';
import { FiMapPin } from 'react-icons/fi';
import { useRecordContext } from 'react-admin';
import { choicesProvincias } from '../../helpers/choicesSelects';
import { getPermissions } from '../../helpers/helpFunctions';


const UbicationCard = () => {

  const record = useRecordContext();
  const permis = getPermissions();
  const ubicationArray = [
    {key: 'Provincia', value: choicesProvincias?.find(item => item.id === record.provincia)?.name},
    {key: 'Zona', value: record.zona},
    {key: 'Direccion', value: record.direccion},
    // {key: 'Es del Interior', value: record['es-del-interior'] ? 'Si' : 'No'},
    {key: 'Link GoogleMaps', value: record['googlemaps-link'], link: true},
  ];

  const editComponent = {
    component: ubicationEdit(),
    label: 'Ubicación',
    icon: <FiMapPin/>
  };

  return (
    <CardBasic title='Ubicacion' route={'sedes'} editComponent={permis !== 'operador' && editComponent} record={record}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <TableMinimal data={ubicationArray} />
      </Box>
    </CardBasic>
  );
};

export default UbicationCard;