import {useEffect, useState} from 'react';
import CardDashboard from './Card/CardDashboard';
import CardContentSmall from './Card/CardContentSmall';

const AltasSmallCard = ({ cobrosMensuales, date, isFetchingMensuales}) => {
  const [datasets, setDatasets] = useState({});
  const newDate = (date.until).split('-');
  const helpText = 'Monto cobrado por mostrador, segmentado por tipo de cobro de los planes y cobros adicionales';
  
  useEffect(() => {
    let data = cobrosMensuales?.data?.json?.data;
    let newAltasArray = [];
    let arr = [];
    data && Object.keys(data?.altas)?.map(e => {
      newAltasArray = data?.altas[e];
    });
    newAltasArray.map(e => {
      arr.push({key: e?.pago, value: e?.monto});   
    });
    setDatasets({datasets: arr});
  }, [cobrosMensuales, date]);

  return(
    <>
      <CardDashboard noPad>
        <CardContentSmall isFetching={isFetchingMensuales} helpText={helpText} title={'Cobros de mostrador por tipo de cobro'} data={datasets} />
      </CardDashboard>
    </>
  );
};

export default AltasSmallCard;