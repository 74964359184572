
import { MdHomeWork } from 'react-icons/md';
import PlanCreate from '../PlanCreate';
import PlanEdit from '../PlanEdit';
import PlanesShow from '../PlanesShow';
import { PlanList } from '../PlanList';

export default{
  icon: MdHomeWork,
  list: PlanList,
  show: PlanesShow,
  create: PlanCreate
};