import { ThemeProvider } from '@mui/styles';
import PlanesShow from './PlanesShow';
import { theme } from '../../Theme/theme';
import {StylesProvider} from '@mui/styles';
import { useRecordContext } from 'react-admin';

const ShowWrapper = ({children}) => {

  const record = useRecordContext();
  return (
    <>
      <StylesProvider injectFirst>
        <PlanesShow record={record} />
      </StylesProvider>
    </>
  );
};

export default ShowWrapper;