import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import ButtonAws from '../../../Components/CustomFields/ButtonAws';
import { createNotification, getPermissions } from '../../helpers/helpFunctions';
import { merchantCentral, merchantContext } from '../../../DataProvider';

const tyCEdit = (record, merchant) => {
  const [value, setValue] = useState('custom');
  const [url, setUrl] = useState((record && record.tyc) ? record.tyc : null);
  const [urlDefault, setUrlDefault] = useState(null);
  const [newTyc, setNewTyc] = useState(null);
  const dataProvider = useDataProvider();

  
  const permis = getPermissions();
  const {getMerchant} = useContext(merchantContext);
  const isCentral = merchantCentral.find(item => item.id === getMerchant()?.id) ? true : false;
  const disabledApplication = (permis === 'superadmin' || permis === 'owner') && !isCentral;
  const legacyEdit = record && record?.['tipo-de-plan'] !== 'cadena' && record?.nivel_de_acceso !== 'Local' && !disabledApplication;


  useEffect(() => {
    merchant && 
      dataProvider.getOne('merchants', {id: merchant}).then(d => handleMerchant(d.data));
  }, [merchant]);

  const handleMerchant = (d) => {
    setUrlDefault(d.default_tyc);
    if(url && url !== d.default_tyc){
      setValue('custom');
    }
    // else{
    //   setUrl(d.default_tyc);
    // }
    // if(!record){
    //   form.change('tyc', d.default_tyc);
    // }
  };

  const handleChange = (urlDef) => {
    // form.change('tyc', urlDef);
    setNewTyc(urlDef);
  };

  useEffect(() => {
    (value && value === 'custom') && setNewTyc(null);
  },[value]);

  const handleDefault = (event) => {
    setValue(event.target.value);
    if(event.target.value == 'default'){
      setUrl(urlDefault);
      handleChange(urlDefault);
    }else{
      setUrl((record && record.tyc) && record.tyc);
    }
  };
  
  const data = [
    {
      custom: true,
      component: 
      <>
      
        <Box style={{width: '100%', margin: '16px'}}>
          <FormControl>
            <FormLabel style={{margin: '0 0 20px 0'}} id="demo-radio-buttons-group-label">Terminos y Condiciones</FormLabel>
            <RadioGroup
              value={value}
              onChange={(event) => handleDefault(event)}
              disabled={legacyEdit}
              name="radio-buttons-group"
            >
              <FormControlLabel value="default" control={<Radio onClick={() => setUrl(urlDefault)} disabled={!urlDefault || urlDefault?.length == 0 || legacyEdit}/>} label="Default del Negocio" />
              <FormControlLabel value="custom" control={<Radio disabled={legacyEdit} onClick={() => setUrl(null)} />} label="Particulares del Plan" />
            </RadioGroup>
          </FormControl>
          <Box style={{display: 'flex', gap: '50px'}}>
            {
              url && (
                <Button 
                  size='small' 
                  variant='outlined'
                  href={url ? url : record.tyc} 
                  target='_blank'>
                 Ver TyC
                </Button>
              )
            
            }
            {
              (value == 'custom') && (
                <Box>
                  <ButtonAws 
                    setUrl={setUrl} 
                    text='Subir TyC' 
                    disabled={legacyEdit}
                    small 
                    variant='contained' 
                    finish={handleChange}
                    accept='.pdf, .docx'
                    succesText='Archivo subido con éxito'
                    failedText='No se pudo subir el archivo'
                  />
                </Box>
              )
            }
          </Box>
        </Box>
   
      </>
    },
    {
      source: 'tyc',
      displayNone: true,
      value: newTyc,
      validate:{
        validate: {
          isRequired: (e) => (value === 'custom' && !newTyc) ? createNotification('warning', 'Si seleccionas Particulares del plan, debes subir los nuevos TyC acorde al plan.'):  true
        }
      }
    }
  ];

  return data;
};

export default tyCEdit;