import { Create, useDataProvider, useRecordContext } from 'react-admin';
import CreateForm from '../../Components/Form/CreateForm';
import { BsCardText } from 'react-icons/bs';
import SectionTabForm from '../../Components/Form/SectionTabForm';
import FormTab from '../../Components/Form/FormTab';
import { getMerchantId, getPermissions } from '../helpers/helpFunctions';
import { merchantContext } from '../../DataProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';


export const AdicionalesCreate = () => {

  const tabs = [
    {
      component: adicionalesInformation(),
      icon: <BsCardText/>,
      label: 'Información'
    }
  ];

  return (
    <>
      <Create mode="pessimistic">
        <CreateForm tabs={tabs} route={'adicionales'} />
      </Create>
    </>
  );
};


export const adicionalesInformation = (isEdit) => {

  const { getMerchant } = useContext(merchantContext);
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'id', order: 'ASC'},
    filter: {}
  };  

  const { data: { data: merchants} = {}} = useQuery(['merchants'], () => {
    
    return dataProvider.getList('merchants', params);

  },
  {
    onError: (err) => { throw new Error();}
  }
  );

  const data = [
    {
      source: 'name',
      validate: {
        required: 'El campo es obligatorio'
      },
      label: 'Nombre',
      xs: 12,
            
    },
    {
      source: 'price',
      type: 'tel',
      validate: {
        required: 'El campo es obligatorio',
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'La duración solo puede contener números.'
        }
      },
      label: 'Precio',
      xs: 12,
              
    },
    merchants?.length > 0 ? {
      source: 'merchant_id',
      type: 'autocomplete',
      choices: merchants,
      label: 'Negocio',
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: isEdit ? record?.merchant_id : getMerchant()?.id,
      xs: 12,
      validate: {
        required: 'El negocio es requerido'
      }
    } : {
      custom: true,
      component: <Skeleton height="50px" />,
      xs: 12
    },
    {
      source: 'duration',
      label: 'Duración (días)',
      type: 'tel',
      validate: {
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'La duración solo puede contener números.'
        }
      },
      xs: 12
    },
    {
      source: 'merchant_name',
      value: null,
      displayNone: true,
      erase: true
    }
  ];

  return data;
};

