import { useState } from 'react';
import { CustomReportFilters } from '../../Resources/Dashboard/Reportes/CustomReport';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



export const ReportButton = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button startIcon={<DownloadIcon />} style={{textTransform: 'uppercase', padding: '10px'}} onClick={() => setOpen(true)}>
    exportar
      </Button>
      {open && (
        <CustomReportFilters
          style={{width: '200px'}}
          variant="outlined"
          open={open}
          setOpen={setOpen}
          {...props}
        />
      )
      }
    </>
  );
};