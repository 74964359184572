import React, { useEffect, useState } from 'react';
import { Box, Chip, IconButton, Popover, Skeleton, Typography } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useDataProvider, useRecordContext } from 'react-admin';
import DotDivider from '../../../Components/UI/DotDivider';
import { dateFormat } from '../../helpers/helpFunctions';
import { FiExternalLink } from 'react-icons/fi';
import { theme } from '../../../Theme/theme';
import { emptyBoxStyle, styleInnerTitleCard, styleSpanPlan } from '../../../Theme/useStyle';
import planSocio from '../SectionsEdit/PlanSocio';
import { BiUser } from 'react-icons/bi';
import { grey } from '@mui/material/colors';
import moment from 'moment';



const PlanCard = ({plan, loading}) => {
  const record = useRecordContext();
  const [corp, setCorp] = useState(null);
  const dataProvider = useDataProvider();
  const [isPlanMN, setIsPlanMn] = useState(false);
  const [showPlan, setShowPlan] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    setShowPlan(!loading && plan?.name);
  },[plan, loading]);

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const endPeriodDate = () => {
    let initialDate = new Date(record?.fecha_vigencia);
    if(plan?.cobro === 'Mensual' && record?.status === 'activo') {
      return new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate() - 21).getTime();
    }
    return initialDate.getTime();
  };

  useEffect(()=> {   
    record?.plan_corporativo && dataProvider.getOne('corporativo', {id: record?.plan_corporativo}).then(d => setCorp(d?.data));
    !loading && record &&  setIsPlanMn(plan?.['tipo-de-plan'] === 'cadena');
  }, [plan]);

  const editComponent = {
    component:  planSocio(plan),
    label: 'Informacion Socio',
    icon: <BiUser />
  };
  
  const capitalLetter = (str) => {
    return str?.[0].toUpperCase() + str?.slice(1).toLowerCase();
  };

  return (
    <CardBasic title='Plan' route={'socios'} record={record} editComponent={editComponent}>
      <Box style={{display: 'flex', gap: '2%'}}>
        {
          record?.status !== 'externo' ? 
            ( loading && !plan ? (
              <Box style={{width: '100%'}}>
                <Skeleton animation='wave' style={{width: '60%'}}/> 
                <Skeleton animation='wave' style={{width: '70%'}}/> 
                <Skeleton animation='wave' style={{width: '80%'}}/> 
              </Box>
            ) : (
              <>
                <Box style={{display: 'flex', alignItems: 'center', width: '50%', gap: '5px'}}>
                  <Box style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    {
                      showPlan && plan ? (
                        <a disabled href={`#/${isPlanMN ? 'multinegocio' : 'planes'}/${plan?.id}/show`} style={styleInnerTitleCard}>
                          <Typography variant='subtitle2' style={{color: 'priamry'}} >
                            {plan?.name} 
                            {/* <FiExternalLink style={{ color: theme.palette.grey[600], marginLeft: '10px'}}/> */}
                          </Typography>
                        </a> 
                      ) : loading ? <Skeleton sx={{width: '100px'}} /> : (
                        <Typography variant='subtitle2' style={{color: 'priamry'}} >
                            Plan Eliminado
                          {/* <FiExternalLink style={{ color: theme.palette.grey[600], marginLeft: '10px'}}/> */}
                        </Typography>
                      )
                    }
                    {corp &&
                      <a href={`#/corporativo/${corp?.id}/show`} style={styleInnerTitleCard}>
                        <Typography variant='subtitle2' style={{color: 'priamry'}} >
                          {corp.name}
                          {/* <FiExternalLink style={{ color: theme.palette.grey[600], marginLeft: '10px'}}/> */}
                        </Typography>
                      </a> 
                    }
                    <Typography variant='subtitle2'>
                      <span style={styleSpanPlan}>{record?.status !== 'inactivo' ? 'Alta' : 'Inactivación'} </span>
                      {record?.status !== 'inactivo' ? moment(record?.last_subscription_date).format('DD/MM/YYYY') : moment(record?.fecha_vigencia).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                </Box>
                <Box style={{display: 'flex', flexDirection: 'column', gap: '5px', width: '100%',alignItems: 'start'}}>
                
                  <Box style={{display: 'flex', gap: '5%', width: '100%'}}>
                    <Box style={{display: 'flex',flexDirection: 'column',gap: '5px', maxWidth: '175px', marginTop: '6px', alignItems: 'start'}}>
                      <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Inicio de periodo<br /> </span>
                        {moment(record?.last_subscription_date).format('DD/MM/YYYY')}
                      </Typography><Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Fin de periodo<br /> </span>
                        {moment(endPeriodDate()).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Fecha de Vigencia<br /> </span>
                        {/* {record?.status === 'inactivo' ? dateFormat(record?.fecha_vigencia?.slice(0, 10)) : new Date(record?.fecha_vigencia).toLocaleString().split(',')[0]} */}
                        {record?.status === 'inactivo' ? moment(record?.fecha_vigencia).format('DD/MM/YYYY') : record?.status === 'baja' ? moment(record?.fecha_vigencia).format('DD/MM/YYYY') : moment(record?.fecha_vigencia).format('DD/MM/YYYY')}

                      </Typography>
                      {/* <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Vigencia </span>
                        {dateFormat(newVigencia)}
                      </Typography> */}
                    </Box>
                    <Box style={{height: '100%', width: '50px',  marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'end' }}>
                      <Box style={{marginBottom: '10px',}}>
                        <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
                          <HelpOutlineIcon sx={{fontSize: '21px'}} />
                        </IconButton>
                        <Popover
                          open={openMenu}
                          anchorEl={anchorEl}
                          onClose={handleCloseMenu}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                        >
                          <Box sx={{
                            maxWidth: '320px',
                            backgroundColor: '#fff',
                            padding: '15px 18px'
                          }}>
                            <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}>La fecha de vigencia corresponde a 20 días de gracia luego del fin del periodo activo.<br /> Si se da de baja este socio, la fecha de vigencia corresponderá a la fecha de fin del periodo</Typography>
                          </Box>
                        </Popover>
                      </Box>
                    </Box>
                    <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '6px'}}>
                      <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Precio </span>
                      ${plan?.price}
                      </Typography>
                      <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Cobro </span>
                        {plan?.cobro} 
                      </Typography>
                      <Typography variant='subtitle2'>
                        <span style={styleSpanPlan}>Método de pago <br /></span>
                        {capitalLetter(record?.preferred_payment_method)} 
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )) : (
              <Box style={emptyBoxStyle}>
                <Typography variant='subtitle2'>
                  Este socio no tiene ningun plan asociado
                  {
                    record?.status == 'externo' && (
                      ' (Externo)'
                    )
                  }
                </Typography>
              </Box>
            )}
      </Box>  
    </CardBasic>
  );
};

export default PlanCard;
