import React from 'react';
import { Create } from 'react-admin';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';
import CorporativoCreateEdit from './CorporativoCreateEdit';

export const CorporativoCreate = (props) => {

  // const transform = (data) => {
  //   delete data['precio-empleado']
  //   delete data['precio-familiar']

  //   return data
  // }

  return (
    <Create 
      {...props}
      // transform={transform}
      title={<TitleEdit title='Crear Campaña '/>} 
    >
      <CorporativoCreateEdit/>
    </Create>
  );
};