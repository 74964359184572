import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Skeleton, Typography, Pagination } from '@mui/material';
import { Datagrid, DateField, ListContextProvider, TextField, useDataProvider, useList, useListContext, useListController, useRefresh, useUpdate } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import DownloadIcon from '@mui/icons-material/Download';
import ModalCoupons from '../../../Components/UI/ModalCoupons';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import keyBy from 'lodash/keyBy';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { emptyTextStyle, styleButtonSection } from '../../../Theme/useStyle';
import { useQuery } from 'react-query';
import ModalCustom from '../../../Components/UI/ModalCustom';
import { theme } from '../../../Theme/theme';
import { createNotification } from '../../helpers/helpFunctions';
import { makeStyles } from '@mui/styles';
import { useRecordContext } from 'react-admin';
import { useGetList } from 'react-admin';

const CouponsCard = () => {
  const record = useRecordContext();
  const [coupons, setCoupons] = useState(null);
  const [allCoupons, setAllCoupons] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [update, { loading, error }] = useUpdate();

  const handleCoupons = (d, t) => {
    setCoupons(d);
    setTotal(t);
  };

  // const { data: plan, isLoading: loadingPlanes} = useQuery(
  //   ['planes', record.plan_id],
  //   () => dataProvider.getOne('planes', {id: record.plan_id}),
  // );

  const params = {
    sort: {field: 'id', order: 'DESC'},
    pagination: {page: page, perPage: 5},
    filter: {corporativo_id: record?.id}
  };

  const { isFetching } = useQuery(
    ['descuentos', {id: record?.id}, page], 
    () =>  dataProvider.getList('descuentos',  params),
    {
      onSuccess: (e) => {
        // if(e?.data?.length > 0){
        //   const mont = Math.max(...e.data.map((c) => c.monto_absoluto)); //maximo monto absoluto de los cupones
        //   const porc = Math.max(...e.data.map((c) => c.porcentaje));  //maximo porcentaje de los cupones
        //   let maxPorcentaje = 0;
        //   const maxPorcentajeMonto = ((100 * mont) / plan?.data?.price).toFixed(2); //calculo el porcentaje del maximo monto absoluto
        //   maxPorcentaje = Math.max(porc, maxPorcentajeMonto); //calculo maximo entre el maximo porcentaje y maximo monto porcentaje
          
        //   if(record?.['porcentaje-descuento-empleado']){
        //     maxPorcentaje += record['porcentaje-descuento-empleado']; 
        //   }

        //   const cuponDescuento = Math.min(100, maxPorcentaje);

        //   let dataSend = {'porcentaje-cupon': cuponDescuento};
        //   if(record?.cupon == false){
        //     dataSend = {...dataSend, cupon: true};
        //   }
        //   // update('corporativo',{ id: record?.id, data: dataSend, previousData: record});
        // }else if(record?.cupon == true){
        //   update('corporativo', {id: record?.id, data: {cupon: false, 'porcentaje-cupon': record?.['porcentaje-descuento-empleado']} , previousData: record} );
        // }
        handleCoupons(e?.data, e?.total);
      },
      onError: (err) => {
        console.log(err);
        throw new Error(err);
      },
      // enabled: !loadingPlanes // Poner false para evitar el fetch inicial
    },
  );

  const exporter = () => {

    const params = {
      sort: {field: 'id', order: 'DESC'},
      pagination: {page: 1, perPage: 9999},
      filter: {corporativo_id: record?.id}
    };

    dataProvider.getList('descuentos',  params).then(({data}) => {
     
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const headerxls = [[
        'Codigo', 
        'Concepto', 
        'Porcentaje',
        'Cupo',
        'Aplicaciones',
        'Aplicaciones Totales',
        'Monto Absoluto',
        'Fecha de Vigencia', 
        'Fecha de Creacion',
      ]];
      const arrayxls = data.map(e => ([
        e.codigo,
        e.concepto,
        e.porcentaje,
        e.cupo,
        e.duracion,
        e.cantidad_de_aplicaciones,
        e.monto_absoluto,
        e.fecha_vigencia,
        e.created_at
      ]));
      const totalArray = headerxls.concat(arrayxls);
      const workSheet = XLSX.utils.aoa_to_sheet(totalArray);
      const workBook = {
        Sheets: { data: workSheet, cols: [] },
        SheetNames: ['data'],
      };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const fileData = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(fileData, 'Reporte de Rechazos' + fileExtension);
   
    });
  };

  return (
    <CardBasic 
      title='Cupones'
      asideAction={
        <>
          <Box style={{alignItems: 'center', justifyContent: 'center'}}>
            <Button 
              size='small' 
              variant='text'
              style={{...styleButtonSection, marginRight: '20px'}}
              onClick={exporter}
              startIcon={<DownloadIcon/>}
           
            >
          Exportar
            </Button>
            <Button 
              size='small' 
              variant='text'
              style={styleButtonSection}
              onClick={()=> setOpen(true)}
              startIcon={<AddOutlinedIcon/>}
            >
        Agregar Cupon
            </Button>
          </Box>
        </>
      }
    >
      {!isFetching ? (
        <DataGridCoupons
          record={record}
          coupons={coupons}
          page={page}
          setPage={setPage}
          total={total}
        />
      ) : (
        <Box style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
          <Skeleton animation='wave' style={{width: '80%'}}/>
          <Skeleton animation='wave' style={{width: '90%'}}/>
          <Skeleton animation='wave'/>
        </Box>
      )
      }
      <ModalCoupons record={record} open={open} setOpen={setOpen}/>
    </CardBasic>
  );
};

const DataGridCoupons = ({coupons}) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [idCoupon, setIdCoupon] = useState(null);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const { data, total, isLoading } = useGetList('descuentos', {
    pagination: { page: page, perPage: 5 },
    sort: { field: 'id', order: 'ASC' },
    filter: { corporativo_id: record?.id },
  }, {
    enabled: Boolean(record)
  });
  
  const useStyles = makeStyles(theme => ({
    backgroundColor: '#fff',
    
    
    thead: {
      backgroundColor: '#fff',
    },
    headerRow: {
      backgroundColor: '#fff',
    },
    row: {

      backgroundColor: '#fff',
    },
    table:{
      backgroundColor: '#fff',
    },
    rowCell: {
      borderBottom: 'none'
    
    }
  
  }));
  const classes = useStyles();

  const handlerEdit = (id) => {
    setIdCoupon(id);
    setOpen(true);
  };

  const handleDeleteAll = () => {
    dataProvider.delete('descuentos_corporativo', {id: record.id}).then(() => {
      createNotification('success', 'Cupón borrado con éxito');
      refresh();
    }
    ).catch((e) => {
      console.log(e.body);
      createNotification('error', 'Error al borrar los descuentos', );
    });
  };
  const listContext = useList({data: data, perPage: 5, isLoading: isLoading});

  
  return(
    <Box overflow={'scroll'}>
      { coupons?.length > 0 ? (
        <ListContextProvider
          value={listContext}
        >
          <Datagrid 
            rowClick={handlerEdit}
            classes={classes}
            bulkActionButtons={false}
          >
            <TextField label="Código" source="codigo" />
            <TextField source="concepto" />
            <TextField label="Duración" source="duracion" />
            <TextField source="porcentaje" />
            {/* <TextField source="cantidad_de_aplicaciones" /> */}
            <TextField label="Fecha creación" source="date_created" />
            <TextField source="cupo" />
            <TextField source="monto_absoluto" />
            <DateField source='fecha_vigencia' />
          </Datagrid>
          <Box style={{display: 'flex', justifyContent: 'space-between', width: '100%', margin: '21px 0'}}>
            <Button variant='contained' color='error' size='small' onClick={() => setOpenDeleteAll(true)}>Eliminar Todos</Button>
            <Pagination page={page} count={Math.ceil(total / 5)} onChange={(e, v)=> setPage(v)}/>
          </Box>
          <ModalCoupons idCoupon={idCoupon} record={record} open={open} setOpen={setOpen}/>
        </ListContextProvider>
      ) : (
        <Box style={{width: '100%', textAlign: 'center', margin: '20px 0'}}>
          <Typography style={emptyTextStyle}> No se encuentran cupones para esta campaña</Typography>
        </Box>
      )}
      <ModalCustom
        open={openDeleteAll}
        setOpen={setOpenDeleteAll}
        title={'Eliminar Cupones'}
      >
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '110px', paddingTop: '12px'}}>
          <Typography variant='subtitle2'>Se elminiaran todos los cupones de esta campaña</Typography>
          <Button 
            variant='contained'
            style={{backgroundColor: theme.palette.error.main}}
            onClick={handleDeleteAll}
          >Eliminar Todos</Button>
        </Box>
      </ModalCustom>
    </Box>
  );
};

export default CouponsCard;