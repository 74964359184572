import React, { useEffect, useState } from 'react';
import { TextInput, SelectInput, useDataProvider, required, SelectArrayInput, AutocompleteArrayInput, ImageField, useRecordContext, regex} from 'react-admin';
import { createNotification, getBrand, getListProvider, getMerchantId, getPermissions, getToken } from '../helpers/helpFunctions';
import SectionForm from '../../Components/Form/SectionForm';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import { BsCardText } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { FiImage } from 'react-icons/fi';
import ButtonAws from '../../Components/CustomFields/ButtonAws';
import { CardMedia, Box, Typography, FormControlLabel, Switch, TextField, Skeleton } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { apiUrl } from '../../DataProvider';
import axios from 'axios';
import CreateForm from '../../Components/Form/CreateForm';
import useCheckField from '../../Hooks/useCheckField';

const UsersCreateedit = ({isEdit, record, ...props}) => {
  const permis = getPermissions();
  const [imagePermission, setImagePermission] = useState('');
 
  const tabs = [
    {
      component: userCustom(setImagePermission),
      label: 'Información',
      icon: <BsCardText/>
    },
  ];

  return (
    <CreateForm tabs={tabs} route="users" />
  );
};

export const userCustom = (setImagePermission) => {
  const record = useRecordContext();
  const isEdit = record?.length > 0 ? true : false;
  const [merchants, setMerchants] = useState(null);
  const [selectRole, setSelectRole] = useState(record?.role ? record?.role : '');
  const [errorEmail, setErrorEmail] = useState(false);
  const [email, setEmail] = useState(isEdit ? record?.email : null);
  const [owners, setOwners] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [sedes, setSedes] = useState('');
  const [ownerId, setOwnerId] = useState(isEdit ? record.owner_id : null);
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(isEdit ? record?.brand_id : getToken()?.brand_id);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSedes, setSelectedSedes] = useState([]);
  const dataProvider = useDataProvider();
  const permis = getPermissions();
  const selectBrandId = permis == 'superadmin' && (selectRole !== 'superadmin' && selectRole !== 'owner' && selectRole !== '');

  // const checkEmail = regex(/\S+@\S+\.\S+/, 'El email no contiene un formato valido');
  
  useQuery(
    ['sedes'],
    () => dataProvider.getList('sedes', params),
    {
      onSuccess: ({data}) => setSedes(data)
    }
  );
  const tokenBrand =  getToken()?.brand_id;
  const checkEmail = useCheckField('distinct/users/email', 'email', email);
  const handlerEmail = async (e) => {
    const value = e.target.value;
    if(value == ''){
      setErrorEmail(true);
      // form.change('email', null);
      return;
    }
    const params = {
      sort: {field: 'nombre', order: 'ASC'},
      pagination: {page: 1, perPage: 5},
      filter: {email: value}
    };
    await dataProvider.getList('users', params).then((s) => {
      if(s.data.length > 0){
        // form.change('email', null);
        createNotification('warning', 'Ya existe un usuario con ese email');
        setErrorEmail(true);
      }else{
        // form.change('email', e.target.value);
        setEmail(value);
        setErrorEmail(false);
      }
    });
  };

  const getChoicesMerchant = (filter) => {
    const token = localStorage.getItem('auth');
    const tokenSend = JSON.parse(token)?.token;
    axios({
      url: `${apiUrl}/merchants?filter=${permis !== 'superadmin' ? JSON.stringify(filter) : '{}'}&range=[0,999]&sort=["id","ASC"]`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*'
      },
    }).then(({data}) => {
      setMerchants(data.data);
     
      if(selectRole == 'accesos' && data.data.some((el) => el.id == '6178652dcfb117ad58a2cd3d')){
        // form.change('merchant', ['6178652dcfb117ad58a2cd3d']);
      }else{
        if(!isEdit){
          // form.change('merchant', []);
        }
      }
    });
  };

  useEffect(()=> {    
    permis == 'superadmin' && dataProvider.getOneFilter('users?filter={"role": "owner"}').then(i => setOwners(i.data));
  }, []);

  useEffect(() => {
    if(record?.merchant && merchants?.length > 0) {
      for(let merch of merchants) {
        for(let rec of record.merchant) {
          if(merch.id === rec) {
            setSelectedMerchants((f) => ([...f, merch]));
          }
        }
      }
    }
    if(record?.sede && sedes?.length > 0) {
      for(let sede of sedes) {
        for(let rec of record.sede) {
          if(sede.id === rec) {
            setSelectedSedes((f) => ([...f, sede]));
          }
        }
      }
    }
    return () => {
      setSelectedMerchants([]);
      setSelectedSedes([]);
    };
  },[merchants, sedes]);

  useEffect(() => {
    setImagePermission(selectRole);
    if(isEdit){
      brandId && getChoicesMerchant({brand_id: brandId});
    }
  },[selectRole, brandId]);

  const handleSelectAll = (e, s) => {
    if(s){
      
      setSelectedMerchants(merchants);
      // form.change('merchant', merchantsSend);
    }else{
      setSelectedMerchants([]);
      // form.change('merchant', []);
    }
    setSelectAll(s);
  };


  const choicesDefault = [
    { id: 'superadmin', name: 'Superadmin', disabled: permis !== 'superadmin'},
    { id: 'owner', name: 'Owner', disabled: (permis !== 'superadmin' && permis !== 'owner')},
    { id: 'admin', name: 'Admin', disabled: (permis !== 'superadmin' && permis !== 'owner' && permis !== 'admin')},
    { id: 'operador', name: 'Operador'},
    { id: 'accesos', name: 'Solo Accesos'},
    { id: 'molinetes', name: 'Punto de Acceso'}
  ];

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field:'name', order:'ASC'},
    filter: {}
  };

  const handleSelectOwner = (e) => {
    setOwnerId(e.target.value);
    getChoicesMerchant({owner_id: e.target.value});
    // form.change('merchant', []);
  };

  const handleSelectBrand = (e) => {
    setBrandId(e.target.value);
    getChoicesMerchant({brand_id: e.target.value});
    // form.change('merchant', []);
  };

  useEffect(() => {
    setOwnerId(record?.owner_id);
  }, [record]);

  const handleChangeRole = (e) => {
    let val = e.target.value;
    // if(val == 'accesos'){
    //   // form.change('merchant', ['6178652dcfb117ad58a2cd3d']);
    // }
    setSelectRole(val);
  };
  
  useQuery(
    ['merchants', selectRole],
    () => {
      
      getChoicesMerchant({brand_id: brandId});
    },
    {
      onError: (err) => {throw new Error(err);}
    }
  );

  const{ refetch } = useQuery(
    ['brands'],
    () => dataProvider.getList('brands', params),
    {
      onSuccess: (data) => setBrands(data.data),
      enabled: false
    }
  );

  useEffect(() => {
    if(permis === 'superadmin') {
      refetch();
    }
  },[]);

  useEffect(() => {
    if(permis === 'owner' && !isEdit && !ownerId) {
      const loggedInfo = getToken();
      setOwnerId(loggedInfo.id);
    }
  },[permis, selectRole]);

  const data = [
    {
      source: 'role',
      type: 'select',
      label: 'Rol',
      choices: choicesDefault,
      optionLabel: 'name',
      optionValue: 'id',
      onChange: handleChangeRole,
      xs: 12
    },
    permis === 'superadmin' && selectRole !== '' && selectRole !== 'superadmin' && !record?.brand_id ? {
      source: 'brand_id',
      type: 'select',
      label: 'Brand',
      choices: brands, 
      optionText: 'name',
      optionValue: 'id',
      validate: {required: 'Falta el campo de Brand'},
      onChange: handleSelectBrand,
      xs: 12
    } : null,
    selectRole && {
      source: 'email',
      label: 'Email',
      // onBlur: handlerEmail,
      checkOnblur: checkEmail,
      onChange: (e) => setEmail(e.target.value),
      xs:  12,
      style: {marginTop: '8px'},
      validate: {
        required: 'El campo de email es requerido',
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: 'El email no contiene un formato valido'
        }
      },
      // parse: (value) => (value ? value.toLowerCase() : ''),
      value: email,
      error: errorEmail,
    },
    ((selectRole == 'accesos' || selectRole == 'molinetes') && sedes) ? {
      source: 'sede',
      type: 'multiple_autocomplete',
      label: 'Sedes',
      validate: { required: 'Falta el campo de Sedes'},
      choices: sedes,
      optionLabel: 'name',
      onChange: (e) => setSelectedSedes(e),
      value: selectedSedes,
      xs: 12

    } : ((selectRole == 'accesos' || selectRole == 'molinetes') && !sedes) ? {
      custom: true,
      component: <Skeleton width="100%" height="50px" />,
      xs: 12
    } : null ,
    (selectRole && selectRole !== 'superadmin' && selectRole !== 'owner' && merchants?.length > 0) ? {
      source: 'merchant',  
      label: 'Negocios',
      type: 'multiple_autocomplete',
      choices: merchants ?? [],
      value: selectedMerchants,
      onChange: (e) => setSelectedMerchants(e),
      style: {display: (selectRole === 'accesos' || selectRole === 'molinetes') ? 'none' : 'block'},
      validate: {required: (selectRole === 'accesos' || selectRole === 'molinetes') ? undefined : 'Falta el campo de Negocios'},
      optionLabel: 'name',
      xs: 12
    } : null,
    selectRole !== 'superadmin' && selectRole !== 'owner' && selectRole !== 'accesos' && selectRole !== 'molinetes' && selectRole !== '' && (brandId || permis === 'superadmin') && merchants?.length > 0 && {
      custom: true,
      component:  <FormControlLabel
        control={
          <Switch checked={selectAll} onChange={handleSelectAll} name="gilad" disabled={!merchants}/>
        }
        label="Seleccionar Todos"
      />,
      xs: 4
    } ,
    permis !== 'superadmin' && selectRole !== '' ? {
      source: 'brand_id', 
      initialValue: tokenBrand,
      value: tokenBrand,
      style: {display: 'none'},
      xs: 12
    } : null,
    {
      source: 'email',
      validate: [required((!errorEmail && email === null) ? 'Ya existe un socio con ese email en BuenClub' : 'Falta el campo Email en Información'), checkEmail],
      xs: 0,
      initialValue: email,
      style: {display: 'none'},
    },
  ];

  return data;

  // return(
  //   <SectionForm title={isEdit ? `Usuario ${record?.email}` : 'Crear Usuario'}  data={data}/>
  // );
};

export default UsersCreateedit;

export const imagenCustom = () => {

  const styledDiv = {
    margin: '20px 0px',
    width: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'start', 
    justifyContent:'space-between'
  };

  const record = useRecordContext();
  const isEdit = record.length > 0 ? true : false;
  const [lightUrl, setLightUrl] = useState(record?.image_light ?? '');
  const [darkUrl, setDarkUrl] = useState(record?.image_dark ?? '');
  const [horizontalUrl, setHorizontalUrl] = useState(record?.horizontal_white ?? '');
  const [logoUrl, setLogoUrl] = useState(record?.mail_logo ?? '');
  

  const handleLightUrl = (url) => {
    setLightUrl(url);
    // form.change('images.image_light', url);
    // form.change('image_light', url);
  };

  const handleDarkUrl = (url) => {
    setDarkUrl(url);
    // form.change("images.image_dark", url)
    // form.change('image_dark', url);
  };

  const handleHorizontal = (url) => {
    setHorizontalUrl(url);
    // form.change("images.horizontal_white", url)
    // form.change('horizontal_white', url);
  };

  const handleLogo = (url) => {
    setLogoUrl(url);
    // form.change("images.mail_logo", url)
    // form.change('mail_logo', url);
  };

  const data = [
    {
      source: 'images.image_light',
      initialValue: lightUrl,
      value: lightUrl,
      displayNone: true
    },
    {
      source: 'images.image_dark',
      initialValue: darkUrl,
      value: darkUrl,
      displayNone: true
    },
    {
      source: 'images.horizontal_white',
      initialValue: horizontalUrl,
      value: horizontalUrl,
      displayNone: true
    },
    {
      source: 'images.mail_logo',
      initialValue: logoUrl,
      value: logoUrl,
      displayNone: true
    },
    {
      custom: true,
      component:
      <>
       
        <Box sx={styledDiv}>
          <Typography variant="h7" style={{width: '100%', borderBottom: '1px solid #bdbdbd', margin: '10px 0', color: '#bdbdbd'}}>Light white</Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', margin: '10px 0'}}>
            {lightUrl && <CardMedia style={{marginRight: '20px'}} component="img" height="200" width={'20'} image={lightUrl} title="title"/>}

            <ButtonAws style={{width: '200px', justifyContent: 'center'}} setUrl={handleLightUrl} text={'Image Light'} />
          </Box>
          <Typography variant="h7" style={{width: '100%', borderBottom: '1px solid #bdbdbd', margin: '10px 0', color: '#bdbdbd'}}>Light Dark</Typography>
          <Box sx={{display: 'flex', margin: '10px 0' ,flexDirection: 'row'}}>
            {darkUrl && <CardMedia style={{marginRight: '20px'}} component="img" height="200" width={'20'} image={darkUrl} title="title"/> }  <ButtonAws style={{width: '200px', justifyContent: 'center'}} setUrl={handleDarkUrl} text={'Image Dark'} />
          </Box>
          <Typography variant="h7" style={{width: '100%', borderBottom: '1px solid #bdbdbd', margin: '10px 0', color: '#bdbdbd'}}>Horizontal image</Typography>
          <Box sx={{display: 'flex', margin: '10px 0' ,flexDirection: 'row'}}>
            {horizontalUrl && <CardMedia style={{marginRight: '20px'}} component="img" height="200" width={'20'} image={horizontalUrl} title="title"/> 
            }  <ButtonAws style={{width: '200px', justifyContent: 'center'}} setUrl={handleHorizontal} text={'Image Horizontal'} />
          </Box>
          <Typography variant="h7" style={{width: '100%', borderBottom: '1px solid #bdbdbd', margin: '10px 0', color: '#bdbdbd'}}>Logo image</Typography>
          <Box sx={{display: 'flex', margin: '10px 0' ,flexDirection: 'row'}}>
            {logoUrl && <CardMedia style={{marginRight: '20px'}} component="img" height="200" width={'20'} image={logoUrl} title="title"/> 
            }  <ButtonAws style={{width: '200px', justifyContent: 'center'}} setUrl={handleLogo} text={'Image Horizontal'} />
          </Box>
        </Box>
    
      </>
    }
  ];

  return data;
};

