import React, { useContext, useEffect, useState } from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { useDataProvider, useRecordContext } from 'react-admin';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { iconsChips } from '../../../Theme/useStyle';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import informacionEdit from '../SectionsEdit/InformacionEdit';
import { BsCardText } from 'react-icons/bs';
import { DispatchErrorContext } from '../../../Context/menuContext';

const CardHeader = ({permis, isAbleToEdit}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [merchant, setMerchant] = useState(null);
  const permission = localStorage.getItem('permissions');
  const isMulti = record?.['tipo-de-plan'] === 'cadena';

  const { clearAllDispatchFieldErrors} = useContext(DispatchErrorContext);

  useEffect(() => {
    permis !== 'operador' && 
      dataProvider.getOne('merchants', {id: record?.merchant_id}).then((d) => {setMerchant(d.data);});
  }, []);

  const chips = [
    merchant && {label: merchant.name, icon: <LocalAtmOutlinedIcon style={iconsChips}/>},
    {label: `$ ${record?.price}`, icon: <LocalOfferOutlinedIcon style={iconsChips}/>},
    {label: record?.cobro, icon: <EventAvailableOutlinedIcon style={iconsChips}/>},
    {label: record?.contacto, icon: <EmailOutlinedIcon style={iconsChips}/>}
  ];

  const transform = (data) => {
    if(data.precio_compare_2){
      if(typeof data.precio_compare_2 === 'number'){
        return({
          ...data, 
          precio_compare_2: '$ ' + data.precio_compare_2.toLocaleString('es'),
          name: data.name.trim()
        });
      }
    }
    return {...data, name: data?.name.trim()};
  };
  
  const editComponent = {
    component: informacionEdit(permission, setMerchant, isMulti),
    label: 'Información',
    icon: <BsCardText/>
  };


  return (
    <CardBasic route={isMulti ? 'multinegocio' : 'planes'} transform={transform} record={record} editComponent={isAbleToEdit && editComponent}>
      <HeaderCardShow 
        title={record?.['titulo-plan']} 
        subtitle={record?.slug}
        archived={record?.archivado}
        chips={chips}
      />
    </CardBasic>
  );
};

export default CardHeader;
