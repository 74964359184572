import React from 'react';
import { Box, Typography } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { dateFormat } from '../../helpers/helpFunctions';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { useRecordContext } from 'react-admin';

const SocioCard = () => {
  const record = useRecordContext();
  
  const dataArray = [
    {key: 'Nombre', value: record['socio_nombre'] },
    {key: 'Apellido', value: record['socio_apellido'] },
    {key: 'Documento', value: record['socio_documento'] },
    {key: 'Nacimiento', value: dateFormat(record['socio_nacimiento'])},
  ];

  return (
    <CardBasic>
      <TableMinimal data={dataArray} noBorderBottom/>
    </CardBasic>
  );
};

export default SocioCard;