import React, { useEffect, useState } from 'react';
import { Box, Chip, Link, Skeleton, Typography } from '@mui/material';
import { useDataProvider, useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import DotDivider from '../../../Components/UI/DotDivider';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { emptyTextStyle, fontTextInfoKey, styleInnerTitleCard, styleSpanPlan } from '../../../Theme/useStyle';
import { useQuery } from 'react-query';
import planEdit from '../SectionsEdit/PlanEdit';
import { CgGym } from 'react-icons/cg';

const PlanCard = ({permisToEdit, isSede, slugValue, editPrice, setEditHaberes, setSlugValue}) => {
  const record = useRecordContext();
  const [plan, setPlan] = useState(null);
  const dataProvider = useDataProvider();
  
  const {data, isLoading} = useQuery(
    ['planes', record.plan_id],
    () => dataProvider.getOne('planes', {id: record.plan_id}),
    {
      onSuccess: ({data}) => setPlan(data)
    }
  );

  useEffect(() => {
    record && setSlugValue(record.slug);
  },[]);

  const editComponent = {
    component: planEdit(slugValue, editPrice, setEditHaberes, plan),
    label: 'Plan',
    icon: <CgGym/>
  };

  return (
    <CardBasic confirmModal title='Plan' record={record} editComponent={permisToEdit && editComponent} route={'corporativo'}>
      {
        plan ? (
          <Box>
            <Box style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
              <Typography style={styleInnerTitleCard} >
                {plan?.name}
              </Typography>
              {plan?.archivado && (
                <Chip 
                  size='small' 
                  color='error' 
                  variant='outlined'
                  label='Archivado'
                />
              )}
            </Box>
            <Box style={{display: 'flex', flexDirection: 'column', marginTop: '10px', gap: '5px'}}>
              <Box style={{display: 'flex'}}>
                <Typography style={fontTextInfoKey}>
                  Precio Original: <span style={styleSpanPlan}>${plan?.price}</span>
                </Typography>
                {
                  record.cupon && record['porcentaje-cupon'] && (
                    <Box style={{display: 'flex'}}>
                      <DotDivider/>
                      <Typography style={fontTextInfoKey}>
                        Descuento con Cupon: <span style={styleSpanPlan}>{record['porcentaje-cupon']}%</span>
                      </Typography>
                    </Box>
                  )
                }
              </Box>
              <Box style={{display: 'flex'}}>
                <Box style={{display: 'flex', gap: '10px'}}>
                  <LabelOutlinedIcon style={{fontSize: '19px', color: '#0f9688'}} />
                  <Typography style={fontTextInfoKey}>
                  Porcentaje de Descuento: <span style={styleSpanPlan}>
                      {record['porcentaje-descuento-empleado']}%
                    </span>
                  </Typography>
                </Box>
                <DotDivider/>
                <Typography style={fontTextInfoKey}>
                  Precio por Empleado: <span style={styleSpanPlan}>${Math.floor(plan?.price - ((record['porcentaje-descuento-empleado'] * plan?.price) / 100))}</span>
                </Typography>
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <Box style={{display: 'flex'}}>
                  {
                    !isSede && (
                      record?.['tiene-planes-familiares-2'] ? (
                        <>
                          <Box style={{display: 'flex', gap: '10px'}}>
                            <LabelOutlinedIcon style={{fontSize: '19px', color: '#0f9688'}} />
                            <Typography style={fontTextInfoKey}>
                            Descuento Familiar: <span style={styleSpanPlan}>
                                {record['porcentaje-descuento-familiar']}%
                              </span>
                            </Typography>
                          </Box>
                          <DotDivider/>
                          <Typography style={fontTextInfoKey}>
                          Precio Familiar: <span style={styleSpanPlan}>
                            ${Math.floor(plan.price - ((record['porcentaje-descuento-familiar'] * plan.price) / 100))}
                            </span>
                          </Typography>
                        </>
                      ) : (
                        <Box style={{display: 'flex', gap: '10px'}}>
                          <LabelOutlinedIcon style={{fontSize: '19px', color: '#960f0f'}} />
                          <Typography style={emptyTextStyle}>No hay planes familiares disponibles</Typography>
                        </Box>
                      )
                    )
                  }
                </Box>
                <Box style={{alignSelf: 'end'}}>
                  <Link style={{fontSize: '12px', fontWeight: '600'}} href={`#/planes/${record.plan_id}/show`} underline='none'>
                  Ver Plan 
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Skeleton animation='wave' style={{width: '30%'}}/>
            <Skeleton animation='wave' style={{width: '80%'}}/>
            <Skeleton animation='wave' style={{width: '80%'}}/>
            <Skeleton animation='wave' style={{width: '80%'}}/>
          </Box>
        )
      }
    </CardBasic>
  );
};

export default PlanCard;