import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CardAws from '../../Components/CustomFields/CardAws';
import { removeAccents } from '../helpers/helpFunctions';

import SectionForm from '../../Components/Form/SectionForm';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import { BsCardText } from 'react-icons/bs';
import { BsWifi } from 'react-icons/bs';
import cardAws from '../../Components/CustomFields/CardAws';
import CreateForm from '../../Components/Form/CreateForm';


const AmenitiesCreateEdit = ({isEdit, ...props}) => {

  const tabs = [
    {
      component: amenitiesCustom(),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: cardAws('Subir Icono','icon.url'),
      label: 'Icono',
      icon: <BsWifi/>
    }
  ];

  return (
    <CreateForm tabs={tabs} route={'servicios'} />
  );

  // return (
  //   <CustomTabbedForm tabs={tabs} deleteEnabled={isEdit} title={isEdit ? `Servicio ${props.record?.name}` : 'Crear Servicio'}  noShow {...props}/>
  // );
};

export default AmenitiesCreateEdit;

export const amenitiesCustom = () => {
  const [slug, setSlug] = useState('');

  const handlerName = (e) => {
    setSlug(removeAccents(e.target.value.toLowerCase().replace(/ /g, '-')));
  };

  const dataArray = [
    {source: 'name', label:'Nombre', onChange: handlerName, xs: 12},
    {source: 'slug', label:'Slug', disabled: true, initialValue: slug, value: slug, xs:12},
  ];

  return dataArray;
};

// const IconAmenities = () => {

//   const data = [
//     {
//       custom: true,
//       component: <Box>
//         <CardAws source='icon.url' text='Subir Icono'/>
//       </Box>,
//       xs: 12
//     }
//   ];
 
//   return data
// };
