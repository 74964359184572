import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { BooleanField, Datagrid, List, ReferenceField, TextField,
  useRedirect, TextInput , ReferenceInput, AutocompleteInput, useDataProvider, FunctionField} from 'react-admin';
import { MdOutlineQrCode } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { useQuery } from 'react-query';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import { apiUrl } from '../../DataProvider';

const PuntoAccesoList = (props) => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [sedes, setSedes] = useState([]);

  const params = {
    sort: {field: 'name', order: 'ASC'},
    pagination: {page: 1, perPage: 999},
    filter: {}
  };

  useEffect(() => {
    dataProvider.getList('sedes', params).then(({data}) => {
      setSedes(data);
    });
  }, []);

  const sedeFilters = [
    
    <AutocompleteInput
      alwaysOn
      source="sede_id"
      label='Sedes'
      variant='outlined'
      size='medium'
      style={{minWidth: '300px', marginLeft: '15px'}}
      resettable
      suggestionLimit={50}
      choices={sedes}
      matchSuggestion={(filter, choices) => {
        const normalize = choices.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (normalize.toLowerCase().includes(filter.toLowerCase()));
      }}
    />
  ];

  return (
    <List 
      bulkActionButtons={false}
      exporter={false}
      perPage={25}
      filters={sedeFilters}
      {...props} 
      empty={false}
    >
      <DatagridCustom
        rowClick='show'
      >
        <BooleanField source="molinete" label='Molinete'/>
        <TextField source='mac_placa' label='MAC'/>
        <TextField source='description' label='Descripción'/>
        <TextField source="name" label="Sede" />
        <FunctionField render={(record) => <CustomFieldQR record={record} label='Descargar QR' source='descargar qr'/>} />
        <TextField source='comando' label='Comando'/>
      </DatagridCustom>
    </List>
  );
};

const CustomFieldQR = ({record}) => {
  const codigoqr = `{
    "funcion": "acceso_simple",
    "tipo":"punto_de_acceso",
    "punto_de_acceso_id" : "${record?.id}",
    "prestador": "${record?.slug}"
  }`;

  const triggerDownload = (imgURI) => {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });
  
    const a = document.createElement('a');
    a.setAttribute('download', `${record.slug}_QR.png`);
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');
    a.dispatchEvent(evt);
  };

  const download = async (e) => {
    e.stopPropagation();
    try {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', 150);
      canvas.setAttribute('height', 150);
      const ctx = canvas.getContext('2d');
  
      ctx.fillStyle = 'white';
      ctx.fillRect(0,0,canvas.width, canvas.height);
      const DOMURL = window.URL || window.webkitURL || window;
      const image = document.getElementById(`${record.id}-codigoQr.png`);
  
      const imageString = new XMLSerializer().serializeToString(image);
      const img = new Image();
      const imageBlob = new Blob([imageString], {type:  'image/svg+xml;charset=utf-8'});
      const bURL = URL.createObjectURL(imageBlob);
      img.onload = function () {
        ctx.drawImage(img, 11, 11);
        DOMURL.revokeObjectURL(bURL);
  
        const imgUri = canvas.toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        triggerDownload(imgUri);
      };
      img.src = bURL;
          
    } catch (err) {
      console.log(err);
    }
  };

  // const download = async () => {
  //   const token = localStorage.getItem('auth');
  //   const tokenSend = await JSON.parse(token)?.token;

  //   axios({
  //     url: `${apiUrl}/qrcode/${record.sede_id}`,
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-Access-Tokens': tokenSend,
  //       'access-control-allow-origin': '*',
  //       'access-control-expose-headers' : '*'
  //     },
  //     responseType: 'blob'
  //   }).then(res => {
  //     const filename = res?.headers?.['content-disposition']?.split('filename=')[1];
  //     const url = window.URL
  //       .createObjectURL(new Blob([res?.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', filename ? `${filename}` : `${record.name}.png`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
    
  // };


  return(
    <Box onClick={(e) => e.stopPropagation()} sx={{margin: '10px 0', display: 'flex', flexDirection: 'column'}}>
      <QRCode id={`${record?.id}-codigoQr.png`} size={128} value={codigoqr} style={{display: 'none'}} />
      <Button variant='outlined' size='small' sx={{maxWidth: '70px'}} disabled={!record?.molinete} onClick={download}> 
        <MdOutlineQrCode size={20} style={{margin: 'auto'}} />
      </Button>
  
    </Box>
  );
};

export default PuntoAccesoList;