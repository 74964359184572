import { useContext, useRef } from 'react';
import { CorpContext } from './ContextCorp';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { apiUrl } from '../../../DataProvider';
import { getMerchantId } from '../../helpers/helpFunctions';
import axios from 'axios';


const useCorpContext = (value, _callback) => {
  const ref = useRef(false);
  let token = localStorage.getItem('auth');
  let tokenSend = token?.slice(10, -2);
  const contextMerchantId = getMerchantId();
    
  const { getValue, setBoolean, setNewPlan, getBack } = useContext(CorpContext);
    
  const { isLoading, isRefetching, refetch } = useQuery(['plan', value],
    () => axios({
      url: `${apiUrl}/planes/${value}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': '',
        'access-control-allow-origin': '*'
      },
    }),
    {
      enabled: false,
      onSuccess: ({data}) => {
        setNewPlan(data?.data);
        setBoolean(data?.data?.['tipo-de-plan'] === 'cadena');
        _callback && _callback();
      },
      onError: (err) =>{{
        _callback && _callback();
        getBack(false);
        throw new Error(err);
      }}
    }
  );

  return refetch;
    
};
    
export default useCorpContext;