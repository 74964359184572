import { Box } from '@mui/material';
import { useAccessRequests, useVentasRequests } from '../dashboardRequests';
import CobrosSection from './CobrosSection';
import AnualesSection from './AnualesSection';
import AccesosSection from './AccesosSection';
import MenuHeader from '../Reportes/MenuTabs';


const MainSection = ({
  switchTab, 
  setSwitchTab,
  selectedDate,
  inputDate,
  setDate,
  date,
}) => {

  const {
    accesosMns,
    isFetchingAccesos,
    errorAccesos,
  } = useAccessRequests(date);
    
  const {
    ventasMensuales, 
    isFetchingVentas,
    errorVentas,
    isFetchedVentas,
    isSuccess,
    cobrosAnuales,
    isFetchingAnuales,
    isFetchingMensuales,
    cobrosMensuales,
    rechazosAnuales,
    isFetchingRechazos,
  } = useVentasRequests(date);

 
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ maxWidth: '1300px', width: '80vw' }}>
        {
          switchTab === 'cobros' ? (
            <>
              <MenuHeader 
                setSwitchTab={setSwitchTab}
                switchTab={switchTab}
                selectedDate={selectedDate}
                setDate={setDate}
                inputDate={inputDate}
                date={date}
              />
              <CobrosSection 
                isSuccess={isSuccess} 
                errorVentas={errorVentas}
                isFetchedVentas={isFetchedVentas}
                isFetchingVentas={isFetchingVentas}
                ventasMensuales={ventasMensuales} 
                date={date} 
                isFetchingMensuales={isFetchingMensuales}
                cobrosMensuales={cobrosMensuales} 
              />
            </>
          ) : switchTab === 'anuales' ? (
            <>
              <MenuHeader 
                setSwitchTab={setSwitchTab}
                switchTab={switchTab}
                date={date}
              />
              <AnualesSection 
                isFetchingAnuales={isFetchingAnuales}
                cobrosAnuales={cobrosAnuales} 
                date={date} 
                isFetchingRechazos={isFetchingRechazos} 
                rechazosAnuales={rechazosAnuales} 
              />
            </>
          ) : 
            (
              <>
                <MenuHeader 
                  setSwitchTab={setSwitchTab}
                  switchTab={switchTab}
                  selectedDate={selectedDate}
                  setDate={setDate}
                  inputDate={inputDate}
                  date={date}
                />
                <AccesosSection
                  accesosMns={accesosMns}
                  date={date}
                  errorAccesos={errorAccesos}
                  isFetchingAccesos={isFetchingAccesos}
                  isSuccess={isSuccess}
                />
              </>

            )
        }
      </Box>
    </Box>

  );
};

export default MainSection;