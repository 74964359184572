import React from 'react';
import { Create, email } from 'react-admin';
import SocioCreateEdit from './SocioCreateEdit';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { createNotification } from '../helpers/helpFunctions';
import { FormProvider, useForm } from 'react-hook-form';

const SocioCreate = ({onSave, ...props}) => {

  const uppercaseString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const transform = (v) => {
   
    const match = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(v.email);
    
    if(match === null) {
      createNotification('warning', 'El email ingresado no contiene un formato correcto');
      return;
    }
    const date = v.nacimiento.split('-');
    const ret = {...v, 
      nacimiento: `${date[2]}/${date[1]}/${date[0]}`,
      nombre: uppercaseString(v.nombre.trim()),
      apellido: uppercaseString(v.apellido.trim()),
      email: uppercaseString(v.email.trim()),
      domicilio: {
        ...v.domicilio,
        calle: uppercaseString(v.domicilio.calle),
        localidad: uppercaseString(v.domicilio.localidad)

      }
    };
    return ret;
  };

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <Create {...props} transform={transform} title={<TitleEdit title='Crear Socio '/>}>
        <SocioCreateEdit  permissions={props.permissions} {...props}/>
      </Create>
    </FormProvider>
  );
};

export default SocioCreate;
