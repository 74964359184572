import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, TextField, Tooltip, Typography } from '@mui/material';
import { Show, useDataProvider, usePermissions, useRecordContext, useRedirect, useRefresh, useUpdate } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import CardHeader from './SectionsShow/CardHeader';
import PlanCard from './SectionsShow/PlanCard';
import DiscountsCard from './SectionsShow/DiscountsCard';
import PaymentCard from './SectionsShow/PaymentCard';
import AccessCard from './SectionsShow/AccessCard';
import DetailsCard from './SectionsShow/DetailsCard';
import TicketsCard from './SectionsShow/TicketsCard';
import AptoMedicoCard from './SectionsShow/AptoMedicoCard';
import HistoryCard from './SectionsShow/HistoryCard';
import PaidIcon from '@mui/icons-material/Paid';
import { ButtonToolbar } from '../../Components/UI/Buttons/ButtonToolbar';
import ModalSocio from '../../Components/UI/ModalSocio';
import CortarAccessoButton from '../../Components/CustomFields/CortarAccessoButton';
import RevertirBajaButton from '../../Components/CustomFields/RevertirBajaButton';
import ModalUploadImage from '../../Components/UI/Show/ModalUploadImage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { WhatsApp, Payment, Timeline } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AiOutlineWarning } from 'react-icons/ai';
import VersionCard from '../../Components/UI/Show/VersionCard';
import EventosCard from './SectionsShow/EventosCard';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalDelete } from '../../Components/UI/Toolbar/CustomBajaSocio';
import { theme } from '../../Theme/theme';
import { useQuery } from 'react-query';
import ModalCustom from '../../Components/UI/ModalCustom';
import { createNotification } from '../helpers/helpFunctions';
import { apiUrl, merchantCadena, merchantContext } from '../../DataProvider';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';
import { WiTime2 } from 'react-icons/wi';
import axios from 'axios';
import moment from 'moment';
import { DispatchErrorContext } from '../../Context/menuContext';


const SocioShow = (props) => {

  const {getMerchant} = useContext(merchantContext);
  const {updatedMerchant} = useContext(DispatchErrorContext);

  return (
    <Show {...props}
      title={<TitleEdit title='Socio: ' source='nombre'/>} 
      aside={<AsideShow  permis={props.permissions}/>} 
      actions={null} 
    >
      <SectionsSocios  />
    </Show>
  );
};

const SectionsSocios = () => {
  return(
    <ShowTransparent>
      <ShowWrapper  />
    </ShowTransparent>
  );
};

export const ShowWrapper = () => {

  const record = useRecordContext();
  const [plan, setPlan] = useState(null);
  const dataProvider = useDataProvider();

  const {refetch, data, isLoading, error, isFetching, } = useQuery(
    ['plan'], 
    () => record?.active_plan_id && dataProvider.getOne('planes',  {id: record?.active_plan_id}),
    {
      onSuccess: ({data}) => setPlan(data),
      onError: (err) => setPlan(null),
      enabled: false
    }
  );

  useEffect(() => refetch && refetch(), []);
  return (
    <>
      <CardHeader />
      {plan && <PlanCard plan={plan} loading={isFetching}/> }
      <PaymentCard/>
      <DiscountsCard plan={data?.data} />
      <TicketsCard plan={data?.data} loading={isFetching}/>
      <AptoMedicoCard/>
      <AccessCard/>
      <DetailsCard/>
      <HistoryCard/>
      <EventosCard />
      <VersionCard/>
    </>
  );
};

const AsideShow = ({ basePath, resource, permis, ...rest}) => {
  
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPausar, setOpenPausar] = useState(false);
  const [plan, setPlan] = useState('');
  const mensajeSocio = `Hola ${record?.nombre}. Cómo estás?`;
  const tel = record?.celular?.substr(0,1) + '1' + record?.celular?.substr(2,20);
  const redirect = useRedirect();
  const baseUrl = `${process.env.REACT_APP_PORTAL_SOCIO_URL}`;
  const permissions = usePermissions().permissions;
  const dataProvider = useDataProvider();
  const handleClick = () => {
    redirect(basePath + '/' + record?.id);
  };
  const [showDiscount, setShowDiscount] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  useQuery(
    ['plan'],
    async () => {
      const response = await dataProvider.getOne('planes', {id: record?.active_plan_id});
      return response.data;
    },
    {
      onSuccess: ({data}) => {
        
        let contextId = getMerchant()?.id;
        setPlan(data);
        if(contextId === data.merchant_id) {
          return setShowDiscount(true);
        } else {
          if(contextId === merchantCadena || contextId === '') {
            return setShowDiscount(true);
          } else {
            setShowDiscount(false);
          }
        }
      },
      onError: (err) => {
        console.log(err);
        throw new Error(err);
      },
      enabled: false
    }
  );

 

  const data = [
    // {text: 'Editar Socio', onClick: handleClick, icon: <EditIcon/>},
    // {divider: true},
    {
      text: 'Enviar Mensaje', 
      href: 'https://api.whatsapp.com/send?phone=+549'+tel+`&text=${mensajeSocio}`,
      icon: <WhatsApp/>
    },
    // {
    //   custom: true,
    //   component: <PreferedPaymentMethod record={record} />
    // },
    // {
    //   custom: true,
    //   component: <PreferedPaymentMethod baseUrl={baseUrl} record={record} plan={plan}/>
    // },
    {
      text: 'Ver Historial de Pagos',
      icon: <Payment />,
      disabled: !record?.mercadopago_id,
      href: 'https://www.mercadopago.com.ar/activities/1?q='+record?.mercadopago_id?.split('-')[0]
    },
    {
      text: 'Enviar Mail',
      icon: <EmailOutlinedIcon />,
      href: 'mailto:'+record?.email
    },
    permissions == 'superadmin' && {
      icon: <Timeline />,
      text: 'Mandrill',
      href: 'https://mandrillapp.com/activity?q=full_email:'+record?.email
    },
    {
      icon: <AccountCircleIcon/>,
      text: 'Portal Socio',
      noBlank: false,
      // href: `https://www.sportclub.pagar.club/login?token_url=https://api.pagar.club/authenticate?doc=${record?.documento}&spice=2718281828&date=${new Date().toISOString()}&brand_name=${record?.brand_name}`
      href: `${baseUrl}?doc=${record?.documento}&spice=2718281828&date=${new Date().toISOString()}&brand_name=${record?.brand_name ?? ''}&subscription=${record?.active_plan_id ?? ''}`
    },
    {divider: true},
    {
      custom: true, component: <ButtonEditImage record={record}/>
    },
    showDiscount && {
      icon: <AddOutlinedIcon />,
      text: 'Crear Descuento',
      onClick: () => setOpen(true)
    },
    {divider: true},
    // {
    //   icon: <WiTime2 />,
    //   text: 'Pausar Suscripción',
    //   disabled: ['inactivo','pausado'].includes(record?.status),
    //   onClick: () => setOpenPausar(true)
    // },
    {
      custom: true, component: <PausarPlanComponent open={openPausar} setOpen={setOpenPausar} record={record} />
    },
    {
      custom: true, component: <ModalSocio open={open} setOpen={setOpen} record={record}/>
    },
    {
      icon: <DeleteIcon style={{fontSize: '18px', marginLeft: '2px'}}/>,
      text: 'Dar de Baja',
      color: (record?.status === 'baja' || record?.status === 'inactivo') ?
        theme.palette.action.disabled : theme.palette.error.main,
      onClick: () => setOpenDelete(true),
      
      disabled: (record?.status === 'baja' || record?.status === 'inactivo')
    },
    {
      custom: true,
      component: <RevertirBajaButton style={{border: 'none'}} record={record} {...rest}/>
    },
    {
      custom: true,
      component: <CortarAccessoButton style={{border: 'none'}} record={record} {...rest}/>
    },
    
    // Esperar a endpoint de checkout para poder redireccionar al querer reactivar un socio
    {
      custom: true,
      component: <VueltaAlta baseUrl={baseUrl} plan={plan}/>
    },
    {
      custom: true,
      component: <ModalDelete plan={plan} open={openDelete} setOpen={setOpenDelete} record={record}/>
    },
   
  ];

  return(
    <AsideActionsShow data={data} />
  );
};

export const PausarPlanComponent = ({open, setOpen, record}) => {
  const [value, setValue] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  const [error, setError] = useState(false);
  const refresh = useRefresh();
  const isNumber = /^[1-9][0-9]*$/;
  const isBetween = /^(1[5-9]|[2-8][0-9]|90)$/;
  const handleRequest = async () => {
    try {
      if(!error) {
        const token = localStorage.getItem('auth');
        const tokenSend = JSON.parse(token)?.token;
        const response = await axios({
          url: `${apiUrl}/socios/${record?.id}/pausar_plan`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Tokens': tokenSend,
            'access-control-allow-origin': '*',
            'access-control-expose-headers': '*',
            'context-merchant': getMerchant()?.id
          },
          data: {days: parseInt(value)}
        });
        if(response.data){
          setOpen(false);
          refresh();
          createNotification('success', 'Suscripción pausada exitosamente.');
        }
      } else {
        createNotification('info', 'Debes ingresar una valor entre 15 y 90 días para poder pausar la suscripción.');
      }

    } catch (error) {
      createNotification('error', 'Hubo un error al pausar la suscripción.');
      throw new Error(error);
    }
  };

  const handleValue = (e) => {
    const value = e.target.value;
    if(!isNumber.test(value)){
      return setError('Debe ingresar un número');
    }
    if(!isBetween.test(value)){
      return setError('La cantidad de días debe ser entre 15 y 90.');
    }
    setError(false);
    return setValue(value);
  };
  return (
    <ModalCustom
      open={open}
      onClose={() => setOpen(false)}
      title="Pausar Suscripción"
      showButtons
      textAction={'Aceptar'}
      onClickAction={handleRequest}
      disabledAction={['inactivo','pausado'].includes(record?.status)}
    >
      <Typography>Al pausar la suscripción, el socio no va a poder ingresar a las sedes de sportclub por el período seleccionado.</Typography>
      {/* <FormGroup>
        <FormControlLabel label="15 días" control={<Radio onClick={() => setValue({days: 15})} />} />
        <FormControlLabel label="90 días" control={<Radio onClick={() => setValue({days: 90})} />} />
      </FormGroup> */}
      <Box sx={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
        <FormLabel sx={{fontSize: '.8em'}}>Solo se podra pausar la suscripción entre 15 y 90 días.</FormLabel>
        <TextField sx={{width: '50%' }} variant="outlined" label="Días" type="tel" error={error} onBlur={handleValue} />
        {error && <FormHelperText style={{color: 'red'}}>{error}</FormHelperText>}
      </Box>
    </ModalCustom>
  );
};

const ButtonEditImage = ({record}) => {
  const [open, setOpen] = useState(false);
  const [update, { loading, error }] = useUpdate();
  const refresh = useRefresh();
  
  const finishSuccess = (urlAws) => {
    setOpen(false);
    update('socios', record.id, {foto_url: urlAws}, record);
    refresh();
  };

  return(
    <>
      <ModalUploadImage
        open={open} 
        setOpen={setOpen} 
        socioName={record?.nombre} 
        finishSuccess={finishSuccess}
      />
      <ButtonToolbar 
        startIcon={<EditIcon/>}
        onClick={()=> setOpen(true)}
        style={{justifyContent: 'flex-start', padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
      >
        Editar Foto de Perfil
      </ButtonToolbar>
    </>
  );
};

const VueltaAlta = ({baseUrl, plan}) => {
  const [open, setOpen] = useState();
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState(null);
  const [lastApproved, setLastApproved] = useState(null);
  const [debt, setDebt] = useState(null);
  const [sinBoleta, setSinBoleta] = useState(false);
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const refresh = useRefresh();
  const testCheckoutUrl = process.env.REACT_APP_CHECKOUT_BACKEND;
  const handleClose = () => {
    setOpen(false);
  };

  let objectDefault = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'original_payment_date', order: 'ASC'},
    filter: {}
  };

  const { refetch } = useQuery(
    ['boletas'],
    () => dataProvider.getList(`socios/${record?.id}/boletas`, objectDefault),
    {
      onSuccess: ({data}) => {
        setLast(data[data.length - 1]);
        console.log('ULTIMA BOLETA', data[data.length - 1]?.status);

        for (let i = data.length - 1; i >=0; i--) {
          if(data[i]?.status === 'approved') {
            setLastApproved(data[i]);
            break;
          }
        }
        let totalDebt = 0;
        data && data?.map(item => {
          if(item.status === 'restored') {
            totalDebt = totalDebt + item.charges_detail.final_price;
          }
        });
        setDebt(totalDebt);
      },
      enabled: false
    }
  );
  const cobroDict = {
    'Mensual': 1,
    'Trimestral': 3,
    'Cuatrimestral': 4,
    'Semestral': 6,
    'Anual': 0
  };


  const calculatePaymentDay = (pid, cobro, pub) => {
    const diasDeAnticipo = 7;
    const cobroNumber = cobroDict[cobro];
    const parsePub = pub.split('/');
    console.log(pub);
    const newDate = new Date(cobroNumber === 0 ? Number(parsePub[1]) + 1 : Number(parsePub[1]), (Number(parsePub[0]) + cobroNumber - 1), pid);
    newDate.setDate(newDate.getDate() - diasDeAnticipo);
    return moment(new Date(newDate)).format('L');

  };

  const setNextVigency = (date, cobro) => {
    let newDate = new Date(date);
    if(cobro === 'Mensual') return moment(new Date(newDate.setDate(newDate.getDate() + 27))).format('L');
    return moment(new Date(newDate.setDate(newDate.getDate() + 7))).format('L');
  };

  useEffect(() => {
    record?.id && refetch();
  },[]);

  const checkVigencia = () => {
    if(plan && lastApproved && record?.status === 'inactivo') {
      let newPaymentDay = calculatePaymentDay(record?.period_init_day, plan?.cobro, lastApproved?.period);
      console.log('FECHA NEW PAYMENTDAY', newPaymentDay);
      setNextVigency(new Date(newPaymentDay), plan?.cobro);
      console.log('FECHA PROXIMA VIGENCIA', setNextVigency(new Date(newPaymentDay), plan?.cobro));
      if(new Date(setNextVigency(new Date(newPaymentDay), plan?.cobro)) > new Date()) {
        console.log('MAYOR');
        setSinBoleta(true);
      } else {
        console.log('MENOR');
        setSinBoleta(false);
      }
    }
  };

  useEffect(() => {
    checkVigencia();
  },[lastApproved, plan]);
  
  const handleCheckout = () => {
    setLoading(true);
    axios({
      method: 'POST',
      url: `${testCheckoutUrl}/reactivar/${record?.id}`
    }).then(res => {
      if(res.data.status === 'rejected') return createNotification('error', res.data.status_detail);
      refresh();
      setOpen(false);
      createNotification('success', 'Socio reactivado con exito.');
    }).catch(err => {
      setOpen(false);
      setLoading(false);
      createNotification('error', err.response.data.msg ?? 'No se pudo crear el pago, dirigirse al checkout.');
      throw new Error(err);
    });
  };

  const handleEfectivo = () => {
    setLoading(true);
    let newRecord = record;
    delete newRecord.fecha_vigencia;
    delete newRecord.version_control;
    delete newRecord.sportaccess_id;
    delete newRecord.payment_ids;
    delete newRecord.last_payment_id;
    delete newRecord.last_subscription_date;
    delete newRecord.next_payment_date;
    delete newRecord.period_init_day;
    delete newRecord.seller_merchant_id;
    delete newRecord.cobros_recurrentes;
    delete newRecord.brand_name;
    delete newRecord.created_by;
    delete newRecord.created_at;
    delete newRecord.status;
    let data = {...newRecord, status: 'activo'};

    dataProvider.create('socios', {data: data}).then(res => {
      refresh();
      setLoading(false);
      setOpen(false);
      createNotification('success', 'Socio reactivado con exito.');
    }).catch(err => {
      setOpen(false);
      console.log(err.headers);
      createNotification('error', 'Hubo un error, Por favor intentar mas tarde.');
      setLoading(false);
      throw new Error(err);
    });
  };
  const handleEfectivoPut = () => {
    setLoading(true);
    let paymentDay = new Date(calculatePaymentDay(record?.period_init_day, plan?.cobro, lastApproved?.period));
    let lastBoletaPaymentDay = new Date(calculatePaymentDay(record?.period_init_day, plan?.cobro, last?.period));
    let nextVigency = new Date(setNextVigency(paymentDay, plan?.cobro));
    let newRecord = record;
    delete newRecord.fecha_vigencia;
    delete newRecord.version_control;
    delete newRecord.sportaccess_id;
    delete newRecord.payment_ids;
    delete newRecord.last_payment_id;
    delete newRecord.last_subscription_date;
    delete newRecord.next_payment_date;
    delete newRecord.period_init_day;
    delete newRecord.seller_merchant_id;
    delete newRecord.cobros_recurrentes;
    delete newRecord.brand_name;
    delete newRecord.created_by;
    delete newRecord.created_at;
    let data = {
      ...newRecord,
      status: 'activo',
      next_payment_date: lastBoletaPaymentDay,
      fecha_vigencia: nextVigency,
    };
    console.log(data);
    dataProvider.update('socios', {id: record?.id, data: data}).then(res => {
      refresh();
      setLoading(false);
      setOpen(false);
      createNotification('success', 'Socio reactivado con exito.');
    }).catch(err => {
      setOpen(false);
      createNotification('error', 'Hubo un error, Por favor intentar mas tarde.');
      setLoading(false);
      throw new Error(err);
    });
  };
  const handleOpen = () => {
    if(plan) {
      refetch();
      setOpen(true);
      checkVigencia();
    } else {
      createNotification('warning', 'El plan asociado al socio ya no existe, si se quiere volver a dar el alta al socio es necesario ir por checkout.');
    }
  };
  const handleActions = () => {
    checkVigencia();
    if(record?.preferred_payment_method === 'tarjeta') {
      return handleCheckout();
    } else {
      if(!sinBoleta) {
        return handleEfectivo();
      } else {
        return handleEfectivoPut();
      }
    }
  };

  return (
    <>
      <Tooltip>
        <div>
          <ButtonToolbar 
            startIcon={<SyncTwoToneIcon/>}
            onClick={()=> handleOpen()}
            disabled={record?.status !== 'inactivo'}
            style={{justifyContent: 'flex-start', padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
            // disabled={record?.status === "baja" ? false : true}
          >
        Reactivar Socio
          </ButtonToolbar>
        </div>
      </Tooltip>

      {
        !sinBoleta ? (
          <ModalCustom
            open={open}
            disabledAction={loading}
            setOpen={setOpen}
            onClose={handleClose}
            title={'Reactivar Socio'}
            showButtons
            startIcon={loading ? <CircularProgress style={{ color: '#fff', width: '1rem', height: '1rem' }} /> : null}
            onClickAction={() => handleActions()}
          >
            {
              record?.preferred_payment_method === 'tarjeta' ?
                <Typography>Estás intentando reactivar a este cliente. Como no tiene una boleta pagada con vigencia al día de hoy, se le hará un intento de cobro a la tarjeta vigente.</Typography>
                : <Typography>Al reactivar el socio, se generará una nueva boleta con estado aprobado.</Typography>}
            <br />
            {
              debt > 0 && (
                <Box style={{backgroundColor: '#fff59d', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', marginBottom: '10px', width: '100%', padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  {debt && <AiOutlineWarning style={{margin: '0 10px'}} size={30} />}
                  {debt && record?.preferred_payment_method === 'efectivo' && <Typography style={{marginLeft: '10px', fontSize: '14px', fontWeight: '400', width: '100%'}}>Este socio posee una deuda de ${debt}.</Typography>}
                  {debt && record?.preferred_payment_method === 'tarjeta' && <Typography style={{marginLeft: '10px', fontSize: '14px', fontWeight: '400', width: '100%'}}>Este socio posee una deuda de ${debt}. Puede ir a pagarla ahora al <a href={baseUrl}>PortalSocio</a>. <br />Sino el sistema de cobro reintantara cobrar a la misma tarjeta cargada.</Typography>}
                </Box>
              )
            }
          </ModalCustom>
        ) : (
          <>
            <ModalCustom
              open={open}
              disabledAction={loading}
              setOpen={setOpen}
              onClose={handleClose}
              title={'Reactivar Socio'}
              showButtons
              startIcon={loading ? <CircularProgress style={{ color: '#fff', width: '1rem', height: '1rem' }} /> : null}
              onClickAction={() => handleActions()}
            >
              {
                record?.preferred_payment_method === 'tarjeta' ?
                  <Typography>Al reactivar el socio, se le extendera la vigencia al {setNextVigency(new Date(calculatePaymentDay(record?.period_init_day, plan?.cobro, lastApproved?.period)), plan?.cobro)}</Typography>
                  : <Typography>Al reactivar el socio, se le volvera a permitir el acceso hasta el {setNextVigency(new Date(calculatePaymentDay(record?.period_init_day, plan?.cobro, lastApproved?.period)), plan?.cobro)}</Typography>}
              <br />
            </ModalCustom>
          </>
        )
      }
    </>
  );
};

const PreferedPaymentMethod = ({record, baseUrl, plan}) => {

  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { getMerchant } = useContext(merchantContext);
  const hasCards = record?.cards?.length > 0;
  console.log(record?.preferred_payment_method === 'efectivo' && hasCards);

  const [ update, {loading, error}] = useUpdate();

  const handleClose = () => {
    record?.active_plan_id && setOpen(false);
  };

  const handleSubmit = () => {
    if(record?.preferred_payment_method !== 'efectivo'){
      dataProvider.update('socios', {data: {...record, preferred_payment_method: 'efectivo'}, id: record?.id}).then(e => {
        createNotification('success', 'Metodo de pago editado con exito');
        setOpen(false);
        refresh();
      });
      return;
    } else if(record?.preferred_payment_method === 'efectivo' && hasCards) { 
      dataProvider.update('socios', {data: {...record, preferred_payment_method: 'tarjeta'}, id: record?.id}).then(e => {
        createNotification('success', 'Metodo de pago editado con exito');
        setOpen(false);
        refresh();
      });
      return;
    } else {
      window.open(`${baseUrl}?doc=${record?.documento}&spice=2718281828&date=${new Date().toISOString()}&brand_name=${record?.brand_name ?? ''}&subscription=${record?.active_plan_id ?? ''}`);
    }
    setOpen(false);
    refresh();
  };
  
  return (
    <>
      <ButtonToolbar 
        startIcon={<PaidIcon/>}
        onClick={()=> setOpen(true)}
        style={{justifyContent: 'flex-start', padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
      >
        Método de Pago
      </ButtonToolbar>
      <ModalCustom
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        onClickAction={() => handleSubmit()}
        disabledAction={loading}
        title='Cambio de Método de Pago'
        textAction={'Aceptar'}
        showButtons
      >
        {record?.preferred_payment_method === 'efectivo' && !hasCards ? (
          <Typography variant="h7">Al aceptar seras redireccionado al portal socio para poder ingresar los datos del socio.</Typography>
        ) : record?.preferred_payment_method === 'efectivo' && hasCards ? (
          <Typography variant="h7">Al aceptar se utilizaran las tarjetas vigentes del socio para abonar pagos futuros.</Typography>
        ) : (
          <Typography variant="h7">Al aceptar el método de cobro pasara a ser en efectivo.<br />¿Estás seguro?</Typography>
        )}
      </ModalCustom>
    
    </>
  );
};

export default SocioShow;
