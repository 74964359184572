import {
  List, Datagrid, TextField,
  ReferenceInput, TextInput, SelectInput, AutocompleteInput,
  ReferenceField, TopToolbar, CreateButton, ExportButton,
  useRedirect, useDataProvider, FilterButton, downloadCSV, BooleanInput,
  ReferenceArrayField, Resource, ShowButton, DatagridBody, SelectArrayInput, useResourceContext, useListContext
} from 'react-admin';
import rowStyle from './rowStyle';
import DownloadIcon from '@mui/icons-material/Download';
import { FunctionField } from 'react-admin';
import { createNotification, getBrand, getMerchantId, getPermissions, isUserSC } from '../helpers/helpFunctions';
import { Autocomplete, Box, Button, Paper, Skeleton, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ModalCustom from '../../Components/UI/ModalCustom';
import { FormControl, FormControlLabel, Radio, RadioGroup, Switch, TableCell, TableRow, TextField as TextFieldInput } from '@mui/material';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ImportCustomButton from '../../Components/UI/Buttons/ImportCustomButton';
import jsonExport from 'jsonexport/dist';
import ActionsField from '../../Components/CustomFields/ActionsField';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import { merchantContext, merchantSelected } from '../../DataProvider';
import { apiUrl } from '../../DataProvider';
import DoubleField from '../../Components/ListComponents/DoubleField';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import { theme } from '../../Theme/theme';
import { ButtonReport } from '../Dashboard/Reportes/ButtonsReport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import { makeStyles } from '@mui/styles';


const defaultSort = { field: 'last_subscription_date', order: 'DESC' };

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    '& .paxton-table--row': {
      border: 'none',
      borderRadius: '12px',
      marginTop: '10px',
      marginBottom: '10px',
      backgroundColor: '#fafafa'
    },
    '& .paxton-table--cell': {
      border: 'none',
    },
  },
}));

export function CustomListBox(props) {
  return (
    <Paper
      {...props}
      sx={{
        width: '200%',
        ...(props.sx || {}),
      }}
    />
  );
}
export const SocioList = props => {
  const permis = getPermissions();
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filter, setFilter] = useState([]);
  const [corps, setCorps] = useState([]);
  const [planes, setPlanes] = useState([]);
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const { getMerchant } = useContext(merchantContext);
  const { setFilters } = useListContext();

  const handleClickCreate = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  
  const clientFilters = [
    <TextInputIcon
      source="q"
      label=""
      placeholder='Buscar'
      alwaysOn
      variant='outlined'
      resettable
      icon='search'
      color='default'
      minLetters={3}
      style={{ width: '100%' }}
    />,
    <SelectArrayInput source="status" emptyText="Todos" label="Estado" variant='outlined'
      choices={[
        { id: 'activo', name: 'Activos' },
        { id: 'baja', name: 'Bajas con vigencia' },
        { id: 'inactivo', name: 'Inactivos' },
        { id: 'pausado', name: 'Pausados' },
        { id: 'pago en proceso', name: 'Pago en Proceso' }
      ]} />,
    corps?.length > 0 ? <AutocompleteInput
      variant='outlined'
      source='plan_corporativo'
      label='Campaña'
      size='small'
      componentsProps={{
        paper: {
          sx: {
            width: 300
          }
        }
      }}
      resettable
      suggestionLimit={9999}
      choices={corps}
    /> : <Skeleton source="plan_corporativo" style={{width: '150px', height: '50px'}} />,
    planes?.length > 0 ?<AutocompleteInput
      variant='outlined'
      source='active_plan_id'
      label='Planes'
      componentsProps={{
        paper: {
          sx: {
            width: 300
          }
        }
      }}
      size='small'
      resettable
      suggestionLimit={9999}
      choices={planes}
    /> : <Skeleton source="active_plan_id" style={{width: '150px', height: '50px'}} />,
  ];

  useEffect(() => {
    const params = {
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 9999 },
      filter: {}
    };
    dataProvider.getList('corporativo', params).then(({ data }) => setCorps(data));
    dataProvider.getList('planes', params).then(({data}) => {
      if(getMerchant()?.id === '') return setPlanes(data);
      return dataProvider.getList('planes', {...params,  filter: {...params.filter, ['tipo-de-plan']: 'cadena'}}).then(({data: dataCadena}) => setPlanes([...data, ...dataCadena]));
    });
  }, [getMerchant()?.id]);

  const exporter = posts => {
    const postsForExport = posts.map(post => {
      const postsReturn = {
        nombre: post.nombre,
        apellido: post.apellido,
        documento: post.documento,
        celular: post.celular,
        email: post.email,
        status: post.status,
        localidad: post.domicilio.localidad,
        provincia: post.domicilio.provincia,
        descuento: post.discounts?.length > 0 ? post.discounts[0]?.porcentaje : ''
      };
      if (post.apto_medico) {
        postsReturn['Vigencia apto medico'] = post.apto_medico.fecha_vigencia;
        postsReturn['Estado apto medico'] = post.apto_medico.status;
      }

      return postsReturn;
    });
    jsonExport(postsForExport, {
      headers: [
        'nombre',
        'apellido',
        'documento',
        'celular',
        'email',
        'status',
        'provincia',
        'localidad',
        'Vigencia apto medico',
        'Estado apto medico',
        'descuento'
      ]
    }, (err, csv) => {
      downloadCSV(csv, 'Reporte de Socios');
    });
  };

  const handleFilter = (val, value) => {
    if (value === 'planes') {
      let idsPlanes = val.map((item) => item.id);
      setFilter((f) => ({ ...f, 'plan': idsPlanes }));
    } else if (value === 'merchant') {
      let idsmerch = val.map((item) => item.id);
      setFilter((f) => ({ ...f, 'merchant': idsmerch }));
    }

  };

  const ListActions = () => (
    <TopToolbar >
      <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* <ShowButton onClick={() => handleBajasFilter()} label="Bajas con vigencia" /> */}
        <FilterButton disableSaveQuery />
        <CreateButton onClick={handleClickCreate} />
        {/* <ButtonReport
          url='socios_reporte' 
          text='Exportar'
          openModal
          viewMerch
          variant='text'
          icon={<FileDownloadIcon/>}
          noPlanes
          // viewSede
          msgError='No existen reportes para este negocio en estas fechas'
        /> */}
        <Button startIcon={<DownloadIcon />} style={{ textTransform: 'uppercase', padding: '10px' }} onClick={() => setOpenExport(true)}>
          exportar
        </Button>
        { openExport &&
          <CustomReportFilters style={{ width: '200px', justifyContent: 'center' }} viewPlanes viewCorporativos open={openExport} setOpen={setOpenExport} text={'Socios'} variant={'outlined'} viewCobros viewMerchants viewPlanAccess viewSocios title={'Reporte de Socios'} downloadText={'socios'} url={'socios_activos_pro'} />
        }
        <ModalCreateSocio open={open} setOpen={setOpen} />
      </Box>
    </TopToolbar>
  );

  useEffect(() => {
    setFilterActive(window.location.href.includes('preferred_payment_method'));
  }, [window.location.href]);

  return (
    <List
      {...props}
      empty={false}
      filters={
        clientFilters
      }
      actions={<ListActions />}
      sort={defaultSort}
      perPage={25}
      exporter={exporter}
    >
      <DatagridCustom
        emptyComponent={
          <Box textAlign="start" height="auto" m={3}>
            <Typography variant="h7" paragraph>
               No existen socios.
            </Typography>
          </Box>
        }
        rowStyle={rowStyle(props.selectedRow)}
        rowClick='show'
      >
        <DoubleField label='Socio' title={['nombre', 'apellido']} subtitle='documento' />
        {/* <TextField source="documento" label="DNI" /> */}
        <TextField sortable={false} source="active_plan" label='Plan Activo' />
        <TextField source="empresa" label="Campaña" />
        {
          // (merchantSelected?.id === '' || merchantSelected?.name === 'Ver todos') && (
          <TextField source="merchant" label="Negocio" />
          // )
        }
        {
          (permis == 'owner' || permis == 'superadmin') && (
            <TextField label="Vendedor" source="seller_merchant" />
          )
        }
        <FunctionField sortBy={'last_subscription_date'} label="Fecha de alta" render={record => {
          const date = new Date(record?.last_subscription_date).toLocaleString().split(',')[0];
          return date;
        }} />
        {filterActive &&
          <FunctionField label="Dias Restantes" render={record => {
            const diff = new Date(record?.fecha_vigencia).getTime() - new Date().getTime();
            return (Math.round(diff / (1000 * 60 * 60 * 24)));
          }} />
        }
        <ActionsField />
      </DatagridCustom>
    </List>
  );
};


export const ModalCreateSocio = ({ open, setOpen }) => {
  const redirect = useRedirect();
  const [action, setAction] = useState('cash');
  const [plan, setPlan] = useState(null);
  const [planes, setPlanes] = useState([]);
  const [planesMulti, setPlanesMulti] = useState([]);
  const [switchMulti, setSwitchMulti] = useState(false);
  const [brandSc, setBrandSc] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  const dataProvider = useDataProvider();


  const handleRedirect = () => {
    if (['cash', 'link', 'terminal'].includes(action)) {
      redirect('create', 'socios', 1, {}, {record: { 'payment_type': action}});
    } else {
      if (plan) {
        if (switchMulti) {
          window.open(`${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/${plan?.slug}?seller_merchant=${getMerchant().id}`);
          setOpen(false);
        } else {
          window.open(`${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/${plan?.slug}`);
          setOpen(false);
        }
      } else {
        createNotification('warning', 'Por favor, selecciona un plan antes de continuar');
      }
    }
  };

  useEffect(() => {
    if (open) {
      const params = {
        pagination: { page: 1, perPage: 9999 },
        sort: { field: 'name', order: 'DESC' },
        filter: {archivado: false}
      };
      dataProvider.getList('planes', {...params, filter: {...params.filter, 'tipo-de-plan': 'local'}}).then((planes) => setPlanes(planes.data));
      dataProvider.getList('planes', {...params, filter: {...params.filter, 'tipo-de-plan': 'cadena'}}).then((planes) => setPlanesMulti(planes.data));
    }
  }, [open]);

  useEffect(async () => {
    setBrandSc(await getBrand());
  }, []);

  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      showButtons
      onClickAction={handleRedirect}
      title='Modo de Pago'
      textAction={'Crear'}
      showOverflow
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
        <FormControl>
          <RadioGroup
            defaultValue="cash"
            name="radio-buttons-group"
            value={action}
            onChange={(e) => setAction(e.target.value)}
            sx={{ display: 'flex', flexDirection: 'column', height: '200px' }}
          >
            <FormControlLabel value="cash" control={<Radio />} label="Efectivo" />
            <FormControlLabel value="link" control={<Radio />} label="Link de pago" />
            <FormControlLabel value="terminal" control={<Radio />} label="Terminal" />
            <FormControlLabel value="credit" control={<Radio />} label="Crédito" />
          </RadioGroup>
        </FormControl>
        {
          action == 'credit' && (
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {
                brandSc &&
                <Box>
                  <FormControlLabel
                    control={<Switch checked={switchMulti} onChange={() => setSwitchMulti(!switchMulti)} name="switchmulti" />}
                    label={switchMulti ? 'Planes Cadena' : 'Planes Locales'}
                  />
                </Box>
              }
              <Autocomplete
                disablePortal
                options={switchMulti ? planesMulti : planes}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { flexShrink: 0 } }} {...props}>
                    {option.name}
                  </Box>)}
                renderInput={(params) => <TextFieldInput {...params} label="Plan" variant='outlined' />}
                onChange={(event, newValue) => {
                  setPlan(newValue);
                }}
              />
            </Box>
          )
        }
      </Box>
    </ModalCustom>
  );
};

