import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import { useTheme } from '@mui/material/styles';
import { Identifier } from 'react-admin';
import { shadowBorder } from '../socios/rowStyle';

const rowStyle = (selectedRow) => (record) => {
  const theme = useTheme();
  let style = {};
  if (!record) {
    return style;
  }
  if (selectedRow && selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    };
  }
  if (record.payment_status === 'approved')
    return {
      ...style,
      // borderLeftColor: green[500],
      // borderLeftWidth: 5,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: green[500] + shadowBorder
    };
  if (record.payment_status === 'in_process')
    return {
      ...style,
      // borderLeftColor: orange[500],
      // borderLeftWidth: 5,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: orange[500] + shadowBorder
    };
  if (record.payment_status === 'abandoned')
    return {
      ...style,
      // borderLeftColor: red[500],
      // borderLeftWidth: 5,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: '#000000' + shadowBorder
    };
  if (record.payment_status === 'rejected')
    return {
      ...style,
      // borderLeftColor: red[500],
      // borderLeftWidth: 5,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: red[500] + shadowBorder
    };
  return style;
};

export default rowStyle;