import { Button, Modal, Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { fontTitleHeader } from '../../Theme/useStyle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import bucketGenerateUrl from '../../Resources/helpers/bucketGenerateUrl';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  minHeight: '200px',
  maxHeight: '80%',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: 24,
};

const styleBoxHead = {
  display: 'flex', 
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px'
};

const ModalCustom = ({
  open, 
  setOpen, 
  children, 
  onClose, 
  onClickAction,
  textAction,
  title = 'Agregar',
  disabledAction, 
  showButtons,
  startIcon,
  replaceButton,
  colorAction,
  handleValidations,
  showOverflow,
  queryOverflow,
  clearAllDispatchFields
}) => {

  const media1000 = useMediaQuery('(min-height: 700px)');
  const media700 = useMediaQuery('(min-height: 700px)');
  const media500 = useMediaQuery('(min-height: 500px)');

  const media1100 = useMediaQuery('(min-width: 1100px)');
  const media900 = useMediaQuery('(min-width: 900px)');
  const media600 = useMediaQuery('(min-width: 600px)');
  
  return (
    <Modal
      open={open}
      onClose={onClose ? onClose : () => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box style={queryOverflow ? {...style, width: 'auto', overflowY: 'scroll'} : showOverflow ? style : {...style, overflow:'hidden'}}>
          <Box style={{padding:'10px 30px'}}>
            <Box style={styleBoxHead}>
              <Typography style={fontTitleHeader}>
                {title}
              </Typography>
              <IconButton aria-label="delete" onClick={onClose ? onClose : () => setOpen(false)} >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            {children}
          </Box>
          {
            showButtons && (
              <Box style={{
                display: 'flex', 
                justifyContent: 'center', 
                justifySelf: 'end',
                position: 'relative',
                width:'100%',
                bottom: 0
              }}>
                {
                  replaceButton ? 
                    replaceButton : 
                    (
                      <div >
                        <Button 
                          style={{margin: '20px', borderRadius: '30px'}}
                          variant='contained'
                          color={colorAction ? colorAction : 'primary'}
                          onClick={onClickAction}
                          disabled={disabledAction}
                          startIcon={startIcon ? startIcon : null}
                        >
                          {textAction ? textAction : 'Guardar'}
                        </Button>
                      </div>
                    )}
              </Box>
            )
          }
        </Box>
      </div>
    </Modal>
  );
};

export const ModalApto = ({url}) => {
  const [open, setOpen] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  useEffect(async () => {
    const urlgenerada = await bucketGenerateUrl(url);
    setNewUrl(urlgenerada);
  },[open])
  return (
    <>
{!url?.startsWith(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`)?
    <Box>
      <Button variant='text' onClick={() => setOpen(true)}>Ver Apto Medico</Button>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
      >
        <Box style={style}>
          <Box>
            <img src={url} style={{maxWidth: '800px'}}/>
          </Box>
        </Box>
      </Modal>
    </Box>:
     <Box>
     <Button variant='text' onClick={() => setOpen(true)}>Ver Apto Medico</Button>
     <Modal
       open={open}
       onClose={()=>setOpen(false)}
     >
       <Box style={style}>
         <Box>
           <img src={newUrl} style={{maxWidth: '800px'}}/>
         </Box>
       </Box>
     </Modal>
   </Box>
  }
  </>
  );
};


{/* <IconButton 
                      aria-label="delete" 
                      disabled={disabledAction} 
                      onClick={onClickAction} 
                      color='primary'
                    >
                      {
                        startIcon ? 
                          startIcon :
                          <AddOutlinedIcon/>
                      }
                    </IconButton> */}
                    
export default ModalCustom;
