import { AdicionalesCreate } from './AdicionalesCreate';
import AdicionalesEdit from './AdicionalesEdit';
import AdicionalesList from './AdicionalesList';
import { FaMoneyBillWave } from 'react-icons/fa';

export default {
  edit: AdicionalesEdit,
  create: AdicionalesCreate,
  list: AdicionalesList,
  icon: FaMoneyBillWave
};