import { Box } from '@mui/material';
import CardDashboard from './Card/CardDashboard';
import { useEffect, useState } from 'react';
import CardContent from './Card/CardContent';

const IngresosCard = ({accesosMns, isFetching, date, isError}) => {
  const [accesosDatasets, setAccesosDatasets] = useState(null);
 
  const helpText = 'Accesos a la sede durante el mes,  segmentados por "Propios" (Ingresos de socios con planes vendidos por la sede), "Sedes" (Ingresos de socios con planes vendidos por otras sedes) y "Centrales" (Ingresos de socios con planes centrales)';
  
  useEffect(() => {
    let datasets = accesosMns?.dashboard_accesos_propios;
    let datasetsSedes = accesosMns?.dashboard_accesos_de_otras_sedes;
    let datasetsCentrales = accesosMns?.dashboard_accesos_sede_central;
    setAccesosDatasets({
      chart: {
        accesos: datasets,
        sedes: datasetsSedes,
        centrales: datasetsCentrales
      }
    });
    return() => setAccesosDatasets(null);
  },[accesosMns]);

  return (
    <Box sx={{ width: '100%'}}>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          width: {xs: '100%'},
          height: 'auto',
          justifyContent: 'space-between'
        }}
      >
        <CardDashboard noPad>
          <CardContent 
            isFetching={isFetching}
            noCard
            chartBar 
            accesos
            datasets={accesosDatasets}
            date={date}
            name="Ingresos"
            isError={isError}
            helpText={helpText}
          />
        </CardDashboard>
      </Box>
    </Box>
  );
};

export default IngresosCard;