import { useEffect, useState } from 'react';
import CardContentSmall from './Card/CardContentSmall';
import CardDashboard from './Card/CardDashboard';

const MediosDePagoCard = ({isFetchingVentas, date, ventasMensuales}) => {
  const [datasets, setDatasets] = useState({});
  const helpText = 'Monto cobrado por mostrador, segmentado por medio de pago.';

  const uppercaseString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  
  useEffect(() => {
    let data = ventasMensuales?.data?.json?.data;
    let mpArr = [];
    data?.medios_de_pago?.map((item, i) => {
      mpArr?.push({key: uppercaseString(item.tipo), value: item.monto});
    });

    setDatasets({datasets: mpArr});
    return() => {
      mpArr = [];
    };
  },[date, ventasMensuales]);
  return(
    <>
      <CardDashboard noPad>
        <CardContentSmall isFetching={isFetchingVentas} helpText={helpText} title={'Cobros de mostrador por medio de pago'} data={datasets} />
      </CardDashboard>
    </>
  );
};

export default MediosDePagoCard;