import React, { useState } from 'react';
import { Datagrid, DateField, downloadCSV, FunctionField, List, ReferenceField, TextField, TextInput, TopToolbar, useDataProvider, useRedirect } from 'react-admin';
import MercadopagoField from '../../Components/CustomFields/MercadopagoField';
import WhatsappField from '../../Components/CustomFields/WhatsappField';
import rowStyle from '../socios/rowStyle';
import * as jsonexport from 'jsonexport/dist';
import * as XLSX from 'xlsx';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import FileSaver from 'file-saver';
import { Button, Typography } from '@mui/material';
import { theme } from '../../Theme/theme';
import { ModalDateCustom } from '../../Components/UI/ExportCustomButton';
import DownloadIcon from '@mui/icons-material/Download';
import { merchantSelected } from '../../DataProvider';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { ButtonReport, CobrosCustomReport } from '../Dashboard/Reportes/ButtonsReport';

const clientFilterDefault = [
  <TextInputIcon
    source="q" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

const exporter = (rechazos) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const headerxls = [[
    'Nombre', 
    'Apellido', 
    'Documento',
    'Email',
    'Celular',
    'Estado',
    'Plan Activo',
    'Empresa',
    'Fecha de Vigencia', 
    'Reintentos de cobro' 
  ]];

  const arrayxls = rechazos.map(e => ([
    e.nombre,
    e.apellido,
    e.documento,
    e.email,
    e.celular,
    e.status_detail,
    e.active_plan,
    e.empresa ? e.empresa : '-',
    e.fecha_vigencia,
    e.tries
  ]));
  const totalArray = headerxls.concat(arrayxls);
  const workSheet = XLSX.utils.aoa_to_sheet(totalArray);
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ['data'],
  };
  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, 'Reporte de Rechazos' + fileExtension);
};

const RechazosList = (props) => {
  const today = new Date();
  const from = new Date(today.getFullYear(), today.getMonth(), 1);

  const [date, setDate] = useState(
    {
      from: new Date(from).toISOString().slice(0,10),
      until: new Date(Date.now()).toISOString().slice(0,10)
    }
  );
  const [newFilter, setNewFilter] = useState('');

  const DICT_MP = {
    // errores
    'Customer not found': ' El perfil del socio no se creó en su negocio en Mercado Pago.Es necesario cambiar la tarjeta.',
    'Security code id cant be null': 'La tarjeta ingresada no acepta cobros recurrentes, cambiar la tarjeta por una que sí acepte',
    'Invalid operators users involved': ' La tarjeta ingresada no está guardada en su perfil de Mercado Pago.Es necesario cambiar la tarjeta.',
    'Card not found': 'Tarjeta no encontrada, es necesario cambiar la tarjeta.',
    'cc_rejected_bad_filled_card_number': 'Revisá el número de tarjeta.',
    'cc_rejected_bad_filled_date': 'Revisá la fecha de vencimiento.',
    'cc_rejected_bad_filled_other': 'Revisá los datos',
    'cc_rejected_bad_filled_security_code': 'Revisa el código de seguridad de la tarjeta.',
    'cc_rejected_blacklist': 'No pudimos procesar tu pago.',
    'cc_rejected_call_for_authorize': 'Debes autorizar ante tu banco, el pago de tu deuda.',
    'cc_rejected_card_disabled': 'Llama a tu banco para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.',
    'cc_rejected_card_error': 'No pudimos procesar tu pago.',
    'cc_rejected_duplicated_payment': 'Ya hiciste un pago por ese valor.Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
    'cc_rejected_high_risk': 'Tu pago fue rechazado. Vuelva a intentar con otra tarjeta.',
    'cc_rejected_insufficient_amount': 'Tu cuenta no tiene fondos suficientes.',
    'cc_rejected_max_attempts': 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
    'cc_rejected_other_reason': 'Tu banco no procesó el pago.'
  };

  const defaultSort = { field: 'fecha_vigencia', order: 'ASC' };
  const rechazosFilter = 
           <CobrosCustomReport
             url='pagos_reporte' 
             text='Exportar'
             openModal
             viewMerch
             date={date}
             setDate={setDate}
             filter={newFilter}
             variant={'none'}
             setFilter={setNewFilter}
             viewDate
             rechazos
             startIcon={<DownloadIcon />}
             style={{
               width: 'auto', 
               justifyContent: 'center', 
               marginTop: '20px',
               color: theme.palette.primary.light,
               padding: 5,
             }}
             msgError='No existen pagos para este negocio en estas fechas'
           />;

          
  

  return (
    <List 
      bulkActionButtons={false}
      sort={defaultSort}
      filters={clientFilterDefault}
      perPage={25}
      actions={rechazosFilter}
      {...props} 
      empty={false}
    >
      <DatagridCustom 
        rowStyle={rowStyle(props.selectedRow)} 
        rowClick={(id) => `/socios/${id}/show`}
      >
        <FunctionField
          label="Nombre"
          render={record => `${record.nombre} ${record.apellido}`}
        />
        <TextField source="documento" label="DNI" />
        <TextField label="Plan activo" source="active_plan" />
        <TextField source="empresa" />
        {/* {(merchantSelected?.name == '' || merchantSelected?.name == 'Ver todos')  && (
          <ReferenceField label="Negocio" source="active_plan_id" reference="planes" >
            <ReferenceField source="merchant_id" reference="merchants" >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
        )
        } */}
        <FunctionField label="Motivo" render={record => DICT_MP[record?.status_detail] ?? record?.status_detail}  />
        <DateField source="fecha_vigencia" label="Vigencia"/>
        <DateField source="last_subscription_date" label="Alta"/>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <WhatsappField source="celular" label="Whatsapp" isSocio/>
          <MercadopagoField source="email" label="Cuenta Corriente" />
        </Box>
      </DatagridCustom>
    </List>
  );
};

const ListActions = () => {
  const [date, setDate] = useState(
    {
      from: new Date(Date.now()).toISOString().slice(0,10),
      until: new Date(Date.now()).toISOString().slice(0,10)
    }
  );
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const onClickDownload = () => {
    setLoading(true);
    const params = {
      sort: {field: 'nombre', order: 'ASC'},
      pagination: {page: 1, perPage: 9999},
      filter: {from: date.from, until: date.until}
    };
    dataProvider.getList('rechazos', params).then(r => {
      exporter(r.data);
      setLoading(false);
    });
  };

  return(
    <TopToolbar>
      <Box style={{display: 'flex', alignItems: 'center'}}>
        <Box>
          <Button 
            style={{...theme.typography.button, color: theme.palette.primary.main}} 
            onClick={()=>setOpen(true)} 
            startIcon={<DownloadIcon />}
          >
            Exportar
          </Button>
          <ModalDateCustom
            loading={loading} 
            setOpen={setOpen} 
            open={open} 
            onClick={onClickDownload} 
            date={date} 
            setDate={setDate}
            viewDate
          />
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default RechazosList;