import { Box, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { GiWeightLiftingUp } from 'react-icons/gi';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { theme } from '../../../Theme/theme';
import { styleChip } from '../../../Theme/useStyle';
import prestacionesEdit from '../SectionsEdit/PrestacionesEdit';

const PrestacionesCard = ({isAbleToEdit}) => {
  const record = useRecordContext();
  const [arrayPrest, setArrayPrest] = useState(null);
  const isMulti = record?.['tipo-de-plan'] === 'cadena';
  const presToArray = () => {
    let benefits = record?.prestaciones;
    setArrayPrest(benefits?.match(/[^<>]+(?=[<])/g));
  };

  useEffect(()=> {
    presToArray();
  }, []);
  
  const editComponent = {
    component: prestacionesEdit(record),
    label: 'Prestaciones',
    icon: <GiWeightLiftingUp/>
  };

  return(
    <CardBasic title='Prestaciones' route={isMulti ? 'multinegocio' : 'planes'} record={record} editComponent={isAbleToEdit && editComponent}>
      {
        arrayPrest ? (
          <Box style={{display: 'flex', gap: '15px 10px', flexWrap: 'wrap'}}>
            {
              arrayPrest.map((item, index) => (
                <Box key={index}>
                  {item.length > 1 && (
                    <Chip 
                      variant='outlined'
                      color='secondary'
                      label={item}
                      style={styleChip}
                    />
                  )}
                </Box>
              ))
            }
          </Box>
        ) : 
          <Box>No existen Prestaciones</Box>
      }
    </CardBasic>
  );
};

export default PrestacionesCard;
