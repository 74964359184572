import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { BsCardImage } from 'react-icons/bs';
import cardAwsArray from '../../../Components/CustomFields/CardAwsArray';
import ModalImage from '../../../Components/UI/ModalImage';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import ImageComponent from '../../../Components/UI/Show/ImageComponent';
import { getPermissions } from '../../helpers/helpFunctions';
import { merchantContext } from '../../../DataProvider';

const styleHeroImage = {
  fontSize: '14px',
  color: '#6470C2',
  margin: '0 0 15px 18px',
  fontWeight: '600'
};


const ImagesCard = () => {
  const record = useRecordContext();
  const permis = getPermissions();
  const { getMerchant } = useContext(merchantContext);
  
  const editComponent = {
    component: cardAwsArray('Subir Hero Image', 'hero-image.url'),
    label: 'Imagenes',
    icon: <BsCardImage/>
  };

  const images = record?.['multi-imagen'];

  const editPermis = ['superadmin', 'owner'].includes(permis) || (getMerchant()?.negocio_central && permis === 'admin');
  
  return (
    <CardBasic title='Imagenes' editComponent={editPermis && editComponent} record={record} route={'sedes'}>
      <Box style={{borderBottom: '1px solid #c9c9c9', padding: '15px 0', marginBottom: '15px'}}>
        <Typography style={styleHeroImage}>Hero Image</Typography>
        <ImageComponent item={record['hero-image']}/>
      </Box>
      <Box style={{display: 'flex',flexWrap: 'wrap', gap: '20px'}}>
        {
          images && images.map(item => (
            <Box key={item.url}>
              <ImageComponent item={item}/>
            </Box>
          ))
        }
      </Box>
    </CardBasic>
  );
};

export default ImagesCard;