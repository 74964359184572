import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { fontStyle } from '../../Theme/useStyle';
import { dateFormat } from '../../Resources/helpers/helpFunctions';

const TableSocios = ({discounts, deleteHandler}) => {
  return (
    <Box style={{width: '100%'}}>
      {discounts?.length > 0 ? (
        <TableContainer component={Paper} sx={{marginBottom: '10px'}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Concepto</TableCell>
                <TableCell align="right">Restantes</TableCell>
                <TableCell align="right">Fecha de Vigencia</TableCell>
                <TableCell align="right">Monto Absoluto</TableCell>
                <TableCell align="right">Descuento</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discounts.map((discount, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{discount.concepto}</TableCell>
                  <TableCell align="right">{discount.aplicaciones_restantes}</TableCell>
                  <TableCell align="right">{dateFormat(discount.fecha_vigencia)}</TableCell>
                  <TableCell align="right">{discount.monto_absoluto}</TableCell>
                  <TableCell align="right">{discount.porcentaje}%</TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="delete" onClick={() => deleteHandler(index)}>
                      <DeleteOutlinedIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ): <Box>
        <Typography style={fontStyle}>No hay descuentos para este socio</Typography>
      </Box>
      }
    </Box>
  );
};

export default TableSocios;
