import { Box } from '@mui/material';
import CobrosCard from '../../../Components/UI/Dashboard/CobrosCard';
import RechazosCard from '../../../Components/UI/Dashboard/RechazosCard';

const AnualesSection = ({
  isFetchingAnuales,
  isFetchingRechazos,
  cobrosAnuales,
  rechazosAnuales,
  date
}) => {
  return (
    <Box>
      <CobrosCard 
        isFetchingAnuales={isFetchingAnuales}
        cobrosAnuales={cobrosAnuales} 
        date={date} 
      />
      <RechazosCard 
        isFetchingRechazos={isFetchingRechazos} 
        cobrosAnuales={cobrosAnuales}
        rechazosAnuales={rechazosAnuales} 
        date={date}
      />
    </Box>
  );
};

export default AnualesSection;