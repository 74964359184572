import React from 'react';
import { Create } from 'react-admin';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';
import BrandsCreateEdit from './BrandsCreateEdit';

const BrandsCreate = (props) => {
  return (
    <Create 
      {...props} 
      title={<TitleEdit title='Crear Campaña '/>} 
    >
      <BrandsCreateEdit/>
    </Create>
  );
};

export default BrandsCreate