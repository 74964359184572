import { Box, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

const DoubleField = ({subtitle, title}) => {
  const record = useRecordContext();
  const titles = title?.map(i => `${capitalizeFirstLetter(record[i])} `);
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: '5px', width: '100%'}}>
      <Typography variant='subtitle2' fontSize={14}>
        {titles}
      </Typography>
      <Typography variant='body2' fontSize={14}>{record[subtitle]}</Typography>
    </Box>
  );
};

export default DoubleField;