import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDataProvider, useRedirect, useRefresh } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalCustom from '../ModalCustom';
import { ButtonToolbar } from '../Buttons/ButtonToolbar';
import { createNotification, handleClickCopy } from '../../../Resources/helpers/helpFunctions';
import { TextField } from '@mui/material';
import moment from 'moment';

const CustomBajaSocio = ({
  className,
  classes,
  saving,
  invalid,
  validating,
  onSave,
  variant,
  setDataRecord,
  translate,
  handleSubmit,
  submitOnEnter,
  locale,
  pristine,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
      <ModalDelete open={open} setOpen={setOpen} record={rest.record}/>
      <ButtonToolbar variant='text'
        startIcon={<DeleteIcon style={{fontSize: '18px'}}/>}
        style={{
          marginLeft: '2px',
          textTransform: 'none',
          justifyContent: 'flex-start'
        }} 
        disabled={(rest.record.status === 'baja' || rest.record.status === 'inactivo')}
        color='error'
        onClick={()=> setOpen(true)}
      >
        Dar de Baja
      </ButtonToolbar>
    </Box>
  );
};


export const ModalDelete = ({open, setOpen, record, plan}) => {
  const dataProvider = useDataProvider();
  const [error, setError] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const redirect = useRedirect();
  
  const onSuccess = () => {
    createNotification('success', 'Socio dado de baja');
    // redirect('/socios');
    refresh();
  };

  const onFailure = (data) => {
    createNotification('error', 'No se pudo dar de baja el socio', ()=> handleClickCopy(data));
  };

  const handlerSubmit = () => {
    if(motivo.length > 0){
      setLoading(true);
      dataProvider.delete('/socios', {id: record.id, data: {...record, motivo: motivo}}).then(()=> {
        setLoading(false);
        setOpen(false);
        onSuccess();
      }).catch(()=> {
        setLoading(false);
        onFailure(record);
      });
    }
    else{
      setError(true);
    }
  };

  const handleClose = () => {
    !loading && setOpen(false);
  };

  const endPeriodDate = () => {
    let initialDate = new Date(record?.fecha_vigencia);
    if(plan?.cobro === 'Mensual') {
      return new Date(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate() - 21).toLocaleString().split(',')[0];
    }
    return initialDate.toLocaleString().split(',')[0];
  };

  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      onClickAction={handlerSubmit}
      startIcon={loading && <CircularProgress style={{width: '20px', height: '20px'}}/>}
      disabledAction={loading || motivo.length === 0}
      showButtons
      textAction={'Confirmar'}
      title={'Baja de socio'}
      colorAction='error'
    >
      <Box style={{display: 'flex', flexDirection: 'column',gap: '15px',alignItems: 'center'}}>
        <Typography variant='subtitle1'>
            ¿Seguro quieres dar de baja a {record?.nombre}? <br />
            Al dar de baja a este socio, su vigencia pasará a ser {moment(endPeriodDate()).format('DD/MM/YYYY')}
        </Typography>
        <TextField
          variant='outlined' 
          required label='Motivo' 
          size='small'
          onChange={(e)=>setMotivo(e.target.value)}
          error={error}
          fullWidth
        />
      </Box>
    </ModalCustom>
  );
};

export default CustomBajaSocio;