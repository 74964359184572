import React from 'react';
import { useRecordContext, maxLength } from 'react-admin';
import InformacionEdit from './SectionsEdit/InformacionEdit';
import informacionEdit from './SectionsEdit/InformacionEdit';
import LevelAccess from './SectionsEdit/LevelAccess';
import levelAccess from './SectionsEdit/LevelAccess';
import PlanesEdit from './SectionsEdit/PlanesEdit';
import planesEdit from './SectionsEdit/PlanesEdit';
import UbicationEdit from './SectionsEdit/UbicationEdit';
import ubicationEdit from './SectionsEdit/UbicationEdit';
import ImagesEdit from './SectionsEdit/ImagesEdit';
import imagesEdit from './SectionsEdit/ImagesEdit';
import SectionForm from '../../Components/Form/SectionForm';
import { BsCardText } from 'react-icons/bs';
import { BsBookmarkStar } from 'react-icons/bs';
import { CgGym } from 'react-icons/cg';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ActivityAmenities from './SectionsEdit/ActivityAmenities';
import activityAmenities from './SectionsEdit/ActivityAmenities';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import { GrTextAlignLeft } from 'react-icons/gr';
import { FiMapPin } from 'react-icons/fi';
import { MdOutlineContactPhone } from 'react-icons/md';
import { BsClockHistory } from 'react-icons/bs';
import ContactoEdit from './SectionsEdit/ContactoEdit';
import contactoEdit from './SectionsEdit/ContactoEdit';
import { BsCardImage } from 'react-icons/bs';
import CreateForm from '../../Components/Form/CreateForm';
import cardAws from '../../Components/CustomFields/CardAws';
import cardAwsArray from '../../Components/CustomFields/CardAwsArray';

export const dataDescription = [
  {
    source: 'descripcion-corta',
    validate: {maxLength: {value: 240, message: 'La descripción debe tener como máximo 240 caracteres'}},
    label: 'Descripción Corta',
    xs: 12
  },
  {
    source:'description',
    label:'Descripción', 
    multiline: true,
    resettable: true,
    xs: 12
  }
];

export const dataHorarios = [
  {source: 'horario-l-a-v', label: 'de Lunes a Viernes', resettable: true, xs: 12},
  {source: 'horario-sabado', label: 'Sábados', resettable: true, xs: 12},
  {source: 'horario-domingo-y-feriados', label: 'Domingos y Feriados', resettable: true, xs: 12}
];

export const SedeCreateEdit = ({ isEdit, permissions, ...props }) => {

  const tabs = [
    {
      component: informacionEdit(),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: levelAccess(),
      label: 'Nivel de Acceso',
      icon: <BsBookmarkStar/>
    },
    {
      component: activityAmenities(),
      label: 'Servicios y Actividades',
      icon: <DirectionsRunIcon/>
    },
    {
      component: dataDescription,
      label: 'Descripción',
      icon: <GrTextAlignLeft/>
    },
    {
      component: ubicationEdit(),
      label: 'Ubicación',
      icon: <FiMapPin/>
    },
    {
      component: contactoEdit(),
      label: 'Contacto',
      icon: <MdOutlineContactPhone/>
    },
    {
      component: dataHorarios,
      label: 'Horarios',
      icon: <BsClockHistory/>
    },
    {
      component: cardAwsArray('Subir Hero Image', 'hero-image.url'),
      label: 'Imagenes',
      icon: <BsCardImage/>
    }
  ];

  return (
    <CreateForm tabs={tabs} route={'sedes'} />
  );
};