import React, { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import { getListProvider, getPermissions } from '../../helpers/helpFunctions';

const cols = [
  {name: 'Nombre', key: 'socio_nombre'},
  {name: 'Sede', key: 'sede_name'},
  {name: 'Fecha', key: 'date'},
  {name: null, key: '#/accesos/'}
];

const AccessCard = () => {
  const [dataAccess, setDataAccess] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const permis = getPermissions()

  useEffect(()=>{
    getListProvider(dataProvider,
      `sedes/${record.id}/accesos` , 
      (d, t) => {
        setDataAccess(d);
        setTotal(t);
      }, 
      null,
      {page: page, perPage: 10},
      {field: 'date', order: 'DESC'}
    );
  }, [page]);

  return (
    <CardBasic
      title='Accesos' 
    >
      <TableBasic 
        cols={cols} 
        rows={dataAccess}
        setPage={setPage} 
        total={total} 
        perPage={10}
        actionShow
        action={'Ver Acceso'} 
        emptyText='No hay registros de accesos para esta sede'
      />
    </CardBasic>
  );
};

export default AccessCard;