import { Box, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecordContext, useResourceContext, useUpdate } from 'react-admin';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { CustomInvalidButton } from '../UI/ButtonsToolbar';

function CortarAccessoButton(props) {
  const record = useRecordContext();
  const resource = useResourceContext();
  const[open, setOpen] = useState(false);

  let tday = new Date();
  let dayms = 24 * 60 * 60 * 1000;
  let yesterday = new Date(tday.getTime() - dayms).toISOString();
  const [removeAccess, { isLoading, error, loaded }] = useUpdate(
    resource,
    {id: record?.id, 
      data: {documento: record?.documento, fecha_vigencia: yesterday, status: 'inactivo'},
      previousData: record}
  );
  const handleRemoveAccess = () => {
    removeAccess();
    setOpen(false);
  };

  const noAccess = record?.fecha_vigencia < tday;
  tday = tday.toISOString().split('.')[0];
  
  useEffect(()=> {
    if(loaded){
      createNotification('success', 'Acceso denegado con exito'); 
    }
    else if(error){
      createNotification('error', 'No se pudo invalidar el acceso', ()=> handleClickCopy(record)); 
    }
  }, [error, loaded]);

  return (
    <Tooltip title='Se le detiene el ingreso al socio' placement='left'>
      <Box>
        <CustomInvalidButton handlerInvalidAccess={handleRemoveAccess} setOpen={setOpen} open={open} loading={isLoading} noAccess={noAccess} {...props}/>
      </Box>
    </Tooltip>
  );
}

export default CortarAccessoButton;