import { FunctionField, List, ListActions, ReferenceField, TextField, usePermissions, useRedirect, useResourceContext } from 'react-admin';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import rowStylePlans from '../planes/rowStylePlans';
import { isUserSede } from '../helpers/helpFunctions';
import { useContext, useEffect, useState } from 'react';
import { merchantContext } from '../../DataProvider';


const AdicionalesList = (props) => {
  // const [canCreate, setCanCreate] = useState(false);
  const permis = usePermissions()?.permissions;
  const redirect = useRedirect();
  const { getMerchant } = useContext(merchantContext);
  const resource = useResourceContext();
  const handleClickCreate = () => {
    redirect(`/${resource}/create`);
  };

  return(
    <>
      <List 
        perPage={25} 
        hasCreate={true}
        exporter={false}
        actions={<ListActions />}
        empty={
          <EmptyLayout
            handleClickCreate={handleClickCreate}
            title={getMerchant()?.id === '' ? 'No se encuentran cobros adicionales cargados, por favor seleccionar un negocio para poder crear.' : 'No se encuentran cobros adicionales cargados'}
            canCreate={getMerchant()?.id !== ''}
          />}
      >
        <DatagridCustom rowStyle={rowStylePlans(props.selectedRow)} rowClick='edit'>
          <TextField source="name" label="Nombre" />
          <TextField source="duration" label="Duración (días)"/>
          <FunctionField source="price" label="Precio" render={(record) => `$ ${record?.price}`} />
          <TextField label="Negocio" source="merchant_name"/>
        </DatagridCustom>
      </List>
    </>

  );
};

export default AdicionalesList;