import React from 'react';
import { Create, useResourceContext } from 'react-admin';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import PlanCreateEdit from './PlanCreateEdit';

const PlanCreate = (props) => {

  const resource = useResourceContext();

  return (
    <Create 
      // transform={transform}
      mutationMode="pessimistic"
      undoable={false}
      {...props} 
      title={<TitleEdit title='Crear Plan'/>}
    >
      <PlanCreateEdit isMulti={resource == 'multinegocio'}/>
    </Create>
  );
};

export default PlanCreate;
