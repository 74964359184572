import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, IconButton, Link, Pagination, Skeleton, Typography } from '@mui/material';
import { dateFormat } from '../../../Resources/helpers/helpFunctions';
import { emptyTextStyle, styleTabbleData, styleTableHead } from '../../../Theme/useStyle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Al final hay un ejemplo de como usar esta tabla

const emptyBoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0'
};

const TableBasic = ({
  rows, 
  cols, 
  page, 
  setPage, 
  emptyText, 
  total, 
  perPage, 
  action,
  actionShow,
  deleteAction,
  handleDelete,
  loading
}) => {

  if(loading && !rows){
    return(
      <Box>
        <Skeleton/>
        <Skeleton/>
        <Skeleton/>
      </Box>
    );
  }

  return (
    <Box>
      {
        loading ? (
          <Box>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
          </Box>
        ) : (
          <TableContainer>
            {rows?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    {
                      cols?.map((item,index) => (
                        <TableCell key={Math.random()+index} style={styleTableHead}>{item.name}</TableCell>
                      ))
                    }
                    {
                      deleteAction && 
                    <TableCell style={styleTableHead}/>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <Row
                      key={Math.random()+index}
                      row={row} 
                      index={index} 
                      action={action}
                      actionShow={actionShow}
                      handleDelete={handleDelete}
                      cols={cols}
                      deleteAction={deleteAction}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box style={emptyBoxStyle}>
                <Typography variant='subtitle2'>{emptyText}</Typography>
              </Box>
            )}
          </TableContainer>
        )
      }
      
      {(page && setPage && rows?.length > 0) && (
        <Box style={{display: 'flex', justifyContent: 'end', marginTop: '10px'}}>
          <Pagination
            component="div"
            count={perPage ? Math.ceil(total / perPage) :  Math.ceil(total / 5)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
};


const Row = ({row, index, action, actionShow, cols, deleteAction, handleDelete}) => {

  return (
    <TableRow
      key={Math.random()+index}
    >
      {
        cols.map((item, index) => (
          <TableCell key={Math.random()+index} align="left" 
            style={styleTabbleData}>
            {
              item.key?.includes('date') ? (
                row[item.key] ? (
                  dateFormat(row[item.key]?.slice(0, 10), true) +': ' + row[item.key]?.slice(11, 16)
                ) : (
                  '-'
                )
              ) : (index == cols?.length - 1) ? (
                action ? (
                  <Link href={actionShow ? 
                    item.key + row.id + '/show' :
                    item.key + row.id
                  } 
                  underline='hover' 
                  style={{fontSize: '13px'}}
                  >
                    {action}
                  </Link>
                ) : (
                  deleteAction ?
                    <IconButton aria-label="delete" color="error" onClick={() => handleDelete(row)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton> : 
                    row[item.key] ? (
                      row[item.key]
                    ) : (
                      '-'
                    )
                )
              ) : row[item.key] ? (
                row[item.key]
              ) : (
                '-'
              )
            }
          </TableCell>
        ))
      }
    </TableRow>
  );
};

export default TableBasic;

// este es un ejemplo de las columnas que tienen que pasar por props
// key seria el source de cada row, y el name seria lo que se mostraria en el head
// const cols = [
//   {name: 'Concepto', key: 'concepto'},
//   {name: 'Descuento', key: 'porcentaje'},
//   {name: 'Restantes', key: 'aplicaciones_restantes'},
//   {name: 'Monto Absoluto', key: 'monto_absoluto'},
//   {name: 'Fecha de Vigencia', key: 'fecha_vigencia'},
// ];
// las rows serian :
// [
//     {
// 			"aplicaciones_restantes": 2,
// 			"concepto": "qweqwe",
// 			"fecha_vigencia": "2022-01-16T00:00:00",
// 			"monto_absoluto": 2,
// 			"porcentaje": 2
// 	   }
// ]