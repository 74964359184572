import { Box, LinearProgress, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { Datagrid, Loading, useGetList, useListContext, useResourceContext} from 'react-admin';
import { backgroundColor } from '../../Theme/theme';
import './styleLoading.css';

const DatagridCustom = ({children, defaultFilter = {}, ...props}) => {
  const { loading, error, loaded } = props;
  const {isFetching, data, setFilters, filterValues} = useListContext();
  const resource = useResourceContext();
  
  if(error){
    return(
      <Box sx={{position:'absolute', top: '0', width: '100%', backgroundColor: backgroundColor, zIndex: '9999'}}>
        <Typography variant='subtitle1'>Codigo de error: {error}</Typography>
      </Box>
    );
  }

  // Lógica para resetear filters a default cadavez que se navega por la aplicacion
  useEffect(() => {
    if(!window.location.href.includes('displayedFilters'))return setFilters(defaultFilter);
  },[]);

  const CustomEmpty = () => <div style={{padding: '20px'}}>No {resource} found</div>;
  
  if(isFetching || (props.isLoading)){
    return(
      <SkeletonList />
    );
  }

  return (
    <Datagrid {...props} empty={props.emptyComponent ? React.cloneElement(props.emptyComponent) : <CustomEmpty />} bulkActionButtons={false} >
      {children}
    </Datagrid>
  );
};

export default DatagridCustom;

export const SkeletonList = () => {
  return (
    <Box sx={{position:'absolute', top: '0', width: '100%', backgroundColor: backgroundColor, zIndex: '9999'}}>
      <Box sx={{display: 'flex', gap: '30px', width: '100%', padding: '0px 20px'}}>
        <Skeleton animation="wave" height={40} width={'20%'}/>
        <Skeleton animation="wave" height={40} width={'20%'}/>
        <Skeleton animation="wave" height={40} width={'20%'}/>
        <Skeleton animation="wave" height={40} width={'20%'}/>
        <Skeleton animation="wave" height={40} width={'20%'}/>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        {
          [0,1,2,3,4].map((i) => (
            <Box 
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                width: '100%',
                height: '60px', 
                backgroundColor: '#fff',
                padding: '9px 20px 9px 20px',
                borderRadius: '9px'
              }}>
              <Skeleton width={'20%'} height={40}/>
              <Skeleton width={'20%'} height={40}/>
              <Skeleton width={'20%'} height={40}/>
              <Skeleton width={'20%'} height={40}/>
              <Skeleton width={'20%'} height={40}/>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};
