import { FunctionField, useDataProvider, useListContext, useRefresh, useSidebarState } from 'react-admin';
import DatagridCustom, { SkeletonList } from '../../../Components/DatagridCustom/DatagridCustom';
import rowStylePlans from '../rowStylePlans';
import { useQuery } from 'react-query';
import { Box, Button, TextField, Checkbox, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress, Tooltip } from '@mui/material';
import { merchantCadena } from '../../../DataProvider';
import { green, grey } from '@mui/material/colors';
import { CheckBox } from '@mui/icons-material';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ModalCustom from '../../../Components/UI/ModalCustom';
import multinegociosEdit from '../SectionsEdit/MultinegociosEdit';
import { MdHomeWork } from 'react-icons/md';
import { createNotification } from '../../helpers/helpFunctions';
import FormTab from '../../../Components/Form/FormTab';
import SplitComponent from '../../../Components/CustomFields/SplitComponent';
import { useTheme } from 'styled-components';
import { useDebounce } from '../../../Hooks/useDebounce';
import { theme } from '../../../Theme/theme';
import { Spinner } from '../../../Components/UI/Dashboard/Spinner/Spinner';
import { FaSave } from 'react-icons/fa';
import { MultinegocioGrid } from './MultinegocioGrid';
import { StyledBody, StyledCell, StyledHead, StyledRow, StyledTable } from './Table/StyledTable';
import { CustomFixedList } from './Table/CustomFixedList';

export const PlanesHabilitados = (props) => {
  const {
    defaultFilters,
    selectedRow,
    selectedMerch
  } = props;
  const dataProvider = useDataProvider();
  const params = {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: 'id', order: 'ASC' },
    filter: {}
  };
  const { isFetching, data: { data } = [] } = useQuery(['merchants'], () => dataProvider.getList('merchants', params), {
    onError: ({ err }) => console.log(err)
  });
  const { data: planes, isFetching: isFetchingPlanes } = useListContext();

  const findValue = (merch, data) => {
    return merch?.planes_multinegocio?.find((item) => {
      if (item?.plan_id === data?.id) {
        return item;
      }
    });
  };

  const longerArr = (arr = []) => arr.filter((item) => item.enabled).length;
  const sortMerchs = (arr) => arr.sort((a, b) => longerArr(a.planes_multinegocio) > longerArr(b.planes_multinegocio) ? -1 : 1);

  const merchs = useMemo(() => {
    if(data?.length > 0) {
      if(selectedMerch?.length > 0) {
        return selectedMerch?.map((item => {
          return data?.find(id => id.id === item.id);
        }))?.sort((a, b) => a.planes_multinegocio.length > b.planes_multinegocio.length ? 1 : -1) ?? [];
      } else {
        return sortMerchs(data?.filter(merch => merch?.id !== merchantCadena && (!merch?.planes_multinegocio || merch?.planes_multinegocio.length !== 0)));
      }
    }
    return [];
  },[data, planes, selectedMerch]);

  const listProps = {
    data: merchs,
    defaultFilters: defaultFilters,
    selectedRow: selectedRow,
    isFetching: [isFetching, isFetchingPlanes].every(Boolean),
    findValue: findValue,
    planes: planes,
    sortMerchs: sortMerchs,
  };

  const MemoCustomFixedList = memo(CustomFixedList);

  return (
    <Box sx={{
      backgroundColor: '#f1f1f1',
      width: '100%',
      height: '100%'
    }}>
      <MemoCustomFixedList {...listProps} />
    </Box>
  );
};
