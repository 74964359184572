
import CreatePA from './CreatePA';
import EditPa from './EditPa';
import PuntoAccesoList from './PuntoAccesoList';
import { BiCurrentLocation } from 'react-icons/bi';
import PuntoAccesoShow from './PuntoAccesoShow';


export default{
  icon: BiCurrentLocation,
  list: PuntoAccesoList,
  create: CreatePA,
  show: PuntoAccesoShow,
  options: { label: 'Puntos de Accesos' }
};