import React, { useEffect, useState } from 'react';
import { AutoCompleteSmall } from '../../../Components/CustomFields/InputSmall';
import { createNotification, getListProvider, getLocations } from '../../helpers/helpFunctions';
import { BooleanInput, required, useDataProvider, useRecordContext } from 'react-admin';
import { choicesProvincias } from '../../helpers/choicesSelects';
import SectionForm from '../../../Components/Form/SectionForm';
import { useDebounce } from '../../../Hooks/useDebounce';

const ubicationEdit = () => {
  const record = useRecordContext();
  const isEdit = record ? true : false;
  const [loadingZonas, setLoadingZonas] = useState(false);
  const [choicesZonas, setChoicesZonas] = useState([]);
  const [location, setLocation] = useState(record?.map2 ? getLocations(record.map2) : null);
  const dataProvider = useDataProvider();

  const successLocalidades = (d, t) => {
    setChoicesZonas(d.data);
    setLoadingZonas(false);
  };

  const failedLocalidades = (e) => {
    setLoadingZonas(false);
  };

  const getLocalidades = (value) => {
    let isGBA = value.includes('GBA') || value.includes('Buenos Aires');
    let params = { 
      pagination: {page: 1, perPage: 9999}, 
      sort: {field: 'nombre', order: 'DESC'}, 
      filter: {localidad: isGBA ? 'Buenos Aires' : value}
    };
    // getListProvider(dataProvider, '/localidades', successLocalidades, failedLocalidades,
    //   {page: 1, perPage: 9999}, {field: 'nombre', order: 'DESC'}, {localidad: isGBA ? 'Buenos Aires' : value});
    dataProvider.getList('localidades', params)
      .then(res => {
        successLocalidades(res);
      })
      .catch(err => {
        failedLocalidades();
      });
  };

  const handlerProvincia = (e) => {
    let value = e.target.value;
    getLocalidades(value);
  };

  const shouldRender = (value) => {
    return value?.length >= 3;
  };

  useEffect(()=> {
    if(isEdit && record){
      const hasProvincia = record?.provincia?.length > 0;
      if(hasProvincia){
        setLoadingZonas(true);
        getLocalidades(record.provincia);
      }
    }
  }, []);

  const componentCustom = (
    <AutoCompleteSmall source='zona' choices={choicesZonas} 
      shouldRenderSuggestions={shouldRender} variant='outlined'
      optionText='nombre' suggestionLimit={25} optionValue='nombre' fullWidth
    />
  );
  
  const isIframe = () => {
    if(!location.latitud || !location.latitud) {
      createNotification('error', 'Hubo un error al ubicar el Map iframe, intentar nuevamente');
    }
  };
  const data = [
    {
      choices: choicesProvincias,
      source: 'provincia',
      type: 'select',
      onChange: handlerProvincia,
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: record?.provincia ? record.provincia : null,
      label: 'Provincia',
      xs: 12
    },
    {
      source: 'zona',
      label: 'Zona',
      xs: 12
    },
    // {
    //   custom: true,
    //   component: componentCustom,
    //   xs: 12
    // },
    {
      source: 'direccion', 
      label: 'Direccion', 
      resettable: true,
      xs: 12
    },
    {
      source: 'googlemaps-link',
      label: 'Google Maps Link', 
      resettable: true,
      xs: 6
    },
    {
      source: 'map2', 
      label: 'Map Iframe', 
      onChange: (e) => setLocation(getLocations(e.target.value)),
      validate: {
        required: 'Falta el campo Map Iframe en Ubicación', 
        pattern: {
          value: /iframe/,
          message: 'El link ingresado no es de formato iframe'
        }
      },
      
      resettable: true,
      xs: 6
    },
    location ? {
      source: 'location',
      initialValue: {'type':'Point','coordinates':[parseFloat(location.longitud),parseFloat(location.latitud)]},
      value: {'type':'Point','coordinates':[parseFloat(location.longitud),parseFloat(location.latitud)]},
      style: {display: 'none'}
    } : null,
  ];

  return data;
};

export default ubicationEdit;