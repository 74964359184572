import React, { useEffect, useState} from 'react';
import { Box } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { merchantSelected } from '../../../DataProvider';
import { useDataProvider, useRecordContext } from 'react-admin';
import { CgGym } from 'react-icons/cg';
import planEdit from '../SectionsEdit/PlanEdit';
import { useQuery } from 'react-query';

const PlanCard = ({isAbleToEdit}) => {
  const [merchant, setMerchant] = useState(null);
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  useQuery(
    ['merchants', record.merchant_id],
    async () => {
      const response = await dataProvider.getOne('merchants', {id: record?.merchant_id});
      return await response.data;
    },
    {
      onSuccess: (data) => setMerchant(data),
      onError: (err) => console.log(err),
    }
  );

  const isMulti = record?.['tipo-de-plan'] === 'cadena';
  const defaultApplication = merchant?.application_fee && Object.keys(merchant?.application_fee)?.find(field => field === record?.cobro?.toLowerCase() && field);
  const isCentral = merchant?.negocio_central;
  const isMarketPlace = merchant?.credentials?.mp_marketplace?.active || merchant?.split;
  const detailsArray = [
    {key: 'Nombre del Plan', value: record?.name},
    {key: 'Nivel de Acceso', value: record?.nivel_de_acceso},
    {key: record?.['precio-original'] ? 'Precio Original': null, value: record?.['precio-original']},
    {key: record?.['promocion'] ? 'Promoción' : null, value: record?.['promocion']},
    {key: 'Precio', value: `$ ${record?.['price']}`},
    {key: record?.['cobro'] ? 'Cobro' : null, value: record?.['cobro']},
    {key: 'Acepta Cuotas', value: record?.['acepta-cuotas'] || (typeof record?.cuotas === 'number' && record?.cuotas !== 1) ? 'Si' : 'No'},
    {key: 'SportClub Cadena', value: record?.['tipo-de-plan'] === 'cadena' ? 'Si' : 'No'},
    {
      key: 'Metodos de pagos', 
      value: `${record?.payment_methods?.efectivo ? 'Efectivo' : ''}${record?.payment_methods?.efectivo && record?.payment_methods?.tarjeta ? ', ' : ''}${record?.payment_methods?.tarjeta ? 'Tarjeta' : ''}` 
    },
    isMulti && merchantSelected?.id !== '0' && merchantSelected?.planes_multinegocio?.length > 0 ? { // && record?.['tipo-de-plan'] === 'cadena';
      key: 'Comisión Cadena', 
      value: merchantSelected?.planes_multinegocio.map(e => ( e?.application_fee && (e.plan_id === record.id)) ? `$ ${e.application_fee}` : (e.plan_id === record?.id && e.seller_fee) ? `${e.seller_fee} %` : (!e.application_fee && !e.seller_fee) && (e.plan_id === record?.id) ? '$ 0' : '' ),
    } : 
      !isMulti && (record?.application_fee || defaultApplication) ? {
        key: 'Comisión Cadena',
        value: `$ ${record?.application_fee ?? merchant?.application_fee?.[defaultApplication] ?? 0}`
      } : null
  ];
  const editComponent = {
    component: planEdit(isMulti, isCentral, isMarketPlace, merchant),
    label: 'Plan',
    icon: <CgGym/>
  };

  return (
    <CardBasic route={isMulti ? 'multinegocio' : 'planes'} title='Plan' record={record} editComponent={isAbleToEdit && editComponent}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <TableMinimal data={detailsArray}/>
      </Box>
    </CardBasic>
  );
};

export default PlanCard;