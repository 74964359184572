import { Box, CircularProgress, Radio, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import DotDivider from '../../../Components/UI/DotDivider';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { createNotification, handleClickCopy, meses } from '../../helpers/helpFunctions';
import { useRecordContext, useUpdate } from 'react-admin';
import { styleButtonSection, styleSpanPlan } from '../../../Theme/useStyle';
import visa from './CardsIcon/visa.png';
import mastercard from './CardsIcon/mastercard.png';
import emptycard from './CardsIcon/cardempty.png';
import { Button } from '@mui/material';
import { CgOptions } from 'react-icons/cg';

const imagePayment = {
  height: '32px',
  objectFit: 'cover',
  backgroundSize: 'contain',
};

const cardBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  marginBottom: '15px'
};

const cardBorderBottom = {
  borderBottom: '1px solid #c9c9c9',
  margin: '10px 0 15px 0',
  padding: '0 0 10px 0'
};

const emptyBoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0'
};

const PaymentCard = () => {
  const record = useRecordContext();
  let cards = record?.cards?.filter(c => c?.valid);
  const baseUrl = `${process.env.REACT_APP_PORTAL_SOCIO_URL}login`;
  return (
    <CardBasic 
      title={`Tarjetas (${cards?.length})`}
      asideAction={
        <Button
          variant='text' size='small' 
          style={styleButtonSection}
          color='primary'
          startIcon={<CgOptions style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
          href={ `${baseUrl}?doc=${record?.documento}&spice=2718281828&date=${new Date().toISOString()}&brand_name=${record?.brand_name ?? ''}&subscription=${record?.active_plan_id ?? ''}`}
          target='_blank'
        >
          Gestionar Tarjetas
        </Button>
      }
    >
      {cards?.length > 0 ? cards?.map((item, index) => {
        if(item){
          return(
            <Box 
              key={item.id} 
              style={index + 1 == cards.length ? cardBoxStyle : {...cardBoxStyle, ...cardBorderBottom}}
            >
              <Box>
                <img 
                  style={imagePayment}
                  src={item?.card_brand?.includes('Visa') ? visa : item?.card_brand?.toLowerCase().includes('mastercard') ?  mastercard : emptycard}
                  loading="lazy"
                />
              </Box>
              <Box style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <Box style={{display: 'flex', gap: '2px', flexDirection: 'column'}}>
                  <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: '', marginBottom:'6px'}}>
                    <Typography variant='subtitle2'>
                      {item.card_brand}
                      <span style={styleSpanPlan}> terminada en </span>
                      {item.last_four_digits}
                    </Typography>
                    <DotDivider margin='0 0'/>
                    <Typography variant='subtitle2'>
                      {item.issuer?.name}
                    </Typography>
                    <DotDivider margin='0 0'/>
                    <Typography variant='subtitle2'>
                      {item?.card_type?.includes('credit') ? 'Credito' : 'Debito'}
                    </Typography>
                  </Box>
                  <Box style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: ''}}>
                    <Typography variant='subtitle2'>
                      {item.cardholder?.name}
                    </Typography>
                    <DotDivider margin='0 0'/>
                    <Typography variant='subtitle2'>
                      <span style={styleSpanPlan}> expira en </span>
                      {meses[item.expiration_month - 1]}
                      <span style={styleSpanPlan}> de </span>
                      {item.expiration_year}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  { index == 0 ? (
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                      <Typography color='primary' variant='subtitle2'>
                        Activa
                      </Typography>
                    </Box>
                  ) : (
                    <ButtonActiveCard item={item} record={record}/>
                  )}
                </Box>
              </Box>
            </Box>
          );
        } else{
          return (
            <Box style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant='subtitle2'>No se puede leer esta tarjeta</Typography>
            </Box>
          );
        }
      }) : (
        <Box style={emptyBoxStyle}>
          <Typography variant='subtitle2'> No hay ninguna tarjeta asociada a este socio</Typography>
        </Box>
      )}
    </CardBasic>
  );
};

const ButtonActiveCard = ({item, record}) => {
  const [update, {isLoading, loaded, error}] = useUpdate();

  const handleActive = (id) => {
    let cardSelected;
    let cardsToSend = record.cards.filter(c => {
      if(c.id == id){
        cardSelected = c;
        return;
      }
      return c;
    });
    cardsToSend = [cardSelected, ...cardsToSend];
    // update('socios', record.id, {cards: cardsToSend}, record);
    update('socios',  {id: record?.id, data: {'cards': cardsToSend}}, {
      onSuccess: () => {
        createNotification('success', 'Nueva tarjeta seleccionada con éxito'); 
      },
      onError: () => {
        createNotification('error', 'No se pudo setear la tarjeta, por favor intente más tarde');
      }
    });
  };

  return(
    <>
      {
        isLoading ? (
          <Box style={{margin: '10px 10px 0 0'}}>
            <CircularProgress style={{width: '20px', height: '20px'}}/>
          </Box>
        ) : (
          <Radio
            onChange={() => handleActive(item.id)}
            size='small'
            value={item.id}
            name="radio-buttons"
          />
        )
      }
    </>
  );
};

export default PaymentCard;
