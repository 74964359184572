import { Box, Typography, IconButton, Chip, Popover, Button, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChartLine from './ChartLine';
import ChartBar from './ChartBar';
import ChartPie from './ChartPie';
import { apiUrl, merchantContext } from '../../../../DataProvider';
import { ButtonReport, CobrosCustomReport } from '../../../../Resources/Dashboard/Reportes/ButtonsReport';
import { Spinner } from '../Spinner/Spinner';
import {MdImageNotSupported} from 'react-icons/md';
import ModalCustom from '../../ModalCustom';
import { RiEyeCloseLine } from 'react-icons/ri';
import { RiEyeLine } from 'react-icons/ri';

const COLORS = [
  '#7FBCD2',
  '#A5F1E9',
  '#E1FFEE',
  '#FFEEAF',
  '#2dc2bd',
];

const CardContent = ({
  egresos, 
  accessCard, 
  accesos, 
  isSuccess, 
  isFetchedVentas, 
  isFetching, 
  rechazosDatasets, 
  loading, 
  ventasData, 
  datasets,
  url, 
  name, 
  helpText, 
  title, 
  chipData,
  chartPie, 
  date, 
  noCard,
  chartBar,
  chartLine, 
  cobros,
  rechazos,
  hideValue,
  setHideValue,
  totalEgresos,
  isError
}) => {

  const today = new Date();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newFilter, setNewFilter] = useState([]);
  const [openReportModal, setOpenReportModal] = useState(false);
  const selectedStartDate = new Date(date?.from);
  const selectedEndDate = new Date(date?.until);
  const [filterDate, setFilterDate] = useState(
    {
      from: new Date(selectedStartDate).toISOString().slice(0,10),
      until: new Date(selectedEndDate).toISOString().slice(0,10)
    }
  );
  useEffect(() => {
    date && setFilterDate({
      from: new Date(selectedStartDate).toISOString().slice(0,10),
      until: new Date(selectedEndDate).toISOString().slice(0,10)
    });
  },[date]);
  const openMenu = Boolean(anchorEl);
  const { setMerchant, getMerchant} = useContext(merchantContext);

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  console.log('total egresos', totalEgresos);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  let currencyFormat = new Intl.NumberFormat('es-ar', {
    style: 'currency',
    currency: 'ARS'
  });

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'start',  overflow: 'hidden', overflowX: 'scroll'}}>
        { isFetching ? (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', minHeight: '320px'}}>
            <Spinner />
          </Box>
        ) : ((!isFetching && (datasets?.chart?.altas?.length <= 0 && datasets?.chart?.recurrentes?.length <= 0) || isError)) ? (
          <>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', minHeight: '320px'}}>
              <MdImageNotSupported size={'100px'} color={grey[400]}/>
              <Typography color={grey[400]} variant="h7" fontWeight="600">No hay Data</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{display: 'flex', flexDirection: 'column',  width: `${noCard ? '100%' : '75%'}`, padding: '12px 21px'}}>
              <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 6px 6px'}}>
                <Typography variant='h6' width={'auto'} minWidth={'450px'} color={grey[700]}>{name}</Typography>
                { 
                  helpText && <>
                    <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
                      <HelpOutlineIcon sx={{fontSize: '21px'}} />
                    </IconButton>
                    <Popover
                      open={openMenu}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    >
                      <Box sx={{
                        maxWidth: '320px',
                        backgroundColor: '#fff',
                        padding: '15px 18px'
                      }}>
                        <Typography variant='subtitle2' fontWeight={400} color={grey[600]}>{helpText}</Typography>
                      </Box>
                    </Popover>
                  </>
                }
              </Box>
              <Box sx={{width: '100%', marginBottom: '12px'}}>
                {
                  chartLine && datasets?.chart && 
              <Box sx={{width: '100%'}}>
                <ChartLine hideValue={hideValue} accesos={accesos} date={date} datasets={datasets} colors={COLORS}/>
              </Box>
                }
                {
                  chartBar && 
            <Box sx={{display: 'flex', justifyContent: 'center', minHeight: '250px' , height: '300px'}}>
              <ChartBar egresos={egresos} accesos={accesos} rechazosDatasets={rechazosDatasets} datasets={datasets} cobros={cobros} rechazos={rechazos} colors={COLORS}/>
            </Box>
                }
        
                {
                  chartPie && datasets?.chart && 
            <Box sx={{display: 'flex', justifyContent: 'center', height: '150px'}}>
              <ChartPie datasets={datasets} colors={COLORS}/>
            </Box>
                }
              </Box>
            </Box>
      
            {
              !noCard && (
                <>
                  <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    backgroundColor: 'secondary', 
                    width: '25%',
                    padding: '20px',
                    borderRadius: '6px'
                  }}>
                    <Box style={{display: 'flex', alignItems: 'start', justifyContent: 'flex-end'}}>
          
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', paddingTop: '5px'}}>
                      <Typography variant='body2' fontWeight={400} color={grey[600]}>Total</Typography>
                      <Box style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h5' fontWeight={600} color={grey[900]}>{(hideValue !== null) && hideValue ? '$ ***,***' : currencyFormat.format(ventasData.totalAltas + ventasData.totalRecurrentes)}</Typography>
                        <Box 
                          onClick={() => setHideValue(!hideValue)}
                          sx={{
                            height: '40px',
                            width: '40px',
                            borderRadius: '50%', 
                            // marginLeft: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            cursor: 'pointer',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              backgroundColor: grey[200]
                            }}}>
                          {
                            hideValue ? <RiEyeCloseLine size={'30px'} /> : <RiEyeLine size={'30px'} />
                          }
                          
                        </Box>
                      </Box>
                      {
                        hideValue !== null && hideValue ? <Typography variant="h6" fontWeight={400} color={grey[500]}>** %</Typography> :
                          (
                            <Typography 
                              variant='h6' 
                              fontWeight={400} 
                              color={chipData?.total >= 0 ? 'green.primary' : 'red.primary'}
                            >
                              {chipData?.total > 0 ? `+${chipData?.total}%` : `${chipData?.total}%`} 
                            </Typography>
                          )
                      }
                    </Box>
                    <Box 
                      sx={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between', 
                        height: '100%',
                        marginTop: '5px'
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'space-between',
                      }}>
                        <Box style={{display: 'flex', flexDirection: 'column', margin: '10px 0'}}>
                          <Typography variant='body2' fontWeight={400} color={grey[600]}>Recurrentes</Typography>
                          <Typography variant='h7' fontWeight={400} color={grey[900]}>{(hideValue !== null) && hideValue ? '$ ***,***' : currencyFormat.format(ventasData.totalRecurrentes)}</Typography>

                          {
                            (hideValue !== null) && hideValue ? <Typography variant="h6" fontWeight={400} color={grey[500]}>** %</Typography> :
                              (
                                <Typography 
                                  variant='h7' 
                                  fontWeight={400} 
                                  color={chipData?.recurrentes >= 0 ? 'green.primary' : 'red.primary'}
                                >
                                  {chipData?.recurrentes > 0 ? `+${chipData?.recurrentes}%` : `${chipData?.recurrentes}%`} 
                                </Typography>
                              )}
                        </Box>
                        <Box style={{display: 'flex', flexDirection: 'column', margin: '10px 0'}}>
                          <Typography variant='body2' fontWeight={400} color={grey[600]}>Mostrador</Typography>
                          <Typography variant='h7' fontWeight={400} color={grey[900]}>{(hideValue !== null) && hideValue ? '$ ***,***' : currencyFormat.format(ventasData.totalAltas)}</Typography>
                          {
                            (hideValue !== null) && hideValue ? <Typography variant="h6" fontWeight={400} color={grey[500]}>** %</Typography> :
                              (
                                <Typography 
                                  variant='h7' 
                                  fontWeight={400}
                                  marginBottom={2}
                                  color={chipData?.altas >= 0 ? 'green.primary' : 'red.primary'}
                                >
                                  {chipData?.altas > 0 ? `+${chipData?.altas}%` : `${chipData?.altas}%`} 
                                </Typography>
                              )}
                        </Box>
                      </Box>
                      <ReportsModal 
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                        newFilter={newFilter}
                        setNewFilter={setNewFilter}
                        openReportModal={openReportModal}
                        setOpenReportModal={setOpenReportModal}
                      />
                    </Box>
                  </Box>
                  <ReportsModal 
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    allDate={date}
                    newFilter={newFilter}
                    setNewFilter={setNewFilter}
                    openReportModal={openReportModal}
                    setOpenReportModal={setOpenReportModal}
                  />
                </>
              )
            }
            {
              accessCard && (
                <Box sx={{
                  display: 'flex', 
                  flexDirection: 'column', 
                  backgroundColor: 'secondary', 
                  width: '25%',
                  minWidth: '100px',
                  padding: '20px 30px',
                  height: 'auto',
                  borderRadius: '6px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}>
                  <Box style={{display: 'flex',  alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', paddingTop: '5px'}}>
                      <Typography variant='body2' fontWeight={400} color={grey[600]}>Total Ingresos</Typography>
                      <Typography variant='h5' fontWeight={600} color={grey[900]}>{datasets?.chart?.accesos?.reduce((a, element) => a+element, 0)}</Typography> 
                    </Box>
                  </Box>
                  <Box style={{display: 'flex',  alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', paddingTop: '5px'}}>
                      <Typography variant='body2' fontWeight={400} color={grey[600]}>Total Egresos</Typography>
                      <Typography variant='h5' fontWeight={600} color={grey[900]}>{totalEgresos?.toString() ?? ''}</Typography> 
                    </Box>
                  </Box>
                </Box>
              )
            }
          </>
        )}
      </Box>
    </>
  );
};

export default CardContent;

export const ReportsModal = ({setOpenReportModal, openReportModal, filterDate, setFilterDate, newFilter, setNewFilter, allDate}) => {

  const {getMerchant, setMerchant} = useContext(merchantContext);
  const today = new Date();
  const [filter, setFilter] = useState([]);
  const selectedStartDate = new Date(allDate?.from);
  const selectedEndDate = new Date(allDate?.until);
 
  const [date, setDate] = useState(
    allDate && {
      from: new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), 1).toISOString().slice(0,10),
      until: new Date(selectedEndDate).toISOString().slice(0,10)
    }
  );
  
  
  const handleClose = () => {
    setOpenReportModal(false);
  };

  return(
    <>
      {/* <Button
      variant='outlined' 
      fullWidth
      color='primary'
      style={{display: 'flex', justifyContent: 'center'}}
      onClick={() => setOpenReportModal(true)}
    > Reportes </Button> */}
      <ModalCustom
        open={openReportModal}
        onClose={handleClose}
        title={'Reportes'}
      >
        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', gap: '20px', padding: '15px', marign: '20px'}}>
          <CobrosCustomReport
            url='pagos_reporte' 
            text='Reporte Cobros'
            openModal
            viewMerch
            date={filterDate}
            setDate={setFilterDate}
            filter={newFilter}
            setFilter={setNewFilter}
            viewDate
            msgError='No existen pagos para este negocio en estas fechas'
          />
          <ButtonReport 
            url='facturacion_reporte' 
            text='Facturas'
            filter={{'from': filterDate.from, 'until': filterDate.until, 'merchant': [getMerchant().id]}}
            openModal
            date={filterDate}
            setDate={setDate}
            viewDate
            msgError='No existen facturas para este negocio en estas fechas'
          />
        </Box>
      </ModalCustom>
    </>
  );
};