import React from 'react';
import { useRecordContext } from 'react-admin';
import { BsQuestionCircle } from 'react-icons/bs';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { dataQuestions } from '../CorporativoCreateEdit';

const QuestionsCard = () => {

  const record = useRecordContext();

  const data = record ? [
    {key: 'Aclaración sobre el precio', value: record['pregunta-sobre-pago']},
    {key: 'Aclaración principal', value: record['pregunta-sobre-debito']},
    {key: 'Aclaración bajo el botón “Asociate”', value: record['descripcion']}

  ] : [];

  const editComponent = {
    component: dataQuestions(),
    label: 'Aclaraciones',
    icon: <BsQuestionCircle/>
  };
  
  return (
    <CardBasic title='Aclaraciones' record={record} editComponent={editComponent} route={'corporativo'}>
      <TableMinimal data={data} widthBoxKey='150px' noBorderBottom textAlign='left'/>
    </CardBasic>
  );
};

export default QuestionsCard;