import React, { useEffect, useState } from 'react';
import { useRefresh } from 'react-admin';
import TableSocios from '../../../Components/UI/TableSocios';
import { Box, Button } from '@mui/material';
import ModalSocio from '../../../Components/UI/ModalSocio';
import { InputSmall } from '../../../Components/CustomFields/InputSmall';
import SectionForm from '../../../Components/Form/SectionForm';

const descuentosSocio = (planCadena) => {
  const [discounts, setDiscounts] = useState([]);
  const [open, setOpen] = useState(false);
  // const refresh = useRefresh()
  const deleteHandler = (index) => {
    setDiscounts(discounts.filter((item,i) => i != index));
  };

  useEffect(() => {
    if(planCadena){
      setDiscounts([]);
    }
  },[planCadena]);


  const data = [
    {
      custom: true,
      component: <Box>
        <TableSocios discounts={discounts} deleteHandler={deleteHandler}/>
        <Box style={{marginTop: '20px'}}>
          <Button variant='outlined'
            disabled={planCadena}
            onClick={()=>setOpen(true)}
          >
        Agregar Descuento
          </Button>
        </Box>
        <ModalSocio open={open} setOpen={setOpen} setDiscounts={setDiscounts} discounts={discounts}/>
        {/* <InputSmall source='discounts' initialValue={discounts} style={{display: 'none'}}/> */}
      
      </Box>,
      xs: 12
    },
    {
      source: 'discounts',
      value: discounts,
      displayNone: true,
      style: {display: 'none'}
    }
  ];
  return data;
  // return (
  //   <SectionForm title='Descuentos' data={data}>
      
  //   </SectionForm>
  // );
};

export default descuentosSocio;