import { useState, useEffect, useContext } from 'react';
import { AccountCircle, OtherHouses, SearchRounded } from '@mui/icons-material';
import { useRefresh } from 'react-admin';
import { Skeleton, Autocomplete, InputAdornment } from '@mui/material';
import { Box, Typography, TextField  } from '@mui/material';
import { apiUrl, merchantCadena, merchantCentral, merchantContext } from '../../../../DataProvider';
import axios from 'axios';
import { useQuery } from 'react-query';
import HomeChart from '../../../../Assets/Images/HomeChart2.png';
import { TextInputSimple } from '../../../TextInputIcon/TextInputIcon';

const SelectMerch = ({
  Image,
  message,
  imageSize,
  setMerchant,
}) => {
  const [merchs, setMerchs] = useState(null);
  let token = localStorage.getItem('auth');
  const { getMerchant } = useContext(merchantContext);
  let tokenSend = token?.slice(10, -2);
  const { isLoading } = useQuery(
    ['merchants'],
    () =>   axios({
      url: `${apiUrl}/merchants?filter={}&range=[0,999]&sort=["id","ASC"]`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': getMerchant()?.id,
        'access-control-allow-origin': '*'
      },
    }),
    {
      onSuccess: ({data}) => {
        let arrMerch = [];
        for(let i = 0; i < data.data.length; i++){
          if((data.data[i]?.id === merchantCadena) || merchantCentral?.find(central => central?.id === data.data[i]?.id)){
            continue;
          } else {
            arrMerch.push(data?.data?.[i]);
          }
        }
        setMerchs(arrMerch);
      },
    }
  );
 

  return (
    <>
      <Box marginTop={'100px'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        {/* <FaRegChartBar size={'230px'} color={grey[400]} /> */}
        <img title="Disabled Menu" style={{ borderRadius: '4px', width: imageSize ?? '600px', opacity: '0.8'}} src={`${Image ?? HomeChart}`} />
        <Box style={{width: '60%',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '60px'}}>
        
          {
            (merchs && merchs?.length === 0) ? (
              <Typography  variant="h5" textAlign="center" paragraph>No hay información de este negocio.</Typography>
            ) : (
              <Typography variant="h5" textAlign="center" paragraph>{message}</Typography>
            )
          }
          {
            !merchs ? (
              <>
                <Skeleton width={280} height={35} /> 
                <Skeleton width={280} height={35} /> 
              </>
            ) : merchs?.length === 0 ? (
              <>
              </>
            ) : (
              <Autocomplete
                size='medium'
                sx={{width: '50%', margin: '30px 0'}}
                disablePortal
                getOptionLabel={(option) => option?.name}
                onInputChange={(e, v) => merchs.map(e => e?.name === v ? setMerchant(e) : null)}
                options={merchs}
                renderInput={(params) => 
                  <TextInputSimple 
                    placeholder='Negocios'
                    alwaysOn 
                    variant='outlined' 
                    resettable 
                    icon='search'
                    color='default'
                    minLetters={3}
                    style={{width: '100%'}}
                    {...params}
                  />
                }
              />
            )
          }
        </Box>
        {/* <EmptyLayout title={"Es necesario seleccionar un negocio para visualizar el dashboard"} /> */}
      </Box>
    </>
  );
};

export default SelectMerch;