import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import { DeleteWithConfirmButton, Show, useRecordContext, useRedirect} from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { ButtonToolbar } from '../../Components/UI/Buttons/ButtonToolbar';
import { asideActionsStyles, bannerStyle } from '../../Theme/useStyle';
import AccessCard from './SectionsShow/AccessCard';
import InstancesCard from './SectionsShow/InstancesCard';
import SocioCard from './SectionsShow/SocioCard';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';

const AccesosShow = (props) => {
  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Acceso: ' source='socio_nombre'/>}
      aside={<AsideShow />} 
      actions={null}
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <SocioCard/>
        <AccessCard/>
        <InstancesCard/>
      </ShowTransparent>
    </Show>
  );
};

const AsideShow = ({basePath, resource, ...rest}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  
  const handleSocio = () => {
    console.log(record);
    localStorage.setItem('documento', record?.socio_documento);
    redirect('/busqueda');
  };
  const data = record ? [
    // {
    //   text: 'Ver Socio', 
    //   icon: <RemoveRedEyeOutlinedIcon style={{fontSize: '17px'}}/>, 
    //   link: `/socios/${record.socio_id}/show`
    // },
    {
      custom: true,
      component: <Button style={{padding: '9px 26px', fontWeight: '600', textTransform: 'none'}} startIcon={<RemoveRedEyeOutlinedIcon style={{fontSize: '17px'}}/>} onClick={() => handleSocio()}>Ver Socio</Button>
    },
    {
      text: 'Ver Sede',
      icon: <RemoveRedEyeOutlinedIcon style={{fontSize: '17px'}}/>,
      link: `/sedes/${record.sede_id}/show`
    },
    { divider: true },
    {
      rest: rest,
      label: 'Eliminar Acceso',
      confirmTitle: '¿Eliminar Acceso?',
      confirmContent:'Seguro quieres eliminar este acceso?',
      record: record
    }
  ] : [];

  return(
    <AsideActionsShow data={data} />
  );
};

export default AccesosShow;