import React from 'react';
import AmenitiesCreateEdit from './AmenitiesCreateEdit';
import { Create } from 'react-admin';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';

const AmenitiesCreate = (props) => {
  return (
    <Create 
      {...props} 
      title={<TitleEdit title='Crear Servicio '/>} 

    >
      <AmenitiesCreateEdit/>
    </Create>
  );
};

export default AmenitiesCreate;
