
export const getPeriods = () => {
  let today = new Date();
  let firstPeriodDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);

  function pushPeriods (date, arr) {

    let newDate = new Date(date);
    let month = Math.floor(newDate.getMonth() + 1);
    let year = Math.floor(newDate.getFullYear());
    let newArr = arr;
    newDate.setMonth(newDate.getMonth() - 1);
    if(Math.floor(newDate.getMonth() + 1) === 12) newDate.setFullYear(year - 1);

    if(!arr) return pushPeriods(newDate, [`${date.getMonth() + 1}/${date.getFullYear()}`]);
    
    if(newArr.length === 23) return arr;

    return pushPeriods(newDate, [...newArr, `${month}/${year}`]);
  }

  return pushPeriods(firstPeriodDate);
};