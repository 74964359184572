import React from 'react';
import { Datagrid, DateField, FunctionField, List, ReferenceField, TextField, useRedirect } from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { SOURCES, STATUS } from '../../Models/Boletas';

const ListCobros = (props) => {
  const redirect = useRedirect();
  return (
    <List
      bulkActionButtons={false} 
      actions={null} 
      perPage={25} 
      
      empty={false}
      {...props}
    >
      <DatagridCustom 
        rowClick={(id, resource, record) => redirect('show', 'socios', record.socio.id)}
      >
        {/* <ReferenceField label='Socio' source='member_id' reference='socios' link='show'>
          <FunctionField
            render={record => `${record.nombre} ${record.apellido}`}
          />
        </ReferenceField>  */}

        {/* <ReferenceField label='Plan' source='plan_id' reference='planes' link={false}>
          <TextField source="name" />
        </ReferenceField>  */}
        <FunctionField
          onCl
          render={record => `${record.socio.nombre} ${record.socio.apellido}`}
        />
        <TextField source="plan" />
      
        <DateField source='original_payment_date' label='Fecha de deuda' />

        <FunctionField
          label='Precio'
          render={record => `$ ${record.final_price}`}
        />
      </DatagridCustom>
    </List>
  );
};

export default ListCobros;