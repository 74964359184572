import React, {useState, useEffect, useContext} from 'react';
import { Box, Typography, IconButton, Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import ChartBar from '../UI/Dashboard/Card/ChartBar';
import { grey } from '@mui/material/colors';
import { MdOutlineFileDownload } from 'react-icons/md';
import { ModalDateCustom } from '../UI/ExportCustomButton';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import { apiUrl, merchantContext } from '../../DataProvider';
import axios from 'axios';


const optionsChart = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 5
  },
  elements: {
    bar: {
      borderWidth: 0
    },
    point:{
      pointRadius: 4,
    }
  },
  scales: {
    x: {
      display: false,
      ticks: {
        display: false
      },
    },
    y: {
      display: false,
      ticks: {
        display: false
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const LabelBar = ({label, small, fullWidth, colors, min, allDate}) => {
  const today = new Date();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);
  const selectedStartDate = new Date(allDate.from);
  const selectedEndDate = new Date(allDate.until);
  const [date, setDate] = useState(
    {
      from: new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth() +1, 1).toISOString().slice(0,10),
      until: new Date(selectedEndDate).toISOString().slice(0,10)
    }
  );
  const { getMerchant } = useContext(merchantContext);
  const handleFilter = (val, value) => {
    if(value === 'merchant') {
      let idsmerch = val.map((item) => item.id);
      setFilter((f) => ({...f, 'merchant': idsmerch}));
    }
  };

  function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(JSON.parse(reader.result));
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  const download = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    const dateFilter = {'from': date.from, 'until': date.until };
    const selectedFilter =  `filter=${JSON.stringify(dateFilter)}`;
    let urlApi = label.url === 'socios_activos_pro' ? `${uri}/${label.url}?filter=${JSON.stringify({'status': ['activo']})}` : `${uri}/${label.url}?${selectedFilter}`; 
    axios({
      url: urlApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': getMerchant().id,
        'access-control-allow-origin': '*',
        'access-control-expose-headers' : '*'
      },
      responseType: 'blob'
    })
      .then((response) => {
        // const filename = response?.headers?.['content-disposition']?.split('filename=')[1];
        // const url = window.URL
        //   .createObjectURL(new Blob([response?.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', filename ? `${filename}` : `${label.title}.xlsx`);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        setOpen(false);
        setLoading(false);
        // createNotification('success', 'Reporte Descargado');
        createNotification('success', `Se enviara un mail con el reporte de ${date.from} hasta ${date.until}`);

      }).catch(async err => {
        const responseData = new Blob([err.response.data]);
        const message = await readBlobAsText(responseData);
        if(message){
          createNotification('error', message.msg);
          setOpen(false);
          setLoading(false);
          return;
        }
        // createNotification("error", err.response?.data.msg);
        createNotification('error', 'Hubo un error');
        setOpen(false);
        setLoading(false);
      });
  };

  const handleReportChoice = (label) => {
    // if(label === 'socios_activos_pro') {
    //   return download();
    // } else {
    //   setOpen(true);
    // }
    download();
  };


  //Responsive
  const xs = useMediaQuery('(max-width:1300px)');
  const xxs = useMediaQuery('(max-width:1000px)');
  const xxxs = useMediaQuery('(max-width:800px)');


  return (
    <>
      {
        loading ? (
          <Box sx={{display: 'flex',flexDirection: 'column'}}>
            <Skeleton width={210} height={35} />
            <Skeleton width={180} height={25} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: small ? '60px' : '80px', 
                maxWidth: '180px',
                // width: 'auto',
                minWidth: '10vw',
                animation: '1s infinite ease-in-out'
              }}
            >
              {
                !min ? (
                  <>
                    <Box sx={{display: 'flex', gap: '12px', alignItems: 'flex-start'}}>
                      <Typography variant={small ? 'h6':'h4'} fontWeight={500} color={grey[700]}>{label.number}</Typography>
                      <Tooltip title={label.tooltip} placement='top'>
                        
                        <Typography variant='body2' color={grey[600]} sx={{
                          marginTop: '6px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}>{label.title}</Typography>
                        
                      </Tooltip>
                    </Box>
                    <Box sx={{height: '65%'}}>
                      <ChartBar datasets={label.datasets} optionsChart={optionsChart} colors={colors}/>
                    </Box>
                  </>   
                ) : (
                  <Box style={{dispaly: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', maxWidth: '140px'}}>
                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center'}}>
                      <Tooltip title={label.tooltip} placement='top'>
                        
                        <Typography variant='h7' fontWeight={500} color={grey[600]} sx={{textAlign: 'start', fontSize: '.8em', marginTop: '6px', textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'}}>{label.title}</Typography>
                        
                      </Tooltip>
                      <Tooltip title={label.tooltip} placement='top'>
                        {
                          label?.url === 'socios_activos_pro' && ((new Date(allDate?.until).getMonth()) !== (today.getMonth()) ) ?
                            <>
                              <IconButton style={{visibility: 'hidden'}} aria-label="download">
                                <MdOutlineFileDownload fontSize={'18px'} color={grey[700]}/>
                              </IconButton>
                            </> : 
                            <IconButton  onClick={() => handleReportChoice(label.url)} aria-label="download">
                              <MdOutlineFileDownload fontSize={'18px'} color={grey[700]}/>
                            </IconButton>
                        }
                      </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'start', gap: '10px', alignItems: 'center'}}>
                      <Typography variant='h5' fontWeight={500} color={grey[800]}>
                        {label.number}
                      </Typography>
                      <Typography 
                        variant='subtitle1' 
                        fontWeight={500} 
                        color={(label.dif >= 0 && (!label.data || label.data === 'altas')) ? 'green.primary' : (label.dif <= 0 && (label.data === 'bajas' || label.data === 'inactivos')) ? 'green.primary' :  'red.primary'}
                      >
                        {label.dif > 0 && '+'}{!isNaN(label.dif) ? label.dif : 0}%
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              { open && (
                <>
                  <ModalDateCustom
                    open={open} 
                    setOpen={setOpen} 
                    loading={loading}
                    onClick={download}
                    disableDates
                    date={date}
                    activos={label.url === 'socios_activos_pro' ? true : false}
                    setDate={setDate}
                    setFilter={handleFilter}
                    viewDate={label.viewDate}
                    tooltip={label.reporteTooltip}
                  />
                </>
              )}
            </Box>
          </>
        )
      }
    </>
  );
};

export default LabelBar;