import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import S3 from 'react-aws-s3';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { Button } from '@mui/material';
import { useResourceContext } from 'react-admin';
import { S3Client, ListObjectsV2Command, PutBucketCorsCommand,PutObjectCommand} from "@aws-sdk/client-s3";


window.Buffer = window.Buffer || require('buffer').Buffer;
const AWS_REGION= process.env.REACT_APP_AWS_REGION
const AWS_ACCESS_KEY_ID=process.env.REACT_APP_AWS_ACCESS_KEY_ID
const AWS_SECRET_ACCESS_KEY=process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

const configAWS = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
};
const awsConfig = {
  region: AWS_REGION,
  credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
  }
};

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

const ButtonAws = ({ setUrl, text, small, variant, style, fileUpload, fileNameCustom, finish, accept, succesText, failedText, disabled}) => {
  const [loading, setLoading] = useState(false);
  const resource = useResourceContext();
  
  const uploadAWS = (file, fileName) => {

    const dirName = resource ? `originals/${resource}` : 'nodirName';
    const reactS3Client = new S3Client(awsConfig);
    const timestamp = Date.now();
    const nameTrim = fileName?.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s/g, '-')+ `-${timestamp}`;
 
    const fileParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: nameTrim,
        Body: file,
        ContentType: file.type
    }
            
    const fileCommand = new PutObjectCommand(fileParams)
    reactS3Client.send(fileCommand)
    .then((data) => {
        setLoading(false);
        const url = `https://${fileParams.Bucket}.s3.amazonaws.com/${nameTrim}`
      setUrl && setUrl(url);
      finish && finish(url);
      createNotification('success', succesText ? succesText : 'Imagen subida con exito');
    }).catch(e => {
      setLoading(false);
      console.log(e);
      createNotification('error', failedText ? failedText : 'No se pudo subir la imagen', ()=> handleClickCopy({e,nameTrim}));
    });
  };

  const handleClick = (e) => {
    if(fileUpload){
      setLoading(true);
      const file = dataURLtoFile(fileUpload, fileNameCustom);
      if(file.size < 4000000){
        uploadAWS(file, fileNameCustom);
      }else{
        createNotification('error', 'La imagen debe tener 4mb como máximo');
      }
    }
    else if (e.target.files.length > 0) {
      setLoading(true);
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      if(file.size < 4000000){
        uploadAWS(file, fileName);
      }else{
        createNotification('error', 'La imagen debe tener 4mb como máximo');
        setLoading(false);

      }
    }
  };
  return (
    <label style={{width: '100%'}}>
      {
        !fileUpload && (
          <Box style={{display: 'none'}}>
            <input type='file' onChange={handleClick} accept={accept} />
          </Box>
        )
      }
      <Button 
        variant={variant ? variant : 'contained'}
        color='primary' 
        component="span" 
        disabled={loading || disabled}
        size={small && 'small'}
        endIcon={loading && <CircularProgress size='20px' />}
        style={style} 
        onClick={fileUpload && (() => handleClick())}
      >
        <span style={{width: '100%', textAlign: 'center'}}>{text}</span>
      </Button>
    </label>
  );
};

export default ButtonAws;
