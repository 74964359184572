import { Box, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecordContext, useShowController } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import { dateFormat } from '../../helpers/helpFunctions';

const cols = [
  {name: 'Acceso', key: 'access_granted'},
  {name: 'Credencial', key: 'source'},
  {name: 'Fecha', key: 'fecha'},
  {name: 'Hora', key: 'time'},
  {name: 'Motivo', key: 'manual_access_reason'},
];

const InstancesCard = () => {
  const record = useRecordContext();
  const [page, setPage] = useState(1);
  const [instances, setInstances] = useState(null);
  
  useEffect(() => {
    if(record.instances){
      const inst = record?.instances?.map((item) => (
        {
          access_granted: item?.access_granted ? 'Si' : 'No',
          source: item?.source?.includes('digital') ? 'Digital' : 'Manual',
          fecha: dateFormat(item?.time?.slice(0,10)),
          time: item?.time?.slice(11,16),
          manual_access_reason: item?.manual_access_reason
        }
      ));
      const instancesPagination = inst?.slice((page * 5) - 5, (page * 5));
      setInstances(instancesPagination);
    }
  }, [page, record]);

  return (
    <CardBasic title='Instancias'>
      {instances ? 
        <TableBasic
          cols={cols} 
          rows={instances}
          page={page}
          setPage={setPage}
          total={record.instances?.length}
          emptyText='No se encuentran instancias de accesos'
        /> : (
          <Box>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
          </Box>
        )
      }
    </CardBasic>
  );
};

export default InstancesCard;