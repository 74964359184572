import React, { useEffect } from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { iconsChips } from '../../../Theme/useStyle';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import { useRecordContext } from 'react-admin';
import informationEdit from '../SectionsEdit/InformationEdit';
import { BsCardText } from 'react-icons/bs';

const CorporativoHeader = ({permisToEdit, isSede, setSlugValue, slugValue, editHaberes, setEditPrice}) => {
  const record = useRecordContext();

  const selectType = record?.tipo;
  const editComponent = {
    component: informationEdit(setSlugValue, slugValue, setEditPrice, editHaberes, null, selectType, null),
    label: 'Información',
    icon: <BsCardText/>
  };
  
  const chips = [
    !isSede && {label: record['email-contacto'], icon: <MailOutlineOutlinedIcon style={iconsChips}/>},
    {label: record.vertical, icon: <CorporateFareOutlinedIcon style={iconsChips}/>}
  ];

  return (
    <CardBasic route={'corporativo'} editComponent={permisToEdit && editComponent} record={record}>
      <HeaderCardShow title={record.name} subtitle={record.slug} chips={chips} archived={record.archivado}/>
    </CardBasic>
  );
};

export default CorporativoHeader;