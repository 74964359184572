import { ThemeProvider } from '@mui/styles';
import SocioShow from './SocioShow';
import { theme } from '../../Theme/theme';
import {StylesProvider} from '@mui/styles';

const ShowWrapper = ({children}) => {

  return (
    <>
      <StylesProvider injectFirst>
        {children}
      </StylesProvider>
    </>
  );
};

export default ShowWrapper;