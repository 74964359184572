import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { apiUrl, merchantContext } from '../DataProvider';
import { useContext } from 'react';
import { useEffect } from 'react';


const useAxios = ({key, url, noContext, header, params, method = 'GET', data, tokens}) => {
  const token = localStorage.getItem('auth');
  const tokenSend = JSON.parse(token)?.token;
  const { getMerchant } = useContext(merchantContext);
  return useQuery(key, () => axios({
    url: url,
    method: method,
    data: data,
    headers: tokens ? {
      'Content-Type': 'application/json',
      'X-Access-Tokens': tokenSend,
      'Access-Control-Request-Method': method,
      'Access-Control-Request-Headers': 'origin, x-requested-with',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'context-merchant': noContext ? '' : (getMerchant()?.id == '0' ? '' : getMerchant()?.id),
      ...header
    } : {
      'Content-Type': 'application/json',
      'X-Access-Tokens': tokenSend,
      'Access-Control-Request-Method': method,
      'Access-Control-Request-Headers': 'origin, x-requested-with',
      'access-control-allow-origin': '*',
      'access-control-expose-headers' : '*',
      'context-merchant': noContext ? '' : (getMerchant()?.id == '0' ? '' : getMerchant()?.id),
      ...header
    },
  }),
  {
    retry: false,
    ...params
  }
  );
};

export default useAxios;