import { Box, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { GrTextAlignLeft } from 'react-icons/gr';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { getPermissions } from '../../helpers/helpFunctions';
import { dataDescription } from '../SedeCreateEdit';

const DescriptionCard = () => {

  const record = useRecordContext();
  const permis = getPermissions();

  const dataArray = [
    {key:'Descripción', value: record.description?.slice(3,-4) ?? ''},
    {key:'Descripción corta', value: record['descripcion-corta'] ?? ''},
  ];

  const editComponent = {
    component: dataDescription,
    label: 'Descripción',
    icon: <GrTextAlignLeft/>
  };

  return (
    <CardBasic title='Descripción' record={record} editComponent={permis !== 'operador' && editComponent} route={'sedes'}>
      <TableMinimal data={dataArray} widthBoxKey='12rem' noBorderBottom textAlign='left'/>
    </CardBasic>
  );
};

export default DescriptionCard;