import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Show,  useUpdate } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import { bannerStyle } from '../../Theme/useStyle';
import BrandsHeader from './SectionsShow/BrandsHeader';
import EditIcon from '@mui/icons-material/Edit';
import VersionCard from '../../Components/UI/Show/VersionCard';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import { createNotification, handleClickCopy } from '../helpers/helpFunctions';
import { merchantContext } from '../../DataProvider';
import ImagesCard from './SectionsShow/ImagesCard';

const BrandsShow = (props) => {
  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Campaña: ' source='name'/>} 
      aside={<AsideShow />} 
      actions={null}
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <BrandsHeader/>
        <ImagesCard/>
        <VersionCard/>
      </ShowTransparent>
    </Show>
  );
};

const AsideShow = ({record, basePath, resource, ...rest}) => {
  const [update, {loaded, loading, error}] = useUpdate();
  const { getMerchant} = useContext(merchantContext);

  const handleArchived = () => {
    const diff = {archivado: !record?.archivado};
    update('brands', record?.id, diff, record);
  };

  useEffect(()=> {
    if(loaded){
      record.archivado ? 
        createNotification('success', 'Brand Archivada con éxito') : 
        createNotification('success', 'Brand Desarchivada con éxito'); 
    }else if(error)(
      record.archivado ? 
        createNotification('error', 'Error al Desarchivar la Brand', ()=> handleClickCopy(record)) :
        createNotification('error', 'Error al Archivarla Brand', ()=> handleClickCopy(record)) 
    );
  }, [loaded, error, record]);

  const data = record ? 
    [
      { link: basePath + '/' + record?.id, text: 'Editar Brand', icon: <EditIcon/> },
      { divider: true },
      // {
      //   icon: loading ? <CircularProgress style={{width: '18px', height: '18px'}}/> :
      //     record?.archivado ? 
      //       <UnarchiveOutlinedIcon style={{fontSize: '16px', marginRight: '2px'}}/> :
      //       <ArchiveOutlinedIcon  style={{fontSize: '16px', marginRight: '2px'}}/>,
      //   disabled: loading,
      //   onClick: handleArchived,
      //   text: record?.archivado ? 'Desarchivar Brand' : 'Archivar Brand'
      // },
      // { divider: true },
      // {
      //   rest: rest,
      //   label: 'Eliminar Brand',
      //   confirmTitle: `¿Eliminar ${record?.name}?`,
      //   confirmContent: '¿Seguro quieres eliminar esta brand?',
      //   record: record,
      //   undoable:true,
      //   basePath: basePath,
      // },
    ] : 
    [];

  return (
    <AsideActionsShow data={data}/>
  );
};

export default BrandsShow;