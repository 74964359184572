export const SOURCES = {
  checkout3: 'Checkout',
  checkout: 'Checkout',
  recurring_charges: 'Cobro Recurrente',
  cobro_por_uso: 'Cobro por uso',
  checkout_miclub: 'Efectivo',
  debi_approval: 'Debi',
  recurring_migration: 'Tarjeta',
  reccurring_manual: 'Recurrente manual',
  recurring_miclub: 'BuenClub Recurrente'
};

export const STATUS = {
  'approved': 'aprobado',
  'cancelled': 'cancelado',
  'charged_back': 'contracargo',
  'error': 'error',
  'expired': 'expirada',
  'in_process': 'en proceso',
  'pending_efectivo': 'pendiente de pago en efectivo',
  'refunded': 'reembolsado',
  'rejected': 'rechazado',
  'restored': 'restaurada',
  'abandoned': 'abandono'
};

export const PROCESSOR = {
  'mercadopago': 'MercadoPago',
  'cash': 'Efectivo',
  'decidir': 'Decidir'
};

export const PAYMENT_TYPE = {
  'cash': 'Efectivo',
  'credit_card': 'Tarjeta de Crédito',
  'debit_card': 'Tarjeta de Débito'
};

const motivos = {
  'accredited': '¡Listo! Se acreditó tu pago. En tu resumen verás el cargo de .',
  'pending_contingency': 'Estamos procesando tu pago. No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó.',
  'pending_review_manual': 'Estamos procesando tu pago. No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó.',
  'cc_rejected_bad_filled_card_number': 'Revisá el número de tarjeta.',
  'cc_rejected_bad_filled_date': 'Revisá la fecha de vencimiento.',
  'cc_rejected_bad_filled_other': 'Revisá los datos',
  'cc_rejected_bad_filled_security_code': 'Revisa el código de seguridad de la tarjeta.',
  'cc_rejected_blacklist': 'No pudimos procesar tu pago.',
  'cc_rejected_call_for_authorize': 'Debes autorizar ante  el pago de .',
  'cc_rejected_card_disabled': 'Llama a  para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.',
  'cc_rejected_card_error': 'No pudimos procesar tu pago.',
  'cc_rejected_duplicated_payment': 'Ya hiciste un pago por ese valor.Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
  'cc_rejected_high_risk': 'Tu pago fue rechazado. Vuelva a intentar con otra tarjeta.',
  'cc_rejected_insufficient_amount': 'Tu  no tiene fondos suficientes.',
  'cc_rejected_invalid_installments': ' no procesa pagos en  cuotas.',
  'cc_rejected_max_attempts': 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
  'cc_rejected_other_reason': ' no procesó el pago.'
};