import styled, { keyframes } from 'styled-components';
import { theme } from '../../../Theme/theme';

export const BoxTab = styled.div`
    width: 100%;
    background-color: #FFF;
    padding: 50px;
    border-radius: 9px;
    ${(props) => ({...props})}
`;

export const TabForm = styled.div`
    display: ${props => (props.active ? null : 'none')}

`;
