import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { minValue, maxValue, useDataProvider, useRefresh, required, onSubmit } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import ModalCustom from './ModalCustom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { fabClasses } from '@mui/material';

const ModalCreateCodigos = ({record, openCodigos, setOpenCodigos}) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [couponData, setCouponData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [validations, setValidations] = useState(false);
  const ref = useRef(false);

  const handlerCreateCupons = () => {
    validateData();
    if(validations){
      setLoading(true);
      dataProvider.create('descuentos', {data: {...couponData, cupo: 1, corporativo_id: record.id, cantidad_de_aplicaciones: couponData.duracion, date_created: new Date().toISOString().slice(0,10)}
      }).then(() => {
        createNotification('success', 'Cupones agregados con éxito');
        refresh();
        setLoading(false);
        setOpenCodigos(false);
      }).catch(()=> {
        createNotification('error', 'No se pudo crear el cupón', ()=> handleClickCopy({couponData, ...record.id}));
        setLoading(false);
      });
    }
    setLoading(false);
  };

  const validateData = () => {
    if(!couponData) {
      setError((f) => ({...f, general: 'Faltan llenar campos obligatorios'}));
    } else {
      setError((f) => ({...f, general: null}));
    }
    if(couponData?.concepto?.length >= 25 || couponData?.concepto == null || couponData?.concepto == '') {
      setError((f) => ({...f, concepto: 'Falta campo de concepto'}));
    
    } else {
      setError((f) => ({...f, concepto: null}));

    }
    if (!couponData?.porcentaje && !couponData?.monto_absoluto) {
      setError((f) => ({...f, total: 'Falta agregar un porcentaje o un monto'}));
    
    } else {
      setError((f) => ({...f, total: null}));

    }
    if ((couponData?.porcentaje && !couponData.monto_absoluto) && ((couponData?.porcentaje > 100) || (couponData.porcentaje < 0))) {
      setError((f) => ({...f, porcentaje: 'Falta el campo de porcentaje'}));
    
    } else {
      setError((f) => ({...f, porcentaje: null}));

    }
    if ((couponData?.monto_absoluto && !couponData.porcentaje) && (couponData?.monto_absoluto < 0 || couponData?.monto_absoluto.length > 9)) {
      setError((f) => ({...f, monto_absoluto: 'El monto no puede ser superior a 10000'}));
    
    } else {
      setError((f) => ({...f, monto_absoluto: null}));

    }
    if (!couponData?.duracion) {
   
      setError((f) => ({...f, duracion: 'Falta agregar una duración'}));
    } else if (couponData?.duracion && ((couponData?.duracion < 0) || (couponData?.duracion.length > 9))) {
 
      setError((f) => ({...f, duracion: 'La duracin no puede ser mayor a 9'}));
    } else {
      setError((f) => ({...f, duracion: null}));
    }
  
    if (!couponData?.fecha_vigencia)  {
      setError((f) => ({...f, fecha_vigencia: 'Falta el campo de fecha'}));
    } else if (couponData?.fecha_vigencia && (new Date(couponData?.fecha_vigencia) < new Date())) {
      setError((f) => ({...f, fecha_vigencia: 'La fecha no puede ser menor al dia de hoy'}));
    } else {
      setError((f) => ({...f, fecha_vigencia: null}));
    }
  
    if ((!couponData?.cantidad)) {
      setError((f) => ({...f, cantidad: 'Falta el campo de cantidad'}));
  
    } else if((couponData?.cantidad < 0) || (couponData?.cantidad > 1000)) {
      setError((f) => ({...f, cantidad: 'El campo de cantidad no puede ser menor que 1, ni mayor a 1000'}));

    } else {
      setError((f) => ({...f, cantidad: null}));
    }
  };

  useEffect(() => {
    Object.values(error).filter(e => {
      if(e !== null) {
        setValidations(false);
        return;
      } 
      setValidations(true);
    });
  }, [error]);

  const handleClose = () => {
    setCouponData(null);
    setOpenCodigos(false);
  };

  useEffect(() => {
    for(let i = 0; i < Object.values(error).length; i++) {
      if (Object.values(error)[i] !== null) {
        createNotification('warning', Object.values(error)[i]);
        break;
      }
      continue;
    }
  }, [error]);

  return (
    <Box>
      <ModalCustom
        open={openCodigos}
        onClose={handleClose}
        title={'Codigos'}
        onClickAction={handlerCreateCupons}
        startIcon={loading && <CircularProgress size='16px'/>}
        disabledAction={loading}
        showButtons
      >
          
        <ModalGrid error={error} setCouponData={setCouponData} couponData={couponData}/>
      </ModalCustom>
    </Box>
  );
};

const ModalGrid = ({setCouponData, couponData, isEdit, validations, error}) => {
  const [viewPorcentaje, setViewPorcentaje] = useState(true);

  return(
    <Box style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <TextField label='Concepto' variant='outlined' size='small'
        
        error={error.concepto}
        onChange={(e) => setCouponData({...couponData, concepto: e.target.value})}
        defaultValue={couponData?.concepto}
      />
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <TextField type='number' label='Aplicaciones' variant='outlined' size='small'
          onChange={(e) => setCouponData({...couponData, duracion: e.target.value})}
          defaultValue={couponData?.duracion}
          error={error.duracion}
          
          style={{width: '45%'}}
        />
        <TextField type='date' label='Vigencia'  size='small'
          variant='outlined' InputLabelProps={{ shrink: true }}
          onChange={(e) => setCouponData({...couponData, fecha_vigencia: e.target.value})}
          error={error.fecha_vigencia}
          
          defaultValue={couponData?.fecha_vigencia?.slice(0,10)}
          style={{width: '45%'}}
        />
        
      </Box>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        {
          viewPorcentaje ? 
            <TextField type='number' label='Porcentaje' variant='outlined' size='small'
              inputProps={{
                step: '0.1'
              }}
              error={error.porcentaje || error.total}
              
              onChange={(e) => setCouponData({...couponData, porcentaje: e.target.value})} 
              validate={[minValue(0), maxValue(100)]} 
              defaultValue={couponData?.porcentaje}
              style={{width: '45%'}}
            />: 
            <TextField type='number' label='Monto absoluto' variant='outlined' size='small'
              onChange={(e) => setCouponData({...couponData, monto_absoluto: e.target.value})}
              validate={[minValue(0)]}
              error={error.monto_absoluto || error.total}

              defaultValue={couponData?.monto_absoluto}
              style={{width: '45%'}}
            />
        }
        <TextField type='number' label='Cantidad de cupones' variant='outlined' size='small'
          onChange={(e) => setCouponData({...couponData, cantidad: Number(e.target.value)})}
          validate={[minValue(0)]}
          error={error.cantidad}
          
          defaultValue={couponData?.cantidad}
          style={{width: '45%'}}
        />
      </Box>
     
      <FormControlLabel
        control={
          <Switch 
            checked={viewPorcentaje} 
            label='Ingresar Monto' 
            onChange={() => setViewPorcentaje(!viewPorcentaje)} 
          />
        }
        label="Monto / Porcentaje"
      />
    </Box>
  );
};

export default ModalCreateCodigos;