import { apiUrl } from '../DataProvider';

async function emailAuth({email}) {
  const request = new Request(`${apiUrl}/login`,{
    method: 'POST',
    body: JSON.stringify({email}),
    headers: new Headers({'Content-Type': 'application/json'})
  });
  const res = await fetch(request);
  const data = await res.json();
  return {data, res};
}

export { emailAuth };