import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useListContext, usePermissions, useRedirect, useResourceContext } from 'react-admin';
import { merchantCadena, merchantCentral, merchantContext } from '../../DataProvider';
import { Box, Typography, Button } from '@mui/material';
import { CgGym } from 'react-icons/cg';
import { theme } from '../../Theme/theme';
import { MdHomeWork, MdOutlineFilterCenterFocus } from 'react-icons/md';
import { grey } from '@mui/material/colors';
import { GiReceiveMoney } from 'react-icons/gi';

export const PlanesNavBar = ({selected, setSelected}) => {
  // const [selected, setSelected] = useState('locales');
  const redirect = useRedirect();
  const resource = useResourceContext();
  const permissions = usePermissions().permissions;
  const {getMerchant} = useContext(merchantContext);
  const { setFilters } = useListContext();
  
  const navBarStyle = {
    'background-color': '#fff',
    'width': '100%', 
    'display': 'flex', 
    'height': '60px', 
    'padding-top': '20px',
    'flex-direction': 'row',
    'align-items': 'flex-end'
  };
  
  const tabStyle = {
    'width': '100%',
    'max-width': '200px',
    'height': '100%',
    'border': `1px solid ${grey[200]}`,
    'background-color': grey[200],
    'align-items': 'center',
    'justify-content': 'center',
    'text-transform': 'uppercase',
    'display': 'flex',
    'flex-direction': 'row',
    'margin-right': '3px',
    'max-height': '60px',
    'border-radius': '5px 5px 0 0',
    'cursor':'pointer'
  };
  
  const typoStyle = {
    'font-size': '.9em',
    'margin-left': '10px',
  };


  // Lógica de perfiles

  const perfilSuperOwner = (permissions === 'superadmin' || permissions === 'owner') && getMerchant()?.id === '';
  const perfilCentral = merchantCentral?.find(item => item?.id === getMerchant()?.id) && (getMerchant()?.id !== merchantCadena);
  const perfilSede = !merchantCentral?.find(item => item?.id === getMerchant()?.id) && (getMerchant()?.id !== merchantCadena);
  const perfilCadena = getMerchant()?.id === merchantCadena;
    
  const PlanesHabilitados = ({_handleRedirect}) => {

    return (
      <Button
        className="habilitados" 
        startIcon={<GiReceiveMoney color={(selected === 'habilitados') ? theme.palette.primary.main : '#fff'} />} 
        style={{
          ...tabStyle, 
          borderTop: (selected === 'habilitados') ? `2px solid ${theme.palette.primary.main}` : '',
          backgroundColor: selected === 'habilitados' ? theme.palette.grey[200] : grey[400]
        }} onClick={() => _handleRedirect('habilitados')}>
        <Typography style={{...typoStyle, color: selected === 'habilitados' ? 'black' : '#fff'}}>Planes Habilitados</Typography>
      </Button>
    );
      
  };
  
  const handleRedirect = (params) => {
    setSelected(params);
    // setTimeout(() => redirect(`/${params === 'locales' ? 'planes' : params}`), 100);
  };

  return(
      
    perfilSuperOwner ? (

      <Box style={navBarStyle}>
        <Button
          className="locales"
          startIcon={<CgGym color={(selected === 'locales') ? theme.palette.primary.main : '#fff'} />} 
          style={{
            ...tabStyle,
            borderTop: (selected === 'locales') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'locales' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('locales')}
        >
              
          <Typography style={{...typoStyle, color: selected === 'locales' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Locales</Typography>
        </Button>
        <Button
          startIcon={<MdOutlineFilterCenterFocus color={(selected === 'centrales') ? theme.palette.primary.main : '#fff'} />} 
          style={{
            ...tabStyle,
            borderTop: (selected === 'centrales') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'centrales' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('centrales')}>
          <Typography style={{...typoStyle, color: selected === 'centrales' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Centrales</Typography>
        </Button>
        <Button
          className="cadena" 
          startIcon={<MdHomeWork color={(selected === 'multinegocio') ? theme.palette.primary.main : '#fff'} />} 
          style={{
            ...tabStyle, 
            borderTop: (selected === 'multinegocio') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'multinegocio' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('multinegocio')}>
          <Typography style={{...typoStyle, color: selected === 'multinegocio' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Cadena</Typography>
        </Button>
        {resource === 'planes' && <PlanesHabilitados _handleRedirect={handleRedirect} />}
      </Box>

    ) : perfilSede ? (

      <Box style={navBarStyle}>
        <Button
          className="locales" 
          startIcon={<CgGym color={(selected === 'locales') ? theme.palette.primary.main : '#fff'} />} 
          style={{...tabStyle,
            borderTop: (selected === 'locales') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'locales' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('locales')}>
          <Typography style={{...typoStyle, color: selected === 'locales' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Locales</Typography>
        </Button>
        <Button
          className="cadena" 
          startIcon={<MdHomeWork color={(selected === 'multinegocio') ? theme.palette.primary.main : '#fff'} />} 
          style={{
            ...tabStyle, 
            borderTop: (selected === 'multinegocio') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'multinegocio' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('multinegocio')}>
          <Typography style={{...typoStyle, color: selected === 'multinegocio' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Cadena</Typography>
        </Button>
      </Box>

    ) : perfilCentral ? (

      <Box style={navBarStyle}>
        <Button
          className="centrales"  
          startIcon={<CgGym color={(selected === 'centrales') ? theme.palette.primary.main : '#fff'} />} 
          style={{...tabStyle,
            borderTop: (selected === 'centrales') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'centrales' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('centrales')}>
          <Typography style={{...typoStyle, color: selected === 'centrales' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Centrales</Typography>
        </Button>
      </Box>

    ) : perfilCadena ? (

      <Box style={navBarStyle}>
        <Button
          className="cadena"  
          startIcon={<MdHomeWork color={(selected === 'multinegocio') ? theme.palette.primary.main : '#fff'} />} 
          style={{
            ...tabStyle, 
            borderTop: (selected === 'multinegocio') ? `2px solid ${theme.palette.primary.main}` : '',
            backgroundColor: selected === 'multinegocio' ? theme.palette.grey[200] : grey[400]
          }} onClick={() => handleRedirect('multinegocio')}>
          <Typography style={{...typoStyle, color: selected === 'multinegocio' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Cadena</Typography>
        </Button>
        {resource === 'corporativo' && (
          <Button 
            startIcon={<CgGym color={(selected === 'centrales') ? theme.palette.primary.main : '#fff'} />} 
            style={{...tabStyle,
              borderTop: (selected === 'centrales') ? `2px solid ${theme.palette.primary.main}` : '',
              backgroundColor: selected === 'centrales' ? theme.palette.grey[200] : grey[400]
            }} onClick={() => handleRedirect('centrales')}>
            <Typography style={{...typoStyle, color: selected === 'centrales' ? 'black' : '#fff'}}>{resource === 'corporativo' ? 'campañas' : resource} Centrales</Typography>
          </Button>
        )}
      </Box>
    ) : null
      
  );
};