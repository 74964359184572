import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Skeleton, Typography } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ListTickets from '../../../Components/UI/ListTickets';
import { dateFormat } from '../../helpers/helpFunctions';
import { useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import ModalPayment from '../ModalPayment';
import { emptyBoxStyle, styleSpanPlan } from '../../../Theme/useStyle';
import { useQuery } from 'react-query';


const TicketsCard = ({plan, loading: loadingPlan}) => {
  const record = useRecordContext();
  const [tickets, setTickets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [acceptCash, setAcceptCash] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleTickets = (d) => {
    setTickets(d?.data);
    setTotal(d.total);
    setLoading(false);
  };

  useEffect(() => {
    !loadingPlan && record && setAcceptCash(plan?.payment_methods?.efectivo);
  }, [plan, loadingPlan]);

  let objectDefault = {
    pagination: {page: page, perPage: 5},
    sort: {field: 'original_payment_date', order: 'DESC'},
    filter: {}
  };

  const { isLoading, refetch, isRefetching, isSuccess } = useQuery(
    ['boletas', page], 
    () => dataProvider.getList(`socios/${record.id}/boletas`, objectDefault),
    {
      onSuccess: (data) => {
        return handleTickets(data);
      }
    }
  );

  if(!record){
    return(
      <CardBasic 
        title='Boletas'
        asideAction={
          !loading && (
            <ButtonPayTicket setOpenModal={setOpenModal}/>
          )
        }
      >
        <Box style={{marginLeft: '30px'}}>
          <Skeleton style={{width: '90%'}}/>
          <Skeleton style={{width: '90%'}}/>
          <Skeleton style={{width: '90%'}}/>
        </Box>
      </CardBasic>
    );
  }
  
  return (
    <CardBasic 
      title='Boletas'
      asideAction={
        !loading && (
          <ButtonPayTicket setOpenModal={setOpenModal}/>
        )
      }
    >
      {
        record?.next_payment_date && (
          <Typography 
            variant='subtitle2'
          >
            <span style={styleSpanPlan}>Proxima Fecha de Pago: </span>{dateFormat(record?.next_payment_date)}
          </Typography>
        )
      }
      {
        loading ? (
          <Box style={{marginLeft: '30px'}}>
            <Skeleton style={{width: '90%'}}/>
            <Skeleton style={{width: '90%'}}/>
            <Skeleton style={{width: '90%'}}/>
          </Box>
        ) : (
          <>
            {
              tickets && tickets.length > 0 ? (
                <ListTickets
                  isRefetching={isRefetching}
                  loading={loading} 
                  refresh={refresh} 
                  refetch={refetch} 
                  setLoading={setLoading} 
                  tickets={tickets} 
                  page={page} 
                  setPage={setPage} 
                  total={total} 
                  acceptCash={acceptCash}
                  plan={plan}
                />
              ) : (
                <Box style={emptyBoxStyle}>
                  <Typography variant='subtitle2'>No se encuentran boletas para este socio</Typography>
                </Box>
              )
            }
          </>
        )
      }
      <ModalPayment open={openModal} setOpen={setOpenModal} record={record} tickets={tickets} acceptCash={acceptCash}/>
    </CardBasic>
  );
};



const ButtonPayTicket = ({setOpenModal}) => (
  <Button 
    variant='text' onClick={() => setOpenModal(true)}
    size='small' style={{fontWeight: '600', fontSize: '12px'}}
    startIcon={<PaymentsOutlinedIcon style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
  >
    Pagar Anticipado
  </Button>
);

export default TicketsCard;