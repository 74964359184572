import decodeJwt from 'jwt-decode';
import {snippetBeacon} from '../Components/Snippets/Snippets';
import { useResourceContext } from 'react-admin';

export default {
  // called when the user attempts to log in
  login: async (token_url) =>  {
    const res = await fetch(token_url);
    if(res.ok){
      const data = await res.json();
      localStorage.setItem('auth', JSON.stringify(data));
      console.log('data',data);
      const decodedToken = decodeJwt(data['token']);
      localStorage.setItem('permissions', decodedToken['role']);
      return Promise.resolve(decodedToken);
    }else{
      return Promise.reject();
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();

  },
};