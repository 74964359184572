import { Alert, Autocomplete, Box, Button, Chip, IconButton, Snackbar, Typography, TextField as TextFieldMui } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import {List, Datagrid, TextInput, TextField, NumberField, 
  ReferenceField, AutocompleteInput, ReferenceInput, EditButton, 
  BooleanInput, ChipField, useRedirect, useDataProvider, downloadCSV, TopToolbar, FilterButton, CreateButton, usePermissions, useResourceContext, useListContext, useGetList, SavedQueriesList, useList, useListController
  //ImageField
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import DownloadIcon from '@mui/icons-material/Download';
import OpenLink from '../../Components/CustomFields/OpenLink';
import { snippetBeacon } from '../../Components/Snippets/Snippets';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { MdHomeWork } from 'react-icons/md';
import { MdOutlineFilterCenterFocus } from 'react-icons/md';
import { CgGym } from 'react-icons/cg';
import { PlanesNavBar } from './PlanesNavBar';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { getPermissions, getToken, roles } from '../helpers/helpFunctions';
import rowStylePlans from './rowStylePlans';
import CloseIcon from '@mui/icons-material/Close';
import { brandSportClub, merchantCadena, merchantCentral, merchantContext, merchantSelected } from '../../DataProvider';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import usePagination from '@mui/material/usePagination/usePagination';
import { theme } from '../../Theme/theme';
import { grey } from '@mui/material/colors';
import { brandsCustom } from '../Brands/BrandsCreateEdit';
import { PlanesHabilitados } from './Habilitados/PlanesHabilitados';
import { useQuery } from 'react-query';




export const PlanList = props => {

  const [merchs, setMerchs] = useState([]);
  const [tab, setTab] = useState('locales');
  const { setFilters } = useListController();
  const permis = usePermissions().permissions;
  const { getMerchant } = useContext(merchantContext);
  const resource = useResourceContext();
  const noOperadorPlanes = (permis !== 'operador');
  const editButtonMulti = (resource == 'multinegocio' && permis !== 'admin' && noOperadorPlanes);
  const editButtonLocales = (resource == 'planes' && noOperadorPlanes);
  const noEditButton = (editButtonMulti || editButtonLocales);
  const canCreate = (noEditButton || noOperadorPlanes) ? true : false;
  const canView = roles.slice(0,2).includes(permis);
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [selectedMerch, setSelectedMerch] = useState([]);
  const [open, setOpen] = useState((getToken()?.brand_id === '6328afb359d052e6817c2200' &&
    (getMerchant().id !== '' && window.location.hash.includes('#/planes')  && getMerchant()?.id !== merchantCadena) && 
      (!getMerchant()?.split)) ? true : false
  );

  const planFiltersDefault = [
    <TextInput
      source="q" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      // color={theme.palette.primary.main}
      tab={tab}
      minLetters={3}
      style={{width: '100%'}}
    />,
    <BooleanInput 
      source='archivado' 
      alwaysOn 
      label={'Planes Archivados'} 
      defaultValue={false}
      size='small'
    />
  
  ];


  const baseUrl = `${process.env.REACT_APP_CHECKOUT_FRONTEND_URL}/paso2/`

  const handleClickCreate = () => {
    redirect(`/${resource}/create`);
  };

  const defaultFilters = tab === 'centrales' ?
    {['tipo-de-plan']: 'central', archivado: false} :
    tab === 'multinegocio' ?
      {['tipo-de-plan']: 'cadena', archivado: false} :
      tab === 'locales' ? 
        {['tipo-de-plan']: 'local', archivado: false} : 
        {archivado: false};
  

  useEffect(() => {
    if(getToken()?.brand_id === '6328afb359d052e6817c2200'){
      if(getMerchant().id !== '' && window.location.hash.includes('#/planes') && getMerchant()?.id !== merchantCadena){
        if(!getMerchant()?.split){
          setOpen(true);
        }
      }
    }
  },[getMerchant()?.id]);
  
  const planFiltersCorp = [
    // <SavedQueriesList />,
    <TextInputIcon
      source="q" 
      label=""
      placeholder='Buscar'
      alwaysOn 
      variant='outlined' 
      resettable 
      icon='search'
      color='default'
      minLetters={3}
      tab={tab}
      style={{width: '100%'}}
    />,
    <AutocompleteInput
      variant='outlined'
      label='Negocios'
      source='merchant_id'
      size='small'
      resettable
      choices={merchs}
      matchSuggestion={(filter, choices) => {
        const normalize = choices.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (normalize.toLowerCase().includes(filter.toLowerCase()));
      }}
    />,
    <BooleanInput 
      source='archivado' 
      alwaysOn 
      label={'Planes Archivados'} 
      defaultValue={false}
    />
  ];

  const { refetch } = useQuery(['merchants'], () => dataProvider.getList('merchants', params), {
    onSuccess: ({data}) => {
      setMerchs(data);
    }
  });
  
  const params = {
    sort: {field: 'name', order: 'ASC'},
    pagination: {page: 1, perPage: 999},
    filter: {}
  };

  useEffect(() => {
    refetch();
    if(getMerchant()?.id === merchantCadena) return setTab('multinegocio');
    if(merchantCentral.find(merch => merch?.id === getMerchant()?.id)) return setTab('centrales');
    if(!merchantCentral.find(merch => merch?.id === getMerchant()?.id)) return setTab('locales');
  }, [getMerchant()?.id]);


  useEffect(() => {
    tab === 'centrales' ?
      setFilters({['tipo-de-plan']: 'central', archivado: false}) :
      tab === 'multinegocio' ?
        setFilters({['tipo-de-plan']: 'cadena', archivado: false}) :
        tab === 'locales' ? 
          setFilters({['tipo-de-plan']: 'local', archivado: false}) : 
          // Logica para filtrar por planes cadena habilitados para vender por otras sedes.
          tab === 'habilitados' ? 
            setFilters({['tipo-de-plan']: 'cadena', archivado: false}) :
            setFilters({archivado: false});
  },[tab]);

  return(
    <>
      {
        !open ? (
          <>
            {((getToken()?.brand_id === '6328afb359d052e6817c2200') || (permis === 'superadmin')) ? (
              <PlanesNavBar setFilters={setFilters} selected={tab} setSelected={setTab}/>
            ) : null }
            {
              tab && (
                <List 
                  {...props}  
                  bulkActionButtons={false} 
                  disableSyncWithLocation
                  filters={(getMerchant()?.id === '') ? planFiltersCorp : planFiltersDefault}
                  perPage={25} 
                  hasCreate={canCreate}
                  filter={ tab === 'centrales' ?
                    {['tipo-de-plan']: 'central'} :
                    ['multinegocio', 'habilitados'].includes(tab) ?
                      {['tipo-de-plan']: 'cadena'} :
                      {['tipo-de-plan']: 'local'} }
                  filterDefaultValues={defaultFilters}
                  exporter={false}
                  actions={
                    <ListActions 
                      tab={tab}
                      tabHabilitados={tab==='habilitados'}
                      setSelectedMerch={setSelectedMerch}
                      isVerTodos={getMerchant()?.id === '' && tab === 'habilitados'}
                      merchs={merchs} 
                      isOwner={getMerchant()?.id === ''} 
                      selectedMerch={selectedMerch}
                    />}
                  empty={false}
                 
                >
                  {
                    tab === 'habilitados' ? (
                      <PlanesHabilitados
                        defaultFilter={defaultFilters} 
                        selectedRow={props.selectedRow}
                        rowClick='show'
                        selectedMerch={selectedMerch}
                      />
                    ) : (
                      <DatagridCustom
                        defaultFilter={defaultFilters} 
                        rowStyle={rowStylePlans(props.selectedRow)}
                        rowClick='show'
                      >
                        
                        <TextField source="name" label='Nombre'/>
                        <TextField source="nivel_de_acceso" />
                        <TextField source="cobro" />
                        <NumberField source="price" label='Precio'/>
                        <NumberField source="socios" label="Socios Activos"/>
                        <OpenLink 
                          source="slug" 
                          label='' 
                          prepend={[
                            {
                              label: 'Checkout',
                              link: baseUrl,
                              viewContext: tab === 'multinegocio'
                            },
                            {
                              label: 'Ver Socios',
                              redirect: true,
                              filter: 'active_plan_id'
                            }
                          ]} 
                        />
                      </DatagridCustom>
                    )
                  }
                </List>
              )
            }
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={open}
              action={
                <>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <Alert 
                onClose={() => setOpen(false)} 
                severity="warning" 
                sx={{ width: '70%', gap: '5px', display: 'flex', alignItems: 'center' }} 
                variant='filled' 
                color='secondary'
              >
          Tu negocio no está configurado para la venta de planes. Es necesario cargar las credenciales del procesador de pago para comenzar a vender.
              </Alert>
            </Snackbar>
          </>

        ) : (
          <>
            <Snackbar
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              open={open}
              action={
                <>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <Alert 
                // onClose={() => setOpen(false)} 
                severity="warning" 
                sx={{ width: '70%', gap: '5px', display: 'flex', alignItems: 'center' }} 
                variant='filled' 
                color='secondary'
              >
  Tu negocio no está configurado para la venta de planes. Es necesario cargar las credenciales del procesador de pago para comenzar a vender.
              </Alert>
            </Snackbar>
          </>
        )
      }
    </>
  );
};

export const ListActions = ({ tabHabilitados, isOwner, merchs, isVerTodos, selectedMerch, setSelectedMerch, tab }) => {
  const permis = getPermissions();
  const resource = useResourceContext();
  const hasPermis = (permis === 'admin' || permis === 'operador') && resource === 'multinegocio';
  const ux = tab === 'habilitados';
  const HabilitadosMerch = ({merchs}) => {

    const renderTags = (tagValue, getTagProps, rest) =>{
      return tagValue?.map((item, index) => {
        if(index <= 1) {
          return <Chip {...getTagProps} label={item?.name} sx={{...getTagProps.sx, maxWidth: '150px'}} />;
        } else if (index <= 2) {
          return <Typography sx={{color: theme.palette.primary.main, fontWeight: '600'}} variant="h7">+{tagValue.length - 2}</Typography>;
        } else {
          return;
        }
      });
    };
    
    return (
      <Autocomplete
        sx={{
          width: '300px'
        }}
        label="Negocios"
        value={selectedMerch}
        // filterOptions={(options) => options.filter(merch => merch.id !== merchantCadena)}
        multiple
        getOptionLabel={(option) => option?.name}
        onChange={(e,v) => setSelectedMerch(v)}
        options={merchs?.filter(merch => merch.id !== merchantCadena) ?? []}
        renderInput={(params) => <TextFieldMui label="Negocios" {...params} variant="outlined" />}
        renderTags={renderTags}
      />
    );
  };
  
  return (
    <TopToolbar style={{width: '100%', minWidth:'324px', justifyContent: ux ? 'flex-start' : 'flex-end'}}>
      <Box style={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'flex-start'}}>
        {(merchs?.length > 0 && isVerTodos) && <HabilitadosMerch merchs={merchs} isVerTodos={isVerTodos} />}
        {!hasPermis && !tabHabilitados && permis !== 'operador' &&  <CreateButton />}
        {!tabHabilitados && <CustomFilter />}
      </Box>
    </TopToolbar>
  );
};

export const CustomFilter = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button startIcon={<DownloadIcon />} style={{textTransform: 'uppercase', padding: '10px'}} onClick={() => setOpen(true)}>
  exportar
      </Button>
      {open && (
        <CustomReportFilters style={{width: '200px'}} text={'Planes'} variant={'outlined'} open={open} setOpen={setOpen} notification viewMerchants viewPlanes viewPlanAccess title={'Reporte de Planes'} downloadText={'planes'} url={'planes_pro'} />

      )}
    </>
  );
};
