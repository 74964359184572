import { useContext, useRef, useState } from 'react';
import { useRefresh } from 'react-admin';
import { apiUrl, merchantContext } from '../../../DataProvider';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import axios from 'axios';
import { createNotification } from '../../helpers/helpFunctions';
import { ActionsDiv, MenuActionsDiv } from '../../../Components/UI/Options/ActionsOptions';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import ModalCustom from '../../../Components/UI/ModalCustom';
import { styleInnerBox } from '../../../Components/UI/ListTickets';
import { ModalComp } from './ModalComp';


export const RowActions = ({refetchCobros, item, key, ...rest}) => {
  
  const refresh = useRefresh();
  const ref = useRef();
  const { getMerchant } = useContext(merchantContext);
  const [loadingAction, setLoadingAction] = useState({verFactura: false, cobrarFactura: false, changeStatus: false, marcarFactura: false});
  const [openMenu, setOpenMenu] = useState(false);
  const [openMarcar, setOpenMarcar] = useState(false);
  const [openVouchers, setOpenVouchers] = useState(false);
  useOnClickOutside(ref, () => setOpenMenu(false));

  const marcarFacturada = async () => {
    if(item?.status === 'approved') {
      setLoadingAction({...loadingAction, marcarFactura: true});
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      const checkUrl = `${apiUrl}/marcar_facturada/${item.boleta_id}`;
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Access-Tokens': tokenSend,
          'access-control-allow-origin': '*',
          'context-merchant': getMerchant()?.id
        }
      };
      const handleRequest = (url) => {
        return axios({
          url: url,
          method: 'PUT',
          ...axiosConfig
        });
      };

      const promiseHandler = (res) => {
        setLoadingAction({...loadingAction, marcarFactura: false});

        return createNotification('success', 'Boleta maracada como facturada.');
      };
      const catchHandler = e => {
        setLoadingAction({...loadingAction, marcarFactura: false});
        createNotification('error', 'Hubo un error, por favor intentar mas tarde.');
      };
      await handleRequest(checkUrl).then(promiseHandler).catch(catchHandler);
      refresh();

    }
  };

  const changeStatusBoleta =  async () => {
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/editar_boleta/${item.boleta_id}`;
    return axios({
      url: checkUrl,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'context-merchant': getMerchant()?.id
      },
      data: {status: 'cancelled'}
    });
  };
  
  const cobrarFactura = async () => {
    setLoadingAction({...loadingAction, cobrarFactura: true});
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/facturar/${item.boleta_id}`;
    
    axios({
      url: checkUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'context-merchant': getMerchant()?.id
      }
    }).then((data) => {
      setLoadingAction({...loadingAction, cobrarFactura: false});
  
      setOpenMenu(false);
      createNotification('success', `${data?.data?.msg}`);
      refetchCobros();
    }).catch((err) => {
      setLoadingAction({...loadingAction, cobrarFactura: false});
  
      createNotification('error', `${err?.response?.data?.msg}`);
    });
    refresh();
    // setLoading(false)
  };
  const changeStatus = async () => {
  
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/cobros_adicionales/${item.id}`;
    Promise.all([axios({
      url: checkUrl,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'context-merchant': getMerchant()?.id
      }
    }), changeStatusBoleta()]).then((data) => {
      setOpenMenu(false);
      createNotification('success', 'El pago fue cancelado.');
      refetchCobros();
  
  
    }).catch((err) => {
      createNotification('error', 'Hubo un error, por favor intentar mas tarde');
    });
  };
  console.log('ASda;ksjdaklsjdkj');
  
  return (
    <>
      {/* <TableCell key={key} style={{...styleTabbleData, fontSize:'.8em', padding: '10px', position: 'relative', zIndex: -1}}> */}
      <MenuActionsDiv position="relative" sx={{position: 'relative'}} ref={ref}>
        <MenuIcon onClick={(e) => {e.stopPropagation(); setOpenMenu(!openMenu);}}
          sx={{position: 'relative', borderRadius: '4px', cursor: 'pointer', '&:hover':{ backgroundColor: '#cfd8dc'}}}/>
        {openMenu && (
          <ActionsDiv id="actionsId">
            {item?.status !== 'refunded' && (
              !loadingAction.changeStatus ? <Button disabled={getMerchant()?.id === ''} onClick={changeStatus} style={{fontSize: '0.8em'}}>Cancelar</Button> : <Skeleton height="20px"  />
            )}
            {item?.status === 'approved' && item.boleta_id && (!item?.factura_id || item?.nota_credito_id) && !item.excluded && (
              !loadingAction.cobrarFactura ? <Button disabled={getMerchant()?.id === ''} onClick={cobrarFactura} style={{fontSize: '0.8em'}}>Facturar</Button> : <Skeleton height="20px"  />
            )}
            {item?.status === 'approved' && item.boleta_id && (!item?.factura_id || !item.nota_credito_id) && !item.excluded && (
              !loadingAction.marcarFactura ? <Button  disabled={getMerchant()?.id === ''} onClick={() => setOpenMarcar(true)} style={{fontSize: '0.8em'}}>{item?.excluded ? 'Desmarcar Facturada' : 'Marcar facturada'}</Button> : <Skeleton height="20px"  />
            )}
            {
              (item?.factura_id || item?.nota_credito_id) && (
                <Button style={{fontSize: '0.8em'}} onClick={() => setOpenVouchers(true)}>Ver Comprobantes</Button>
              )
            }
          </ActionsDiv>
        )}
      </MenuActionsDiv>
      <ModalCustom
        open={openMarcar}
        setOpen={setOpenMarcar}
        onClickAction={marcarFacturada}
        textAction={'Confirmar'}
        showButtons
        onClose={() => setOpenMarcar(false)}
        title={'Marcar Boleta como facturada'}
      >
        <Box style={styleInnerBox}>
          {
        
            <Box>
              <Typography variant='subtitle1'>
                  ¿Estás seguro? La boleta se marcara como facturada sin emitir una factura.                
              </Typography>
            </Box>
          
          }
        </Box>
      </ModalCustom>
      {openVouchers && <ModalComp item={item} open={openVouchers} setOpen={setOpenVouchers} />}
      {/* </TableCell> */}
    </>
  );
};