import React, { useContext } from 'react';
import { maxLength, required, useRecordContext } from 'react-admin';
import SectionForm from '../../../Components/Form/SectionForm';
import { getPermissions } from '../../helpers/helpFunctions';
import { merchantCentral, merchantContext } from '../../../DataProvider';



const descripcionEdit = () => {
  const record = useRecordContext();
  const permis = getPermissions();
  const {getMerchant} = useContext(merchantContext);
  const isCentral = merchantCentral.find(item => item.id === getMerchant()?.id) ? true : false;
  const disabledApplication = (permis === 'superadmin' || permis === 'owner') && !isCentral;
  const legacyEdit = record && record?.['tipo-de-plan'] !== 'cadena' && record?.nivel_de_acceso !== 'Local' && !disabledApplication;

  const data = [
    {
      source: 'titulo-plan', 
      label: 'Título', 
      disabled: legacyEdit,
      validate: { maxLength: 49,required: 'Falta el campo Título en Descripción'},
      xs: 12
    },
    {
      source: 'texto-condicion-plan',
      label: 'Condición de plan',
      disabled: legacyEdit,
      validate: { maxLength: 100},
      tooltip: 'Condiciones a cumplir para acceder a un plan',
      xs: 12
    },
    {
      source: 'descripcion',
      label: 'Descripción',
      disabled: legacyEdit,
      validate: { maxLength: 50, required: 'Falta el campo Descripción.'},
      xs: 12
    },
    {
      source: 'descripcion-plan',
      label: 'Descripción del Plan',
      disabled: legacyEdit,
      validate:{ maxLength: 239},
      resettable: true, 
      richText: true,
      xs: 12
    },
    {
      source: 'disclamer', 
      label: 'Disclaimer', 
      validate:{ maxLength: 239},
      disabled: legacyEdit,
      resettable: true,
      richText: true,
      xs: 12
    },
  ];

  return data;
};

export default descripcionEdit;