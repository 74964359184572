import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import { useTheme } from '@mui/material/styles';

export const shadowBorder = ' 3px 0px 0px 1px inset';

const rowStyle = (selectedRow) => (record) => {
  const theme = useTheme();
  let style = {

  };
  if (!record) {
    return style;
  }
  if (selectedRow && selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    };
  }
  if (record.status === 'activo')
    return {
      ...style,
      // borderLeftColor: green[400],
      // borderLeftWidth: 1,
      // borderLeftStyle: 'solid',
      // borderRadius: '12px',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: green[500] + shadowBorder
    };
  if (record.status === 'baja')
    return {
      ...style,
      // borderLeftColor: orange[500],
      // borderLeftWidth: 3,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: orange[500] + shadowBorder
    };
  if (record.status === 'inactivo' || record.status === 'pausado')
    return {
      ...style,
      // borderLeftColor: red[500],
      // borderLeftWidth: 3,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: red[500] + shadowBorder
    };
  if (record.status === 'externo')
    return {
      ...style,
      // borderLeftColor: '#000000',
      // borderLeftWidth: 3,
      // borderLeftStyle: 'solid',
      borderTopLeftRadius: '15px 10px',
      borderBottomLeftRadius: '15px 10px',
      boxShadow: '#000000' + shadowBorder
    };
  return style;
};

export default rowStyle;