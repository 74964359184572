import React from 'react';
import { Stack, Typography } from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { dateFormat } from '../../helpers/helpFunctions';
import { theme } from '../../../Theme/theme';

const fontAccessTitle = {
  fontSize: '13px',
  fontWeight: '600',
  lineHeight: '1.5',
  color: '#787878'
};
  
const ListAccess = ({access}) => {

  return (
    <Stack spacing={1} style={{minWidth: '75%'}}>
      {access?.map(item => (
        <Stack key={item.id} direction='row' spacing={1}>
          {item?.entered ? (<LabelOutlinedIcon style={{fontSize: '19px', color: '#0f9688'}} />) : <LabelOutlinedIcon style={{fontSize: '19px', color: theme.palette.error.main}} />}
          <Typography style={{...fontAccessTitle, fontWeight: '500'}}>Accedio a</Typography>
          <Typography style={fontAccessTitle}>{item?.sede_name}</Typography>
          <Typography style={{...fontAccessTitle, fontWeight: '500'}}>
            el dia <span style={{fontWeight: '600'}}>{dateFormat(item?.date?.slice(0,10), false)} </span> 
            a las <span style={{fontWeight: '600'}}>{item?.date?.slice(11,16)}</span>
          </Typography>
          <Typography style={{...fontAccessTitle, fontWeight: '500'}}>con acceso</Typography>
          <Typography style={{...fontAccessTitle}}>
            {item?.instances[0]?.source.includes('digital') ? 'Digital' : 'Manual'}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ListAccess;
