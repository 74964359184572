import { TextField } from 'react-admin';
import {Box, Typography} from '@mui/material';
import ModalCustom from '../../Components/UI/ModalCustom';

const ModalEvent = ({open, setOpen}) => {
  return (
    <>
      <ModalCustom 
        open={open}
        setOpen={setOpen}
        showButtons
        title={'Agregar Evento'}
      >
        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
        </Box>
      </ModalCustom>
    </>
  );
};

export default ModalEvent;