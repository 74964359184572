import React from 'react';
import { Create } from 'react-admin';
import { SedeCreateEdit } from './SedeCreateEdit';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';

export const SedeCreate = (props) => {
  return (
    <Create 
      {...props}
      title={<TitleEdit title='Crear Sede ' />} 
      onFailure={(e)=> console.log(e)}
    >
      <SedeCreateEdit permissions={props.permissions}/>
    </Create>
  );
};
