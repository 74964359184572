import { Box } from '@mui/material';
import CardDashboard from './Card/CardDashboard';
import { useEffect, useState } from 'react';
import CardContent from './Card/CardContent';

const AccesosCard = ({accesosMns, isSuccess, date, errorVentas, isFecthed, isFetching, accesosData, egresosSedes, isError}) => {
  const [accesosDatasets, setAccesosDatasets] = useState(null);
  const [totalEgresos, setTotalEgresos] = useState(null);
  
  const helpText = 'Accesos';
  
  useEffect(() => {
    const datasets = accesosMns?.dashboard_accesos_por_dia;
    const egresos = accesosMns?.dashborad_accesos_a_otras_sedes;
  
    setAccesosDatasets({chart: {accesos: datasets}});
    let total = 0;
    egresos?.map(sede => {
      total = total + sede[1];
    });
    setTotalEgresos(total);
    return() => {
      setAccesosDatasets(null);
      setTotalEgresos(null);
      total = 0;
    };
  },[ accesosMns]);

  return (
    <Box sx={{ width: '100%'}}>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          width: {xs: '100%'},
          height: 'auto',
          justifyContent: 'space-between'
        }}
      >
        <CardDashboard noPad>
          <CardContent 
            noCard
            isSuccess={isSuccess} 
            errorVentas={errorVentas}
            isFecthed={isFecthed} 
            isFetching={isFetching} 
            datasets={accesosDatasets} 
            accesosData={accesosData} 
            date={date}
            accessCard
            isError={isError}
            chartLine 
            name={'Accesos'} 
            accesos
            totalEgresos={accesosMns?.dashboard_accesos_a_otras_sedes}
            helpText={helpText}
            title={'Diferencia'}
            totalTitle={'Total Ingresos'}
          />
        </CardDashboard>
      </Box>
    </Box>
  );
};

export default AccesosCard;