import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { theme } from '../../../Theme/theme';
import { useState } from 'react';
import ModalReportes  from './ModalReportes';

const MenuTabs = ({switchTab, setSwitchTab, date}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box style={{ width: 'auto', display: 'flex', justifyContent: 'flex-start', textAlign: 'center', margin: '5px 0'}}>
        <Box sx={{ 
          margin: '0 0 0 5px',
          padding: '5px',
          backgroundColor: `${switchTab  === 'cobros' ? theme.palette.primary.main : '#fff'}`,
          borderRadius: '10px',
          alignItems: 'center',
          width: '10vw',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setSwitchTab('cobros')}
        >
          <Typography variant='subtitle2' color={switchTab === 'cobros' ? '#fff' : ''} sx={{textAlign: 'center', fontSize: '0.9em', textTransform: 'uppercase'}}>
            Cobros del mes
          </Typography>
        </Box>
       
        <Box sx={{ 
          backgroundColor: `${switchTab  === 'anuales' ?  theme.palette.primary.main : '#fff'}`,
          margin: '0px 0 0 20px',
          padding: '5px',
          borderRadius: '10px',
          alignItems: 'center',
          width: '10vw',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setSwitchTab('anuales')}
          
        >
          <Typography variant='subtitle2' color={switchTab  === 'anuales' ? '#fff' : ''} sx={{textAlign: 'center', fontSize: '0.9em', textTransform: 'uppercase'}}>
            Cobros Anuales
          </Typography>
        </Box>
        <Box sx={{ 
          backgroundColor: `${switchTab === 'accesos' ? theme.palette.primary.main : '#fff'}`,
          margin: '0 0 0 20px',
          padding: '5px',
          borderRadius: '10px',
          width: '10vw',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center'
        }}
        onClick={() => setSwitchTab('accesos')}
        >
          <Typography variant='subtitle2' color={switchTab  === 'accesos' ? '#fff' : ''} sx={{textAlign: 'center', fontSize: '0.9em', textTransform: 'uppercase'}}>
            Accesos
          </Typography>
        </Box>
        <Box sx={{ 
          margin: '0px 0 0 20px',
          padding: '5px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          width: '10vw',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          alignItems: 'center',

        }}
        onClick={() => setOpen(true)}
        >
          <Typography variant='subtitle2' sx={{fontSize: '0.9em', textTransform: 'uppercase'}}>
            Reportes
          </Typography>
        </Box>
      </Box>
      <ModalReportes open={open} setOpen={setOpen} selectedDate={date} />
    </>
  );
};

const MenuHeader = ({
  setSwitchTab, 
  switchTab, 
  date, 
  selectedDate, 
  setDate, 
  inputDate
}) => {

  const handleChangeInput = (e, f) => {
    const from = f.key.slice(2);
    let { until } = inputDate.find((e) => e.key == from);
    setDate({ from: from, until: (new Date(until) >= new Date()) ? new Date().toISOString().slice(0, 10) : until });
  };

  return (
    <>
      {
        (switchTab === 'cobros' || switchTab === 'accesos') ?
          (
            <Box sx={{ 
              width: '100%',
              display: 'flex', 
              flexWrap: 'wrap',
              flexDirection: 'row', 
              alignItems: 'center', 
              marginTop: '20px',
              justifyContent: 'space-between'
            }}>
              <MenuTabs setSwitchTab={setSwitchTab} switchTab={switchTab} date={date} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                  variant='outlined'
                  style={{ width: '300px', display: 'flex', flexDirection: 'column' }}
                  size='small'
                  select
                  displayEmpty
                  value={selectedDate}
                  onChange={handleChangeInput}
                  SelectProps={{
                    renderValue: ((value) => value),
                  }}
                >
                  {
                    inputDate.map(o => (
                      <MenuItem key={o.key} value={o.value} >
                        {o.value}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            </Box>
          ) : (
            <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}>
              <MenuTabs setSwitchTab={setSwitchTab} switchTab={switchTab} date={date} />
            </Box>
          )
      }
    </>
  );
};

export default MenuHeader;