import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Payment } from '@mui/icons-material';
import ButtonMessage from './ButtonMessage';
import { Box, IconButton, Link } from '@mui/material';

const MercadopagoField = ({ source }) => {
  const record = useRecordContext();
  return (
    <Box style={{textAlign:'center'}}>
      <Link
        onClick={(e)=> e.stopPropagation()} 
        href={(record && record[source]) ? 
          'https://www.mercadopago.com.ar/activities/1?q='+record[source] :
          ''
        }
        icon={<Payment/>}
        underline='none' 
        target="_blank" 
        rel="noreferrer"
      >
        <IconButton>
          <Payment/>
        </IconButton>
      </Link>
    </Box>
  );
};

export default MercadopagoField;