import { List, Datagrid, TextField, CreateButton, TopToolbar, ExportButton} from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';

const ActividadesListActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    <ExportButton />
  </TopToolbar>
);

const defaultFilter = [
  <TextInputIcon
    source="name" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

const ActividadesList = props => {
  return(
    <List 
      bulkActionButtons={false} 
      actions={<ActividadesListActions/>} 
      perPage={25} 
      filters={defaultFilter}
      {...props}
    >
      <DatagridCustom rowClick='show' >
        <TextField source="name" label='Nombre'/>
        <TextField source='slug'/>
      </DatagridCustom>
    </List>
     
  );
};

export default ActividadesList;