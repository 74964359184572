import { Box } from '@mui/material';
import CardDashboard from './Card/CardDashboard';
import { useEffect, useState } from 'react';
import CardContent from './Card/CardContent';

const EgresosCard = ({accesosMns, isFetching, date, isError}) => {
  const [accesosDatasets, setAccesosDatasets] = useState(null);
  
  const helpText = 'Accesos a otras sedes de socios con planes vendidos por esta sede.';
  useEffect(() => {
    let datasets = accesosMns?.dashboard_accesos_por_sede;
    setAccesosDatasets({
      chart: {
        accesos: datasets,
      }
    });
    return() => setAccesosDatasets(null);
  },[accesosMns]);
  
  return (
    <Box sx={{ width: '100%'}}>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          width: {xs: '100%'},
          height: 'auto',
          justifyContent: 'space-between'
        }}
      >
        <CardDashboard noPad>
          <CardContent 
            isFetching={isFetching}
            noCard
            chartBar 
            egresos
            datasets={accesosDatasets}
            date={date}
            name="Egresos"
            isError={isError}
            helpText={helpText}
          />
        </CardDashboard>
      </Box>
    </Box>
  );
};

export default EgresosCard;