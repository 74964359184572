import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, IconButton, TextField } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { CgCreditCard } from 'react-icons/cg';
import DotDivider from './DotDivider';
import { useRefresh, useUpdate } from 'react-admin';
import ModalCustom from './ModalCustom';
import { theme } from '../../Theme/theme';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { Button } from '@mui/material';

const styleList = {
  width: '100%',
  height: '290px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.3)',
    borderRadius: 10
  }
};

const styleButtons = { 
  backgroundColor: '#fff',
  zIndex: '99',
  padding:'10px 15px', 
  display: 'flex',
  justifyContent: 'space-between',
  position: 'sticky',
  bottom: '-1px'
};

const ModalWhitelist = ({record, open, setOpen}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [whitelist, setWhitelist] = useState(record?.whitelist !== null ? record?.whitelist : []);
  const [update, { isFetching, data, error}] = useUpdate();
  const [change, setChange] = useState(false);
  const [limitWhitelist, setLimitWhitelist] = useState(50);
  const refresh = useRefresh();


  const deleteHandler = (dni) => {
    const filterWhitelist = whitelist.filter((item, i) => i != whitelist.indexOf(dni));
    setWhitelist(filterWhitelist);
    if(filterWhitelist.length == 0){
      update('corporativo', {id: record.id, data: {'whitelist': []}});
    }else{
      setChange(true);
    }
  };

  const handleClickDeletAll = () => {
    setWhitelist([]);
    setOpenConfirm(false);
    update('corporativo', {id: record.id, data: {'whitelist': []}}, record);
  };

  const handleExport = () => {
    let csvContent = 'data:text/csv;charset=utf-8,' + whitelist.join('\n');
    let encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Whitelist-${record.name}.csv`);
    document.body.appendChild(link);
    link.click();
  };
  
  useEffect(()=> {
    console.log('useupdate data', data);
    if(data || data){
      createNotification('success', 'DNI/s eliminado/s con éxito');
      setOpen(false);
      refresh();
    }else if(error){
      createNotification('error', 'No se pudo eliminar el/los DNI/s', ()=> handleClickCopy(whitelist));
    }
  }, [data, error]);

  const handleClose = () => {
    if(change){
      update('corporativo', {id: record.id, data: {'whitelist': whitelist}}, record);
      setChange(false);
    }else{
      setOpen(false);
    }
    setLimitWhitelist(50);
  };

  return (
    <ModalCustom
      open={open}
      onClose={handleClose}
      title='Whitelist'
    >
      <Box style={{width: '670px'}}>
        <Box style={{display: 'flex', justifyContent: 'center'}}>
          {
            (isFetching) ? (
              <Box style={{display: 'flex', justifyContent: 'center', margin: '50px'}}>
                <CircularProgress/>
              </Box>
            ) : (
              <Box sx={styleList}>
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                 
                  {whitelist && whitelist?.length > 0 &&
                  whitelist.slice(0,limitWhitelist).map((item, index) => {
                    if((index+1) == limitWhitelist && whitelist.length > limitWhitelist){
                      return(
                        <Box key={Math.random()} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Button variant='text' size='small' onClick={() => setLimitWhitelist(limitWhitelist + 50)}>
                            Ver Mas
                          </Button>
                        </Box>
                      );
                    }else{
                      return(
                        <Box 
                          key={item}
                          style={{display: 'flex', alignItems: 'center', width: '160px', justifyContent: 'space-between'}}
                        >
                          <Box style={{display: 'flex', alignItems: 'center'}}>
                            <DotDivider/>
                            <CgCreditCard style={{fontSize: '16px', marginRight: '5px'}}/>
                            <Typography variant='subtitle2' color='secondary'>{item}</Typography>
                          </Box>
                          <IconButton 
                            aria-label="delete"
                            onClick={() => deleteHandler(item)} 
                            size='medium'
                            color='error'
                          >
                            <DeleteOutlinedIcon/>
                          </IconButton>
                        </Box>
                      );
                    }
                  }) 
                  }
                </Box>
                {record.whitelist?.length > 0 &&  (
                  <Box style={styleButtons}>
                    <Button variant='contained' size='small' color='primary' onClick={handleExport}>Exportar</Button>
                    <Button variant='contained' size='small' onClick={()=> setOpenConfirm(true)} style={{color: '#fff', backgroundColor: theme.palette.red.primary}} color='error'>
                      Eliminar Todos
                    </Button>
                  </Box>
                )}
              </Box>
            )
          }
        </Box>
        <ModalConfirm
          setOpen={setOpenConfirm}
          open={openConfirm} 
          handleClickDelet={handleClickDeletAll}
          name={record.name}
        />
      </Box>
    </ModalCustom>
  );
};

const ModalConfirm = ({open, setOpen, handleClickDelet, name}) => {
  return(
    <ModalCustom
      open={open}
      setOpen={setOpen}
      title='Eliminar Whitelist'
      showButtons
      onClickAction={handleClickDelet}
      textAction='Eliminar'
      colorAction='error'
    >
      <Box style={{width: '400px', textAlign: 'center'}}>
        <Typography style={{...theme.typography.subtitle2, fontSize: '16px'}}>
          {name ? 
            `Perderás toda la lista de usuarios cargados en la whitelist de '${name}'` : 
            'Perderas toda la lista de usuarios cargados'
          }
        </Typography>
      </Box>
    </ModalCustom>
  );
};

export default ModalWhitelist;