import React, { useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { WhatsApp } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.success.main
  }
}));

const WhatsappField = ({ source, isSocio, mnsj}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [resumen, setResumen] = useState(null);
  const classes = useStyles();
  
  const mensajeSocio = record && `¡Hola ${record?.nombre}! Nos comunicamos desde SportClub para informarte que tu cuota fue rechazada y te estarás quedando sin vigencia. Podés regularizar tu cuenta a través del siguiente link: ${process.env.REACT_APP_PORTAL_SOCIO_URL}. Por consultas, escribir a rechazos@sportclub.com.ar. ¡Muchas gracias!`;
  const mensajeEfectivo = record && `Hola ${record?.nombre}, queríamos recordarte que cuando puedas pases a efectuar el pago de tu cuota por recepción.  El monto a pagar es de $${resumen && resumen.final_price}. Cualquier duda avisanos! Gracias!`;
  const planLead = !isSocio && !mnsj && record.plan.replace(/-/g,' ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  const mensajeLead = record && `¡Hola ${record?.first_name}! ¿Cómo estás? Me comunico de SportClub. Te escribo porque notamos tu interés en ${planLead} y quería saber si tenías alguna duda con la que te pueda ayudar.`;
  const hardcodedSecondCharPhone = record && record[source] && (record[source && source]?.slice(0,2) == '15' ? '11' + record[source && source]?.slice(2,20) : record[source && source]);

  React.useEffect(() => {
    record && mnsj && dataProvider.getOne('socios', {id: `${record.id}/resumen-pago`}).then(item => setResumen(item.data));
  }, []);


  if(record?.[source]?.length >= 9) {
    return (
      <Box style={{textAlign:'center'}}>
        <Link 
          onClick={(e)=> e.stopPropagation()} 
          href={'https://api.whatsapp.com/send?phone=+549'+hardcodedSecondCharPhone+`&text=${mnsj ? mensajeEfectivo : isSocio ? mensajeSocio : mensajeLead}`} 
          underline='none' 
          target="_blank" 
          rel="noreferrer"
        >
          <IconButton color="success" className={classes.iconButton}>
            <WhatsApp/>
          </IconButton>
        </Link>
      </Box>
    );
  } else{
    return(
      <div>
        <IconButton disabled>
          <WhatsApp/>
        </IconButton>
      </div>);
  }
};

export default WhatsappField;