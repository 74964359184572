import { useContext, useEffect, useState } from 'react';
import ModalReportes from '../Dashboard/Reportes/ModalReportes';
import { useRedirect } from 'react-admin';
import { merchantCadena, merchantContext } from '../../DataProvider';

const Reportes = () => {
  const {getMerchant} = useContext(merchantContext);
  const redirect = useRedirect();
  const [open, setOpen] = useState(true);
  const [isCadena, setIsCadena] = useState(getMerchant()?.id === merchantCadena);
  const [date, setDate] = useState({
    from: new Date().toISOString().slice(0, 10),
    until: new Date().toISOString().slice(0, 10),
  });
  
  useEffect(() => {
    if(getMerchant()?.id === merchantCadena) {
      setIsCadena(true);
    } else {
      setIsCadena(false);
    }
  },[merchantContext]);

  return(
    <>
      {/* <Box style={{width: '100%', display: 'flex', margin: '100px'}}>
        <Button style={{width: '100px'}} variant="outlined" onClick={() => setOpen(!open)}>Reportes</Button>
      </Box> */}
      <ModalReportes open={true} selectedDate={date} setOpen={setOpen} notDisabledMerchs={false} onClose={redirect('list', 'socios')} />
    </>
  );
};

export default {
  list: Reportes
};


