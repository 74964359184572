import React from 'react';
import { Edit, Toolbar } from 'react-admin';
import AmenitiesCreateEdit from './AmenitiesCreateEdit';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import { FormTransparent } from '../../Components/CustomFields/FormTransparent';
import CustomToolbar, { CustomEditToolbar } from '../../Components/UI/Toolbar/CustomToolbar';

const AmenitiesEdit = (props) => {
  return (
    <Edit 
      {...props} 
      title={<TitleEdit title='Editar Servicio: ' source='name'/>} 
      mutationMode="pessimistic"
    >
      <AmenitiesCreateEdit isEdit/>
    </Edit>
  );
};

export default AmenitiesEdit;
