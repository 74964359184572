import React from 'react';
import { Box, Button } from '@mui/material';


const handlerFileDnis = (e, success, onClick) => {
  const file = e.target.files[0];
  if(file){
    let file = new FileReader();
    file.readAsText(e.target.files[0]);
    file.onload = () => {
      let arrayOnlyNumbers = file.result.replace(/[;]|\./g, '');
      let arrayFileDnis = arrayOnlyNumbers.split(', ');
      if(arrayFileDnis.length <= 1){
        arrayFileDnis = arrayOnlyNumbers.split('\r\n');
      }
      arrayFileDnis = arrayFileDnis.filter(i => i.length >= 7);
      success(arrayFileDnis);
      onClick && onClick();
    };
  }
};

const InputFile = ({text, success, onClick, style, accept, startIcon, disabled, fullWidth}) =>  (
  <label style={fullWidth && {width: '100%'}}>
    <Box style={{display: 'none'}}>
      <input type='file' accept={accept} onChange={(e) => handlerFileDnis(e, success, onClick)} />
    </Box>
    <Button 
      variant='contained' 
      component="span" 
      style={style} 
      startIcon={startIcon} 
      disabled={disabled}
    >
      {text}
    </Button>
  </label>
);

export default InputFile;
