import { Box, Card, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {  useDataProvider, useRefresh } from 'react-admin';
import HeaderSections from '../../Components/HeaderSections/HeaderSections';
import { merchantCadena, merchantCentral, merchantContext, merchantSelected } from '../../DataProvider';
import SelectMerch from '../../Components/UI/Dashboard/Screen/SelectMerch';
import { getToken } from '../helpers/helpFunctions';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { usePeriodRequest } from './dashboardRequests';
import MainSection from './DashboardComponents/MainSection';
import HomeChart from '../../Assets/Images/HomeChart2.png';
import { getPeriods } from '../helpers/getPeriods';
import merchants from '../merchants';
import { CorpContext } from '../socios/SectionsEdit/ContextCorp';


const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const Dashboard = () => {

  const { getMerchant, setMerchant } = useContext(merchantContext);
  const { setContext, contextMerchant } = useContext(CorpContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [switchTab, setSwitchTab] = useState('cobros');
  const [selectedMerch, setSelectedMerch] = useState(getMerchant());
  const permission = localStorage?.getItem('permissions');
  const brandId = getToken()?.brand_id; 

  const today = new Date();
  const until = new Date(today.getFullYear(), today.getMonth(), 1);

  const [date, setDate] = useState(
    {
      from: new Date(until).toISOString().slice(0, 10),
      until: new Date().toISOString().slice(0, 10)
    }
  );

  const inputDate = useMemo(() => {
    const onSuccess = (data) => {
      let distinctDate = [];
      data?.map(e => {
        const date = new Date(e.split('/')[0] + '/01/' + e.split('/')[1]);
        if(date > new Date()) return;
        distinctDate.push({
          key: date.toISOString().slice(0, 10),
          value: (`${meses[date.getMonth()]} (${date.getFullYear()})`),
          until: new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
        });
      });
      const sortedDistinct = distinctDate.sort((a, b) => (a.key < b.key) ? 1 : ((b.key < a.key) ? -1 : 0));
      return sortedDistinct;
    };
    return onSuccess(getPeriods());
  }, []);

  useEffect(() => {
    inputDate.map(e => {
      if (e.key === date.from) {
        setSelectedDate(e.value);
      }
    });
  }, [inputDate, date]);
  useEffect(() => {
    setSwitchTab('cobros');
  },[getMerchant()]);
  const refresh = useRefresh();

  const handleSetContextMerchant = (merch) => {
    setSelectedMerch(merch);
    setMerchant(merch);
    setContext(merch);
    refresh({hard: true});
  };

  const MemoComponent = useMemo(() => {

    setSelectedMerch(getMerchant());
    if (
      permission !== 'superadmin' &&
      brandId !== '6328afb359d052e6817c2200'
    ) {
      return (
        <EmptyLayout
          title='No existe data en los negocios para visualizar el dashboard'
        />
      );
    }
  
    if (
      (getMerchant()?.id === '') ||
      (getMerchant()?.id === merchantCadena ||
        merchantCentral?.find(item => item?.id === getMerchant()?.id))
    ) {
      if (permission === 'owner' || permission === 'superadmin') {
        return (
          <SelectMerch
            message={'Debes seleccionar un negocio para visualizar el dashboard'}
            setMerchant={handleSetContextMerchant}
            getMerchant={getMerchant}
          />
        );
      }
  
      if (
        permission !== 'owner' || permission !== 'superadmin'
      ) {
        if (
          getMerchant()?.id === merchantCadena ||
          merchantCentral?.find(item => item?.id === getMerchant()?.id)
        ) {
          return (
            <>
              <Box
                marginTop={'100px'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <img
                  title='Disabled Menu'
                  style={{
                    borderRadius: '4px',
                    width: '600px',
                    opacity: '0.8'
                  }}
                  src={`${HomeChart}`}
                />
                <Typography
                  marginTop='50px'
                  variant='h5'
                  textAlign='center'
                  paragraph
                >
                  No hay información de este negocio.
                </Typography>
              </Box>
            </>
          );
        }
      }
    }
  
    return (
      <Card
        style={{
          backgroundColor: '#fafafa',
          height: 'calc(100vh - 48px)',
          overflowX: 'hidden'
        }}
      >
        <HeaderSections
          date={date}
          switchTab={switchTab}
          setSwitchTab={setSwitchTab}
          min
        />
        <MainSection
          switchTab={switchTab}
          setSwitchTab={setSwitchTab}
          selectedDate={selectedDate}
          inputDate={inputDate}
          setDate={setDate}
          date={date}
        />
      </Card>
    );
  }, [getMerchant()?.id, selectedDate, selectedMerch, contextMerchant, switchTab]);

  return MemoComponent;
};

export default Dashboard;



