import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useRefresh, useSidebarState } from 'react-admin';
import { createNotification } from '../../../helpers/helpFunctions';
import { SkeletonList } from '../../../../Components/DatagridCustom/DatagridCustom';
import { Box, Tooltip, Typography } from '@mui/material';
import { StyledBody, StyledCell, StyledHead, StyledRow, StyledTable } from './StyledTable';
import ModalCustom from '../../../../Components/UI/ModalCustom';
import { MultinegocioGrid } from '../MultinegocioGrid';
import { green, grey } from '@mui/material/colors';
import { CustomPlanList } from './CustomPlanList';
import { CustomMerchList } from './CustomMerchList';
import { useAbsolute } from '../../../../Hooks/usePosition';

export const CustomFixedList = (props) => {


  const {
    data,
    planes,
    findValue,
    isFetching,
  } = props;

  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [multimerch, setMultimerch] = useState([]);
  const [sort, setSort] = useState('default');
  // Tenemos que usar estados de loading porque "isFetching" o "isLoading" de react query no andan correctamente.
  const [loading, setLoading] = useState(false);
  const [putLoading, setPutLoading] = useState(false);
  
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleClose = () => { setSelectedRow(null);setOpen(false);};
  const postSave = (row) => sendDataMultiMerchant(row.id);
      
  const sendDataMultiMerchant = async (id) => {
    setPutLoading(true);
    try {
      let responseArr = await Promise.all(multimerch?.map((a, item, index) => {
        if(a.edit) {
          return rowToDataSend(a, id);
        }
      },[]));
  
      if(responseArr){
        refresh();
        setPutLoading(false);
        setOpen(false);
        setSelectedRow(false);
        createNotification('success', 'Negocio/s actualizados satisfactoriamente');
      }
      return responseArr;
  
    } catch (error) {
      setPutLoading(false);
      setOpen(false);
      setSelectedRow(false);
      createNotification('error', 'Hubo un error con la carga de 1 o más negocios, por favor volver a intentar.');
    }
  };
      
  const rowToDataSend = async (row, id) => {
    let index = data.findIndex(obj => obj.id == row.id);
    let merchantEdit = data[index];
    const indexMerch = merchantEdit.planes_multinegocio?.findIndex(item => item.plan_id == id);
    if(row.habilitado){
      if(indexMerch >= 0) {
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.habilitado ? row.habilitado : false;
      }else{
        merchantEdit.planes_multinegocio.push({
          application_fee: row.application_fee ? row.application_fee : 0,
          enabled: row.enabled,
          plan_id: id
        });
      }
    }else{
      if(indexMerch >= 0) {
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.enabled;
      }
    }
    delete merchantEdit.keys;
    delete merchantEdit.credentials;
    delete merchantEdit.name;
    delete merchantEdit.afip;
    delete merchantEdit.keys_marketplace;
    let response = await dataProvider.update('merchants', {id: merchantEdit.id, data: merchantEdit});
    return response;
  };
      
  const createRow = (row) => {
    let ret = {
      id: row.id,
      name: row.name,
      hasKeys: row?.split || (row.credentials?.mp_marketplace && (row.credentials?.mp_marketplace?.access_token && row.credentials?.mp_marketplace.public_key)),
      application_fee: 0,
      habilitado: false,
      enabled: false,
      edit: false
    };
    if(selectedRow){
      row?.planes_multinegocio?.map(item => {
        if(item.plan_id == selectedRow?.id){
          ret = {
            ...ret,
            application_fee: item.application_fee ? item.application_fee : 0,
            habilitado: item?.enabled ? item.enabled : false,
            enabled: item?.enabled ? item.enabled : false,
          };
        }
      });
    }
    return ret;
  };
        
  const handleMultiMerch = async () => {
    let arrayRows = [];
    data.map(item => arrayRows.push(createRow(item)));
    setLoading(false);
    return arrayRows.sort((a, b) => a.enabled);
  };
  
  useEffect(async () => {
    setLoading(true);
    setMultimerch(await handleMultiMerch());
  }, [selectedRow]);
  
  const merchantCount = (id, arr) => {
    let total = 0;
    for(let i = 0; i < arr.length; i++){6;
      if(arr[i].planes_multinegocio.find(item => (item.plan_id === id) && item.enabled)) {
        total++;
      }
      continue;
    }
    return total;
  };
    
  const sortedPlanes = useMemo(() => {
    switch(sort !== null){
    case sort === true:
      return planes?.sort((a, b) => a.name.localeCompare(b.name));
    case sort === false:
      return planes?.sort((a, b) => b.name.localeCompare(a.name));
    }
    return planes?.sort((a, b) => merchantCount(a.id, data) > merchantCount(b.id, data) ? -1 : 1);
  },[planes, data, sort]);


  // Fixed Position for divs
  const divId = 'header-div';
  const fixedPosition = useAbsolute(divId);


  const merchlistProps ={
    data,
    sortedPlanes,
    findValue,
    setSelectedRow,
    setOpen,
    fixedPosition
  };
  return (
    <>
      {
        isFetching ? <SkeletonList /> : (
          <Box 
            id={divId}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
  
            }}>
            <CustomPlanList 
              fixedPosition={fixedPosition}
              sortedPlanes={sortedPlanes} 
              setSort={setSort} 
              sort={sort} 
            />
            <CustomMerchList {...merchlistProps} />
            
          </Box>
        )
      }
      {
        selectedRow && (
          <ModalCustom onClose={handleClose} open={open} setOpen={setOpen} title={`${selectedRow?.name} - Negocios habilitados`} queryOverflow>
            <MultinegocioGrid putLoading={putLoading} loading={loading} postSave={postSave} selectedRow={selectedRow} multimerch={multimerch} setMultimerch={setMultimerch} />
          </ModalCustom>  
        )
      }
    </>
  );
  
};
  