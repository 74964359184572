import { useContext, useMemo, useState } from 'react';
import { getPerfilSocio } from '../requests';
import { Box, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { merchantContext,merchantsInToken } from '../../../DataProvider';
import { getPermissions } from '../../helpers/helpFunctions';
import moment from 'moment';

export const InitialArraySearch = ({data, isFetching, err, setSocios, setSocio, setDni, setShowLink}) => {
  const [loadingSocios, setLoadingSocios] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  const permis = getPermissions();

  const checkDate = (date) => {
    let today = new Date();
    if(moment(date).isSameOrAfter(today)) return (<Typography variant="h7" style={{color: 'green'}}>{moment(date).format('DD/MM/YYYY')}</Typography>);
    return (<Typography variant="h7" style={{color: 'red'}}>{moment(date).format('DD/MM/YYYY')}</Typography>);
  };
  const cols = [
    {headerName: 'Nombre', field: 'nombre'},
    {headerName: 'Apellido', field: 'apellido'},
    {headerName: 'Documento', field: 'documento'},
    {headerName: 'Email', field: 'email'},
    {headerName: 'Fecha de vigencia', field: 'fecha_vigencia', minWidth: 150, renderCell: (params) => checkDate(params.value)},
    {headerName: 'Origen', field: 'source'},
  ];
  
  // KEVIN : MODIFIQUE ESTO, EN CASO DE QUE DATA SEA 1, TAMBIEN LE AGREGA ID.
  const addIds = useMemo(() => {
    if(data.length >1){
      return data?.map((item, index) => {
        let obj = {...item};
        obj['id'] = new Date().getTime() + index;
        return obj;
      });
    }else{
      let obj = {...data};
      obj['id'] = new Date().getTime() + Math.random();
      console.log(obj,'OBJETO CON ID ?');

      return obj;
    }
    
  },[data]);
  
  const selectSocio = async (e) => {
    setLoadingSocios(true);
    let value = e?.row;
    let source= e?.row?.source;
    console.log(source);
    try {
      let queryKey = {
        queryKey: [null, value.documento],
        source
      };
      let request = await getPerfilSocio(queryKey);
      if(request.data){
        let dataSocio = request?.data;
        setSocios(null);
        setLoadingSocios(false);
        setSocio(request.data);
        setDni(request.data.documento);
        // console.log(
        //   request.data?.id, request.data['_id'] ,
        //   !request.data?.externo , request.data?.source !== 'Proclub' ,
        //   ([request.data?.plan_details?.merchant_id, ''].includes(getMerchant()?.id) ,
        //   getMerchant()?.negocio_central)
        // );
        let socioMerchId = dataSocio?.seller_merchant_id || dataSocio?.plan_details?.merchant_id;
        if(dataSocio?.externo === true){
          setShowLink(false);
        }else{
          // 2* Verifico si tiene permisos de superadmin o owner, si lo es HABILITO ver socio.
          if(permis === 'superadmin' || permis === 'owner'){
            setShowLink(true);
          }else{
            if(dataSocio?.id || dataSocio['_id']){
              // 3* Por cada SELLER de los que tiene el ADMIN actual >
              for (let merch of merchantsInToken) {
                // Verifico si coincide con el ID del SELLER del SOCIO, si es asi HABILITO el ver socio.
                if(socioMerchId === merch.id){
                  setShowLink(true);
                  break;
                }else{
                  // Si no coincide , DISABLED ver socio.
                  setShowLink(false);
                }
              }
            }else{
              setShowLink(false);
            }
          }          
        }
      }
    } catch (error) {
      setLoadingSocios(false);
      throw new Error(error);
    }
  };

  return (
    <>
      {
        data && !loadingSocios && !err && (
          <Box sx={{height: '375px', width:'100%'}}>
  
            <DataGrid
              columns={cols} 
              rows={addIds}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSize={5}
              pageSizeOptions={[5]}
              onRowClick={selectSocio}
              sx={{
                // '.MuiDataGrid-row': {
                //   borderLeft: (props, e) => e?.row?.status ? '5px red solid' : '5px green solid'
                // },
                '.MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                      
                }
              }}
            />
          </Box>
  
        )
      }
      {
        loadingSocios && (
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress style={{width: '28px', height: '28px'}}/>
          </Box>
        ) }
                    
    </>
  );
};
  
  
  