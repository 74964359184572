import ModalCustom from './ModalCustom';
import {useEffect, useRef, useState} from 'react';
import { Autocomplete as AutocompleteMui, Box, InputLabel, TextField as Textfield2 } from '@mui/material';
import { TextField, OutlinedInput, Autocomplete, Select, MenuItem } from '@mui/material';
import { useQuery } from 'react-query';
import DataProvider from '../../DataProvider';
import { DateInput, DateTimeInput, SimpleListLoading, useDataProvider, useUpdate } from 'react-admin';
import { createNotification, getMerchantId } from '../../Resources/helpers/helpFunctions';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';


const ModalEvento = ({open, setOpen, row, isEdit, record, setLoading}) => {
    
  const [sedes, setSedes] = useState([]);
  const [evento, setEvento] = useState({});
  const [errors, setErrors] = useState(false);
  const ref = useRef(false);
  const [update, {loading, error}] = useUpdate();
  const dataProvider = useDataProvider();
  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field:'name', order:'ASC'},
    filter: {}
  };
    
  const handleClose = () => {
    setOpen(false);
    setErrors(false);
  };

  useEffect(() => {
    setErrors(false);
    setEvento({});
  },[]);
  useOnClickOutside(ref, () => setErrors(true));

  useQuery(
    ['sedes', getMerchantId().id], 
    () => dataProvider.getList('sedes', params),
    {
      onSuccess: ({data}) => setSedes(data)
    }
  );
    
  const handleValidations = () => {
    if(!evento.title || evento.title.length <= 0) {
      createNotification('warning', 'Falta el campo de título');
      return false;
    }
    if(!evento.sede_id) {
      createNotification('warning', 'Falta el campo de sede');
      return false;
    }
    if(!evento.date_created) {
      createNotification('warning', 'Falta el campo de fecha');
      return false;
    }
    if(!evento.description) {
      createNotification('warning', 'Falta el campo de descripcion');
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if(handleValidations()) {
      setLoading(true);
      dataProvider.update('socios', {data: {eventos: (record.eventos ? [...record.eventos, evento] : [evento])}, id: record?.id}, record)
        .then(e => {
          setLoading(false);
          createNotification('success', 'Evento creado');
          setLoading(false);
          setErrors(false);
        }).catch(e => {
          setLoading(false);
          createNotification('error', 'Hubo un error');
          setErrors(false);

        });
      setOpen(false);
      setEvento({});
    }
    return;
  };

    

  return(
    <>
      <ModalCustom 
        open={open}
        setOpen={setOpen}
        showButtons
        title={'Crear Evento'}
        onClose={handleClose}
        onClickAction={() => handleSubmit()}
      >
        <Box ref={ref} style={{width: '100%', minWidth: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>   
          <TextField 
            style={{width: '100%', margin: '15px'}} 
            label={'Título'} 
            variant={'outlined'}
            error={errors && !evento.title}
            onChange={(e) => setEvento((f) => ({...f, title: e.target.value}))} 
          />
          <TextField
            labelId="sedes-label"
            style={{ width: '100%', margin: '15px', borderRadius: '5px'}}
            variant={'outlined'}
            label="Sedes"
            select
            error={errors && !evento.sede_id}
            onChange={(e) => setEvento((f) => ({...f, sede_id: e.target.value.id}))}
          >
            {
              sedes.map((e, i) => (
                <MenuItem key={i} value={e}>
                  {e.name}
                </MenuItem>
              )
              )
            }
          </TextField>
          <OutlinedInput 
            type={'date'} 
            style={{width: '100%', margin: '15px'}} 
            label={'fecha'}
            error={errors && !evento.date_created}
            onChange={(e) => setEvento((f) => ({...f, date_created: e.target.value}))} 
          />
          <TextField 
            multiline 
            rows={5}
            error={errors && !evento.description}
            style={{width: '100%', margin: '15px'}} 
            label={'Descripción'} 
            onChange={(e) => setEvento((f) => ({...f, description: e.target.value}))}
            variant={'outlined'} />
                
        </Box>
      </ModalCustom>
    </>
  );
};

export default ModalEvento;

