import React, { StrictMode, useContext, useEffect, useState } from 'react';
import { Admin, Resource, useDataProvider, useRedirect } from 'react-admin';
import { i18nProvider } from './translations/i18nprovider';
import DataProvider, { apiUrl, merchantContext } from './DataProvider';
import Authentication from './Authentication';
import socios from './Resources/socios';
import planes from './Resources/planes';
import leads from './Resources/leads';
import corporativo from './Resources/corporativo';
import Cobros from './Resources/Cobros';
import CustomLoginPage from './Resources/auth/CustomLoginPage';
import sedes from './Resources/sedes';
import amenities from './Resources/amenities/index';
import actividades from './Resources/Actividades/index';
import puntoacceso from './Resources/PuntoAcceso/index';
import brands from './Resources/Brands/index';
import Rechazos from './Resources/Rechazos/index';
import Accesos from './Resources/Accesos/index';
import merchants from './Resources/merchants/index';
import users from './Resources/Users/index';
import { theme } from './Theme/theme';
import { Token, hasAuth, isUserSC, roles, getToken, getBrand, isUserCentral, isUserSede } from './Resources/helpers/helpFunctions';
import Dashboard from './Resources/Dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CgGym } from 'react-icons/cg';
import Multinegocio from './Resources/planes/Multinegocio';
import { snippetBeacon } from './Components/Snippets/Snippets';
import { VscDashboard } from 'react-icons/vsc';
import LayoutCustom from './Components/UI/Layout/LayoutCustom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {ThemeProvider} from '@mui/material/styles';
import { Button, CssBaseline, TextField } from '@mui/material';
import { DispatchErrorProvider } from './Context/menuContext';
import { CorpProvider } from './Resources/socios/SectionsEdit/ContextCorp';
import Busqueda from './Resources/Busqueda/index';
import Central from './Resources/planes/Central';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import Reportes from './Resources/Reportes';
import CobrosAdicionales from './Resources/Adicionales';
import Adicionales from './Resources/Adicionales';
import Caja from './Resources/Caja';
import RedirectResource from './Resources/v2Pages';

function App() {
  const [open, setOpen] = useState(true);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      },
    },
  });

  if (window.location.search.length > 5) {
    console.log(window.location.search.slice(6).split('&')[0]);
  }

  useEffect(() => {
    const url = window.location.href;
    const queryString = url.split('?')[1];
    
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const id = params.get('merchantId');
      localStorage.setItem('CURRENTMERCHANT', id)
    }
  }, []);

  return (
    <DispatchErrorProvider>
      <CssBaseline />
      <CorpProvider>
        <Admin
          theme={theme}
          dataProvider={DataProvider}
          authProvider={Authentication}
          i18nProvider={i18nProvider}
          queryClient={queryClient}
          loginPage={CustomLoginPage}
          layout={LayoutCustom}
          requireAuth
        >
          {async (permissions) => [
            hasAuth(permissions, roles.slice(0, 3)) ? (
              <Resource
                name="dashboard"
                options={{ label: "DASHBOARD" }}
                list={Dashboard}
                icon={VscDashboard}
              />
            ) : null,
            hasAuth(permissions, roles.slice(0, 5)) && (
              <Resource
                name="SOCIOS "
                options={{ isMenuParent: true }}
                icon={CgGym}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) ? (
              <Resource
                name="socios"
                {...socios}
                options={{ label: "Socios", menuParent: "SOCIOS " }}
              />
            ) : (
              <Resource name="socios" />
            ),

            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="abandoned"
                {...leads}
                options={{ label: "Prospectos", menuParent: "SOCIOS " }}
              />
            ),

            (await getBrand()) && (
              <Resource
                name="accesos"
                {...Accesos}
                options={{ label: "Accesos", menuParent: "SOCIOS " }}
              />
            ),

            (await getBrand()) && (
              <Resource
                name="busqueda"
                {...Busqueda}
                options={{ label: "Red SportClub", menuParent: "SOCIOS " }}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="planes"
                options={{ label: "Planes", menuParent: "ADMINISTRATIVO" }}
                {...planes}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="adicionales"
                options={{
                  label: "Cobros Adicionales",
                  menuParent: "ADMINISTRATIVO",
                }}
                {...Adicionales}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) &&
              isUserCentral(permissions) && (
                <Resource
                  name="centrales"
                  customRoutes
                  centrales
                  options={{
                    label: "Centrales",
                    menuParent: "PLANES ",
                    hidden: true,
                  }}
                  {...Central}
                />
              ),

            hasAuth(permissions, roles.slice(0, 4)) && (await getBrand()) && (
              <Resource
                style={{ display: "none" }}
                name="multinegocio"
                options={{
                  label: "Cadena",
                  menuParent: "PLANES ",
                  hidden: true,
                }}
                {...Multinegocio}
              />
            ),

            (await getBrand()) && (
              <Resource
                name="accesos"
                {...Accesos}
                options={{ label: "Accesos", menuParent: "SOCIOS " }}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="BOLETAS "
                options={{ isMenuParent: true }}
                icon={CgGym}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) ? (
              <Resource
                name="efectivo"
                options={{ label: "Efectivo", menuParent: "BOLETAS " }}
                {...Cobros}
              />
            ) : (
              <Resource name="boletas" />
            ),
            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="cierre_caja"
                options={{ label: "Cierre de Caja", menuParent: "BOLETAS " }}
                {...Caja}
              />
            ),

            hasAuth(permissions, roles.slice(0, 4)) && (
              <Resource
                name="rechazos"
                {...Rechazos}
                options={{ label: "Rechazos", menuParent: "BOLETAS " }}
              />
            ),

            hasAuth(permissions, [...roles.slice(0, 4), roles[5]]) && (
              <Resource
                name="ADMINISTRATIVO"
                options={{ isMenuParent: true }}
                icon={CgGym}
              />
            ),

            hasAuth(permissions, roles.slice(0, 3)) ? (
              <Resource
                name="sedes"
                {...sedes}
                options={{ label: "Sedes", menuParent: "ADMINISTRATIVO" }}
              />
            ) : (
              <Resource name="sedes" />
            ),

            hasAuth(permissions, roles.slice(0, 3)) && (
              <Resource
                name="reportes"
                {...Reportes}
                icon={HiOutlineDocumentReport}
                options={{ label: "Reportes", menuParent: "ADMINISTRATIVO" }}
              />
            ),

            // isUserCentral(permissions) &&
            hasAuth(permissions, roles.slice(0, 3)) && (await getBrand()) ? (
              <Resource
                name="corporativo"
                {...corporativo}
                options={{ label: "Campañas", menuParent: "ADMINISTRATIVO" }}
              />
            ) : (
              <Resource name="corporativo" />
            ),

            hasAuth(permissions, roles.slice(0, 3)) ? (
              <Resource name="merchants" {...merchants} />
            ) : (
              <Resource name="merchants" />
            ),

            hasAuth(permissions, roles.slice(0, 2)) && (await getBrand()) ? (
              <Resource
                name="servicios"
                {...amenities}
                options={{ label: "Servicios", menuParent: "ADMINISTRATIVO" }}
              />
            ) : (
              <Resource name="servicios" />
            ),

            hasAuth(permissions, roles.slice(0, 2)) && (await getBrand()) ? (
              <Resource
                name="actividades"
                {...actividades}
                options={{ label: "Actividades", menuParent: "ADMINISTRATIVO" }}
              />
            ) : (
              <Resource name="actividades" />
            ),

            hasAuth(permissions, [...roles.slice(0, 2), roles[5]]) &&
            (await getBrand()) ? (
              <Resource
                name="punto_acceso"
                {...puntoacceso}
                options={{
                  label: "Puntos de Accesos",
                  menuParent: "ADMINISTRATIVO",
                }}
              />
            ) : (
              <Resource name="punto_acceso" />
            ),

            hasAuth(permissions, roles.slice(0, 2)) ? (
              <Resource name="brands" {...brands} />
            ) : (
              <Resource name="brands" />
            ),

            hasAuth(permissions, roles.slice(0, 3)) ? (
              <Resource
                name="users"
                {...users}
                options={{ label: "Usuarios", menuParent: "ADMINISTRATIVO" }}
              />
            ) : (
              <Resource name="users" />
            ),

            <Resource name="socios_planes" />,

            <Resource name="planes_socios" />,

            <Resource name="registrar_acceso" />,

            <Resource name="distinct" />,

            <Resource name="refresh_token" />,

            //Agregar las rutas de los modulos de los microfront aca, sector name es la ruta en los MF y name es el nombre de la ruta que queremos que redirecion aca en el monolito.
            <Resource
              name="v2/planes"
              list={() => <RedirectResource sectorName="planes" /> }
              options={{
                menuParent: "SIN-MENU",
              }}
             
            />,

            <Resource
              name="v2/negocios"
              list={() => <RedirectResource sectorName="negocios" />}
              options={{
                menuParent: "SIN-MENU",
                
              }}
            />,

            <Resource
              name="v2/accesos"
              list={() => <RedirectResource sectorName="accesos" />}
              options={{
                menuParent: "SIN-MENU",
              }}
            />,
            <Resource
            name="v2/reportes"
            list={() => <RedirectResource sectorName="reportes" />}
            options={{
              menuParent: "SIN-MENU",
            }}
          />,
          <Resource
          name="v2/busqueda"
          list={() => <RedirectResource sectorName="busqueda" />}
          options={{
            menuParent: "SIN-MENU",
          }}
        />,
        <Resource
        name="v2/adicionales"
        list={() => <RedirectResource sectorName="adicionales" />}
        options={{
          menuParent: "SIN-MENU",
        }}
      />,
      <Resource
      name="v2/brands"
      list={() => <RedirectResource sectorName="brands" />}
      options={{
        menuParent: "SIN-MENU",
      }}
    />,
    <Resource
    name="v2/users"
    list={() => <RedirectResource sectorName="usuarios" />}
    options={{
      menuParent: "SIN-MENU",
    }}
  />,
          ]}
        </Admin>
      </CorpProvider>
      <ToastContainer />
    </DispatchErrorProvider>
  );
}

export default App;