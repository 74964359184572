import { useContext, useState } from 'react';
import ModalCustom from '../../../Components/UI/ModalCustom';
import { Box, Button, CircularProgress, Modal } from '@mui/material';
import { ModalDateCustom } from '../../../Components/UI/ExportCustomButton';
import { useQuery } from 'react-query';
import axios from 'axios';
import { apiUrl, merchantContext } from '../../../DataProvider';
import { createNotification } from '../../helpers/helpFunctions';
import { capitalizeFirstLetter } from '../../../Components/ListComponents/DoubleField';


const StatusSocioModal = ({open, setOpen}) => {
  const {getMerchant} = useContext(merchantContext);

  const [date, setDate] = useState(
    {
      from: new Date().toISOString().slice(0,10),
      until: new Date().toISOString().slice(0,10)
    }
  );

  const tooltips = {
    tooltipActivos: 'Socios con status activo al finalizar el mes seleccionado',
    tooltipAltas: 'Genera un reporte con los socios que se dieron de alta entre las fechas seleccionadas',
    tooltipBajas: 'Genera un reporte con los socios que solicitaron baja entre las fechas elegidas, aún si hoy en día ya se encuentran inactivos',
    tooltipsInactivos: 'Genera un reporte con los socios que se inactivaron de manera automática por sistema entre las fechas elegidas. No incluye a socios inactivos por baja manual',
  };
  return(
    <>
      <Button
        onClick={() => setOpen(true)} 
        // startIcon={<CircularProgress style={{color: '#fff', width: '1rem', height: '1rem'}}/>}
        // disabled={loading}
        color={'secondary'}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px'}}
        variant={'outlined'}>
            Estado de Socios
      </Button>
      <ModalCustom
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        title={'Estado de socios'}
       
        disabledAction>
        <Box  style={{display: 'flex', gap: '10px', flexDirection: 'column', marginBottom: '10px'}}>
          <StatusModal tooltip={getMerchant()?.id === '' ? tooltips.tooltipActivos : null} title={'Activos'} date={date} setDate={setDate} noDate url={'socios_activos_pro'} />
          <StatusModal tooltip={tooltips.tooltipAltas} title={'Dados de alta'} date={date} setDate={setDate} url={'socios_altas'} />
          <StatusModal tooltip={tooltips.tooltipBajas} title={'Bajas Solicitadas'} date={date} setDate={setDate} url={'socios_bajas'} />
          <StatusModal tooltip={tooltips.tooltipsInactivos} title={'Bajas Automáticas'} date={date} setDate={setDate} url={'socios_inactivos'} />
        </Box>
      </ModalCustom>
    </>
  );
};

export const StatusModal = ({title, date, setDate, url, noDate, tooltip}) => {
  const [open, setOpen] = useState(false);
  const {getMerchant} = useContext(merchantContext);
  const [merch, setMerch] = useState('');
  const [loading, setLoading] = useState(false);

  function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(JSON.parse(reader.result));
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  const { refetch } = useQuery(
    [`reporte_socios_${url}`], 
    () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('auth');
        const tokenSend = JSON.parse(token)?.token;
        const selectedFilter =  `filter=${JSON.stringify(date)}`;
        let urlApi = url === 'socios_activos_pro' ? `${apiUrl}/${url}?filter=${JSON.stringify({'status': ['activo']})}` : `${apiUrl}/${url}?${selectedFilter}`; 
   
        const response = axios({
          method: 'GET',
          url: urlApi,
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Tokens': tokenSend,
            'context-merchant': getMerchant()?.id === '' ? merch?.id : getMerchant()?.id,
            'access-control-allow-origin': '*',
            'access-control-expose-headers' : '*'
          },
          responseType: 'blob'
        });

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    {
      onSuccess: (response) => {
        setLoading(false);
        setOpen(false);
        createNotification('success', `Se enviara un mail con el reporte de ${date.from} hasta ${date.until}`);
      },
      onError: async (err) => { 
        const responseData = new Blob([err.response.data]);
        const message = await readBlobAsText(responseData);
        if(message){
          createNotification('error', message.msg);
          setOpen(false);
          setLoading(false);
          return;
        }
        createNotification('error', 'Hubo un error');
        setLoading(false);
      },
      enabled: false,
      staleTime: 1
    }
  );
  console.log(loading);
  return(
    <>
      <Box style={{alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'center'}}>

        <Button
          onClick={() => {
            if((getMerchant()?.id !== '') && (url === 'socios_activos_pro')) return refetch();
            return setOpen(true);
          }
          } 
          disabled={loading}
          color={'secondary'}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px'}}
          variant={'outlined'}
        >
          {loading ? <CircularProgress style={{color: '#606060', width: '1.5rem', height: '1.5rem'}}/> : title}
        </Button>
      </Box>
      <ModalDateCustom
        title={title}
        onClick={refetch}
        open={open}
        date={date}
        setDate={setDate}
        setOpen={setOpen}
        filter={merch}
        tooltip={tooltip}
        setFilter={setMerch}
        onClose={() => setOpen(false)}
        viewDate={noDate ? false : true}
        viewMerch={getMerchant()?.id === ''}
        multipleMerchs={false}
        loading={loading}
      />
    </>
  );
};

export default StatusSocioModal;