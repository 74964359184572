export const mockedBoletas = [
  {key: 'local', label: 'Sin split'},
  {key: 'multinegocio', label: 'Con split'}
];
export const mockedStatus = [
  {key: 'approved', label: 'Aprobado'},
  {key: 'rejected', label: 'Rechazado'},
  {key: 'error', label: 'Error'},
  {key: 'in_process', label: 'En Proceso'},
  {key: 'refunded', label: 'Reembolso'},
  {key: 'charged_back', label: 'Contracargo'},
  {key: 'others', label: 'Otros'},
];
export const mockedMetodos = [
  {key: 'debit_card', label: 'Tarjeta de Débito'},
  {key: 'credit_card', label: 'Tarjeta de Crédito'},
  {key: 'cash', label: 'Efectivo'},
  {key: 'link', label: 'Link de pago'},
  {key: 'terminal', label: 'Terminal'},
  // {key: 'otros', label: 'Otros'}
];
export const mockedOrigenes = [
  {key: [
    'recurring_charges',
    'recurring_manual',
    'recurring_miclub',
    'recurring_migration'
  ], label: 'Recurrente'},
  {key: [
    'checkout',
    'checkout3',
    'checkout_miclub',
  ], label: 'Checkout'},
  {key: 'cobro_por_uso', label: 'Cobro por uso'},
  {key: 'cobros_adicionales', label: 'Cobros Adicionales'},
  {key: 'others', label: 'Otros'},
];
export const mockedStatusTries = [
  {key: 'approved', label: 'Aprobado'},
  {key: 'rejected', label: 'Rechazado'},
  {key: 'error', label: 'Error'},
  {key: 'in_process', label: 'En Proceso'},
  {key: 'refunded', label: 'Reembolso'},
  {key: 'charged_back', label: 'Contracargo'},
];
export const mockedPagos = [
  {key: 'mercadopago', label: 'Mercado Pago'},
  {key: 'decidir', label: 'Decidir'},
  {key: 'cash', label: 'Efectivo'},
  // {key: 'otros', label: 'Otros'}
];
