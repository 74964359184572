import { useDataProvider } from 'react-admin';
import { merchantContext } from '../../DataProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';


export const useAccessRequests = (date) => {

  const dataProvider = useDataProvider();
  const { getMerchant } = useContext(merchantContext);
  const period = `${moment(date.from).format('M')}/${moment(date.until).format('YYYY')}`;
  
  const fetchAccessHandler = ({queryKey}) => dataProvider.getFile(`${queryKey[0]}?filter=${JSON.stringify({'period': period})}`);
  
  const responseOptions = {
    onError: (err) => {throw new Error(err);},
    enabled: getMerchant()?.id === '' ? false : true
  };

  const {  data: { data: { json: accesosMns} = {}} = {}, isSuccess,  isFetching: isFetchingAccesos, isError: errorAccesos } = useQuery(
    ['dashboard_accesos', getMerchant()?.id, date], fetchAccessHandler, responseOptions
  );


  return {
    accesosMns,
    isSuccess,
    isFetchingAccesos,
    errorAccesos,
  };
};

export const useVentasRequests = (date) => {

  const dataProvider = useDataProvider();
  const { getMerchant } = useContext(merchantContext);

  const fetchHandler = ({queryKey}) => dataProvider.getFile(`${queryKey[0]}?filter=${JSON.stringify({ 'from': date.from, 'until': date.until, merchant: queryKey[1] })}`);
  const responseOptions = {
    onError: (err) => {throw new Error(err);},
    enabled: getMerchant()?.id === '' ? false : true
  };

  const { data: ventasMensuales, isFetching: isFetchingVentas, error: errorVentas, isFetched: isFetchedVentas, isSuccess } = useQuery(
    ['dashboard_cobros', getMerchant().id, date], fetchHandler, responseOptions
  );

  const { data: cobrosAnuales, isFetching: isFetchingAnuales, error: errorAnuales } = useQuery(
    ['dashboard_cobros_anuales', getMerchant().id, date], fetchHandler, responseOptions
  );

  const { data: cobrosMensuales, isFetching: isFetchingMensuales, isSuccess: isSuccessAccesos, isFetched: isFetchedAccesos } = useQuery(
    ['dashboard_cobros_mensual', getMerchant().id, date], fetchHandler, responseOptions
  );

  const { data: rechazosAnuales, isFetching: isFetchingRechazos, error: errorRechazos } = useQuery(
    ['dashboard_rechazos', getMerchant().id, date], fetchHandler, responseOptions
  );

  return {
    ventasMensuales, 
    isFetchingVentas,
    errorVentas,
    isFetchedVentas,
    isSuccess,
    cobrosAnuales,
    isFetchingAnuales,
    errorAnuales,
    isFetchingMensuales,
    isSuccessAccesos,
    isFetchedAccesos,
    cobrosMensuales,
    rechazosAnuales,
    isFetchingRechazos,
    errorRechazos
  };
};

export const usePeriodRequest = () => {
  const dataProvider = useDataProvider();
  const params = {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: 'name', order: 'ASC' },
    filter: {'type':'cobros_x_dia'}
  };
  const { data } = useQuery(['distinct/dashboard/period'], () => dataProvider.getList('distinct/dashboard/period', params));
  return data;
};
