import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AutocompleteInput, maxLength, maxValue, minValue, required, useDataProvider, useGetOne, useRecordContext } from 'react-admin';
import { AutoCompleteSmall, BooleanInputSmall } from '../../../Components/CustomFields/InputSmall';
import { getListProvider, getPermissions } from '../../helpers/helpFunctions';
import { createNotification } from '../../helpers/helpFunctions';
import { useQuery } from 'react-query';
import SectionForm from '../../../Components/Form/SectionForm';
import { FormControlLabel, Switch, TextField, Autocomplete, Skeleton, Box } from '@mui/material';
import moment from 'moment';
import { apiUrl, merchantCadena, merchantContext, merchantsInToken } from '../../../DataProvider';
import axios from 'axios';

const planEdit = (slugValue, editPrice, setEditHaberes, plan, selectType, selectedMerch) => {

  const { getMerchant } = useContext(merchantContext);
  const permis = getPermissions();
  const record = useRecordContext();
  const isEdit = record ? true : false;
  const [choicesPlanes, setChoicesPlanes] = useState(null);
  const [planSelected, setPlanSelected] = useState(null);
  const [switchMulti, setSwitchMulti] = useState(
    plan ? plan?.['tipo-de-plan'] :
      selectType === 'local' ?
        {name: 'local'} : 
        ['cadena-sede', 'cadena-habilitadas'].includes(selectType) ?
          {name: 'cadena'} : 
          selectType === 'central' ? {name: 'central'} :
            getMerchant()?.id === merchantCadena ? {name: 'cadena'} :
              getMerchant()?.id !== merchantCadena && getMerchant()?.negocio_central ? {name: 'central'} :
                {name: 'local'}
  );
  const [precioNativo, setPrecioNativo] = useState(null);
  const [precioEmpleado, setPrecioEmpleado] = useState(null);
  const [precioFamiliar, setPrecioFamiliar] = useState(null);
  const [planes, setPlanes] = useState(null);
  const [apertura, setApertura] = useState(new Date().toISOString().slice(0, 10));
  const [activos, setActivos] = useState(null);
  const [preventa, setPreventa] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const discountsEdit = {
    employeeDiscount: record?.['porcentaje-descuento-empleado'] ? record['porcentaje-descuento-empleado'] : 0,
    familyDiscount:  record?.['porcentaje-descuento-familiar'] ? record['porcentaje-descuento-familiar'] : 0,
  };
  const { data: merch } = useGetOne('merchants', {id: record?.merchant_id}, {enabled: false});
  const isOwner = getMerchant()?.id === '';

  const isAdmin = !getMerchant()?.negocio_central && Boolean(getMerchant()?.sede_principal);

  const negocioSede = isOwner ? (!merch?.negocio_central && Boolean(merch?.sede_principal)) : isAdmin;
  
  const [discount, setDiscount] = useState(isEdit ? discountsEdit : { employeeDiscount: 0,familyDiscount: 0 });
  const [discountPrice, setDiscountPrice] = useState(null);
  const [showPlan, setShowPlan] = useState(record?.['tiene-planes-familiares-2'] ?? record?.['tiene-planes-familiares2']);
  const [numberSocios, setNumberSocios] = useState(null);
  
  const dataProvider = useDataProvider();
  const handlerPlan = (e) => {
    const planSelect = choicesPlanes?.filter(item => item?.id === e)[0];
    setPlanSelected(planSelect);
    setPrecioNativo(planSelect ? (planSelect['precio-formateado'] ? 
      planSelect['precio-formateado'] : '$' + planSelect?.price) :
      record['precio-plan-nativo']);
    setPrecioEmpleado(planSelect ? 
      (planSelect?.price - ((planSelect?.price * discount.employeeDiscount) / 100)) :
      record['precio-empleado']);
    setPrecioFamiliar(planSelect ? 
      (planSelect?.price - ((planSelect?.price * discount.familyDiscount) / 100)) :
      record['precio-familiar']);
  };

  useEffect(() => {
    
    record?.['porcentaje-descuento-empleado'] && planes && 
      setPrecioEmpleado(
        Math.floor(
          planes?.find(item => 
            item?.id === record?.plan_id && item)?.price - 
            (Math.floor((record?.['porcentaje-descuento-empleado'] * 
            planes?.find(item => item?.id === record?.plan_id && item)?.price) / 100))));
    
    record?.['porcentaje-descuento-familiar'] && planes && 
    setPrecioFamiliar(Math.floor(planes?.find(item => item?.id === record?.plan_id && item)?.price - ((record['porcentaje-descuento-familiar'] * planes?.find(item => item?.id === record?.plan_id && item)?.price) / 100)));

  },[record, planes]);

  const successPlanes = (data) => {
    setChoicesPlanes(data);
    if(isEdit){
      let planSel = data.filter(item => item?.id == record?.plan_id);
      setPlanSelected(planSel[0]);
      setDiscountPrice({employeePrice: (planSel?.[0]?.price - ((planSel?.[0]?.price * discount?.employeeDiscount) / 100)), familyPrice: (planSel?.[0]?.price - ((planSel?.[0]?.price * discount.familyDiscount) / 100))});

    }
  };


  const sociosParams = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {
      plan_corporativo: record?.id,
    }
  };

  record?.id && useQuery(
    ['nro_socios'],
    () => dataProvider.getList('socios', sociosParams),
    {
      onSuccess: ({data}) => setNumberSocios(data.length)
    }
  );
  
  useEffect(() => {
    if(discount.employeeDiscount === 100) {
      setEditHaberes(true);
    } else {
      setEditHaberes(false);
    }
  },[discount]);

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {
      archivado: false,
    }
  };

  const planFilter = useMemo(() => {
    if (switchMulti.name === 'local') return Object.fromEntries([['tipo-de-plan', 'local']]);
    if (switchMulti.name === 'cadena') return Object.fromEntries([['tipo-de-plan', 'cadena']]);
    if (switchMulti.name === 'central') return Object.fromEntries([['tipo-de-plan', 'central']]);
  },[switchMulti, selectType]);

  const planesGetList = async (filters, merchantId) => {

    let merchant = merchantsInToken.find((item) => item?.id === merchantId);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    let headers = {
      'Content-Type': 'application/json',
      'X-Access-Tokens': tokenSend,
      'access-control-allow-origin': '*',
      'Context-Merchant': merchant?.id ?? getMerchant()?.id
    };

    return axios({
      method: 'GET',
      headers: headers,
      url: `${apiUrl}/planes?filter=${JSON.stringify(filters)}&range=[0,9999]&sort=["id","ASC"]`
    });
  };
  
  const {refetch} = useQuery(['planes', record, plan,], () => {
    setIsFetching(true); // No usamos el isFetching de useQuery por que no siempre anda bien.
    // return dataProvider.getList('planes', {...params, filter: {...planFilter, ...params.filter}});
    return planesGetList({...planFilter, ...params.filter}, selectedMerch);
  },
  {
    onSuccess: ({data : {data} = []}) => {
      setIsFetching(false);
      const allPlanes = [...data];
      const found = allPlanes?.find(item => item?.id === plan?.id);
      if(!found) {
        setPlanes(plan ? [...allPlanes, plan] : [...allPlanes]);
        successPlanes(plan ? [...allPlanes, plan] : [...allPlanes]);
        return;
      }
      setPlanes(allPlanes);
      successPlanes(allPlanes);
      if(isEdit) {
        let found = allPlanes?.find(item => item.id === record?.plan_id);
        setActivos(found?.socios > 0);
      }
    },
    onError: ({err}) => {
      setIsFetching(false);
      throw new Error(err);
    }
  }
  );
  useEffect(() => {
    if(selectType){
      selectType === 'local' ?
        setSwitchMulti(Object.fromEntries([['name', 'local']])) : 
        ['cadena-sede', 'cadena-habilitadas'].includes(selectType) ?
          setSwitchMulti(Object.fromEntries([['name', 'cadena']])) : 
          setSwitchMulti(Object.fromEntries([['name', 'central']]));
    }
  },[selectType]);

  useEffect(() => {
    refetch();
  }, [switchMulti, plan, selectedMerch]);

  const handlePriceEmploye = (e) => {
    // let value = decimalFunction(Number(e.target.value));
    let value = e.target.value;

    if(!value) value = 0;
    if(value >= 0 && value <= 100){
      setDiscount({...discount, employeeDiscount: value});
      setDiscountPrice({...discountPrice, employeePrice: (planSelected?.price - ((planSelected?.price * value) / 100))});
      setPrecioEmpleado(planSelected ? 
        (planSelected.price - ((planSelected.price *value) / 100)) :
        record['precio-empleado']);
    }
  };

  const decimalFunction = (e) => {
    return Math.round(e * 100) / 100;
  };
  
  const handleDiscountFamily = (e) => {
    // let value = decimalFunction(Number(e.target.value));
    let value = e.target.value;
    if(!value) value = 0;
    if(value >= 0 && value <= 100){
      setDiscount({...discount, familyDiscount: value});
      setDiscountPrice({...discountPrice, familyPrice: (planSelected.price - ((planSelected.price * value) / 100))});
      setPrecioFamiliar(planSelected ? 
        (planSelected.price - ((planSelected.price * value) / 100)) :
        record['precio-familiar']);
    }
  };

  const planesFilter = useMemo(() => {
    
    switch (true) {
    case ['cadena-habilitadas', 'cadena-sede'].includes(selectType):
      return [{ name: 'cadena' }];
  
    case selectType === 'central' && getMerchant()?.id === '' :
      return [{ name: 'central' }, { name: 'cadena' }];

    case selectType === 'central' && getMerchant()?.id === merchantCadena:
      return [{ name: 'cadena' }];

    case selectType === 'central' && getMerchant()?.negocio_central && getMerchant()?.id !== merchantCadena:
      return [{ name: 'cadena' }];
  
    case selectType === 'local':
      return [{ name: 'local' }];
  
    default:
      return [];
    }
  }, [selectType]);

  const data = [
    planesFilter?.length > 1 && { 
      source: '',
      type: 'radioSelect',
      label: 'Filtro de planes',
      onClick: (e) => setSwitchMulti(planesFilter[e]),
      disabled: planesFilter?.length <= 1,
      choices: planesFilter,
      xs: 12
    },
    planes?.length > 0 && !isFetching ? {
      source: 'plan_id',
      type: 'autocomplete',
      choices: planes,
      optionLabel: 'name',
      onChange: (e) => handlerPlan(e),
      initialValue: isEdit ? planes.find(item => item?.id === record?.plan_id && item) : null,
      label: 'Plan',
      validate: { required: 'El campo del plan es requerido'},
      // disabled: activos,
      tooltip: `${activos ? 'No se puede cambiar el plan nativo de un plan con socios activos' : ''}`,
      placement: 'top',
      confirmation: record?.plan_id !== planSelected?.id ? true : false,
      confirmationMessage: `Al cambiar el plan de la campaña, se veran afectados los planes nativos de ${numberSocios} socios`,
      xs: 12
    } : {
      custom: true,
      component: <Skeleton height="50px"/>,
      xs: 12
    },
    {
      source: 'precio-plan-nativo',
      value: precioNativo,
      displayNone: true,
    },
    {
      source: 'preventa',
      type: 'boolean',
      label: 'Preventa',
      disabled: record,
      labelPlacement: 'start',
      style: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      value: preventa,
      onChange: () => setPreventa(!preventa),
      xs: 6,
    },
    preventa ? {
      source: 'fecha_apertura',
      type: 'date',
      value: apertura,
      disabled: record,
      onChange: (e) => setApertura(e.target.value),
      validate: {
        required: 'La fecha de apertura es obligatoria',
        validate: {
          lessThan: (e) => moment(new Date(e).toISOString().slice(0, 10)).isBefore(new Date().toISOString().slice(0,10)) ? 'La fecha de apertura no puede ser menor a hoy.' : undefined
        }
      },
      label: 'Fecha de apertura',
      xs: 6,
    } : {
      component: <Box />,
      custom: true,
      xs: 6
    },
    {
      source: 'porcentaje-descuento-empleado',
      label: 'Porcentaje de Descuento',
      disabled: !planes  || !planSelected || editPrice,
      validate: {
        min: 0, 
        max: {
          value: 100,
          message: 'El máximo porcentaje aplicable es del 100%'
        },
        pattern: {
          value: /^\d+(\.\d+)?$/,
          message: 'El campo de porcentaje sólo puede contener números.'
        }
      },
      onChange: handlePriceEmploye,
      initialValue: discount?.employeeDiscount,
      // defaultValue: discount?.employeeDiscount,
      // value: discount?.employeeDiscount,
      toNumber: true,
      type: 'tel',
      tooltip: editPrice ? 'No se puede editar el descuento del empleado si esta habilitado el descuento por haberes' : '',
      placement: 'bottom',
      xs: 12
    },
    {
      source: 'precio-empleado',
      label: 'Precio por Empleado',
      value: precioEmpleado,
      disabled: true,
      xs: 6
    },
    
    {
      source: 'plan_id',
      validate: {required: 'Falta el campo Plan'},
      style: {display: 'none'},
      xs: 12
    },
    !negocioSede ? {
      source:'tiene-planes-familiares-2',
      label: 'Planes Familiares',
      type: 'boolean',
      labelPlacement: 'start',
      style: {
        display: 'row',
        justifyContent: 'flex-end'
      },
      defaultChecked: showPlan,
      onChange: () => setShowPlan(!showPlan),
      xs: 12
    } : null,
    showPlan ? { 
      source: 'porcentaje-descuento-familiar',
      label: 'Descuento Familiar',
      onChange: handleDiscountFamily,
      validate: {
        min: 0, 
        max: {
          value: 100,
          message: 'El máximo porcentaje aplicable es del 100%'
        },
        pattern: {
          value: /^\d+(\.\d+)?$/,
          message: 'El campo de porcentaje sólo puede contener números.'
        }
      },
      initialValue: discount.familyDiscount,
      // value: discount.familyDiscount,
      type: 'tel',
      toNumber: true,
      xs: 12
    } : null,
   
    showPlan ? {
      source: 'precio-familiar',
      label: 'Precio Familiar',
      value: precioFamiliar,
      disabled: true,
      xs: 6
    } : null,
    showPlan ? {
      source: 'campana-familiar',
      label: 'Link Checkout Familiar',
      xs: 6
    } : null
  ];

  return data;
};

export default planEdit;