import React, {useEffect, useState} from 'react';
import { Box } from '@mui/material';
import CardContent from './Card/CardContent';
import CardDashboard from './Card/CardDashboard';


const CobrosCard = ({cobrosAnuales, isFetchingAnuales, date}) => {

  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    let data = cobrosAnuales?.data?.json.data;
    const newAltasArray = [];
    const newAltasArray2 = [];
    const newRecurrentesArray = [];
    const newRecurrentesArray2 = [];
    const sortedArray = [];
    const periodArr = [];
    
    data && Object.keys(data?.altas).map(e => {
      let month = e.split('/');
      sortedArray.push(month[0]);
      
    });
    const newArraySorted = sortedArray.sort((a, b) => {
      
      if(Number(b) > Number(a)) {
        return -1;
      } else {
        return 1;
      }
    }).reverse();
    newArraySorted.map(e => {
      let newDate = new Date();
      let actualMonth = new Date().getMonth() + 1;
      if(e <= actualMonth) {
        periodArr.push(e + '/' + newDate.getFullYear());
      } else {
        periodArr.push(e + '/' + (newDate.getFullYear() - 1));
      }
    });
    periodArr.sort((a,b) => {
      if(Number(a.split('/')[1]) > Number(b.split('/')[1])){
        return -1;
      } else {
        return 1;
      }
    } );
    
    data && periodArr
      .forEach(e => {
        let montoTotal = 0;
        data?.altas[e]?.map(r => {
          montoTotal = montoTotal + r?.monto;
        });
        newAltasArray.push({
          key: e,
          value: montoTotal
        });
      });
    data && periodArr
      .forEach(e => {
        let montoTotal = 0;
        data?.recurrentes[e]?.map(r => {
          montoTotal = montoTotal + r?.monto;
        });
        newRecurrentesArray.push({
          key: e,
          value: montoTotal
        });
      });
    setDatasets({chart: {altas: newAltasArray, recurrentes: newRecurrentesArray}});
  },[cobrosAnuales]);

  return (
    <>
      <Box sx={{ width: '100%'}}>
        <Box 
          sx={{
            display: 'flex', 
            flexDirection: 'column', 
            width: {xs: '100%'},
            height: 'auto', 
            justifyContent: 'space-between'
          }}
        >
          <CardDashboard noPad>
            <CardContent date={date} isFetching={isFetchingAnuales} name={'Cobros Anuales'} data noCard cobros datasets={datasets} chartBar cobrosReporte />
          </CardDashboard>
        </Box>
      </Box>
    </>
  );
};

export default CobrosCard;