import { Button } from '@mui/material';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { BsFileMedical } from 'react-icons/bs';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { dateFormat } from '../../helpers/helpFunctions';
import aptoMedicoSocio from '../SectionsEdit/AptoMedicoSocio';
import { greaterThan } from '../../Busqueda/SearchComponents';
import bucketDownloader from '../../helpers/bucketDownloader';
import Close  from '../../../Assets/Images/close.png';
import Tick  from '../../../Assets/Images/tick.png';
import moment from 'moment';

const emptyBoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0'
};

const spanPayment = {
  fontWeight: '500',
  color: '#787878',
  fontSize: '13px',
};

const AptoMedicoCard = () => {
  const record = useRecordContext();

  const editComponent = {
    component: aptoMedicoSocio(),
    label: 'Apto Medico',
    icon: <BsFileMedical/>
  };

  const handleClick = () => {
    
    // si la url coincide con la nueva url https://%{process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/ + lo que siga hacer un bucketdownloader de lo contrario un href a recordaptomedicourl
    //ESTO ES TEMPORAL HASTA QUE SE MIGREN EL TODOS LOS APTO MEDICOS AL ACTUAL BUCKET
    if(record?.apto_medico?.url?.startsWith(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`)) {
    bucketDownloader(record?.apto_medico?.url);
  } else {
    window.open(record?.apto_medico?.url, '_blank');
  }
  };




  return (
    <CardBasic 
      route={'socios'} 
      record={record} 
      editComponent={editComponent} 
      title='Apto Medico'
    >
      { record && 
        record?.apto_medico ? (
          <Box style={{display: 'flex', gap: '30px', alignItems: 'center'}}>
            {
              record?.apto_medico?.url?.length > 0 ? (
                <Button onClick={handleClick}  variant='contained' size='small' color='primary'>Ver Apto Medico</Button>
              ) : (
                <Button disabled target='_blank' variant='contained' size='small' color='primary'>Sin Apto Medico</Button>
              )
            }
            <Typography style={{fontSize: '13px', fontWeight: '600',}}>
              Fecha de Vigencia: <span style={spanPayment}>{dateFormat(moment(record.apto_medico.fecha_vigencia).utc().format('YYYY-MM-DD'))}</span>
            </Typography> 
            {!greaterThan(record.apto_medico.fecha_vigencia) ? 
              <img src={Close} style={{width: '13px', marginRight: '15px'}} /> : <img src={Tick} style={{width: '13px', marginRight: '15px'}} /> }
    
          </Box>
        ) : (
          <Box style={emptyBoxStyle}>
            <Typography variant='subtitle2'>
              No se encuentra el apto medico para este socio
            </Typography>
          </Box>
        )
      }
    </CardBasic>
  );
};

export default AptoMedicoCard;