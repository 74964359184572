import { useContext, useMemo, useState } from 'react';
import { merchantContext } from '../../../DataProvider';
import { Box, Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import { useRecordContext } from 'react-admin';
import { AiOutlineDownload } from 'react-icons/ai';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { RowActions } from './RowActions';
import moment from 'moment';

export const styleTableHead = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '1.3',
  color: '#6470B1',
  border: 'none',
  justifySelf: 'end',
  alignSelf: 'end',
  whiteSpace: 'nowrap',
  width: 'auto'
};



const exporter = (data) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const headerxls = [[
    'Id',
    'Concepto',
    'Creado por',
    'Fecha de Compra',
    'Documento',
    'Vencimiento',
    'Id de la factura',
    'Negocio',
    'Id de Nota de crédito',
    'Precio',
    'Estado'
  ]];

  const arrayxls = data?.map(e => ([
    e.id,
    e.concepto,
    e.created_by,
    e.date_created,
    e.documento,
    e.expiration_date,
    e.factura_id,
    e.merchant_name,
    e.nota_credito_id,
    e.price,
    e.status
  ]));
        
  const totalArray = headerxls.concat(arrayxls);
  const workSheet = XLSX.utils.aoa_to_sheet(totalArray);
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ['data'],
  };
  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, 'Cobros Adicionales' + fileExtension);
  // });
};

export const CobrosDataGrid = ({refetchCobros, redirect, adicionales}) => {
  const { getMerchant } = useContext(merchantContext);
  const [page, setPage] = useState(1);
  const newPages = useMemo(() => {
    return Array.from({length: Math.ceil(adicionales.length / 5)}, (_, index) => (
      Array.from({ length: 5 }, (_, subIndex) => (
        adicionales[index * 5 + subIndex]
      ))
    ));
  }, [adicionales]);
  
  const aditionalPage = useMemo(() => {
    return newPages[page-1];
  },[page]);
  

  return(
    <>
      {
        aditionalPage && (
          <Box sx={{height: '375px', width: '100%'}}>
            <TableContainer component={Paper} style={{boxShadow: 'none', overflowX: 'hidden'}}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead, maxWidth: '40px'}}>Concepto</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '30px'}}>Precio</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '30px'}}>F. Compra</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '30px'}}>Vencimiento</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '30px'}}>Estado</TableCell>
                    {getMerchant()?.id === '' &&  <TableCell style={{...styleTableHead}}>Negocio</TableCell>}
                    <TableCell style={{...styleTableHead, maxWidth: '40px'}}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aditionalPage && aditionalPage.map((row, index) => {
                    if(row) {
                      return (
                        <Row 
                          key={row.id} 
                          row={row}
                          index={index}
                          refetchCobros={refetchCobros}
                        />
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              component="div"
              count={Math.ceil(adicionales.length / 5)}
              page={page}
              onChange={(event, value) => {
                console.log('VALUE', value);
                return setPage(value);
              }}
            />
            <Box sx={{
              position: 'relative',
              width: '100%', borderTop: '1px solid black'}}>
              <DownloadCSV adicionales={adicionales} />
            </Box>
          </Box>
        )
      }
    </>
  );
};

export const Row = ({row, index, refetchCobros}) => {

  const { getMerchant } = useContext(merchantContext);
  const styleTabbleData = {
    padding: '0 0 8px 18px',
    color: '#64648C',
    border: 'none',
    zIndex: 9999999
  };
  
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
      background: '#efefef',
      cursor: 'pointer',
      overflowX: 'hidden'
    }, }}>
      <>
        <TableCell style={{...styleTabbleData, maxWidth: '100px', padding: '0 0 0 10px'}}>{row.concepto}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>$ {row.price}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{moment(row.date_created).format('DD/MM/YYYY')}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{moment(row.expiration_date).format('DD/MM/YYYY')}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.status}</TableCell>
        {getMerchant()?.id === '' && <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.merchant_name}</TableCell>}
        <TableCell 
          style={{ 
            ...styleTabbleData,
            display: 'flex',
            flexDirection: 'column',
            height: '42px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 0 0 10px'
          }}
        >
          <RowActions refetchCobros={refetchCobros} item={row} key={index} />
        </TableCell>
      </>
    </TableRow>
  );
};
  
export const DownloadCSV = ({adicionales}) => {


  return(
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '10px',
        position: 'relative'
      }}
    >
      <Button variant="none" sx={{marginRight: '30px'}} onClick={() => exporter(adicionales)}><AiOutlineDownload size="20px" /></Button>
  
    </Box>
  );
};
  
  
  