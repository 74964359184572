
import React from 'react';
import { useRecordContext } from 'react-admin';
import { RiFilePaper2Line } from 'react-icons/ri';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import tyCEdit from '../SectionsEdit/TyCEdit';

const TyCCard = ({isAbleToEdit}) => {
  const record = useRecordContext();
  const isMulti = record?.['tipo-de-plan'] === 'cadena';
  const data = [
    {key: 'Ver PDF', value: record?.tyc, link: true},
  ];

  const merchant = record?.merchant_id;

  const editComponent = {
    component: tyCEdit(record, merchant),
    label: 'Términos y Condiciones',
    icon: <RiFilePaper2Line/>
  };

  

  return (
    <CardBasic route={isMulti ? 'multinegocio' : 'planes'} title='Terminos y Condiciones' record={record} editComponent={isAbleToEdit && editComponent} collapsed>
      <TableMinimal widthBoxKey='300px' data={record.tyc ? data : []} emptyText={'No se encuentran cargados los terminos y condiciones'}/>
    </CardBasic>
  );
};

export default TyCCard;