import { useEffect, useState } from 'react';

export const useAbsolute = (index) => {
  const [fixedPosition, setFixedPosition] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const parent = document.getElementById(index);
      const parentRect = parent?.getBoundingClientRect();
      if(parentRect.top <= 0) {
        setFixedPosition(true);
      } else {
        setFixedPosition(false);
      }
    };
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, []);
  return fixedPosition;
};