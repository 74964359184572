import { Box } from '@mui/material';
import React from 'react';

const dotStyle = {
  backgroundColor: '#64648c',
  borderRadius: '50%',
  width: '4px',
  height: '4px',
  alignSelf: 'center',
};

const DotDivider = ({margin = '0 13px'}) => {
  return <Box style={{...dotStyle, margin}}></Box>;
};

export default DotDivider;
