import { useDataProvider, useRecordContext, useRedirect } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { useQuery } from 'react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { theme } from '../../../Theme/theme';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl, merchantContext } from '../../../DataProvider';
import LinkIcon from '@mui/icons-material/Link';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AiOutlineDownload } from 'react-icons/ai';
import { getPermissions, isUserCentral, isUserSC } from '../../helpers/helpFunctions';



const CorporativosCard = () => {
  const[corporativos, setCorporativos] = useState(null);
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const permis = getPermissions();
  const {getMerchant} = useContext(merchantContext);
  useEffect(() => refetch && refetch(), []);

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {plan_id: record?.id}
  };
  
  const { data, isFetching, refetch} = useQuery(['campanas'], async () => {
    const response = await dataProvider.getList('corporativo', params);
    return response;
  }, {
    onSuccess: ({data}) => {
      setCorporativos(data);
    },
    enabled: false
  });
  const handleSearch = (e) => {
    if(!corporativos) return;
    let value = e.target.value.toUpperCase();
    let arr = [];
    if(value === '' || !value) {
      return setCorporativos(data.data);
    }
    corporativos.map((item,i) => {
      if((item.name.toUpperCase()).includes(value)) {
        arr.push(item);
      };
    });
    setCorporativos(arr);
  };


  const exporter = (e) => {
    const token = localStorage.getItem('auth').slice(10,-2);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    fetch(`${apiUrl}/corporativo?filter={"plan_id":${JSON.stringify(record?.id)}}&range=[0,999]&sort=["id","ASC"]`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': token,
          'context-merchant': getMerchant()?.id
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        const headerxls = [[
          'Nombre',
          'Archivado',
          'Descripción',
          'Socios Activos del plan'
        ]];

        const arrayxls = data.data?.map(e => ([
          e.name,
          e.archivado,
          e.descripcion,
          e.socios
        ]));
        
        const totalArray = headerxls.concat(arrayxls);
        const workSheet = XLSX.utils.aoa_to_sheet(totalArray);
        const workBook = {
          Sheets: { data: workSheet, cols: [] },
          SheetNames: ['data'],
        };
        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, 'Campañas' + fileExtension);
      });
  };

  const cols = [
    {field: 'name', headerName: 'Nombre', width: 200},
    {field: 'socios', headerName: 'Socios', width: 100},
 
    {field: 'porcentaje-descuento-empleado', headerName: '% Empleado', width: 200},
    {headerName: 'Landing', width: 100, renderCell: (params) => {
      const onClick = () => {
        const baseUrl = `${process.env.REACT_APP_SPORTCLUB_LANDING}/corporativo/${params.row.slug}`;
        window.open(baseUrl, '_blank');
      };
      return (
        <Tooltip title="Ver Landing">

          <Button onClick={onClick}>
            <LinkIcon />
          </Button>
        </Tooltip>

      );
    }
    },
    {field: 'link-del-plan', headerName: 'Checkout', width: 100, renderCell: (params) => {
      const onClick = () => {
        console.log(params);
        window.location.href = params.formattedValue;
      };
      return (
        <Tooltip title="Ver Checkout">
          <Button onClick={onClick}>
            <LinkIcon />
          </Button>
        </Tooltip>
      );
    }},
  ];

  return(
    <CardBasic
      title="Campañas del plan"
    >
      {
        data ? (
          <Box sx={{maxHeight: '650px', width: '100%'}}>
            
            <Box style={{display: 'flex', flexDirection : 'row', justifyContent:'space-between', paddingBottom: '20px'}}>
              <TextField variant="outlined" onChange={handleSearch} label="Search" />
              <Button variant="outlined" onClick={exporter}><AiOutlineDownload size="20px" /></Button>
            </Box>
            {
              corporativos?.length > 0 ? (
                <>
                  <Box sx={{height: '500px', width: '100%'}}>
                    <DataGrid
                      columns={cols} 
                      rows={corporativos}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick={isUserSC(permis) || isUserCentral(permis)}
                      onRowClick={(e) => redirect('show', 'corporativo', e.id)}
                      sx={{
                        '.MuiDataGrid-row': {
                          borderLeft: (props, e) => e?.row?.archivado ? '5px red solid' : '5px green solid'
                        },
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                          cursor: 'pointer',
                    
                        }
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box sx={{height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row'}}>
                  <Typography>No existen campañas para este plan</Typography>
                </Box>
              )
            }
          </Box>
        ) : (
          <>
            <Skeleton size={25} />
            <Skeleton size={25} />
            <Skeleton size={25} />
          </>
        )
      }
    </CardBasic>
  );
};

export default CorporativosCard;