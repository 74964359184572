import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useRecordContext } from 'ra-core';
import React from 'react';
import { GrTextAlignLeft } from 'react-icons/gr';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import descripcionEdit from '../SectionsEdit/DescripcionEdit';

const DescriptionCard = ({isAbleToEdit}) => {
  const record = useRecordContext();
  const isMulti = record?.['tipo-de-plan'] === 'cadena';
  const detailsArray = [
    {key: 'Descripción', value: record?.descripcion},
    {key: record?.['descripcion-plan'] ? 'Descripción del Plan' : null, value: record?.['descripcion-plan']},
    {key: record?.['texto-condicion-plan']  ? 'Condición del Plan': null, value: record?.['texto-condicion-plan'] },
  ];

  const editComponent = {
    
    component: descripcionEdit(),
    label: 'Descripción',
    icon: <GrTextAlignLeft/>
    
  };
  return (
    <CardBasic route={isMulti ? 'multinegocio' : 'planes'} title='Descripcion' record={record} editComponent={isAbleToEdit && editComponent}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <TableMinimal data={detailsArray} />
      </Box>
    </CardBasic>
  );
};

export default DescriptionCard;
