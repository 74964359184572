import PersonIcon from '@mui/icons-material/Person';
import UsersList from './UsersList';
import UsersCreate from './UsersCreate';
import UsersEdit from './UsersEdit';
import { FaUser } from 'react-icons/fa';
import UsersShow from './UsersShow';


export default {
  icon: FaUser,
  list: UsersList,
  create: UsersCreate,
  show: UsersShow,
};