import React from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { iconsChips } from '../../../Theme/useStyle';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import { useRecordContext } from 'react-admin';
import { brandsCustom } from '../BrandsCreateEdit';
import {  BsCardText } from 'react-icons/bs';

const BrandsHeader = () => {
  const record = useRecordContext();

  const chips = [
    {label: record?.['email_contacto'], icon: <MailOutlineOutlinedIcon style={iconsChips}/>},
    
  ];

  const editComponent = {
    component: brandsCustom(),
    label: 'Información',
    icon: <BsCardText/>
  };

  return (
    <CardBasic route="brands" editComponent={editComponent} record={record}>
      <HeaderCardShow title={record?.name} subtitle={record?.slug}  chips={chips} archived={record?.archivado}/>
    </CardBasic>
  );
};

export default BrandsHeader;