import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ModalCustom from '../ModalCustom';
import { theme } from '../../../Theme/theme';
import moment from 'moment';
import { styleTabbleData, styleTableHead } from '../../../Theme/useStyle';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { Spinner } from '../Dashboard/Spinner/Spinner';
import { useCallback, useEffect, useState } from 'react';

function setValue(total, disc) {
  if(disc?.tipo === 'porcentual') return Number((total * disc.valor) / 100);
  return disc?.value;
};

const params = {
  pagination: { page: 1, perPage: 9999 },
  sort: { field: 'id', order: 'ASC' },
  filter: {}
};

export const ModalDetail = (props) => {
  const {
    open,
    setOpen,
    row,
    record,
    lastDate
  } = props;

  const formatMoney = (value) => {
    return value?.toLocaleString('es-ar', {
      style: 'currency',
      currency: 'ARS'
    });
  };

  const dataProvider = useDataProvider();
  const descCorp = row?.charges_detail?.discounts?.find(item => item.concepto === 'descuento corporativo');
  const descMasivo = row?.charges_detail?.discounts?.find(item => item?.concepto === 'descuento masivo');
  const nativePlanPrice = row?.charges_detail?.native_plan;
  const descs = row?.charges_detail?.discounts?.filter(item => !['descuento masivo', 'descuento corporativo'].includes(item.concepto));  
  const { data: {data: plan} = {}, isFetching: isFetchingPlan} = useQuery(['plan'], () => dataProvider.getOne('planes', {id: row?.plan_id}),);
  const {data: {data: corporativo} = {}, isFetching} = useQuery(['corporativo'], () => dataProvider.getOne('corporativo', {id: row?.plan_corporativo}),{enabled: !!row?.plan_corporativo});

  const { data: accesos } = useQuery(['accesos'], () => dataProvider.getList('accesos', {...params, filter: {...params.filter, date: lastDate, documento: record?.documento}}), {enabled: !!row?.charges_detail?.access});
 
  console.log(row?.charges_detail);
  

  const handleSubTotal = (value, str) => {
    if(!value) return nativePlanPrice;
    if(str === 'masivo'){
      return handleSubTotal((nativePlanPrice - value ?? 0));
    }
    if(str === 'corporativo') {
      return handleSubTotal((nativePlanPrice - value ), 'masivo');
    }
    if(str === 'adicionales'){
      return handleSubTotal((nativePlanPrice - value ?? 0), 'corporativo');

    }
    return value;
  }; 

  const handleMoney = (value, initialValue) => {
    if(initialValue){
      if (value.tipo === 'porcentual') return (initialValue - (initialValue * value.valor) / 100);
      return value.valor;
    }
    if (value.tipo === 'porcentual') return (nativePlanPrice - (nativePlanPrice * value.valor) / 100);
    return value.valor;
  };
  
  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      title={'Detalle de Boleta'}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {
        isFetching || isFetchingPlan ? (
          <Spinner />
        ) : (
          <Box sx={{height: '375px', width: '100%'}}>
            <Box sx={{
              width: '800px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}>
              <Box>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Nombre del plan: </span>{plan?.name}</Typography>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Nombre de la campaña: </span>{corporativo?.name}</Typography>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Fecha de creación: </span>{moment(row?.date_created).format('YYYY-MM-DD - HH:mm')}</Typography>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Periodo: </span>{row?.period}</Typography>
              </Box>
              <Box>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Socio: </span>{record?.nombre + ' ' +  record?.apellido}</Typography>
                <Typography sx={{fontSize: '.8em', 'span': {color: theme.palette.primary.main}}}><span>Documento: </span>{record?.documento}</Typography>
              </Box>
            </Box>
            <TableContainer component={Paper} style={{boxShadow: 'none', overflowX: 'hidden', maxWidth: '800px'}}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow sx={{width: '100%'}}>
                    <TableCell style={{...styleTableHead, maxWidth: '100px'}}>Concepto</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '100px'}}>Descripción</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '40px'}}>Cantidad</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '40px'}}>Precio</TableCell>
                    <TableCell style={{...styleTableHead, maxWidth: '40px'}}>Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{width: '100%'}}>
                    <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>Plan</TableCell>
                    <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{plan.name}</TableCell>
                    <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>1.00</TableCell>
                    <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(row.charges_detail.native_plan)}</TableCell>
                    <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(row.charges_detail.native_plan)}</TableCell>
                  </TableRow>
                  {
                    descMasivo && (
                      <TableRow sx={{width: '100%'}}>
                        <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>Descuento por congelamiento</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '100px'}}> </TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>1.00</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleMoney(descMasivo))}</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleSubTotal( descMasivo?.valor, 'masivo'))}</TableCell>
                      </TableRow>
                    )
                  }
                  {
                    descCorp && (
                      <TableRow sx={{width: '100%'}}>
                        <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>Campaña</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{corporativo?.name} - {descCorp?.valor}%</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>1.00</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleMoney(descCorp, handleSubTotal(descMasivo?.valor, 'masivo')))}</TableCell>
                        <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleSubTotal(handleMoney(descCorp, handleSubTotal(descMasivo?.valor, 'masivo'), 'corporativo')))}</TableCell>
                      </TableRow>
                    )
                  }
                  {
                    // descs && descs?.map((item, index) => {
                    descs && Array.from({length: descs.length}, (_, index) => {

                      let total = 0;
                      const acc = Array.from({length: index + 1}, (_, i) => Math.abs(descs[i].valor));
                      let prevValue = handleSubTotal(handleMoney(descCorp, handleSubTotal(descMasivo?.valor, 'masivo'), 'corporativo'));
                      total =+ acc.reduce((a,b,i) => a + b, 0);
                      let rest = prevValue - total;

                      if(!['descuento masivo', 'descuento corporativo'].includes(descs[index].concepto)) return (
                        <TableRow sx={{width: '100%'}}>
                          <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{index <= 0 ? 'Descuentos Adicionales' : ''}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{descs[index]?.concepto}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>1.00</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleMoney(descs[index]))}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(rest)}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                  {
                    accesos && Array.from({length: accesos.length}, (_, index) => {

                      let total = 0;
                      const acc = Array.from({length: index + 1}, (_, i) => Math.abs(descs[i].valor));
                      let prevValue = handleSubTotal(handleMoney(descCorp, handleSubTotal(descMasivo?.valor, 'masivo'), 'corporativo'));
                      total =+ acc.reduce((a,b,i) => a + b, 0);
                      let rest = prevValue - total;

                      if(!['descuento masivo', 'descuento corporativo'].includes(descs[index].concepto)) return (
                        <TableRow sx={{width: '100%'}}>
                          <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{index <= 0 ? 'Accesos' : ''}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '100px'}}>{descs[index]?.concepto}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>1.00</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(handleMoney(descs[index]))}</TableCell>
                          <TableCell style={{...styleTabbleData, maxWidth: '40px'}}>{formatMoney(rest)}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Typography sx={{
                fontWeight: '600',
                fontSize:'1.3em'
              }}>Total: {formatMoney(row?.charges_detail?.final_price)}</Typography>
            </Box>
          </Box>
        )
      }
    </ModalCustom>
  );
};