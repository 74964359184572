import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Empty from '../../../Assets/Images/Empty.png';
import imageEmpty from './empty.png';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ButtonToolbar } from '../Buttons/ButtonToolbar';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const EmptyLayout = ({handleClickCreate, handleClickImport, title, subtitle, hasImport, component, canCreate}) => {
  
  return (
    <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <Box textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={Empty} style={{maxWidth: '352px', margin: '100px 0 50px 0'}}/>
        <Typography variant="h5" paragraph>
          {title}
        </Typography>
        <Typography variant="body1">
          {subtitle}
        </Typography>
        {component}
        <Box style={{marginTop: '40px', display: 'flex', gap: '40px'}}>
          { canCreate &&
          <ButtonToolbar onClick={handleClickCreate} startIcon={<AddOutlinedIcon/>} variant='text'>
            Crear
          </ButtonToolbar>
          }
          {
            hasImport && 
          <ButtonToolbar onClick={handleClickImport} variant='text' size='small' startIcon={<SaveAltOutlinedIcon/>}>
            Importar
          </ButtonToolbar>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyLayout;