import React from 'react';
import { Box } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import levelAccess from '../SectionsEdit/LevelAccess';
import { BsBookmarkStar } from 'react-icons/bs';
import { useRecordContext } from 'react-admin';
import { getPermissions } from '../../helpers/helpFunctions';


const LevelAccessCard = () => {
  const record = useRecordContext();
  const permis = getPermissions();
  
  const ubicationArray = record?.nivel_de_acceso && [
    {key: 'FlexCorporativo', value: (record['nivel_de_acceso']?.flexcorporativo || record['nivel_de_acceso']?.flexcorporativo === 0) ? `$ ${record['nivel_de_acceso']?.flexcorporativo?.toString()}` : 'denegado'},
    {key: 'Full', value: (record['nivel_de_acceso']?.full || record['nivel_de_acceso']?.full === 0) ? `$ ${record['nivel_de_acceso']?.full?.toString()}` : 'denegado'},
    {key: 'Plus', value: (record['nivel_de_acceso']?.plus || record['nivel_de_acceso']?.plus === 0) ? `$ ${record['nivel_de_acceso']?.plus?.toString()}` : 'denegado'},
    {key: 'Total', value: (record['nivel_de_acceso']?.total || record['nivel_de_acceso']?.total === 0) ? `$ ${record['nivel_de_acceso']?.total?.toString()}` : 'denegado'},
    {key: 'Elite', value: (record['nivel_de_acceso']?.elite || record['nivel_de_acceso']?.elite === 0) ? `$ ${record['nivel_de_acceso']?.elite?.toString()}` : 'denegado'},
    {key: 'Local', value: (record['nivel_de_acceso']?.local || record['nivel_de_acceso']?.local === 0) ? `$ ${record['nivel_de_acceso']?.local?.toString()}` : 'denegado'},
    // {key: 'Digital', value: (record['nivel_de_acceso']?.digital || record['nivel_de_acceso']?.digital === 0) ? `$ ${record['nivel_de_acceso']?.digital?.toString()}` : 'denegado'},
    {key: 'SocioFlex', value: (record['nivel_de_acceso']?.socioflex || record['nivel_de_acceso']?.socioflex === 0) ? `$ ${record['nivel_de_acceso']?.socioflex?.toString()}` : 'denegado'},
    {key: 'Workclub', value: (record['nivel_de_acceso']?.workclub || record['nivel_de_acceso']?.workclub === 0) ? `$ ${record['nivel_de_acceso']?.workclub?.toString()}` : 'denegado'},
  ];

  const editComponent = {
    component: levelAccess(),
    label: 'Nivel de Acceso',
    icon: <BsBookmarkStar/>
  };

  const transform = (data) => {
    let newNivel = Object.keys(data?.nivel_de_acceso).reduce((result, key, i) => {
      let item = data?.nivel_de_acceso[key];
      if (item !== 'denegado') {
        console.log('FLOAT', parseFloat(item));
        result[key] = parseFloat(item);
      }
      return result;
    }, {});

    return {
      ...data, 
      nivel_de_acceso: newNivel
    };
  };

  return (
    <CardBasic title='Nivel de Acceso' transform={transform} editComponent={permis !== 'operador' && editComponent} record={record} route={'sedes'}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <TableMinimal data={ubicationArray}/>
      </Box>
    </CardBasic>
  );
};

export default LevelAccessCard;
