import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, 
  Collapse, IconButton, Paper, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Typography, Input, Tooltip, Pagination, Skeleton, Modal, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material';
import { createNotification, getListProvider, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { useRefresh, useUpdate , useDataProvider, ImageField, useRecordContext} from 'react-admin';
import ModalCustom from './ModalCustom';
import { styleTabbleData, styleTableHead } from '../../Theme/useStyle';
import axios from 'axios';
import { apiUrl } from '../../DataProvider';
import {merchantSelected} from '../../DataProvider';
import DisabledMenu from '../../Assets/Images/DisabledMenu.png';

import MenuIcon from '@mui/icons-material/MoreHoriz';
import { ActionsDiv, MenuActionsDiv } from './Options/ActionsOptions';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { useQuery } from 'react-query';
import TableMinimal from './Show/TableMinimal';
import moment from 'moment';
import { theme } from '../../Theme/theme';
import { ModalDetail } from './ListTicketsModals/ModalDetail';
import { ModalComp } from '../../Resources/Busqueda/SearchComponents/ModalComp';

export const styleInnerBox = {
  display: 'flex',
  flexDirection: 'column', 
  justifyContent:'space-between', 
  height: '100%', 
  textAlign: 'center',
  paddingTop: '10px'
};

const origenmap = {
  checkout3: 'Checkout',
  checkout: 'Checkout',
  recurring_charges: 'Cobro Recurrente',
  cobro_por_uso: 'Cobro por uso',
  checkout_miclub: 'Alta manual',
  debi_approval: 'Debi',
  recurring_migration: 'Tarjeta',
  reccurring_manual: 'Recurrente manual',
  recurring_miclub: 'MiClub Recurrente'

};

const ListTickets = ({
  isRefetching, 
  loading, 
  refetch, 
  refresh, 
  tickets,
  page, 
  setPage,
  total, 
  acceptCash, 
  setLoading,
  plan
}) => {

  return (
    <Box style={{width: '100%'}}>
      {
        tickets?.length > 0 && (
          <>
            <TableContainer component={Paper} style={{boxShadow: 'none'}}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead, maxWidth: '80px', padding: '10px 0 10px 10px'}}>Estado</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Fecha</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Periodo</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Precio</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Comisión</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Final</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Origen</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody >
                  {tickets.map((row, index) => (
                    <Row plan={plan} loading={loading} isRefetching={isRefetching} refetch={refetch} refresh={refresh} setLoading={setLoading} key={row.id} row={row} tickets={tickets} index={index} acceptCash={acceptCash}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box style={{display: 'flex', justifyContent: 'end', marginTop: '10px'}}>
              <Pagination
                count={Math.ceil(total / 5)}
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </Box>
          </>
        )
      }
    </Box>
  );
};


const Row = ({loading, isRefetching, refresh, refetch, row, tickets, index, acceptCash, setLoading, plan}) => {
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openModal6, setOpenModal6] = useState(false);
  const [openModalMail, setOpenModalMail] = useState(false);
  const [revert, setRevert] = useState(false);
  const [actions, setActions] = useState(false);
  const [merchant, setMerchant] = useState('');
  const [sellerMerchant, setSellerMerchant] = useState('');
  const [cancelarBoleta, setCancelarBoleta] = useState(false);
  const [openComprobantes, setOpenComprobantes] = useState(false);
  const dataProvider = useDataProvider();
  const ref = useRef();
  const record = useRecordContext();

  useQuery(
    ['merchants', {id: row.merchant_id}, refresh], 
    () =>  dataProvider.getOne('merchants', {id: row.merchant_id}),
    {
      onSuccess: (data) => setMerchant(data),
      enabled: row.merchant_id ? true : false
    }
  );

  useQuery(
    ['merchants', {id: row.seller_merchant_id}, refresh], 
    () =>  dataProvider.getOne('merchants', {id: row.seller_merchant_id}),
    {
      onSuccess: (data) => setSellerMerchant(data),
      enabled: row.seller_merchant_id ? true : false
    }
  );
  
  useEffect(() => {
    // row.merchant_id && dataProvider.getOne('merchants', {id: row.merchant_id}).then(data => setMerchant(data));
    // row.seller_merchant_id && dataProvider.getOne('merchants', {id: row.seller_merchant_id}).then(data => setSellerMerchant(data));
    if(row?.merchant_id === '6178652dcfb117ad58a2cd3d' && row?.seller_merchant_id) {
      setMerchant(merchantSelected);
    }
  },[]);

  useOnClickOutside(ref, () => setActions(false));

  // Revert time management

  const today = new Date().getTime();
  const lastTry = new Date(row?.tries?.slice(-1)[0]?.payment_day).getTime();
  const difference = !isNaN(lastTry) ? today - lastTry : null;
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  const revertPay = (row.source === 'recurring_miclub' && row.status == 'approved');
  const noCheckout = (row.source !== 'checkout' && row.source !== 'checkout3');
 

  // Corregir esta función para que el último try de la boleta sea el evaluado si payment_type es igual a 'cash'
  const payCash = (row) => {
    let hasCash = false;
    row.tries?.map((item) => {
      if(item.payment_type === 'cash'){
        hasCash = true;
      }
    });
    return hasCash;
  };

  const handleActions = (e) => {
    e.stopPropagation();
    setActions(!actions);
  };

  const handleRevert = () => {
    setRevert(true);
    setOpenModal(true);
  };

  const handleCancelar = () => {
    setCancelarBoleta(true);
    setOpenModal2(true);
  };

  const handleRestaurar = (e) => {
    e.stopPropagation();
    setOpenModal5(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenModal2(false);
    setOpenModal3(false);
    setOpenModal4(false);

  };

  useEffect(() => {
    if(!isRefetching) {
      setLoading(false);
      return;
    }
  },[isRefetching]);
  
  const cobrarFactura = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/boletas/${row.id}/facturar`;
    axios({
      url: checkUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*'
      }
    }).then((data) => {
      setOpenModal3(false);
      refetch();
      createNotification('success', `${data?.data?.msg}`);
      refresh();
      !isRefetching ? setLoading(false) : null;
    }).catch((err) => {
      refresh();
      createNotification('error', `${err?.response?.data?.msg}`);
      setLoading(false);
    });
    refresh();
    // setLoading(false)
  };

  const restoreTicket = () => {
    if(row?.status === 'cancelled') {
      setLoading(true);
      dataProvider.update('editar_boleta', {data: {status: 'restored'}, id: row?.id})
        .then(res => {
          refetch();
          setOpenModal5(false);
          refresh({hard: true});
          createNotification('success', 'Boleta restaurada');
        }).catch((e) => {
          refetch();
          setOpenModal5(false);
          refresh({hard: true});
          createNotification('error', 'Hubo un error');
        });
      refresh();
    }
  };
 

  const cancelTicket = () => {
    if(row?.status === 'expired' || row?.status === 'restored') {
      setLoading(true);
      dataProvider.update('editar_boleta', {data: {status: 'cancelled'}, id: row?.id})
        .then(res => {
          refetch();
          setCancelarBoleta(true);
          setOpenModal2(false);
          refresh({hard: true});
          createNotification('success', 'Boleta cencelada');
        }).catch((e) => {
          refetch();
          setCancelarBoleta(true);
          setOpenModal2(false);
          refresh({hard: true});
          createNotification('error', 'Hubo un error');
        });
      refresh();
    }
  };


  const marcarFacturada = () => {
    if(row?.status === 'approved') {
      setLoading(true);
      dataProvider.update('marcar_facturada', { data: row, id: row?.id})
        .then(res => {
          refetch();
          setCancelarBoleta(true);
          setOpenModal2(false);
          refresh();
          createNotification('success', 'Boleta marcada como facturada.');
        }).catch((e) => {
          refetch();
          setCancelarBoleta(true);
          setOpenModal2(false);
          refresh({hard: true});
          createNotification('error', 'Hubo un error');
        });
      refresh();
    }
  };

  const refundTicket = async (mot) => {
    
    if(row?.status === 'approved') {
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      const merchantSelectedId = merchantSelected.id == '0' ? '' : merchantSelected.id;
      const checkUrl = `${apiUrl}/refund/${row.id}`;
      axios({
        method: 'PUT',
        url: checkUrl,
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Tokens': tokenSend,
          'access-control-allow-origin': '*',
          'Context-Merchant' : merchantSelectedId
        },
        data: {'motivo': mot}

      }).then(res => {
        setOpenModal4(false);
        createNotification('success', `${res.data?.msg}`);
        refresh();
      }).catch(msg => {
        setOpenModal4(false);
        createNotification('error', `${msg?.response?.data.msg}`);
        refresh();

      });
    }
    
  };
  const sameMerchant = (merchant?.data?.id !== sellerMerchant?.data?.id);
  // Validations for actions

  /*
Menú de acciones posibles para las boletas en el show de socios buen club y las lógicas necesarias.

Merchant: Negocio de la boleta


Facturar: 
Es necesario que el negocio o el negocio vendedor de la boleta tengan un cuit válido.
La boleta no debe tener factura existente.
El status de la boleta debe ser ‘approved’
el final_price de la boleta debe ser mayor a 0.

Nota de crédito: 
Es necesario que el negocio o el negocio vendedor de la boleta tengan un cuit válido.
La boleta no debe tener una nota de crédito existente.
La boleta debe tener una factura.
El status de la boleta debe ser ‘refunded’ o ‘charged_back’

Ver Factura:
Tener una factura existente.

Cobrar boleta:
El status de la boleta debe ser alguno de los siguientes: ‘rejected’, ‘error’, ‘charged_back’, ‘pending_efectivo’, ‘expired’.
El origen de la boleta no debe ser de ‘checkout3’.
El plan al cual la boleta apunta debe aceptar como método de pago efectivo.
No debe tener factura existente.

Revertir pago:
El origen de la boleta debe ser igual a “recurring_miclub” y status debe ser ‘approved’ ó  si el último try de la boleta tiene payment_type igual a ‘cash’ (Está lógica no está así en el front, corroborar para corregir). 
El origen de la boleta no debe ser de ‘checkout3’.
La diferencia de días entre hoy y la fecha del último try de la boleta debe ser menor o igual a 30 días.
el status de la boleta debe ser distinto a ‘cancelled’

Cancelar boleta:
el status de la boleta debe ser ‘expired’ o ‘restored’

Refund:
Status de la boleta debe ser ‘approved’
El payment_id del último try de la boleta debe ser distinto a 11 (osea un pago en efectivo).
El origen debe ser distinto a ‘recurring_miclub’
El final price de la boleta debe ser mayor a 0

Estado del mail
Debe existir una factura para la boleta.
  */

  const valFacturar = (merchant?.data?.afip?.cuit || sellerMerchant?.data?.afip?.cuit) && (!row?.data_facturacion?.factura_id || row?.data_facturacion?.nota_credito_id) && !row?.data_facturacion?.excluded && row?.status === 'approved' && row?.charges_detail?.final_price > 0;

  const valNotaCred = ((merchant?.data?.afip?.cuit || sellerMerchant?.data?.afip?.cuit) && !row?.data_facturacion?.nota_credito_id && row?.data_facturacion?.factura_id && !row?.data_facturacion?.excluded);

  const valFactura = row?.data_facturacion?.factura_id;

  const valCobrar = ((row.status == 'rejected' || row.status == 'error' || row.status == 'charged_back' || row.status == 'pending_efectivo' || row.status == 'expired' || row.status == 'restored') && noCheckout && acceptCash && !row?.data_facturacion?.factura_id);

  const valRevertir = ((revertPay || (payCash(row))) && index == 0 && noCheckout && totalDays <= 30 && row?.status !== 'cancelled');

  const valCancelar = row.status == 'expired' || row.status == 'restored'; 

  const valRefund = row?.status == 'approved' && row?.tries[row.tries.length -1].payment_id !== 11 && row?.source !== 'recurring_miclub' && row?.charges_detail?.final_price > 0;

  const valMail = row?.data_facturacion?.factura_id;

  const valRestaurar = row?.status === 'cancelled';

  const valAdicionales = row?.source !== 'adicionales';

  return(
    <React.Fragment>
      
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
        background: '#efefef',
        cursor: 'pointer'
      }, }} onClick={() => setOpen(!open)}>
        <>
          <TableCell component="th" scope="row" style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.status}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.original_payment_date?.slice(0,10)}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.period}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>${row.charges_detail?.native_plan}</TableCell>
          {row.application_fee ? <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>${row.application_fee}</TableCell> : <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}> - </TableCell>}
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>${row.charges_detail?.final_price}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{origenmap[row.source] ? origenmap[row.source] : row.source}</TableCell>
          <TableCell 
            style={{ 
              ...styleTabbleData,
              display: 'flex',
              flexDirection: 'column',
              height: '42px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 0 0 10px'
            }}
          >
            <MenuActionsDiv ref={ref}>
              {[valCancelar, valCobrar, valFacturar, valNotaCred, valFactura, valRevertir, valRefund, valRestaurar, valAdicionales].some(Boolean) ?
                <MenuIcon sx={{borderRadius: '4px', cursor: 'pointer', '&:hover':{ backgroundColor: '#cfd8dc'}}} onClick={handleActions}/>
                : <img title="Disabled Menu" style={{ borderRadius: '4px', width: '16px'}} src={`${DisabledMenu}`} /> 
              }
                  
              {actions && (
                <>
                  <ActionsDiv sx={{top: null, left: null, transform: 'translate(-40%, 18px)'}} id='actions_div'>
                    { valAdicionales && <Button sx={{fontSize: '0.8em', color: '#000000'}} onClick={() => setOpenDetail(true)}>Ver Detalle</Button> }
                    { 
                      (valFacturar)&& (
                        <Button style={{fontSize: '0.8em'}} onClick={() => {row?.source === 'checkout_miclub' ? setOpenModal3(true) : cobrarFactura();}}>Facturar</Button>)
                    }
                    {/* { 
                      (valNotaCred) && (
                        <Button style={{fontSize: '0.8em'}} onClick={(e) =>{e.stopPropagation(), emitirNota();}}>Emitir N. Crédito</Button>)
                    } */}
                    {valFactura && (
                      <Button style={{fontSize: '0.8em'}} onClick={() => setOpenComprobantes(true)}>Ver comprobantes</Button>
                    )}
                    {valCobrar ? ( <Button style={{fontSize: '0.8em'}} onClick={() => setOpenModal(true)}>Cobrar</Button> ) 
                        
                      : valRevertir && (
                        <Button style={{fontSize: '0.8em'}} onClick={(e) => handleRevert()}>Revertir efectivo</Button>
                      )
                    }
                    {valCancelar && (
                      <Button style={{fontSize: '0.8em'}} onClick={(e) => handleCancelar()}>Cancelar Boleta</Button>
                    )
                    }
                    {valRestaurar && (
                      <Button style={{fontSize: '0.8em'}} onClick={handleRestaurar}>Restaurar Boleta</Button>
                    )
                    }
                    {valRefund && (
                      <Button style={{fontSize: '0.8em'}} onClick={(e) => {e.stopPropagation(),setOpenModal4(true);}}>Revertir Pago</Button>
                    )
                    }
                    {valMail && (
                      <Button style={{fontSize: '0.8em'}} onClick={(e) => {e.stopPropagation(), setOpenModalMail(true);}}>Estado del Mail</Button>
                    )
                    }
                    {
                      valFacturar && (
                        <Button style={{fontSize: '0.8em'}} onClick={() => setOpenModal6(true)}>Marcar facturada</Button>
                      )
                    }
                  </ActionsDiv>
                </>
              )}
            </MenuActionsDiv>
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell style={{...styleTabbleData, padding: '0px'}}colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{margin: '10px 0'}}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Estado</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>ID de Pago</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Fecha</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Detalle</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Método de pago</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Motivo Rechazo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.tries?.map((item) => (
                    <TableRow key={item.payment_id}>
                      <TableCell style={styleTabbleData}>{item.status}</TableCell>
                      <TableCell style={styleTabbleData}>{item.payment_id}</TableCell>
                      <TableCell style={styleTabbleData}>{item?.payment_day?.slice(0, 10)} {item.payment_day?.slice(11, 16)}</TableCell>
                      <TableCell style={styleTabbleData}>
                        {item.status_detail.length > 21 ?
                          <Tooltip title={item.status_detail}>
                            <Box>
                              {
                                item.status_detail.slice(0, 18) + '...' 
                              }
                            </Box>
                          </Tooltip>
                          : 
                          item.status_detail}
                      </TableCell>
                      <TableCell style={styleTabbleData}>{item?.payment_type || '-'}</TableCell>
                      <TableCell style={styleTabbleData}>{item?.motivo ? (
                        item.motivo.length > 20 ?  <Tooltip title={item.motivo}>
                          <Box>
                            {
                              item.motivo.slice(0, 18) + '...' 
                            }
                          </Box>
                        </Tooltip> :
                          item.motivo
                      ) : 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openDetail && <ModalDetail lastDate={tickets[index - 1]} open={openDetail} setOpen={setOpenDetail} row={row} plan={plan} record={record} />}
      <ModalPay openModal={openModal} setOpenModal={setOpenModal} tickets={tickets} revert={revert} row={row}/>
      <ModalRefund openModal4={openModal4} setOpenModal4={setOpenModal4} row={row} refundTicket={refundTicket} />
      <ModalMail setOpen={setOpenModalMail} open={openModalMail} id={row?.data_facturacion?.factura_id} />
      <ModalComp item={row} open={openComprobantes} setOpen={setOpenComprobantes} />
      <ModalCustom
        open={openModal3}
        setOpen={setOpenModal3}
        onClickAction={cobrarFactura}
        textAction={'Confirmar'}
        showButtons
        onClose={handleClose}
        title={'Facturar Boleta'}
      >
        <Box style={styleInnerBox}>
          {
        
            <Box>
              <Typography variant='subtitle1'>
                {
                  '¿Estás seguro? Una vez facturado en efectivo no se podrá revertir el pago' 
                }
              </Typography>
            </Box>
          
          }
        </Box>
      </ModalCustom>
      <ModalCustom
        open={openModal6}
        setOpen={setOpenModal6}
        onClickAction={marcarFacturada}
        textAction={'Confirmar'}
        showButtons
        onClose={handleClose}
        title={'Marcar Boleta como facturada'}
      >
        <Box style={styleInnerBox}>
          {
        
            <Box>
              <Typography variant='subtitle1'>
                  ¿Estás seguro? La boleta se marcara como facturada sin emitir una factura.                
              </Typography>
            </Box>
          
          }
        </Box>
      </ModalCustom>
      <ModalCustom
        open={openModal2}
        setOpen={setOpenModal2}
        onClickAction={cancelTicket}
        textAction={'Confirmar'}
        showButtons
        onClose={handleClose}
        title={'Cancelar Boleta'}
      >
        <Box style={styleInnerBox}>
          {
        
            <Box>
              <Typography variant='subtitle1'>
                {cancelarBoleta &&
                  '¿Estás seguro? Se cancelara la boleta seleccionada' 
                }
              </Typography>
            </Box>
          
          }
        </Box>
      </ModalCustom>
      <ModalCustom
        open={openModal5}
        setOpen={setOpenModal5}
        onClickAction={restoreTicket}
        textAction={'Confirmar'}
        showButtons
        onClose={handleClose}
        title={'Restaurar Boleta'}
      >
        <Box style={styleInnerBox}>
          {
        
            <Box>
              <Typography variant='subtitle1'>
               
                  ¿Estás seguro? Se restaurara la boleta seleccionada
             
              </Typography>
            </Box>
          
          }
        </Box>
      </ModalCustom>
    </React.Fragment>
  );
};

const ModalPay = ({openModal, setOpenModal, tickets, revert, row}) => {
  const refresh = useRefresh();
  const [payTicket, {isLoading, data, error}] = useUpdate();
  const [payment, setPayment] = useState(null);
  const ref = useRef(false);
  const record = useRecordContext();

  const handlePay = () => {
    if(revert){
      payTicket('revertir_pago', {id: row?.id, data: tickets, previousData: record});
      // if(row?.data_facturacion?.factura_id) emitirNota();
      setOpenModal(false);
      refresh();
    }else{
      const ticketUpdate = row;
      //const ticketUpdate = tickets[0];
      delete ticketUpdate.status;
      delete ticketUpdate.member_id;
      delete ticketUpdate.merchant_id;
      delete ticketUpdate.plan_id;
      payTicket('boletas', {id: ticketUpdate?.id, data: {...ticketUpdate, 'payment_type': payment}, previousData: row}, { onSuccess: (res) => {
        refresh();
      }});
      setOpenModal(false);
    }
  };

  const handleModalClose = () => {
    setPayment(null);
    setOpenModal(false);
  };

  useEffect(()=> {
  
    if(ref.current === false) {
      ref.current = true;
      return;
    } else {

      if(!error && !isLoading && data){
        console.log(revert);
        createNotification('success', revert ? 'Boleta revertida con éxito' : 'Boleta pagada con éxito');
        refresh();
      }
      else if(error && !isLoading){
        createNotification('error', revert ? 'No se pudo revertir la boleta' : 'No se pudo pagar la boleta', ()=> handleClickCopy(tickets)); 
      }
    }
    return() => {
      ref.current = false;
    };

  },[data, error, isLoading]);

  return(
    <ModalCustom
      open={openModal}
      setOpen={setOpenModal}
      onClickAction={handlePay}
      textAction={'Confirmar'}
      disabledAction={!revert ? !payment : false}
      showButtons
      onClose={handleModalClose}
      title={revert ? 'Revertir Boleta' : 'Pagar Boleta'}
    >
      <Box style={styleInnerBox}>
        {
          isLoading ? (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Typography variant='subtitle1'>
                {revert ? 
                  '¿Estás seguro? Se eliminará la boleta seleccionada' :(
                    <>
                      <Typography>Seleccionar método de pago de la boleta</Typography>
                      <FormControl>
                        <RadioGroup>
                          <FormLabel sx={{color: '#dzdzdz'}}>Elegir el método de pago</FormLabel>
                          <FormControlLabel label="Efectivo" onChange={(e) => setPayment(e.target.value)} control={<Radio value="cash" />} />
                          <FormControlLabel label="Terminal" onChange={(e) => setPayment(e.target.value)} control={<Radio value="terminal" />} />
                          <FormControlLabel label="Link de pago" onChange={(e) => setPayment(e.target.value)} control={<Radio value="link-de-pago" />} />
                        </RadioGroup>
                      </FormControl>
                    </>
                  )
                }
              </Typography>
            </Box>
          )
        }
      </Box>
    </ModalCustom>
  );
};

const ModalRefund = ({openModal4, setOpenModal4, refundTicket, row, handleClose}) => {
  const [motivo, setMotivo] = useState(''); 

  const handleRefund = () => {
    refundTicket(motivo);
    setOpenModal4(false);
  };

  return(
    <ModalCustom
      open={openModal4}
      setOpen={setOpenModal4}
      onClickAction={() => handleRefund()}
      textAction={'Confirmar'}
      showButtons
      onClose={handleClose}
      title={'Revertir Pago'}
    >
      <Box style={styleInnerBox}>
        {
        
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant='subtitle1'>
              {
                '¿Estás seguro? El Pago será revertido, por favor indicar el motivo.'
              }
            </Typography>
            <Input fullWidth style={{margin: '20px 0'}} type="text" onChange={(e) => {setMotivo(e.target.value);}} placeholder="Por favor ingrese el motivo"></Input>
          </Box>
          
        }
      </Box>
    </ModalCustom>
  );
};

const ModalMail = ({id, open, setOpen}) => {
  const dataProvider = useDataProvider();
  const [dataset, setDataset] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const { refetch } = useQuery(
    ['mail_factura', open], 
    async () => {
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      const url = `${apiUrl}/email_facturacion/${id}`;
      try {
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'X-Access-Tokens': tokenSend,
            'access-control-allow-origin': '*',
            'access-control-expose-headers' : '*',
          },
        });
        return response;
      } catch (error) {
        setNotFound(true);
      }
    },
    {
      onSuccess: ({data}) => setDataset(data.data),
      onError: () => setNotFound(true),
      enabled: open && Boolean(id)
    }
  );
  
  useEffect(() => {
    (open && Boolean(id)) && refetch();
  },[open]);

  const Opened = ({arr}) => (
    <Box style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between'}}>
      {
        arr.map(item => (
          <>
            <Box style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
              <Typography variant="h7">Fecha: </Typography>
              <Typography variant="h7">{moment(item).format('DD/MM/YYYY, h:mm:ss')}</Typography>
            </Box>
          </>
        ))
      }
    </Box>
  );

  const data = [
    {key: 'Email', value: dataset?.email},
    dataset?.opens_detail?.length > 0 ? 
      {label: 'Abierto', collapse: true, value: <Opened arr={dataset?.opens_detail} />} : 
      {key: 'Abierto', value: 'El mail no fue abierto.'},
    {key: 'Enviado', value: moment(dataset?.send_at).format('DD/MM/YYYY')},
  ];


  return (
    <ModalCustom
      open={open}
      setOpen={setOpen}
      showButtons
      onClose={handleClose}
      replaceButton
      title={'Informacion de email'}
    >
      <Box style={{...styleInnerBox, paddingBottom: '20px'}}>
        {
          !notFound ? (
            dataset ? (
              <TableMinimal data={data}/>
            ) : (
              <Skeleton />
            )
          ) : (
            <Typography variant="h7">No existen registros.</Typography>
          )
        }
      </Box>
    </ModalCustom>
  );
};

export default ListTickets;