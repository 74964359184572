import { Checkbox, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { MdHomeWork } from 'react-icons/md';
import { RiFilePaper2Line } from 'react-icons/ri';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { styleTabbleData, styleTableHead } from '../../../Theme/useStyle';
import { createNotification } from '../../helpers/helpFunctions';
import multinegociosEdit from '../SectionsEdit/MultinegociosEdit';
import tyCEdit from '../SectionsEdit/TyCEdit';
import { merchantCadena } from '../../../DataProvider';


const NegociosHabilitados = ({isAbleToEdit}) => {

  const record = useRecordContext();
  const isMulti = record?.merchant_id === merchantCadena;
  const [multimerch, setMultimerch] = useState([]);
  const [merchants, setMerchants] = useState(null);
  const [activeSocios, setActiveSocios] = useState(false);
  const [price, setPrice] = useState(record ? record.price : null);
    
  const dataProvider = useDataProvider();

  const disabledRow = (item, value) => {
    if(!activeSocios){
      return false;
    }else{
      if(item.application_fee == 0 || item.application_fee == 100) return true;
      else return false;
    }
  };
    
  const createRow = (row) => {
    let ret = {
      id: row.id,
      name: row.name,
      hasKeys: row?.split || (row.credentials?.mp_marketplace && (row.credentials?.mp_marketplace?.access_token && row.credentials?.mp_marketplace.public_key)),
      application_fee: 0,
      habilitado: false,
      enabled: false,
      edit: false
    };
    if(record){
      row?.planes_multinegocio?.map(item => {
        if(item.plan_id == record?.id){
          ret = {
            ...ret,
            habilitado:true, 
            application_fee: item.application_fee ? item.application_fee : 0,
            enabled: item?.enabled ? item.enabled : false,
            disabled: disabledRow(item)
          };
        }
      });
    }
    return ret;
  };
    
  const postSave = (data) => {
    console.log(data);
    sendDataMultiMerchant(data.data.id);
  };
    
  const sendDataMultiMerchant = (id) => {
    console.log('senDataMultiMerchant', id);
    multimerch.map(item => {
      if(item.edit){
        rowToDataSend(item, id);
      }
    });
  };
    
  const rowToDataSend = (row, id) => {
    let index = merchants.findIndex(obj => obj.id == row.id);
    let merchantEdit = merchants[index];
    const indexMerch = merchantEdit.planes_multinegocio?.findIndex(item => item.plan_id == id);
    console.log(indexMerch);
    if(row.habilitado){
      if(indexMerch >= 0) {
        console.log(row.habilitado, row.enabled);
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.habilitado ? row.habilitado : false;
      }else{
        merchantEdit.planes_multinegocio.push({
          application_fee: row.application_fee ? row.application_fee : 0,
          enabled: row.enabled,
          plan_id: id
        });
      }
    }else{
      if(indexMerch >= 0) {
        // merchantEdit.planes_multinegocio?.splice(indexMerch, 1);
           
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.enabled;
      }
    }
    delete merchantEdit.keys;
    delete merchantEdit.credentials;
    delete merchantEdit.name;
    delete merchantEdit.afip;
    delete merchantEdit.keys_marketplace;
    console.log(merchantEdit);
    console.log(merchantEdit.planes_multinegocio);
    dataProvider.update('merchants', {id: merchantEdit.id, data: merchantEdit});
  };
    
      
  const handleMultiMerch = async () => {
    let arrayRows = [];
    const params = {
      pagination: {page: 1, perPage: 999},
      sort: {field: 'credentials.mp_marketplace.access_token', order: 'DESC'},
      filter: {},
      noContext: true,
    };
    try {
      const response = await dataProvider.getList('merchants', params, true);
          
      let merchants = await response?.data?.filter(merch => merch.id !== '6178652dcfb117ad58a2cd3d');
      merchants.map(item => {
        arrayRows.push(createRow(item));
      });
      setMerchants(response?.data);
      return arrayRows;
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(async () => {
    setMultimerch(await handleMultiMerch());
  }, []);
    
    
      
  const validateSubmit = () => {
    const checkComisionPrice = multimerch.some((item) => item.application_fee > price * 0.95);
    const checkPrice = multimerch.some((item) => item.application_fee > price);
    if(checkPrice) {
      createNotification('error', 'El precio del plan no puede ser menor a la comisión cadena en los negocios habilitados');
      return;
    }
    if(checkComisionPrice){
      createNotification('error', 'La comisión de cadena no puede ser mayor al 95% del precio de plan');
    }
    return !checkComisionPrice;
  };
  
  const editComponent = {
    component: multinegociosEdit(multimerch, setMultimerch),
    label: 'Negocios Habilitados',
    icon: <MdHomeWork/>
  };
    
  return (
    <>
      {
        isMulti && (
          <CardBasic route={'multinegocio'} title='Negocios Habilitados' record={record} editComponent={isAbleToEdit && editComponent} onSuccess={postSave}>
            <TableContainer component={Paper} style={{boxShadow: 'none', maxHeight: '500px', marginTop: '20px'}}>
              <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead, maxWidth: '80px', padding: '10px 0 10px 10px'}}>Negocio</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Habilitado</TableCell>
                    <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Comisión</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {multimerch.map((row, index) => (
                    <Row row={row} index={index} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBasic>
        )
      }
    </>
  );
};

export default NegociosHabilitados;

export const Row = ({row, index}) => {

  return(
    <>
      <TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
        background: '#efefef'
      }}} >
        <TableCell component="th" scope="row" style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.name}</TableCell>
        <TableCell style={{ padding: '0 0 0 2px'}}>
          <FormControl>
            <Checkbox
              defaultChecked={row.habilitado}
              disabled
            />
          </FormControl>
        </TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.application_fee}</TableCell>
      </TableRow>
    </>
  );
};