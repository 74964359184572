import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Button, Autocomplete, CircularProgress, Modal, Radio, Checkbox,Tooltip, Popover, FormGroup, Switch } from '@mui/material';
import { useDataProvider, usePermissions, useRefresh } from 'react-admin';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import DownloadIcon from '@mui/icons-material/Download';
import { theme } from '../../Theme/theme';
import ModalCustom from './ModalCustom';
import { grey } from '@mui/material/colors';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControlLabel, IconButton, Input, RadioGroup, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { apiUrl, merchantCadena, merchantContext } from '../../DataProvider';
import { DateRange } from 'react-date-range';
import { useQuery } from 'react-query';
import { CustomReportFilters } from '../../Resources/Dashboard/Reportes/CustomReport';
import { getPeriods } from '../../Resources/helpers/getPeriods';
import { mockedBoletas, mockedMetodos, mockedOrigenes, mockedPagos, mockedStatus, mockedStatusTries } from './reportModels';

const ExportCustomButton = ({viewRangeDate}) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterSede, setFilterSede] = useState([]);
  const { getMerchant } = useContext(merchantContext);
  const [date, setDate] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'dateaccess',
  }]);

  const download = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const uri = `${apiUrl}`;
    axios({
      url: `${uri}/accesos_metric?filter=${JSON.stringify({'sede': filterSede, 'from': date[0].startDate.toISOString().slice(0,10), 'until': date[0].endDate.toISOString().slice(0,10)})}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'access-control-expose-headers' : '*',
        'context-merchant': getMerchant().id == '0' ? '' : getMerchant().id 
      },
      responseType: 'blob'
    })
      .then((response) => {
        const filename = response?.headers?.['content-disposition']?.split('filename=')[1];
        const url = window.URL
          .createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename ? `${filename}` : 'Reporte de Accesos.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        document.body.removeChild(link);
        setOpen(false);
        setLoading(false);
      }).catch(err => {
        if(err.response.status === 400) {
          createNotification('error', 'No existen accesos para esta fecha');
        }
        setOpen(false);
        setLoading(false);
      });
  };
  
  const handleClick = () => {
    if(filterSede.length > 0){
      download();
    }else{
      createNotification('error', 'Selecciona una sede antes de descargar');
    }
  };

  return (
    <Box>
      <Button style={{...theme.typography.button, color: theme.palette.primary.main}} onClick={()=>setOpen(true)} startIcon={<DownloadIcon />}>Exportar</Button>
      <CustomReportFilters style={{width: '200px'}} text={'Accesos'} viewDocumento variant={'outlined'} open={open} setOpen={setOpen} showDate viewSedes viewPlanAccess title={'Reporte de Accesos'} downloadText={'accesos'} url={'accesos_metric_pro'} />

    </Box>
  );
};

export const ModalDateCustom = ({disableDates, title, multipleMerchs = true, loading, activos, contextMerch, tooltip, setOpen, open, noPlanes, onClick, date, setDate, viewRangeDate, handleFilterPlanes, setFilter,setFilterSedes,viewDate,viewPlanes, viewSede, viewMerch, ...props}) => {
  const dataProvider = useDataProvider();
  const {getMerchant} = useContext(merchantContext);
  const [sedes, setSedes] = useState(null);
  const [merchs, setMerchs] = useState(null);
  const [planes, setPlanes] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const token = localStorage.getItem('auth');
  const tokenSend = JSON.parse(token)?.token;
  const uri = `${apiUrl}`;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const data = useQuery(
    ['merchants'],
    () => axios({
      url: `${uri}/merchants?filter={}&range=[0,999]&sort=["id","ASC"]`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'context-merchant': getMerchant()?.id,
        'access-control-allow-origin': '*',
        
      },
    }),
    {
      onSuccess: ({data}) => {
        setMerchs(data?.data);
      },
    }
  );

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {}
  };

  const {isLoading: loadSedes} = useQuery(
    ['sedes', getMerchant()?.id], 
    () => dataProvider.getList('sedes', params),
    {
      onSuccess: (d) => setSedes(d.data),
      enabled: viewSede ? true : false
    }
  );

  const {isLoading: loadPlanes} = useQuery(
    ['planes', getMerchant()?.id], 
    () => dataProvider.getList('planes', params),
    {
      onSuccess: (d) => setPlanes(d.data),
      enabled: viewPlanes ? true : false
    }
  );

  const handleSelectDate = item => {
    const startDate = new Date(item.dateaccess.startDate);
    const endDate = item.dateaccess.endDate;
    const maxDateCalculated = new Date(startDate.setDate(startDate.getDate() + 30));
    item.dateaccess.endDate = maxDateCalculated > endDate ? endDate : maxDateCalculated;
    setDate([item.dateaccess]);
  };
  // <Tooltip title={label.tooltip} placement='top'>
  return(
    <ModalCustom
      open={open}
      onClose={() => !loading && setOpen(false)}
      setOpen={setOpen}
      onClickAction={onClick}
      textAction={'Descargar'}
      startIcon={loading && <CircularProgress size='16px'/>}
      disabledAction={loading}
      showButtons
      showOverflow
      title={title ?? ''}
    >
      <Box style={{display: 'flex', justifyContent: 'center'}}>
        <Box style={{display: 'flex',flexDirection: 'column', alignItems: 'center', width: '80%', gap: '30px'}}>
          {
            tooltip && (
              <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-beween', width: '100%'}}>
                <Typography variant="h6">Filtros</Typography>
                <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
                  <HelpOutlineIcon sx={{fontSize: '21px'}} />
                </IconButton>
                <Popover
                  open={openMenu}
                  anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                >
                  <Box sx={{
                    maxWidth: '320px',
                    backgroundColor: '#fff',
                    padding: '15px 18px'
                  }}>
                    <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}>{tooltip}</Typography>
                  </Box>
                </Popover>
              </Box>
            )
          }
          {
            viewDate && 
              <>
                {
                  !activos && (
                    <Box style={{width: '100%'}}>
                      <Typography style={theme.typography.subtitle2}>Desde</Typography>
                      <Input
                        disabled={disableDates}
                        type='date' defaultValue={date.from} style={{width: '100%'}}
                        onChange={(e)=> setDate({...date, from: e.target.value})}
                        fullWidth
                      />
                    </Box>
                  )
                }
                <Box style={{width: '100%'}}>
                  <Typography style={theme.typography.subtitle2}>{!activos ? 'Hasta' : 'Fecha'}</Typography>
                  <Input 
                    disabled={disableDates}
                  
                    type='date' style={{width: '100%'}}
                    onChange={(e)=> setDate({...date, until: e.target.value})} 
                    fullWidth
                    defaultValue={date.until}/>
                </Box>
              </>
          }
          {
            viewRangeDate && (
              <>
                <DateRange
                  showMonthAndYearPickers={false}
                  navigatorRenderer={null}
                  showPreview={false}
                  showDateDisplay={false}
                  ranges={date}
                  onChange={handleSelectDate}
                  maxDate={new Date()}
                />
              </>
            )
          }
          {
            viewSede && <Autocomplete
              multiple
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.name}
              options={sedes ? sedes : []}
              renderInput={(params) => <TextField {...params}  label="Sedes" />}
              loading={loadSedes}
              onChange={(e, n) => {
                setFilter(n.map(i => i.id));
              }}
              fullWidth
            />
          }
          {
            viewPlanes && <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.name}
              options={planes ? planes : []}
              renderInput={(params) => <TextField {...params}  label="Planes" />}
              loading={loadPlanes}
              onChange={(e, n) => setFilter(n, 'planes')}
              fullWidth
            />
          }
          {
            viewMerch && <Autocomplete
              multiple={multipleMerchs}
              disablePortal
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.name}
              options={merchs ? merchs : []}
              renderInput={(params) => <TextField {...params}  label="Negocios" />}
              loading={loadSedes}
              fullWidth
              onChange={(e, n) => {
                setFilterSedes && setFilterSedes(n);
                !noPlanes ? setFilter(n, 'merchant') : setFilter(n);
              }}
            />
          }
        </Box> 
      </Box>
    </ModalCustom>
  );
};

export const ModalReporteCustom = ({loading, setOpen, open, setLoading, onClick, date, setDate, filter, setFilter, setFilterSedes,viewDate,viewPlanes, viewSede, viewMerch, rechazos}) => {
  const { getMerchant } = useContext(merchantContext);

  const permis = localStorage.getItem('permissions');
  const noOpeAcc = permis === 'operador' || permis === 'accesos';

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [merchs, setMerchs] = useState(null);
  const [planes, setPlanes] = useState(null);
  const [value, setValue] = useState([]);
  const [merchant, setMerchant] = useState([]);
  const [period, setPeriod] = useState(false);
  const [status, setStatus] = useState([]);
  const [statusTry, setStatusTry] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [metodo, setMetodo] = useState([]);
  const [origen, setOrigen] = useState([]);
  const [boleta, setBoleta] = useState([]);
  const [error, setError] = useState(null);
  const [plan, setPlan] = useState([]);
  const [intentos, setIntentos] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTry, setAnchorElTry] = useState(null);
  const [nulos, setNulos] = useState(false);

  const distinct = getPeriods();
  // Querys
  useQuery(
    ['planes'],
    () =>  dataProvider.getList('planes', params), { onSuccess: (d) => setPlanes(d.data) }
  );

  useQuery(
    ['merchants'],
    // () =>  dataProvider.getList('merchants', params), {
    async () => {
      let token = localStorage.getItem('auth');
      let tokenSend = await token?.slice(10, -2);
      const response = await axios({
        url: `${apiUrl}/merchants?filter={}&range=[0,999]&sort=["id","ASC"]`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Tokens': tokenSend,
          'context-merchant': getMerchant()?.id,
          'access-control-allow-origin': '*'
        },
      });
      return response.data;
    },
    {
      onSuccess: (d) => {
        if(d.data.data) {
          setMerchs(d.data.data);
          if(getMerchant()?.id !== '') setMerchant([d.data.data?.find(item => item.id === getMerchant().id && item)]);
        } else {
          setMerchs(d.data); 
          if(getMerchant()?.id !== '') setMerchant([d.data?.find(item => item.id === getMerchant().id && item)]);
        }
        
      }
    }
  );

  // MediaQuery
  const matches = useMediaQuery('(min-height: 700px)');


  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'id', order: 'ASC'},
    filter: {}
  };  

  // useEffect(() => {
  //   console.log(filter)
  //   setFilter({...filter, merchants: getMerchant().id});
  // },[open])

  useEffect(() => {
    if(period) {
      let origenArr = origen.filter(item => item.key !== 'cobros_adicionales');
      let filterOrigen = filter?.['source_boleta']?.filter(item => item !== 'cobros_adicionales');
      setOrigen(origenArr);
      setFilter((f) => ({...f, 'filter_by' : { 'last_try_date' : {}, 'period': value, }, 'source_boleta': filterOrigen}));
      console.log(filter, 'period');
    } else {
      setFilter((f) => ({...f, 'filter_by' : { 'period' : {}, 'last_try_date' : date}}));
    }
  },[value, date, period]);

  useEffect(() => {
    setFilter((f) => ({...f, 'merchants' : merchant.map(mer => mer.id ?? mer)}));
    merchant?.length > 0 && setError(false);
  },[merchant]);

  useEffect(() => {
    const statusArray = [];
    setFilter((f) => ({...f, 'status_boleta' : status.map(e => e.key)}));
    setStatusTry(status);
  },[status]);

  useEffect(() => {
    setFilter((f) => ({...f, 'processor_tries' : pagos.map(e => e.key )}));
  },[pagos]);

  useEffect(() => {
    rechazos && setStatus([mockedStatusTries[1]]);
  },[rechazos]);

  useEffect(() => {
    const statusArray = [];
    statusTry.map(e => {
      if(Array.isArray(e.key)) {
        e.key.forEach(r => {
          statusArray.push(r);
        }); 
      } else {
        statusArray.push(e.key);
      }
    });
   
    setFilter((f) => ({...f, 'status_tries' : statusArray.map(e => e)}));
  },[status, statusTry]);

  useEffect(() => {
    const origenArray = [];
    origen.map(e => {
      if(Array.isArray(e.key)) {
        e.key.forEach(r => {
          origenArray.push(r);
        }); 
      } else {
        origenArray.push(e.key);
      }
    });
    setFilter((f) => ({...f, 'source_boleta' : origenArray.filter(item => {
      console.log('item origen', item);
      return period ? item !== 'cobros_adicionales' : item;
    })}));
  },[period, origen]);

  useEffect(() => {
    setFilter((f) => ({...f, 'plan_type' : boleta.map(e => e.key)}));
  },[boleta]);

  useEffect(() => {
    setFilter((f) => ({...f, 'planes' : plan.map(e => e.id)}));
  },[plan]);

  useEffect(() => {
    setFilter((f) => ({...f, 'payment_method_tries' : metodo.map(e => e.key )}));
  },[metodo]);

  useEffect(() => {
    setFilter((f) => ({...f, 'nro_intentos' : { 'ultimo': (!intentos).toString() , 'todos': intentos.toString()}}));
  },[intentos]);

  useEffect(() => {
    setFilter((f) => ({...f, 'pagos_nulos' : nulos.toString()}));
  },[nulos]);

  const handleValidations = () => {
    if (period === null || (period && value.length <= 0)){
      createNotification('warning', 'Es necesario seleccionar un periodo o una fecha');
      setLoading(false);
      setError(true);
      return;
    }
    if (merchant.length === 0 || !filter.merchants){
      createNotification('warning', 'Faltan completar el campo de Negocio');
      setLoading(false);
      setError(true);
      return;
    }
    setError(false);
    onClick();
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClickMenu2 = (event) => {
    setAnchorElTry(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorElTry(null);
  };

  return(
    <ModalCustom
      open={open}
      onClose={() => !loading && setOpen(false)}
      setOpen={setOpen}
      onClickAction={handleValidations}
      //handleValidations={handleValidations}
      textAction={'Descargar'}
      startIcon={loading && <CircularProgress size='16px'/>}
      disabledAction={loading}
      showButtons
      queryOverflow
      title='Reporte detallado de Cobros'
    >
      <Box>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', color: '#000a12', textTransform: 'uppercase', width: '100%'}}>
          <Tooltip title={'Boleta'} placement="top">
            <Typography style={{fontWeight: 800, fontSize: '14px'}}>Filtros por Boleta</Typography>
          </Tooltip>
        </Box>
        <Box style={{display: 'flex', alignItems: 'start', justifyContent: 'center', width: 'auto'}}>
          <Box style={{display: 'flex',flexDirection: 'row', width: '100%', alignItems: 'start', justifyContent: 'flex-start'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex', flexDirection: 'row', minWidth: '300px', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <RadioGroup
                  style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', margin: '15px 45px'}}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel style={{ width: '200px', margin: '10px 15px'}} value={false} control={<Radio checked={!period}  onChange={() => {setPeriod(false);}} />} label="Por fecha del último intento de cobro" />
                  {!noOpeAcc && <FormControlLabel style={{ width: '200px', margin: '10px 15px'}} value={true} control={<Radio checked={period} onChange={() => {setPeriod(true);}} />} label="Por periodo de la boleta" />}
               
                </RadioGroup>
              </Box>
            </Box>
                
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center',  width: '100%', marginLeft: '25px'}}>
              { period === true ? (
                <Autocomplete
                  style={{ width: '50%', margin: '15px', padding: '0 15px 15px 15px',  border: `${(error && value.length === 0) ? '2px solid #e57373' : ''}`, borderRadius: '5px'}}
                  disablePortal
                  multiple
                  disableCloseOnSelect
                  limitTags={1}
                  getOptionLabel={(option) => option}
                  options={distinct}
                  onChange={(e, v) => setValue(v)}
                  renderInput={(params) => <TextField variant="outlined" {...params}  label="Periodo" />}
                /> ) :
                <Box sx={{display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-around', margin: '15px 15px'}}>
                  <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', marginRight: '10px'}}>
                    <Typography style={theme.typography.subtitle2}>Desde</Typography>
                    <Input type='date' defaultValue={date?.from} style={{width: 'auto'}}
                      onChange={(e)=> setDate({...date, from: e.target.value})}
                    />
                  </Box>
                  <Box sx={{display: 'flex', flexDirection: 'column', width: '50%',marginRight: '10px' }}>
                    <Typography style={theme.typography.subtitle2}>Hasta</Typography>
                    <Input type='date' style={{width: 'auto'}}
                      onChange={(e)=> setDate({...date, until: e.target.value})} 
                      defaultValue={date?.until}/>
                  </Box>
                
                </Box>
              }
            </Box>
          </Box> 
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', height: 'auto', justifyContent: 'space-around', width: 'auto', minWidth: '1000px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
           
            <Autocomplete
              style={{ width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disablePortal
              required
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.label}
              options={mockedStatus}
              value={status}
              onChange={(e, v) => setStatus(v)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Estado de la boleta" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em', borderRadius: '5px',fontWeight: 400, padding: '3px', margin: '0 0px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
         
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disableCloseOnSelect
              limitTags={1}
              getOptionDisabled={(option) => period ? option.key === 'cobros_adicionales' : null}
              value={origen}
              getOptionLabel={(option) => option.label}
              options={mockedOrigenes}
              onChange={(e, v) => setOrigen(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Origen" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em', borderRadius: '5px',fontWeight: 400, padding: '3px', margin: '0 0px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disableCloseOnSelect
              disablePortal
              getOptionLabel={(option) => option.label}
              options={mockedBoletas}
              limitTags={1}
              onChange={(e, v) => setBoleta(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Tipo de plan" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em', borderRadius: '5px', padding: '3px',fontWeight: 400, margin: '0 0px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disableCloseOnSelect
              disablePortal
              limitTags={1}
              getOptionLabel={(option) => option.name}
              options={planes ? planes : []}
              onChange={(e, v) => setPlan(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Plan" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em', borderRadius: '5px',fontWeight: 400, padding: '3px', margin: '0 5px 0 0', backgroundColor: '#e2f1f8'}}>{e.name}</Typography>)}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disableCloseOnSelect
              options={merchs ? merchs : []}
              getOptionLabel={(option) => option.name}
              disablePortal
              limitTags={1}
              value={merchant}
              defaultValue={(getMerchant()?.id && merchs) ? [merchs?.find(item => item.id === getMerchant()?.id)] : []}
              onChange={(e, v) => setMerchant(v)}
              renderInput={(params) => <TextField {...params} error={error} helperText={error ? 'El campo de negocio es requerido.' : null} variant="outlined" style={{fontSize: '8px'}}  label="Negocio" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em', borderRadius: '5px', padding: '3px 0px',fontWeight: 400, margin: '0 5px 0 0', backgroundColor: '#e2f1f8'}}>{e.name}</Typography>)}
              
            />
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start',  flexDirection: 'row', width: '30%', margin: '0px auto'}}>
            
            <Checkbox style={{margin: '0px 10px'}} onChange={(e) => setNulos(e.target.checked)}/>
            <Typography style={{fontSize: '1em', fontWeight: '400'}}>Incluir las boletas con precio final cero</Typography>  
          </Box>
          <Box id="empty_space" sx={{display: `${!matches ? 'flex' : 'none'}`, flexDirection: 'column', width: `${matches ? '30%' : '70%'}`, margin: '0px auto'}} />
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', height: 'auto', justifyContent: 'space-around', width: 'auto'}}>
          <Box style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', color: '#000a12', textTransform: 'uppercase', margin: '30px 15px 0px 15px', width: '100%'}}>
            <Typography style={{fontWeight: 800, fontSize: '14px'}}>Filtros por Intento</Typography>
          </Box>
        
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{ width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disablePortal
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.label}
              options={mockedStatusTries}
              // defaultValue={rechazos && [mockedStatusTries[1]]}
              value={statusTry}
              onChange={(e, v) => setStatusTry(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Estado del intento" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em',fontWeight: 400, borderRadius: '5px', padding: '3px', margin: '0 5px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
         
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              multiple
              disablePortal
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option) => option.label}
              options={mockedPagos}
              onChange={(e, v) => setPagos(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Procesador" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontSize: '0.8em',fontWeight: 400, borderRadius: '5px', padding: '3px', margin: '0 0px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}}>
            <Autocomplete
              style={{width: 'auto', margin: '0 10px', padding: '0 15px 15px 15px', borderRadius: '5px'}}
              disablePortal
              multiple
              disableCloseOnSelect
              size="small"
              limitTags={1}
              getOptionLabel={(option) => option.label}
              options={mockedMetodos}
              onChange={(e, v) => setMetodo(v)}
              renderInput={(params) => <TextField {...params} variant="outlined"  label="Método de pago" />}
              renderTags={(params) => 
                params.map(e => <Typography style={{fontWeight: 400, fontSize: '0.8em', borderRadius: '5px', padding: '3px', margin: '0 0px 0 0', backgroundColor: '#e2f1f8'}}>{e.label}</Typography>)}

            />
          </Box>
          <Box sx={{display: 'flex', alignItems: 'start', justifyContent: 'start',  flexDirection: 'column', width: '30%', margin: '10px auto'}}>
            <Typography style={{marginLeft: '10px', fontSize: '0.9em', color: '#000a12'}}>N° de intentos</Typography>
            <RadioGroup
              style={{width: '100%', margin: '0 20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel style={{ width: '300px'}} control={<Radio checked={!intentos} onClick={() => setIntentos(!intentos)}/>} label="Solo el último intento" />
              <FormControlLabel style={{ width: '300px'}} control={<Radio checked={intentos} onClick={() => setIntentos(!intentos)} value="added_tries"  />} label="Todos los intentos" />
            </RadioGroup>      
          </Box>  
          <Box id="empty_space" sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}} />
          <Box id="empty_space" sx={{display: 'flex', flexDirection: 'column', width: `${matches ? '30%' : '20%'}`, margin: '0px auto'}} />
        </Box>
      </Box>
    </ModalCustom>
  );
};

export default ExportCustomButton;
