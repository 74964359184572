import React, { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import prestacionesEdit from './SectionsEdit/PrestacionesEdit';
import tyCEdit from './SectionsEdit/TyCEdit';
import informacionEdit from './SectionsEdit/InformacionEdit';
import { BsCardText } from 'react-icons/bs';
import { CgGym } from 'react-icons/cg';
import { GrTextAlignLeft } from 'react-icons/gr';
import { GiWeightLiftingUp } from 'react-icons/gi';
import { RiFilePaper2Line } from 'react-icons/ri';
import { BsCardImage } from 'react-icons/bs';
import { MdHomeWork } from 'react-icons/md';
import CreateForm from '../../Components/Form/CreateForm';
import planEdit from './SectionsEdit/PlanEdit';
import descripcionEdit from './SectionsEdit/DescripcionEdit';
import cardAws from '../../Components/CustomFields/CardAws';
import { createNotification } from '../helpers/helpFunctions';
import multinegociosEdit from './SectionsEdit/MultinegociosEdit';
import { ErrorPage } from '../../Components/UI/Layout/LayoutCustom';

const PlanCreateEdit = ({isEdit, isMulti, ...props}) => {
  const record = useRecordContext();
  const permission = localStorage.getItem('permissions');
  const [merchant, setMerchant] = useState(record ? record.merchant_id : null);
  const [multimerch, setMultimerch] = useState([]);
  const [merchants, setMerchants] = useState(null);
  const [isCentral, setIsCentral] = useState(isMulti ? true : false);
  const [isMP, setIsMP] = useState(false);
  const [activeSocios, setActiveSocios] = useState(false);
  const [price] = useState(isEdit ? record.price : null);
  const dataProvider = useDataProvider();
  
  const disabledRow = (item, value) => {
    if(!activeSocios){
      return false;
    }else{
      if(item.application_fee == 0 || item.application_fee == 100) return true;
      else return false;
    }
  };

  const createRow = (row) => {
    let ret = {
      id: row.id,
      name: row.name,
      hasKeys: row?.split || (row.credentials?.mp_marketplace && (row.credentials?.mp_marketplace?.access_token && row.credentials?.mp_marketplace.public_key)),
      application_fee: 0,
      habilitado: false,
      enabled: false,
      edit: false
    };
    if(isEdit){
      row?.planes_multinegocio?.map(item => {
        if(item.plan_id == record?.id){
          ret = {
            ...ret,
            habilitado:true, 
            application_fee: item.application_fee ? item.application_fee : 0,
            enabled: item?.enabled ? item.enabled : false,
            disabled: disabledRow(item)
          };
        }
      });
    }
    return ret;
  };

  const postSave = (data) => {
    sendDataMultiMerchant(data.data.id);
  };

  const sendDataMultiMerchant = (id) => {
    console.log('senDataMultiMerchant', id);
    multimerch.map(item => {
      if(item.edit){
        rowToDataSend(item, id);
      }
    });
  };

  const rowToDataSend = (row, id) => {
    let index = merchants.findIndex(obj => obj.id == row.id);
    let merchantEdit = merchants[index];
    const indexMerch = merchantEdit.planes_multinegocio?.findIndex(item => item.plan_id == id);
    console.log(indexMerch);
    if(row.habilitado){
      if(indexMerch >= 0) {
        console.log(row.habilitado, row.enabled);
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.habilitado ? row.habilitado : false;
      }else{
        merchantEdit.planes_multinegocio.push({
          application_fee: row.application_fee ? row.application_fee : 0,
          enabled: row.enabled,
          plan_id: id
        });
      }
    }else{
      if(indexMerch >= 0) {
        // merchantEdit.planes_multinegocio?.splice(indexMerch, 1);
       
        merchantEdit.planes_multinegocio[indexMerch].application_fee = row.application_fee ? row.application_fee : 0;
        merchantEdit.planes_multinegocio[indexMerch].plan_id = id;
        merchantEdit.planes_multinegocio[indexMerch].enabled = row.enabled;
      }
    }
    delete merchantEdit.keys;
    delete merchantEdit.credentials;
    delete merchantEdit.name;
    delete merchantEdit.afip;
    delete merchantEdit.keys_marketplace;
    console.log(merchantEdit);
    console.log(merchantEdit.planes_multinegocio);
    dataProvider.update('merchants', {id: merchantEdit.id, data: merchantEdit});
  };

  
  const handleMultiMerch = async () => {
    let arrayRows = [];
    const params = {
      pagination: {page: 1, perPage: 999},
      sort: {field: 'credentials.mp_marketplace.access_token', order: 'DESC'},
      filter: {},
      noContext: true,
    };
    try {
      const response = await dataProvider.getList('merchants', params, true);
      
      let merchants = await response?.data?.filter(merch => merch.id !== '6178652dcfb117ad58a2cd3d');
      merchants.map(item => {
        arrayRows.push(createRow(item));
      });
      setMerchants(response?.data);
      return arrayRows;
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(async () => {
    setMultimerch(await handleMultiMerch());
  }, []);



  
  const validateSubmit = () => {
    const checkComisionPrice = multimerch.some((item) => item.application_fee > price * 0.95);
    const checkPrice = multimerch.some((item) => item.application_fee > price);
    if(checkPrice) {
      createNotification('error', 'El precio del plan no puede ser menor a la comisión cadena en los negocios habilitados');
      return;
    }
    if(checkComisionPrice){
      createNotification('error', 'La comisión de cadena no puede ser mayor al 95% del precio de plan');
    }
    return !checkComisionPrice;
  };

    
  const transform = (data) => {
    if(data.precio_compare_2){
      if(typeof data.precio_compare_2 === 'number'){
        return({
          ...data, 
          precio_compare_2: '$ ' + data.precio_compare_2.toLocaleString('es'),
          name: data.name.trim()
        });
      }
    }
    return {...data, name: data?.name.trim()};
  };
  
  const tabs = [
    {
      component: informacionEdit(permission, setMerchant, isMulti, multimerch, setIsCentral, setIsMP),
      label: 'Información',
      icon: <BsCardText/>
    },
    {
      component: planEdit(isMulti, isCentral, isMP, merchants?.find(merch => merch?.id === merchant)),
      label: 'Plan',
      icon: <CgGym/>
    },
    isMulti && (permission === 'superadmin' || permission === 'owner') &&  {
      component: multinegociosEdit(multimerch, setMultimerch),
      label: 'Negocios Habilitados',
      icon: <MdHomeWork/>
    },
    {
      component: descripcionEdit(),
      label: 'Descripción',
      icon: <GrTextAlignLeft/>
    },
    {
      component: prestacionesEdit(record, isEdit, isMulti),
      label: 'Prestaciones',
      icon: <GiWeightLiftingUp/>
    },
    {
      component: tyCEdit(record, merchant, isEdit),
      label: 'Términos y Condiciones',
      icon: <RiFilePaper2Line/>
    },
    {
      component: cardAws('Subir Foto', 'foto'),
      label: 'Imagen',
      icon: <BsCardImage/>
    }
  ];

  return (
    <>
      {(permission === 'admin' || permission === 'operador') && isMulti ? (
        <ErrorPage />
      ) : (
        <CreateForm tabs={tabs.filter(tab => tab)} transform={transform} route={isMulti ? 'multinegocio' : 'planes'} onSuccess={postSave} customValidate={validateSubmit} />
      )}
    </>
  );
};

export default PlanCreateEdit;
