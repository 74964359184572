import { SaveButton, DeleteButton, DeleteWithConfirmButton, CreateButton, Loading } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ButtonAddAccess from './ButtonAddAccess';
import { Box, Button, Typography } from '@mui/material';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import { ButtonToolbar } from './Buttons/ButtonToolbar';
import { useState } from 'react';
import ModalCustom from './ModalCustom';

export const CustomDeleteButton = ({
  className,
  classes,
  saving,
  invalid,
  validating,
  onSave,
  variant,
  record,
  ...rest
}) => <DeleteWithConfirmButton disabled={(record?.status=='baja')}
  label='Dar de Baja' variant='text' confirmTitle={`Dar baja a ${record?.nombre} ${record?.apellido}?`}
  confirmContent="Seguro quieres dar de baja a este socio?" record={record} undoable={true} {...rest}
/>;

export const CustomSaveButton = ({
  validating,
  translate,
  mutationMode,
  submitOnEnter,
  locale,
  setDataRecord,
  pristineState,
  pristine,
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  ...rest
}) => <SaveButton label='Guardar' redirect='list'
  submitOnEnter={true} {...rest}/>;

export const CustomBajaButton = ({
  onClick,
  noAccess,
  loading,
  classes,
  saving,
  invalid,
  onSave,
  variant,
  translate,
  mutationMode,
  setDataRecord,
  submitOnEnter,
  handleSubmit,
  handleSubmitWithRedirect,
  basePath,
  record,
  redirect,
  validating,
  locale,
  undoable,
  pristine,
  ...rest
}) => (
  <ButtonToolbar
    onClick={onClick}
    {...rest} 
    disabled={!(record?.status=='baja') || noAccess}  variant="text" 
    disableElevation color="primary" redirect='list' size="small" 
    startIcon={ loading ? <CircularProgress size='20px'/> : <ReplayIcon />}
    style={{padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
  > 
    Revertir Baja
  </ButtonToolbar>
);

export const CustomInvalidButton = ({
  handlerInvalidAccess,
  noAccess,
  loading,
  classes,
  saving,
  invalid,
  variant,
  translate,
  mutationMode,
  onSave,
  setDataRecord,
  submitOnEnter,
  handleSubmit,
  handleSubmitWithRedirect,
  basePath,
  record,
  redirect,
  validating,
  locale,
  undoable,
  pristine,
  open,
  setOpen,
  ...rest
}) => {
  
  return (
    <>
      <ButtonToolbar
        onClick={() => setOpen(true)}
        {...rest}
        disabled={!(record?.status=='baja') || noAccess} variant="text" 
        disableElevation color='inherit' size="small" 
        startIcon={<BlockOutlinedIcon/>}
        style={{padding: '9px 26px', fontWeight: '600', textTransform: 'none'}}
      >
        {noAccess ? 'Sin Acceso' : 'Invalidar Acceso'}
      </ButtonToolbar>
      <ModalCustom
        title='Invalidar acceso'
        disabledAction={!(record?.status=='baja') || noAccess || loading}
        startIcon={loading && <CircularProgress size='20px'/>}
        textAction={'Confirmar'} showButtons open={open} setOpen={setOpen} onClickAction={handlerInvalidAccess}>
        <Box style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', padding: '20px'}}>
          <Typography variant="h7">
             ¿Estás seguro?
              Al invalidar el acceso al socio, cambiará su estatus a &apos;Inactivo&apos;.
          </Typography>
        </Box>
      </ModalCustom>
    </>
  );};

export const CustomCreateButton = ({
  defaultTitle,
  currentSort,
  displayedFilters,
  filterValues,
  hasCreate,
  hideFilter,
  loading,
  loaded,
  onToggleItem,
  onUnselectItems,
  perPage,
  selectedIds,
  setFilters,
  setPage,
  hasShow,
  hasEdit,
  hasList,
  setPerPage,
  setSort,
  showFilter,
  totalPages,
  exporter,
  refetch,
  classes,
  syncWithLocation,
  ...rest
}) => (
  <CreateButton {...rest}/>
);

export const ToolbarCustomButton = ({
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  data,
  setSendData,
  redirect,
  refresh,
  setSocio,
  ...rest
}) => <ButtonAddAccess {...rest} redirect={redirect} setSocio={setSocio} refresh={refresh} setSendData={setSendData} data={data}/>;

export const ToolbarSedeCustom = ({
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  record,
  ...rest
}) => (
  <Box sx={{width: '100%',display: 'flex', justifyContent: 'space-between'}}>
    <SaveButton
      redirect="list"
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      saving={saving}
      {...rest}
    />
    {record?.qr_url && (
      <Button href={record.qr_url} 
        variant='contained' 
        color="success"
        startIcon={<QrCode2OutlinedIcon/>}
        sx={{fontWeight: '600'}}
      >
        Descargar QR
      </Button>
    )}
    <DeleteButton {...rest} record={record}/>
  </Box>
);

export const CustomButtonBasic = ({
  onClick,
  variant,
  style,
  children,
  ...rest
}) => (
  <Button onClick={onClick} variant={variant} style={style}>{children}</Button>
);


