import React from 'react';
import { useRecordContext } from 'react-admin';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import VersionHistory from '../../../Components/UI/VersionHistory/VersionHistory';

const VersionCard = () => {
  const record = useRecordContext();
  return (
    <CardBasic title='Control de versiones' collapsed>
      <VersionHistory record={record}/>
    </CardBasic>
  );
};

export default VersionCard;