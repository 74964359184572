import { Box } from '@mui/material';
import React from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import ImageComponent from '../../../Components/UI/Show/ImageComponent';
import { useRecordContext } from 'react-admin';
import cardAws from '../../../Components/CustomFields/CardAws';
import { BsCardImage } from 'react-icons/bs';
import { imagesCardEdit } from '../SectionsEdit/ImagesCardEdit';


const ImagesCard = () => {
  const record = useRecordContext();

  const editComponent = {
    component: imagesCardEdit(),
    label: 'Imagenes',
    icon: <BsCardImage/>
  };
  return (
    <CardBasic route="brands" record={record} editComponent={editComponent} title='Imagenes'>
      <Box style={{display: 'flex', gap: '20px'}}>
        <ImageComponent item={record?.images?.horizontal_white}/>
        <ImageComponent item={record?.images?.image_dark}/>
        <ImageComponent item={record?.images?.image_light}/>
        <ImageComponent item={record?.images?.mail_logo} />
      </Box>
    </CardBasic>
  );
};

export default ImagesCard;

