import { FunctionField, List, TextField, TopToolbar, useDataProvider, useRefresh } from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { Box, Button, Typography, TextField as TextFieldMui } from '@mui/material';
import moment from 'moment';
import ModalCustom from '../../Components/UI/ModalCustom';
import React, { useContext, useMemo, useRef, useState } from 'react';
import TableMinimal from '../../Components/UI/Show/TableMinimal';
import { createNotification } from '../helpers/helpFunctions';
import { merchantContext, merchantSelected } from '../../DataProvider';
import SelectMerch from '../../Components/UI/Dashboard/Screen/SelectMerch';
import { FaCashRegister } from 'react-icons/fa';
import CashMachine from '../../Assets/Images/cash-machine.png';
import { CorpContext } from '../socios/SectionsEdit/ContextCorp';
import { CustomReportFilters } from '../Dashboard/Reportes/CustomReport';
import { useQuery } from 'react-query';

const formatDate = (date) => moment(date).format('DD/MM/YYYY - HH:mm');


export const CajaList = () => {
  const [record, setRecord] = useState(false);
  const { getMerchant, setMerchant } = useContext(merchantContext);
  const { setContext } = useContext(CorpContext);
  
  const dataArray = (record) => {
    let obj = [
      {key: 'Email', value: record['email'] },
      {key: 'Fecha de inicio', value: formatDate(record['fecha_inicio']) },
      {key: 'Fecha de cierre', value: formatDate(record['fecha_cierre']) },
      {key: 'Negocio', value: record['merchant_name'] },
      {key: 'Monto', value: currencyFormat.format(record['monto']) },
      {key: 'Comentario', value: record['motivo'] },
    ];
    return obj;
  };

  const currencyFormat = new Intl.NumberFormat('es-ar', {
    style: 'currency',
    currency: 'ARS'
  });

  const handleSetMerch = (merch) => {
    setMerchant(merch);
    setContext(merch);
  };

  const MemoedComponent = useMemo(() => {
    if(getMerchant().id === '' || getMerchant()?.negocio_central) {
      return (
        <SelectMerch 
          message="Debes seleccionar un negocio para visualizar los cierres de caja" 
          Image={CashMachine} 
          imageSize="400px"
          setMerchant={handleSetMerch} 
        />
      );
    } else {
      return (
        <>
          <List
            actions={<ListActions getMerchant={getMerchant} />}
            empty={false}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}

          >
            <DatagridCustom
              rowClick={(e,v, y) => setRecord(y)}
              emptyComponent={
                <Box textAlign="start" height="auto" m={3}>
                  <Typography variant="h7" paragraph>
      No existen Cierre de cajas
                  </Typography>
                </Box>
              }>
              <TextField source="email" />
              <FunctionField label="Fecha de inicio" render={(record) => formatDate(record?.fecha_inicio)} />
              <FunctionField label="Fecha de cierre" render={(record) => formatDate(record?.fecha_cierre)} />
              <FunctionField label="Monto" render={(record) => currencyFormat.format(record['monto'])} />
              <TextField source="merchant_name" label="Negocio" />
            </DatagridCustom>
          </List>
          {
            !!record && (
              <ModalCustom title='Detalle de cierre de caja' open={!!record} onClose={() => setRecord(null)}>
                <TableMinimal minWidth={700} sx={{padding: '20px'}} data={dataArray(record)} />
              </ModalCustom>
            )
             
          }
        </>
      );
    }
  },[getMerchant(), record]);

  return MemoedComponent;
};

const ListActions = ({getMerchant}) => {
  const [open, setOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const disabled = useMemo(() => {
    return getMerchant()?.id !== '' ? false : true;
  }, [getMerchant()?.id]);

  return(
    <TopToolbar>
      <Box style={{display: 'flex', alignItems: 'center'}}>
        <Button disabled={disabled} onClick={() => setOpen(true)}>Crear</Button>
        <Button onClick={() => setOpenReport(true)}>Exportar</Button>
        <CustomReportFilters 
          style={{width: '200px'}}
          text={'Reporte de cierre de caja'} 
          open={openReport} 
          setOpen={setOpenReport}
          variant={'outlined'} 
          showDate
          title={'Reporte de Cierre de cajas'} 
          downloadText={'cierre_de_caja'} 
          url={'reporte_cierre_caja'} 
        />

      </Box>
      {
        !!open && <CreateModal open={open} setOpen={setOpen} />
      }
    </TopToolbar>
  );
};

const CreateModal = ({open, setOpen}) => {
  const [motivo, setMotivo] = useState('');
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const ref = useRef(false);

  const { refetch, isFetching } = useQuery(['cierre_de_caja'], () => dataProvider.create('cierre_caja', {data: {motivo: motivo}}), {
    onSuccess: ({data}) => {
      setOpen(false);
      refresh();
      return createNotification('success', 'Cierre de caja cerrado con éxito.');
    },
    onError: (error) => {
      let err = {...error};
      return createNotification('error', err?.body?.msg ?? 'Hubo un error');
    },
    enabled: false
  });

  return (
    <ModalCustom 
      showButtons 
      title="Cierre de caja" 
      onClickAction={refetch} 
      disabledAction={isFetching}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box ref={ref} sx={{display: 'flex', flexDirection: 'column', padding: '20px', gap: 4}}>
        <Typography variant="h7">Por favor ingresar un motivo por el cierre de caja.</Typography>
        <TextFieldMui 
          onChange={(e) => setMotivo(e.target.value)}
          multiline 
          label="Comentario" 
          minRows={4} 
          variant="outlined"
          InputLabelProps={{shrink: true}}
        />
      </Box>
    </ModalCustom>
  );
};