import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {  required, useDataProvider, TextInput, useRecordContext } from 'react-admin';
import {Autocomplete, FormGroup, MenuItem, Select, Skeleton, TextField, TextField as TextFieldMui} from '@mui/material';
import SectionForm from '../../../Components/Form/SectionForm';
import { getPermissions, getToken } from '../../helpers/helpFunctions';
import { Box, Checkbox, Typography, Button } from '@mui/material';
import { useQuery } from 'react-query';
import useCheckField from '../../../Hooks/useCheckField';
import { DispatchErrorContext } from '../../../Context/menuContext';
import { brandSportClub } from '../../../DataProvider';




const choicesProcessor = [
  {id: true, name: 'MercadoPago'}
];

const informacionMerchant = (central, setCentral) => {
  const record = useRecordContext();
  const isEdit = record?.length > 0 ? true : false;
  const role = localStorage.getItem('permissions');
  const permis = getPermissions();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [name, setName] = useState('');
  const [brands, setBrands] = useState([]);
  const [erase, setErase] = useState(record?.sede_principal ? true : false);
  const [sedeName, setSedeName] = useState('');
  const [sedes, setSedes] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {}
  };
  
  useEffect(()=> {
    refetchSedes();
    if(role == 'owner'){
      const token = getToken();
      dataProvider.getOneFilter(`users?filter={'email': '${token.email}'}`).then(i => setData(i.data[0]));
    }else{
      dataProvider.getOneFilter('users?filter={"role": "owner"}').then(i => setData(i.data));
    }
  }, []);

  role == 'superadmin' && useQuery(
    ['brands'],
    () => dataProvider.getList('brands', params),
    {
      onSuccess: (data) => setBrands(data.data)
    }
  );
  const refetch = useCheckField('merchants', 'name', name);

  const paramsSedes = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'name', order: 'DESC'},
    filter: {}
  };

  const {refetch: refetchSedes, loading} = useQuery(
    ['sedes'],
    () => dataProvider.getList('sedes', paramsSedes),
    {
      onSuccess: ({data}) => data && setSedes(data),
      onError: (err) => console.log(err),
      enabled: false
    }
  );

  const handleCentral = (e) => {
    setCentral(e);
  };

  useEffect(() => {
    if(central){
      setSedeName(null);
      setErase(true);
    } else {
      setErase(false);
    }
  },[central]);
  
  const dataArray = [
    {
      source: 'name',
      label: 'Nombre',
      onChange: (e) => setName(e.target.value),
      checkOnblur: refetch,
      xs: 8, 
      validate: {
        required: 'Falta el campo Nombre en Información'},
    },
    (brandSportClub || (permis === 'superadmin' && (selectedBrand === '6328afb359d052e6817c2200'))) &&  {
      source: 'negocio_central',
      tooltip: 'Un negocio central no posee una sede, y se encarga de administrar la venta de planes que tengan acceso hacia otras sedes.',
      type: 'boolean',
      checked: central,
      optionLabel: 'name',
      disabled: record ? true : !record?.central && 
    ((role !== 'superadmin' && brandSportClub) ? true :
      (role === 'superadmin' && selectedBrand === '6328afb359d052e6817c2200') ? true : false) ,
      onChange: (e) => handleCentral(e.target.checked),
      label: 'SportClub Central',
      xs: 4
    },
    sedes ? {
      source: 'sede_principal',
      label: 'Sede principal',
      type: 'autocomplete',
      onChange: (e) => setSedeName(e),
      value: sedes?.find(item => item.id === sedeName)?.id,
      optionText: 'name',
      optonLabel: 'name',
      optionValue: 'id',
      choices: sedes,
      erase: central,
      disabled: central,
      central: central,
      xs: 6,
    } : {
      custom: true,
      component: <Skeleton width="100%" height={50} />,
      xs: 6
    },
    {source: 'afip.email', label: 'Email', validate: {
      pattern: {
        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'No es un email valido'
      }
    },
    defaultValue: record?.afip?.email && record?.afip?.email,
    xs: 6},
    {source: 'afip.telefono', label: 'Telefono', 
      validate: {
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'No puede contener letras'
        }
      },
      defaultValue: record?.afip?.telefono && record?.afip?.telefono, xs: 6},
    (role == 'superadmin') && {
      source: 'brand_id',
      type: 'select',
      label: 'Brands',
      choices: brands, 
      value: selectedBrand,
      onChange: (e) => setSelectedBrand(e.target.value),
      validate: {required: 'Falta el campo Brand en Información'},
      optionText: 'name',
      optionValue: 'id',
      disabled: isEdit,
      xs: 6
    },
    {
      source: 'application_fee',
      style: {display: 'none'},
      erase: central
    }
  ];

  return dataArray;

  // return (
  //   <SectionForm title='Negocio' data={dataArray} />
  // );
};

export default informacionMerchant;