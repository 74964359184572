import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useDataProvider, useRefresh } from 'react-admin';
import ModalCustom from '../../Components/UI/ModalCustom';
import { createNotification, handleClickCopy } from '../helpers/helpFunctions';


const ModalPayment = ({open, setOpen, record, tickets = [], acceptCash}) => {
  const [ticketDetail, setTicketDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [canPay, setCanPay] = useState(false);
  
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = record.id;

  useEffect(()=> {
    record && dataProvider.getOne('socios', {id: `${id}/resumen-pago`}).then(item => setTicketDetail(item.data));
  }, []);

  const styleInnerBox = {
    display: 'flex',
    flexDirection: 'column', 
    justifyContent:'space-between', 
    textAlign: 'center',
    maxWidth: '370px'
  };

  const checkPay = () => {
    const now = new Date();
    const datePay = new Date(record?.next_payment_date);
    const datePay20 = new Date(record?.next_payment_date).setDate(datePay.getDate() - 19);
    if(now <  datePay && now > datePay20) {
      setCanPay(true);
    }else{
      setCanPay(false);
    }
    tickets?.map((item)=> {
      if(item.status == 'rejected' || item.status == 'error' || item.status == 'expired'){
        setCanPay(false);
        return;
      }
    });
  };

  const handlePay = async () => {
    setLoading(true);
    dataProvider.create('boletas', {
      data: {
        'socio': id,
        'payment_type': payment
        
      }
    }).then(item => {
      setLoading(false);
      createNotification('success', 'Boleta de pago anticipado generada correctamente');
      setOpen(false);
      refresh();
    }).catch(()=> {
      setLoading(false);
      createNotification('error', 'No se pudo generar la boleta de pago', ()=> handleClickCopy({ticketDetail, id}));
      setOpen(false);
    });
  };

  useEffect(()=> {
    checkPay();
    return () => {
      setPayment(null);
    };
  }, [tickets, open]);

  const handleClose = () => {
    !loading && setOpen(false);
  };
  console.log(payment);
  return (
    <ModalCustom 
      open={open} 
      setOpen={setOpen} 
      onClickAction={handlePay} 
      textAction={'Pagar'} 
      disabledAction={!canPay || loading || !payment} 
      showButtons
      onClose={handleClose}
      title='Pago anticipado'
    >
      <Box style={styleInnerBox}>
        {
          loading ? (
            <Box style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress/>
            </Box>
          ) : (
            <>
              {
                !acceptCash ? (
                  <Typography variant='subtitle1'>
                    El plan asociado no acepta pagos en efectivo
                  </Typography>
                ) :
                  canPay ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '30px'
                    }}>
                      <Typography variant='subtitle1'>
                    Total a pagar: <span>${ticketDetail?.final_price} </span>
                      </Typography>
                      <FormControl>
                        <RadioGroup>
                          <FormLabel sx={{color: '#dzdzdz'}}>Elegir el método de pago</FormLabel>
                          <FormControlLabel label="Efectivo" onChange={(e) => setPayment(e.target.value)} control={<Radio value="cash" />} />
                          <FormControlLabel label="Terminal" onChange={(e) => setPayment(e.target.value)} control={<Radio value="terminal" />} />
                          <FormControlLabel label="Link de pago" onChange={(e) => setPayment(e.target.value)} control={<Radio value="link" />} />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Typography variant='subtitle1'>
                    No puedes pagar anticipado si debes una boleta o si ya pagaste el proximo mes
                    </Typography>
                  )
              }
            </>
          )
        }
      </Box>
    </ModalCustom>
  );
};

export default ModalPayment;