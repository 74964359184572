import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { TextInput, useDataProvider, required, BooleanInput, ReferenceInput, AutocompleteInput  } from 'react-admin';
import SectionForm from '../../Components/Form/SectionForm';
import {MdOutlineQrCode} from 'react-icons/md';
import { useRecordContext } from 'react-admin';
import QRCode from 'react-qr-code';

export const paCustom = () => {
  const record = useRecordContext();
  const [molinete, setMolinete] = useState(record?.molinete ? record.molinete : false);
  const [sedes, setSedes] = useState([]);
  const [codigo, setCodigo] = useState('');
  const [sede, setSede] = useState({});
  const dataProvider = useDataProvider();

  useEffect(()=> {
    const params = {
      pagination: {page: 1, perPage: 9999},
      sort: {field: 'name', order: 'DESC'},
      filter: {}
    };
    dataProvider.getList('sedes', params).then((data) => setSedes(data.data));
    record?.sede_id && dataProvider.getOne('sedes', {id: record?.sede_id}).then(res => setSede(res.data)).catch(e => console.log(e));
  }, []);
  
  useEffect(() => {
    setCodigo(`{
        "funcion": "acceso_simple",
        "tipo":"punto_de_acceso",
        "punto_de_acceso_id" : "${record?.id}",
        "prestador": "${sede.slug}"
      }`);
  },[sede]);
  
  const triggerDownload = (imgURI) => {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });
  
    const a = document.createElement('a');
    a.setAttribute('download', `${sede.name + sede.slug}.png`);
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');
    a.dispatchEvent(evt);
  };
  
  const download = async () => {
     
    try {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', 150);
      canvas.setAttribute('height', 150);
      const ctx = canvas.getContext('2d');
  
      ctx.fillStyle = 'white';
      ctx.fillRect(0,0,canvas.width, canvas.height);
      const DOMURL = window.URL || window.webkitURL || window;
      const image = document.getElementById('codigoQr.png');
  
      const imageString = new XMLSerializer().serializeToString(image);
      const img = new Image();
      const imageBlob = new Blob([imageString], {type:  'image/svg+xml;charset=utf-8'});
      const bURL = URL.createObjectURL(imageBlob);
      img.onload = function () {
        ctx.drawImage(img, 11, 11);
        DOMURL.revokeObjectURL(bURL);
  
        const imgUri = canvas.toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        triggerDownload(imgUri);
      };
      img.src = bURL;
          
    } catch (err) {
      console.log(err);
    }
  };

  const secDict = [
    {key: 1, value: '014F0303030A00'},
    {key: 2, value: '014F0303031400'},
    {key: 3, value: '014F0303031E00'},
    {key: 4, value: '014F0303032800'},
    {key: 5, value: '014F0303033200'},
    {key: 6, value: '014F0303033C00'},
    {key: 7, value: '014F0303034600'},
    {key: 8, value: '014F0303035000'},
    {key: 9, value: '014F0303035A00'},
    {key: 10, value: '014F0303036400'},
    {key: 11, value: '014F0303036E00'},
    {key: 12, value: '014F0303037800'},
    {key: 13, value: '014F0303038200'},
    {key: 14, value: '014F0303038C00'},
    {key: 15, value: '014F0303039600'},
    {key: 16, value: '014F030303A000'},
    {key: 17, value: '014F030303AA00'},
    {key: 18, value: '014F030303B400'},
    {key: 19, value: '014F030303BE00'},
    {key: 20, value: '014F030303C800'},
  ];

  const ips = ['54.232.135.107', '18.230.6.50', '54.233.222.137', '52.67.13.255'];

  const data = [
    {
      source: 'molinete',
      label: 'Molinete',
      labelPlacement: 'start',
      type: 'boolean',
      sx: {
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'center',
        gap: '20px'
      },
      onChange: (e) => setMolinete(e.target.checked),
      // defaultChecked: molinete,
      initialValue: molinete,
      xs: 12
    },
    {
      source: 'server_ip',
      label: 'IP',
      choices: ips,
      type: 'select',
      defaultValue: record?.server_ip ? record.server_ip : '18.230.6.50',
      xs: 12
    },
    {
      source: 'description',
      label: 'Descripción',
      xs: 12
    },
    {
      source: 'mac_placa',
      label: 'Mac Placa',
      validate:{
        required: 'El campo de Mac Placa es obligatorio'
      },
      xs: 12,
    },
    {
      source: 'sede_id',
      label: 'Sedes',
      type: 'autocomplete',
      choices: sedes,
      initialValue: record?.sede_id ? sede : null,
      optionLabel: 'name',
      xs: 12
    },
    {
      source: 'comando',
      label: 'Segundos',
      type: 'select',
      defaultValue: !record?.comando ? '014F0303032000' : record?.comando,
      choices: secDict,
      optionLabel: 'key',
      optionValue: 'value',
      xs: 12,
    },
    // {
    //   source: 'comando',
    //   label: 'Comando',
    //   defaultValue: !record?.comando ? '014F0303032000' : record?.comando,
    //   xs: 12
    // },
    sede && record?.id && {
      custom: true,
      component: <>
        <Box sx={{margin: '10px 0', display: 'flex', flexDirection: 'column'}}>
          <QRCode id="codigoQr.png" size={128} value={codigo} style={{display: 'none'}} />
          <Button disabled={!record?.molinete} onClick={() => download()} style={{backgroundColor: `${molinete && sede.id ? '#5584AC' : '#d7ccc8'}`, fontWeight: 400}}> 
            <MdOutlineQrCode size={20} style={{margin: '0 0 0 10px', color: '#FFFFFF'}} />
            <Typography variant="h7" style={{color: '#FFFFFF', fontWeight: 600, margin: '0 10px'}}>Descargar Código Qr</Typography>
          </Button>
  
        </Box>
      </>
    }
  ];

  return data;
};
