import React, { useEffect, useState } from 'react';
import SectionForm from '../../../Components/Form/SectionForm';
import { useDataProvider, useRecordContext } from 'react-admin';
import { getListProvider } from '../../helpers/helpFunctions';

const activityAmenities = () => {
  const record = useRecordContext();
  const [actividades, setActividades] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [activs, setActivs] = useState([])
  const [servs, setServs] = useState([])
  const dataProvider = useDataProvider();

  const params = {
    pagination: {page: 1, perPage: 9999},
    sort: {field: 'id', order: 'DESC'},
    filter: {}
  }

  useEffect(() => {
    // getListProvider(dataProvider, '/actividades', (d,t) => setActividades(d), null,
    //   {page: 1, perPage: 9999}, {field: 'id', order: 'DESC'});

    // getListProvider(dataProvider, '/servicios', (d,t) => setServicios(d), null,
    //   {page: 1, perPage: 9999}, {field: 'id', order: 'DESC'});
    dataProvider.getList('actividades', params).then(res => {
      setActividades(res.data);
        for (let item of res.data) {
          for (let rec of record.actividades){
            if(item.id === rec) {
              setActivs((f) => ([...f, item]))
            }
          }
        }
      
    })
    dataProvider.getList('servicios', params).then(res => {
      setServicios(res.data);
      for (let item of res.data) {
        for (let rec of record.amenities){
          if(item.id === rec) {
            setServs((f) => ([...f, item]))
          }
        }
      }
    })
      
    
  }, []);
  
  const data = [
    actividades && {
      source: 'amenities',
      label: 'Servicios',
      choices: servicios,
      onChange: (e) => setServs(e),
      value: servs,
      optionLabel: 'name',
      type: "multiple_autocomplete",
      xs: 12
    },
    servicios && {
      source: 'actividades',
      label: 'Actividades',
      choices: actividades,
      optionLabel: 'name',

      onChange: (e) => setActivs(e),
      value: activs,
      type: "multiple_autocomplete",
      xs: 12
    }
  ];
 
  return data
};

export default activityAmenities;