import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { SedeList } from './SedesList';
import { SedeEdit } from './SedesEdit';
import { SedeCreate } from './SedesCreate';
import SedesShow from './SedesShow';
import { AiTwotoneShop } from 'react-icons/ai';

export default{
  icon: AiTwotoneShop,
  list: SedeList,
  show: SedesShow,
  create: SedeCreate,
};