import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { dateFormat, getPermissions } from '../../../Resources/helpers/helpFunctions';
import TableMinimal from '../Show/TableMinimal';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { styleTabbleData, styleTableHead } from '../../../Theme/useStyle';

const rowBoxStyle = {
  display: 'flex',
  // justifyContent: 'space-between',
  borderBottom: '1px solid #e8e8e8',
  paddingBottom: '10px',
  width: '100%'
};

const VersionHistory = ({ record }) => {
  const versionSlice = record?.version_control?.slice(-5).reverse();
  let version = null;
  if (record?.created_by) {
    version = {
      modified_by: record.created_by,
      date: dateFormat(record.created_at),
      previous_status: {
        creado_por: 'created'
      }
    };
  }

  if (version) {
    versionSlice?.push(version);
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
      {
        versionSlice?.map((item, index) => <RowHistory key={item.date} row={item} />)
      }
    </Box>
  );
};

const RowHistory = ({ row }) => {
  const [newVc, setNewVc] = useState({});
  const prevStatus = !row.source && Object.keys(row?.previous_status ? row.previous_status : {});
  const permis = getPermissions();

  const uppercaseString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (row.source) {
      const data = new Object();
      data.source = row.source;
      data.source = row.source;
      data.date = row.modified_at;
      data.modified_by = row.modified_by;
      data.changes = new Array();
      row.changes.map(item => {
        data.changes.push(item);
      });
      setNewVc(data);
    }
    return () => {
      setNewVc([]);
    };
  }, []);

  return (
    <>
      <Box style={{
        borderBottom: '1px solid #e8e8e8',

      }}>
        <Box style={{ ...rowBoxStyle, borderBottom: 'none' }}>
          <Box style={{ display: 'flex', width: '50%', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography style={{ fontSize: '14px', color: '#64648c' }}>{row?.modified_by}</Typography>
            <Typography style={{ fontSize: '12px', color: '#767676' }}>{dateFormat(row?.date ?? newVc?.date)}</Typography>
            {row?.source && <Typography style={{ fontSize: '12px', color: '#767676' }}>{newVc?.source}</Typography>}
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start', width: '100%' }}>
              {
                row.source ? (
                  newVc?.changes?.map((item, i) => (
                    <Typography key={i} style={{ fontSize: '14px', color: '#767676' }}>
                      {uppercaseString(item?.field)}
                      {i !== newVc.changes.length - 1 && <span>, &nbsp;</span>}
                    </Typography>
                  ))
                ) : prevStatus?.map((item, index) => (
                  <Typography key={index} style={{ fontSize: '14px', color: '#767676' }}>
                    {uppercaseString(item.replace(/[_-]/g, ' '))}
                    {index !== prevStatus.length - 1 && <span>,&nbsp;</span>}
                  </Typography>
                ))

              }
            </Box>
          </Box>
        </Box>
        <Box>
          {(permis === 'superadmin' || permis === 'owner') && !row.source && <SeeMore row={row?.previous_status} />}
          {(permis === 'superadmin' || permis === 'owner') && row.source && <SeeMoreVc changes={newVc?.changes} />}
        </Box>
      </Box>
    </>
  );
};

export default VersionHistory;


const SeeMore = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}>
      {open ? (
        <IconButton aria-label="span" onClick={() => setOpen(false)}>
          <KeyboardArrowUpOutlinedIcon style={{ fontSize: '20px' }} />
        </IconButton>
      ) : (
        <IconButton aria-label="collapse" onClick={() => setOpen(true)}>
          <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
        </IconButton>
      )}
      <Collapse in={open}>
        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {JSON.stringify(row)}
        </Box>
      </Collapse>
    </Box>
  );
};

const SeeMoreVc = ({ changes }) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const handleLower = (obj) => {
    let arr = [];
    if(typeof obj === 'object') {
      Object.keys(obj).map((field, i) => {
        let newObj = {};
        newObj.key = field;
        newObj.value = Object.values(obj)[i];
        arr.push(newObj);
      });
    }
    return arr;
  };

  return (
    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}>
      {open ? (
        <IconButton aria-label="span" onClick={() => handleClose(false)}>
          <KeyboardArrowUpOutlinedIcon style={{ fontSize: '20px' }} />
        </IconButton>
      ) : (
        <IconButton aria-label="collapse" onClick={() => setOpen(true)}>
          <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
        </IconButton>
      )}
      <Collapse style={{ width: '100%' }} in={open}>
        <Table>
          <TableHead style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
            <TableRow style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', width: '100%'}}>
              <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Campo</TableCell>
              <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Valor Previo</TableCell>
              <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Valor Nuevo</TableCell>
              <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Cambios</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '0 24px' }}>
            {changes && changes?.map((item, i) => (
              <>
                <TableRow style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%'}} key={i}>
                  <TableCell style={styleTabbleData}>{item?.field}</TableCell>
                  {((typeof item?.previous_value === []) || (typeof item?.previous_value === 'object')) && item?.new_value ? (
                    <>
                      <Box>
                        {open2 ? (
                          <IconButton aria-label="span" onClick={() => setOpen2(false)}>
                            <KeyboardArrowUpOutlinedIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        ) : (
                          <IconButton aria-label="collapse" onClick={() => setOpen2(true)}>
                            <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        )}
                      </Box>
                    </>
                  ) : (
                    (<TableCell style={styleTabbleData}>{JSON.stringify(item?.previous_value)}</TableCell>)
                  )
                  }
                  {((typeof item?.new_value === []) || (typeof item?.new_value === 'object')) && item?.new_value ? (
                    <>
                      <Box>
                        {open2 ? (
                          <IconButton aria-label="span" onClick={() => setOpen2(false)}>
                            <KeyboardArrowUpOutlinedIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        ) : (
                          <IconButton aria-label="collapse" onClick={() => setOpen2(true)}>
                            <KeyboardArrowDownOutlinedIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        )}
                      </Box>
                    </>
                  ) : (
                    (<TableCell style={styleTabbleData}>{JSON.stringify(item?.new_value)}</TableCell>)
                  )
                  }
                  <TableCell style={styleTabbleData}>{item?.change_type}</TableCell>
                </TableRow>
                {((typeof item?.previous_value === []) || (typeof item?.previous_value === 'object')) && item?.new_value && 
                <TableRow style={{width: '100%'}}>
                  <Collapse style={{width: '100%'}} in={open2}>
                    <Table>
                      <TableHead style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }}>
                        <TableRow style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', alignItems: 'end'}}>
                          <TableCell style={{...styleTableHead,  fontSize: '10px', alignSelf: 'start'}}>Valor Previo</TableCell>
                          <TableCell style={{...styleTableHead,  fontSize: '10px', alignSelf: 'start'}}>Valor Nuevo</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '0 24px' }}>
                        {
                          changes?.map((item, i) => (
                            <>
                              <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', overflow: 'hidden'}}>
                                {
                                  <TableRow style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', alignItems: 'end'}}>{
                                    item?.previous_value ? handleLower(item?.previous_value).map((value, i) => (
                                      <>
                                        <TableCell style={{...styleTabbleData, width: '50%',  fontSize: '10px', alignSelf: 'start', overflow: 'hidden'}}><Typography style={{fontSize: '8px'}}>{JSON.stringify(value?.key)}</Typography></TableCell>
                                        <TableCell style={{...styleTabbleData, width: '50%',  fontSize: '10px', alignSelf: 'start', overflow: 'hidden'}}>{JSON.stringify(value?.value)}</TableCell>
                                      </>
                                    )) : (
                                      <>
                                        <TableCell style={{...styleTabbleData,  fontSize: '10px', alignSelf: 'start'}} />
                                        <TableCell style={{...styleTabbleData,  fontSize: '10px', alignSelf: 'start'}} />
                                      </>
                                    )}
                                  </TableRow>
                                }
                                {
                                  <TableRow style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', alignItems: 'end', overflow: 'hidden'}}>{
                                    item?.new_value ? handleLower(item?.new_value).map((value, i) => (
                                      <>
                                        <TableCell style={{...styleTabbleData, width: '50%', fontSize: '10px', alignSelf: 'start', overflow: 'hidden'}}><Typography style={{fontSize: '10px'}}>{JSON.stringify(value?.key)}</Typography></TableCell>
                                        <TableCell style={{...styleTabbleData, width: '50%',  fontSize: '10px', alignSelf: 'start', overflow: 'hidden'}}><Typography style={{textOverflow: 'clip', maxWidth: '100px', fontSize: '10px'}}>{JSON.stringify(value?.value)}</Typography></TableCell>
                                      </>
                                    )) : (
                                      <>
                                        <TableCell style={{...styleTabbleData,  fontSize: '10px', alignSelf: 'start'}} />
                                        <TableCell style={{...styleTabbleData,  fontSize: '10px', alignSelf: 'start'}} />
                                      </>
                                    )}
                                  </TableRow>
                                }
                              </Box>

                            </>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableRow>
                
                }
              </>
            ))}
          </TableBody>
          
        </Table>
        
      </Collapse>
      
    </Box>
  );
};