import { Box, Button, Modal, Pagination, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ModalCustom from '../../../Components/UI/ModalCustom';
import { createNotification, getMerchantId, strCapitalize } from '../../helpers/helpFunctions';
import { styleTableHead } from './CobrosDataGrid';
import { ActionsDiv, MenuActionsDiv } from '../../../Components/UI/Options/ActionsOptions';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import { useContext, useEffect, useRef, useState } from 'react';
import { apiUrl, merchantCadena, merchantContext } from '../../../DataProvider';
import axios from 'axios';
import { useDataProvider, useRefresh } from 'react-admin';
import { styleInnerBox } from '../../../Components/UI/ListTickets';
import { useQuery } from 'react-query';
import moment from 'moment';
import { Spinner } from '../../../Components/UI/Dashboard/Spinner/Spinner';
import DisabledMenu from '../../../Assets/Images/DisabledMenu.png';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';

const TIPO_COMPROBANTE = {
  1: 'factura_a',
  3: 'nota_credito_a',
  6: 'factura_b',
  8: 'nota_credito_b',
  11: 'factura_c',
  13: 'nota_credito_c'
};

const params = {
  pagination: {page: 1, perPage: 9999},
  sort: {field: 'name', order: 'DESC'},
  filter: {}
};

export const ModalComp = ({ open, setOpen, item, ...rest }) => {

  const [comprobantes, setComprobantes] = useState(null);
  const [comprobantesCadena, setComprobantesCadena] = useState(null);
  const dataProvider = useDataProvider();

  const { refetch, isFetching } = useQuery(['comprobantes'], async () => {
    
    if(item.data_facturacion) {
      try {
        const response = await dataProvider.getList('facturas', {...params, filter: {boleta_id: item.id}});
        return response;
      } catch (error) {
        throw new Error('Error');
      }
    }
  }, {
    onSuccess: (res) => {
      const compCadena = res?.data?.filter(item => { if(item?.data_facturante?.merchant_id === merchantCadena) return item;}).map(item => item);
      const compSeller = res?.data?.filter(item => { if(item?.data_facturante?.merchant_id !== merchantCadena) return item;}).map(item => item);
      
      setComprobantesCadena(compCadena);
      setComprobantes(compSeller);
    },
    onError: (err) => {
      console.error(err);
    },
    enabled: false
  });

  useEffect(() => {

    refetch(); 
    return () => {
      setComprobantes(null);
    };
  }, [open]);

  return(
    <ModalCustom
      open={open}
      setOpen={setOpen}
      title="Comprobantes"
    >
      {
        isFetching ? (
          <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <Spinner />
          </Box>
        ) : (
          <TableContainer 
            component={Paper}
            colSpan={2}
            sx={{
              boxShadow: 'none',
              overflowX: 'hidden',
              width: '100%',
              minHeight: '300px',
            }}>
            {
              comprobantes?.length > 0 && (
                <Box sx={{marginBottom: '50px'}}>
                  <Typography sx={{fontWeight: '600', fontSize: '.8em'}}>Razon social: {comprobantes[0].data_facturante.razon_social}</Typography>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{...styleTableHead, minWidth: '140px'}}>Concepto</TableCell>
                        <TableCell style={{...styleTableHead}}>Precio</TableCell>
                        <TableCell style={{...styleTableHead}}>F. Emisión</TableCell>
                        <TableCell style={{...styleTableHead, minWidth: '120px'}}>Tipo</TableCell>
                        <TableCell style={{...styleTableHead}}>Método de pago</TableCell>
                        <TableCell style={{...styleTableHead}}>N° de comp</TableCell>
                        <TableCell style={{...styleTableHead}}>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
              
                    <TableBody>
                      {
                        comprobantes?.length > 0 ? comprobantes?.map((comp, index) => {
                          return (
                            <Row boleta={item} item={comp} refetch={refetch} index={index} />
                          );
                        }) : (
                          <TableRow>
                            <TableCell colSpan={'100%'}>
                      No existen comprobantes para esta boleta.
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                
                  </Table>
                </Box>
              )
            }
            {
              comprobantesCadena?.length > 0 && (
                <Box>
                  <Typography sx={{fontWeight: '600', fontSize: '.8em'}}>Razon social: {comprobantesCadena[0].data_facturante.razon_social}</Typography>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{...styleTableHead, minWidth: '140px'}}>Concepto</TableCell>
                        <TableCell style={{...styleTableHead}}>Precio</TableCell>
                        <TableCell style={{...styleTableHead}}>F. Emisión</TableCell>
                        <TableCell style={{...styleTableHead, minWidth: '120px'}}>Tipo</TableCell>
                        <TableCell style={{...styleTableHead}}>Método de pago</TableCell>
                        <TableCell style={{...styleTableHead}}>N° de comp</TableCell>
                        <TableCell style={{...styleTableHead}}>Acciones</TableCell>

                      </TableRow>
                    </TableHead>
                
                  
                    <TableBody>
                      {
                        comprobantesCadena?.length > 0 ? comprobantesCadena?.map((comp, index) => {
                          return (
                            <Row boleta={item} item={comp} refetch={refetch} index={index} />
                          );
                        }) : (
                          <TableRow>
                            <TableCell colSpan={'100%'}>
                      No existen comprobantes para esta boleta.
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  
              
                  </Table>
                </Box>

              )
          
            }
          </TableContainer> 
        )}

      {/* <Pagination
        component="div"
      /> */}
    </ModalCustom>
  );
};

export const Row = (props) => {
  const {
    item,
    refetch,
    index,
    boleta
  } = props;
  const styleTabbleData = {
    padding: '0 0 8px 18px',
    color: '#64648C',
    border: 'none',
    zIndex: 9999999
  };

  console.log('item', item?.data_factura);

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
      // background: '#efefef',
      // cursor: 'pointer',
      overflowX: 'hidden',
      width: '100%'
    }, }}>
      <>
        <TableCell style={{...styleTabbleData, maxWidth: '100px', padding: '0 0 0 10px'}}>{item?.data_plan?.plan_name}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>$ {item?.data_venta?.importe_total}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{moment(item?.data_factura?.fecha_factura).format('DD/MM/YYYY')}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{strCapitalize(TIPO_COMPROBANTE[item?.data_factura?.tipo_comprobante]).replace(/_/g, ' ')}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{item?.data_factura?.payment_type}</TableCell>
        <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{item?.data_factura?.num_comprobante}</TableCell>

        <TableCell 
          style={{ 
            ...styleTabbleData,
            display: 'flex',
            flexDirection: 'columny',
            height: '42px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 0 0 10px'
          }}
        >
          {
            (!boleta?.nota_credito_id && !item.excluded) ? (<RowAction id={item.id} comp={item} refetchCobros={refetch} item={boleta} key={index} />) : (<img title="Disabled Menu" style={{ borderRadius: '4px', width: '16px'}} src={`${DisabledMenu}`} /> )
          }
        </TableCell>
      </>
    </TableRow>
  );
};

export const RowAction = (props) => {

  const {
    item: {data_facturacion: item}, 
    refetchCobros,
    id,
    comp
  } = props;
  const ref = useRef();

  const [loadingAction, setLoadingAction] = useState({verFactura: false, cobrarFactura: false, changeStatus: false, marcarFactura: false});
  const [openMenu, setOpenMenu] = useState(false);
  const { getMerchant } = useContext(merchantContext);
  const refresh = useRefresh();

  useOnClickOutside(ref, () => setOpenMenu(false));

  const isCred = [3, 8, 13].includes(comp?.data_factura?.tipo_comprobante);
  
  const verFactura = async () => {   
    if(item?.factura_id) {
      setLoadingAction({...loadingAction, verFactura: true});
      const token = localStorage.getItem('auth');
      const tokenSend = await JSON.parse(token)?.token;
      const checkUrl = `${apiUrl}/pdf/${id}`;

      const axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Access-Tokens': tokenSend,
          'access-control-allow-origin': '*',
          'context-merchant': getMerchant()?.id
        }, 
        responseType: 'blob'
      };

      const handleRequest = (url) => {
        return axios({
          url: url,
          method: 'GET',
          ...axiosConfig
        });
      };

      const promiseHandler = (res) => {
        setLoadingAction({...loadingAction, verFactura: false});
        if(res) {
          setOpenMenu(false);
          const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('target', '_blank');
          link.setAttribute('download', res.headers.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
      const catchHandler = e => {
        setLoadingAction({...loadingAction, verFactura: false});
        createNotification('error', 'No se encontro la factura, vuelva a intentar más tarde');
      };
      await handleRequest(checkUrl).then(promiseHandler).catch(catchHandler);
    } 
  };

  const changeStatusBoleta =  async () => {
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/editar_boleta/${item.boleta_id}`;
    return axios({
      url: checkUrl,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'context-merchant': getMerchant()?.id
      },
      data: {status: 'refunded', excluded: true}
    });
  };
  
  const emitirNota = async () => {
    setLoadingAction({...loadingAction, cobrarFactura: true});
    const token = localStorage.getItem('auth');
    const tokenSend = await JSON.parse(token)?.token;
    const checkUrl = `${apiUrl}/facturas/${id}/nota_credito`;
    
    axios({
      url: checkUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Access-Tokens': tokenSend,
        'access-control-allow-origin': '*',
        'context-merchant': getMerchant()?.id
      }
    }).then((data) => {
      createNotification('success', `${data?.data?.msg}`);
      setLoadingAction({...loadingAction, cobrarFactura: false});
      setOpenMenu(false);
      createNotification('success', `${data?.data?.msg}`);
      refetchCobros();
    }).catch((err) => {
      setLoadingAction({...loadingAction, cobrarFactura: false});
  
      createNotification('error', `${err?.response?.data?.msg}`);
    });
    refresh();
  };

  return (
    <>
      <MenuActionsDiv position="relative" sx={{position: 'relative'}} ref={ref}>
        <MenuIcon onClick={(e) => {e.stopPropagation(); setOpenMenu(!openMenu);}}
          sx={{position: 'relative', borderRadius: '4px', cursor: 'pointer', '&:hover':{ backgroundColor: '#cfd8dc'}}}/>
        {openMenu && (
          <ActionsDiv id="actionsId">
            {(!item?.nota_credito_id && item?.factura_id && !item.excluded && !isCred) && (
              !loadingAction.cobrarFactura ? <Button disabled={!item?.factura_id || getMerchant()?.id === '' || item?.nota_credito_id} onClick={emitirNota} style={{fontSize: '0.8em'}}>Emitir N. de crédito</Button> : <Skeleton height="20px" />
            )}
            {item?.factura_id && (
              !loadingAction.verFactura ? <Button disabled={!item?.factura_id} onClick={verFactura} style={{fontSize: '0.8em'}}>Ver comprobante</Button> : <Skeleton height="20px" />
            )}
          </ActionsDiv>
        )}
      </MenuActionsDiv>
      
    </>
  );
};