import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import {CardAws} from '../../../Components/CustomFields/CardAws';
import SectionForm from '../../../Components/Form/SectionForm';

const imagesEdit = () => {
  const record = useRecordContext();
  // const defaultUrl = 'https://muvimages.s3-us-east-2.amazonaws.com/Header-landing-Empresas---mobile.jpg';
  const defaultDesktop = 'https://muvimages.s3-us-east-2.amazonaws.com/DESKTOPjpg.jpeg';
  const defaultMobile = 'https://muvimages.s3-us-east-2.amazonaws.com/MOBILEjpg.jpeg';
  const defaultLaptop = 'https://muvimages.s3-us-east-2.amazonaws.com/NOTEBOOKjpg.jpeg';
  // const initalUrl = hasRecord ? hasRecord.url : defaultUrl ? defaultUrl : '';
  const [urlSmall, setUrlSmall] = useState(record?.['hero-1000x700']?.url ? record?.['hero-1000x700'].url : defaultMobile);
  const [urlMedium, setUrlMedium] = useState(record?.['hero-1280x523']?.url ? record?.['hero-1280x523'].url : defaultLaptop);
  const [url, setUrl] = useState(record?.['hero']?.url ? record?.['hero'].url : defaultDesktop);

  const data = [
    {
      source: 'hero-1000x700.url',
      value: urlSmall,
      displayNone: true
    },
    {
      source: 'hero-1280x523.url',
      value: urlMedium,
      displayNone: true
    },
    {
      source: 'hero.url',
      value: url,
      displayNone: true
    },
    {
      custom: true, 
      component: 
      <>
        <Box style={{display: 'flex', flexWrap: 'nowrap'}}>
          <Box display='flex' justifyContent='flex-start'>
            <Box marginRight='30px'>
              <CardAws setUrl={setUrlSmall} url={urlSmall} source='hero-1000x700.url' text='Imagen Mobile (1000x700)'/>
            </Box>
          </Box>
          <Box display='flex' justifyContent='flex-start'>
            <Box marginRight='30px' >
              <CardAws setUrl={setUrlMedium} url={urlMedium} source='hero-1280x523.url' text='Imagen Laptop (1280x523)' />
            </Box>
          </Box>
          <Box display='flex' justifyContent='flex-start'>
            <Box marginRight='30px'>
              <CardAws setUrl={setUrl} url={url} source='hero.url' text='Imagen Desktop (2500x832)' />
            </Box>
          </Box>
        </Box>
      </>
    }
  ];

  return data;
};

export default imagesEdit;