import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { BiHome } from 'react-icons/bi';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import { dateFormat } from '../../helpers/helpFunctions';
import domicilioSocio from '../SectionsEdit/DomicilioSocio';

const DetailsCard = () => {
  const record = useRecordContext();
  const detailsArray = record && [
    {key: 'ID Sport Access', value: record.sportaccess_id},
    {key: 'Provincia', value: record.domicilio?.provincia.toUpperCase()},
    {key: 'Localidad', value: record.domicilio?.localidad.toUpperCase()},
    {key: 'Calle', value: record.domicilio?.calle + ' ' + record.domicilio?.altura},
    {key: 'Ultima Suscripción', value: dateFormat(record?.last_subscription_date?.slice(0,10))},
    {key: 'Apto Lote', value: record.domicilio?.apto_lote},
  ];

  const uppercaseString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const transform = (v) => {
    let ret = {
      ...v,
      domicilio: {
        ...v.domicilio,
        calle: uppercaseString(v.domicilio.calle),
        localidad: uppercaseString(v.domicilio.localidad)
    
      }
    };
    if(!ret?.domicilio?.apto_lote) delete ret.domicilio.apto_lote;
    return ret;
  };


 

  const editComponent =  {
    component: domicilioSocio(record),
    label: 'Domicilio',
    icon : <BiHome/>
  };
 
  return (
    <CardBasic transform={transform} route={'socios'} editComponent={editComponent} record={record} title='Detalles del socio'>
      <TableMinimal data={detailsArray}/>
    </CardBasic>
  );
};

export default DetailsCard;
