import React, { useEffect,  useState } from 'react';
import { Avatar, Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDataProvider, useRecordContext } from 'react-admin';
import { dateFormat, getListProvider, uppercaseString } from '../../helpers/helpFunctions';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import ListAccess from './ListAccess';
import { emptyTextStyle, iconsChips} from '../../../Theme/useStyle';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import { useQuery } from 'react-query';
import informacionSocio from '../SectionsEdit/InformacionSocio';
import ModalCustom from '../../../Components/UI/ModalCustom';
import FormTab from '../../../Components/Form/FormTab';
import { BiUser } from 'react-icons/bi';

const accessCard = {
  marginTop: '15px',
  display: 'flex',
  justifyContent: 'space-between' 
};

const CardHeader = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [planCorp, setPlanCorp] = useState(null);
  const [seller, setSeller] = useState(null);
  const [open, setOpen] = useState(false);
  useEffect(()=> {
    (record && record.seller_merchant_id) && 
      dataProvider.getOne('merchants', {id: record?.seller_merchant_id}).then(data => {
        setSeller(data?.data);
      });
  }, []);

  const chips = [
    {label: record?.documento, icon: <BadgeOutlinedIcon style={iconsChips}/>},
    {label: record?.celular, icon: <PhoneOutlinedIcon style={iconsChips}/>},
    record?.nacimiento !== '22/02/2022' && 
      {label: dateFormat(record?.nacimiento), icon: <CakeOutlinedIcon style={iconsChips}/>},
    planCorp && {label: planCorp.name, icon:<CorporateFareOutlinedIcon style={iconsChips}/>},
    
  ];

  const editComponent = {
    component: informacionSocio(),
    label: 'Informacion Socio',
    icon: <BiUser />
  };

  const transform = (v) => {
    const date = v.nacimiento.split('-');
    if(date.length > 2) {
      const ret = {...v, 
        nacimiento: `${date[2]}/${date[1]}/${date[0]}`,
        nombre: v.nombre.trim(),
        apellido: v.apellido.trim(),
        email: v.email.trim()
      };
      return ret;
    } 
    return {...v,
      nombre: uppercaseString(v.nombre.trim()),
      apellido: uppercaseString(v.apellido.trim()),
      email: v.email.trim(),
    };
    
  };

  const StatusComponent = () => {
    const colorStatus = record?.status == 'activo' ? 'success' : 'error';
    return(
      <Box>
        <Chip size='small'
          color={colorStatus}
          variant='outlined' 
          icon={colorStatus == 'success' ? 
            <CheckCircleOutlineOutlinedIcon /> :
            <CancelOutlinedIcon style={{marginRight: '1px'}}/>
          } 
          label={record?.status} 
          style={{paddingLeft: '5px'}}
        />
      </Box>
    );
  };
  return (
    <>
      <CardBasic transform={transform} route={'socios'} record={record} editComponent={editComponent}>
        <HeaderCardShow
          chips={chips} 
          title={`${record?.nombre} ${record?.apellido}`}
          subtitle={record?.email}
          status={<StatusComponent />}
          showImage
          urlImage={record?.foto_url ? record?.foto_url : ''}
        />
        {seller &&
        <Box sx={{margin: '12px 0'}}>
          <Typography variant='subtitle2'>Negocio vendedor: {seller.name}</Typography>
        </Box>
        }
        <RowsAccess idSocio={record?.id}/>
      </CardBasic>
   
    </>
  );
};

const RowsAccess = ({idSocio}) => {
  const [access, setAccess] = useState(null);
  const dataProvider = useDataProvider();

  const params = {
    pagination: {page: 1, perPage: 5},
    sort: {field: 'date', order: 'DESC'},
    filter: {}
  };

  const { isFetching } = useQuery(
    ['socios/accesos', {id: idSocio}, 1], 
    () =>  dataProvider.getList(`socios/${idSocio}/accesos`, params),
    {
      onSuccess: (e) => setAccess(e.data.slice(0,3)),
    }
  );

  return(
    <Box>
      {
        isFetching ? (
          <Box style={{width: '100%', marginTop: '12px'}}>
            <Skeleton style={{width: '70%'}}/>
            <Skeleton style={{width: '60%'}}/>
            <Skeleton style={{width: '65%'}}/>
          </Box>
        ) : (
          <Box style={access?.length > 0 ? accessCard : {margin: '6px 0 0 0'}}>
            <ListAccess access={access}/>
            {access?.length == 0 &&(
              <Box sx={{marginTop: '6px'}}>
                <Typography variant='body2'>No hay registros de accesos para este socio</Typography>
              </Box>
            )
            }
          </Box>
        )
      }
    </Box>
  );
};


export default CardHeader;
