import React, {useEffect, useState} from 'react';
import { Box } from '@mui/material';
import CardContent from './Card/CardContent';
import CardDashboard from './Card/CardDashboard';


const RechazosCard = ({rechazosAnuales, isFetchingRechazos, cobrosAnuales, date}) => {
  const [datasets, setDatasets] = useState([]);

  const helpText = 'Porcentaje del monto a cobrar que fue rechazado en el primer intento, segmentado por el cobrado en reintentos y el no cobrado.';

  useEffect(() => {
    let data = rechazosAnuales?.data?.json?.data;
    setDatasets([]);
    const newRechazosArray = [];
    let totales = 0;
    let approved = 0;
    let rejected = 0;
    data && Object.keys(data)
      .reverse()
      .forEach(e => {
      
        newRechazosArray.push({
          key: e,
          values: data[e]
        });
      });
    
    for(let i = 0; i < newRechazosArray.length; i++) {
      let obj = {
        key: newRechazosArray[i].key
      };
      for (let j = 0; j < Object.keys(newRechazosArray[i].values).length; j++) {
        if(Object.keys(newRechazosArray[i].values)[j] === 'totales') {
          obj = {...obj, totales: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'approved') {
          obj = {...obj, approved: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'charged_back') {
          obj = {...obj, charged_back: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'rejected') {
          obj = {...obj, rejected: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'in_process') {
          obj = {...obj, in_process: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'error') {
          obj = {...obj, error: Object.values(newRechazosArray[i].values)[j].monto};
        } else if (Object.keys(newRechazosArray[i].values)[j] === 'expired') {
          obj = {...obj, expired: Object.values(newRechazosArray[i].values)[j].monto};
        } 
      }
      setDatasets((f) => [...f, obj]);
     
    }
   
  },[rechazosAnuales]);

  return (
    <>
      <Box sx={{ width: '100%'}}>
        <Box 
          sx={{
            display: 'flex', 
            flexDirection: 'column', 
            width: {xs: '100%'},
            height: 'auto', 
            justifyContent: 'space-between'
          }}
        >
          <CardDashboard noPad>
            <CardContent helpText={helpText} date={date} isFetching={isFetchingRechazos} name={'Rechazos Anuales'} data noCard rechazos cobrosAnuales={cobrosAnuales} rechazosDatasets={datasets} chartBar rechazosReporte />
          </CardDashboard>
        </Box>
      </Box>
    </>
  );
};

export default RechazosCard;