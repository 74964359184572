import { Box, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { styleTabbleData, styleTableHead } from '../../Theme/useStyle';
import moment from 'moment';
import { dateFormat } from '../../Resources/helpers/helpFunctions';
import { useRecordContext } from 'react-admin';


const PaginationForm = ({cols, rows, sortedField}) => {

  const [numberPage, setNumberPage] = useState(0);
  const [page, setPage] = useState([]);
  const count = Math.ceil(rows?.length / 5);
  const loopFunction = (data) => {
    let sortedData = sortedField ? data.sort((a,b) => a?.[sortedField] > b?.[sortedField] ? -1 : 1) : data;
    let up = 0;
    let arr = [];
    let pageArr = [];
    
    for(let i = 0; i <= sortedData.length; i++){
      if ((up / 5 === 1) || (i === sortedData.length)) {
        pageArr.push(arr);
        arr = [];
        up = 0;
        arr.push(sortedData[i]);
        continue;
      }
      arr.push(sortedData[i]);
      up += 1;
    }
    return pageArr;
  };

  useEffect(() => {
    setPage(loopFunction(rows));
    return () => {
      setPage([]);
    };
  },[rows]);

  return(

    <>
      <Table>
        {
          rows?.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  {
                    cols?.map((item, i) => (
                      <TableCell style={styleTableHead}>{item?.name}</TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  page[numberPage]?.map((item, i) => {
                    let row = item;
                    return(
                      <>
                        <Row key={i} row={row} cols={cols} />
                      </>
                    );
                  })
                }
              </TableBody>
            </>
          ) : (
            <Box>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
            </Box>
          )
        }
      </Table>
      {rows.length > 5 && <Pagination onChange={(e, v) => setNumberPage(v - 1)} count={count} />}
    </>
  );
};

export default PaginationForm;

const Row = ({row, cols}) => {
  return(
    <>
      <TableRow>
        {
          cols?.map((item, i) => (
            <TableCell style={styleTabbleData}>{
              item?.key.includes('date') ? dateFormat(row[item.key]) : row[item.key]
            }</TableCell>
          )
          )
        }
      </TableRow>
    </>
  );
};