import './Spinner.css';

export const Spinner = () => {


  return (
    <div className={'spinner'}>
      <div className={'double-bounce1'}></div>
      <div className={'double-bounce2'}></div>
    </div>
  );
};