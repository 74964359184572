import {useEffect, useMemo, useState} from 'react';

import { Box, Typography, Divider } from '@mui/material';

import { theme } from '../../../Theme/theme';
import { IOSSwitch } from '../../../Components/UI/Switch/IOSSwitch';
import { PopoverComponent } from '../../../Components/Notifications/PopoverComponent';
import { grey } from '@mui/material/colors';

const defaultDate = new Date(3000, 1, 1);
const today = new Date();


export const CheckInComponent = ({
  record,
  setAfip,
  afip, 
  setDateCheck,
  dateCheck, 
  checkBool, 
  setCheckBool, 
  showCheck,
  setShowCheck,
  split,
  setSplit
}) => {

  const fieldValidation = () => {
    const fields = [
      afip?.cuit?.length === 11,
      afip?.razon_social,
      afip?.pto_venta,
      afip?.ii_bb,
      afip?.inicio_de_actividades,
      afip?.domicilio?.provincia,
      afip?.domicilio?.localidad,
      afip?.domicilio?.calle,
      afip?.domicilio?.altura,
      afip?.domicilio?.['código postal']
    ];
    return fields.every(Boolean);
  };
    
  const [activate, setActivate] = useState(fieldValidation());
  const [validate, setValidate] = useState(record?.afip?.activate_facturacion ? record.afip.activate_facturacion : null);
  const [cobrosValidate, setCobrosValidate] = useState(record?.afip?.facturar_cobros ? record.afip.facturar_cobros : null);

  useEffect(() => {
    setActivate(fieldValidation());
    if(activate) {
      setValidate(true);
    } 
    if(showCheck && !fieldValidation()){
      setShowCheck(false);
    }
  },[afip]);

  const handlerButton = () => {
    if(activate) {
      setValidate(true);
      setShowCheck(!showCheck);
    } else if(!activate) {
      setValidate(false);
      setDateCheck(null);
    }
  };


  useEffect(() => {
    if(cobrosValidate){
      return setAfip({...afip,
        date_init_cobros: today
      });
    } else {
      return setAfip({...afip,
        date_init_cobros: defaultDate
      });
    }
  },[cobrosValidate]);

  const handleChange = (e) => {
    let value = e.target.checked;
    return setAfip({...afip, ['tipo_facturacion']: !value ? 'total' : 'dividida'});
  };

  const handleChangeFacturaA = (e) => {
    let value = e.target.checked;
    return setAfip({...afip, ['acepta_factura_a']: value});
  };

  const enableEfectivo = useMemo(() => { return !!showCheck && !!activate && !!validate;}, [showCheck, validate, activate]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          paddingBottom: '5px',
          borderBottom: `1px solid ${grey[300]}`
        }}
      >
        <Typography 
          sx={{
            textTransform: 'uppercase',
            fontSize: '.8em',
            fontWeight: '600',
            display:'flex'
          }}>Proceso de Facturación automático de planes</Typography>
        <SwitchComponent value={showCheck} onChange={handlerButton} />
      </Box>

      <Box 
        sx={{
          width: '100%', 
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center', 
          flexDirection: 'column', 
          fontWeight: 'bold'
        }}>
        {validate === false && !activate && (
          <>
            <Typography
              sx={{textTransform: 'uppercase',
                color: '#ef5350',
                fontSize: '0.8rem',
                textAlign: 'center',
                margin: '20px 0'
              }}> * Faltan campos requeridos para activar el proceso de facturacion </Typography>
          </>
        )}
        {/* {
          showCheck && activate && validate && ( */}
        <>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}>
               
            <Box 
              sx={{
                display: 'flex', 
                flexDirection: 'row',
                width: '100%', 
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Box 
                sx={{
                  display:'flex',
                  alignItems: 'center',
                  gap: '20px'
                }}>
                <Typography 
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '.8em',
                    fontWeight: '600',
                  }}>Incluir en el proceso de facturación las boletas pagadas en efectivo</Typography>
                <PopoverComponent text={'Solo se puede incluir las boletas en efectivo si el proceso de facturación automatica esta activado.'} />
              </Box>

              <SwitchComponent 
                disabled={!enableEfectivo} 
                value={checkBool} 
                onChange={() => setCheckBool(!checkBool)}
              />
            </Box>
          </Box>
        </>
        {/* )
        } */}
      </Box>
      <Divider />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'

        }}
      >
        <Box 
          sx={{
            display:'flex',
            alignItems: 'center',
            gap: '20px'
          }}>
          <Typography sx={{
            textTransform: 'uppercase',
            fontSize: '.8em',
            fontWeight: '600',
          }}>Habilitar Factura A</Typography>
          <PopoverComponent text={'Al activar la facturación a, la venta de planes podra dar la opción de ser factura con tipo a'} />
        </Box>
        <SwitchComponent value={afip?.acepta_factura_a} onChange={handleChangeFacturaA} />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'

        }}
      >
        <Box 
          sx={{
            display:'flex',
            alignItems: 'center',
            gap: '20px'
          }}>
          <Typography sx={{
            textTransform: 'uppercase',
            fontSize: '.8em',
            fontWeight: '600',
          }}>Factura dividida</Typography>
          <PopoverComponent text={'Al activar la facturación dividida, el proceso de facturación pasara a facturar las comisiones a SportClub Cadena y el restante al negocio.'} />
        </Box>
        <SwitchComponent value={afip?.tipo_facturacion === 'dividida' ? true : false} onChange={handleChange} />
      </Box>
      
      {/* <Divider /> */}

      {/* <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'

        }}
      >
        <Typography  style={{  fontSize: '.8em', textTransform: 'uppercase', color: theme.palette.primary.main, fontWeight: 'normal', display:'flex'}}>Proceso de Facturación automático Facturación de cobros adicionales</Typography>
        <SwitchComponent value={cobrosValidate} onChange={handlerButton} /> */}

      {/* <Button
          variant="outlined"
          defaultValue=''
          onClick={handleCobrosButton}
          style={{
            color: `${!cobrosValidate ? '' : !activate ? '#cccab5' : '#ef5350' }`, fontWeight: 'normal'}}
          initialValue={activate}
          disabled={!activate} 
        >
          {
            (!cobrosValidate && !activate) ? 'Activar' : 
              (!cobrosValidate && activate) ? 'Activar' : 'Desactivar'}
        </Button> */}
      {/* </Box> */}

      {/* <Box style={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', borderRadius: '10px', padding: '5px', fontWeight: 'bold'}}>
    
        {cobrosValidate === false && !activate && (
          <>
            <Typography style={{textTransform: 'uppercase', color: '#ef5350', fontSize: '0.8rem', textAlign: 'center', margin: '20px 0'}}> * Faltan campos requeridos para activar el proceso de facturacion </Typography>
          </>
        )}
      </Box> */}
    </Box>
  );
};


export const SwitchComponent = ({
  onChange,
  value,
  disabled
}) => {

  console.log('disabled', disabled);
  return (
    <IOSSwitch 
      sx={{
        width: 40, 
        height: 25,
        alignItems: 'center',
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 20,
          height: 20,
        },
        '& .MuiSwitch-track': {
          height: 25,
        },
      }} 
      checked={value} 
      onChange={onChange} 
      disabled={disabled}
    />
  );
};