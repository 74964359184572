import { grey } from '@mui/material/colors';
import { Box, Divider, Skeleton, IconButton, Popover } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDataProvider } from 'react-admin';
import ChartBar from '../UI/Dashboard/Card/ChartBar';
import { useQuery } from 'react-query';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LabelBar from './LabelBar';
import { merchantContext } from '../../DataProvider';
import { Typography } from '@mui/material';

const colors = [
  '#2C3333',
  '#395B64',
  '#A5C9CA',
  '#E7F6F2',
];
const transformData = ({data}) => {
  const socios = data?.json?.data;
  const dataSocios = {
    title: 'Dashboard',
    helpText: 'Datos tomados del mes seleccionado, y comparados con un período de igual duración del mes anterior.',
    subtitle: 'Resumen del mes',
    labels: [
      {
        number: socios?.activos?.activo_mes_actual,
        dif: socios?.activos?.diff_activos,
        title: 'Activos',
        tooltip: 'Socios con status activo al finalizar el mes seleccionado',
        url: 'socios_activos_pro',
        // reporteTooltip: 'Genera un reporte con los socios con status activo en la fecha elegida',
      },
      {
        number:  socios?.altas?.altas_mes_actual,
        dif: socios?.altas?.diff_altas,
        title: 'Altas',
        tooltip: 'Cantidad de altas en el mes.',
        url: 'socios_altas',
        data: 'altas',
        reporteTooltip: 'Genera un reporte con los socios que se dieron de alta entre las fechas seleccionadas',
        viewDate: true

      },
      {
        number: socios?.bajas?.bajas_mes_actual,
        title: 'Bajas solicitadas',
        dif: socios?.bajas?.diff_bajas,
        url: 'socios_bajas',
        tooltip: 'Cantidad de bajas solicitadas en el mes',
        data: 'bajas',
        reporteTooltip: 'Genera un reporte con los socios que solicitaron baja entre las fechas elegidas, aún si hoy en día ya se encuentran inactivos',
        bajas: true,
        viewDate: true
      },
      {
        number: socios?.inactivos?.inactivos_mes_actual,
        title: 'Bajas Automáticas',
        tooltip: 'Cantidad de inactivaciones automáticas por falta de pago en el mes.',
        dif: socios?.inactivos?.diff_inactivos,
        url: 'socios_inactivos',
        bajas: true,
        data: 'inactivos',
        reporteTooltip: 'Genera un reporte con los socios que se inactivaron de manera automática por sistema entre las fechas elegidas. No incluye a socios inactivos por baja manual',
        viewDate: true
      }
    ]
  };
  return dataSocios;
};


const HeaderSections = ({ min, switchTab, setSwitchTab, date}) => {
  const dataProvider = useDataProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState(null);

  const openMenu = Boolean(anchorEl);
  const { getMerchant } = useContext(merchantContext);

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { isLoading, error, isFetching } = useQuery(
    ['dashboard_socios', {download: false, merchant: getMerchant().id}, getMerchant().id, date],
    () => dataProvider.getFile(`dashboard_socios?filter=${JSON.stringify({'from': date.from, 'until':date.until})}`),
    {
      onSuccess: (res) => {
        setData(transformData(res));
      },
      enabled: getMerchant().id !== ''
    }
  );

  return (
    <>
      <Box 
        sx={{
          p: min ? '30px 50px 30px 50px' : '20px 60px 41px 41px',
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            maxWidth: '1200px',
            width: '100%'
          }}
        >
          <Box sx={{display: 'flex',flexDirection: 'column'}}>
            {
              !isFetching && data ? (
                <>
                  <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'start'}}>
                    <Box style={{display: 'flex', flexDirection: 'column'}}>
                      <Typography variant='h5' style={{fontSize: min ? {xs: '1rem', md: '2rem'} : {xs: '2rem', lg: '3rem'}}}>{data.title}</Typography>
                      <Typography variant='subtitle1' color={grey[600]} style={{marginLeft: '0', color: grey[600]}}>{data.subtitle}</Typography>
                    </Box>
                    {
                      data.helpText && <>
                        <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
                          <HelpOutlineIcon sx={{fontSize: '21px'}} />
                        </IconButton>
                        <Popover
                          open={openMenu}
                          anchorEl={anchorEl}
                          onClose={handleCloseMenu}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        >
                          <Box sx={{
                            maxWidth: '320px',
                            backgroundColor: '#fff',
                            padding: '15px 18px'
                          }}>
                            <Typography variant='subtitle2' style={{fontWeight: '400', color: grey[600]}}>{data.helpText}</Typography>
                          </Box>
                        </Popover>
                      </>
                    }
                  </Box>
                </>
              ) : (
                <>
                  <Skeleton width={280} height={35} />
                  <Skeleton width={240} height={30} />

                </>
              )
            }
          </Box>
          {
            ( !isFetching && data && data?.labels ) ?  (
              <Box sx={{
                display: 'grid',
                width: '80%',
                justifyContent: 'space-between',
                gridTemplateColumns: `repeat(${data.labels.length}, auto)`,
                gap:'20px'
              }}>
                {
                  data?.labels?.map(label => (
                  // <Box sx={{display: 'flex'}}>
                    <LabelBar allDate={date} label={label} colors={colors} min={min} small={min}/>
                  // </Box>
                  ))
                }
              </Box>
            ) : (
              <Box sx={{display: 'flex', justifyContent: 'space-around', gap: '20px'}}>
                <Box sx={{display: 'flex',flexDirection: 'column'}}>
                  <Skeleton width={180} height={35} />
                  <Skeleton width={180} height={25} />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'column'}}>
                  <Skeleton width={180} height={35} />
                  <Skeleton width={180} height={25} />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'column'}}>
                  <Skeleton width={180} height={35} />
                  <Skeleton width={180} height={25} />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'column'}}>
                  <Skeleton width={180} height={35} />
                  <Skeleton width={180} height={25} />
                </Box>
              </Box>

            )
          }
        </Box>
      </Box>
    </>
  );
};

export default HeaderSections;