import React from 'react';
import { Create } from 'react-admin';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';
import  CreatePAEdit from './CreatePAEdit';


const CreatePA= (props) => {

  return (
    <Create 
      {...props} 
      title={<TitleEdit title='Crear Punto de Acceso'/>} 
    >
      <CreatePAEdit/>
    </Create>
  );
};

export default CreatePA;