import React, { useState, useEffect, useCallback } from 'react';
import { minValue, maxValue, useDataProvider, useRefresh } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import ModalCustom from './ModalCustom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { createNotification, handleClickCopy } from '../../Resources/helpers/helpFunctions';
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import useCheckField from '../../Hooks/useCheckField';

const ModalCoupons = ({idCoupon, record, open, setOpen}) => {
  const dataProvider = useDataProvider();
  const [couponData, setCouponData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);

  const refresh = useRefresh();

  const createDiscount = () => {
    dataProvider.create('descuentos', {data: {...couponData, corporativo_id: record.id, date_created: new Date().toISOString().slice(0,10)}}).then(() => {
      createNotification('success', 'Cupón agregado con éxito');
      setLoading(false);
      setOpen(false);
      refresh();
    }).catch(()=> {
      createNotification('error', 'No se pudo crear el cupón', ()=> handleClickCopy({couponData, ...record.id}));
      setLoading(false);
    });
  };


  const handlerAddCoupon = () => {
    setLoading(true);
    if(validateData()){
      if(!record?.cupon) {
        return dataProvider.update('corporativo', {id: record?.id, data: {'cupon': true}}).then(() => {
          createDiscount();
        });
      }
      return createDiscount();
    }
    setCouponData(null);
  };

  const handlerEditCoupon = () => {
    setLoading(true);
    if(validateData()){
      dataProvider.update('descuentos', {data: couponData, id: idCoupon, corporativo_id: record.id}).then(() => {
        createNotification('success', 'Cupón editado con éxito');
        setOpen(false);
        setLoading(false);
        refresh();
      }).catch(()=> {
        createNotification('error', 'No se pudo editar el cupón', ()=> handleClickCopy({data: couponData, id: idCoupon, corporativo_id: record.id}));
        setLoading(false);
        setOpen(false);
      });
      setCouponData(null);
    }
  };

  const handlerDelete = () => {
    setLoadingDel(true);
    dataProvider.delete('descuentos', {id: idCoupon}).then(() => {
      createNotification('success', 'Cupón borrado con éxito');
      setOpen(false);
      setLoadingDel(false);
      refresh();
    }).catch(()=> {
      setLoading(false);
      createNotification('success', 'No se pudo borrar el cupón', ()=> handleClickCopy({id: idCoupon}));
    });
  };

  const validateData = () => {
    if(!couponData) return false;
    if(couponData?.concepto?.length >= 25 || couponData?.concepto == null || couponData?.concepto == '') return false;
    if ((couponData?.porcentaje) && ((couponData?.porcentaje > 100) || (couponData.porcentaje < 0))) return false;
    if ((couponData?.cupo) && ((couponData?.cupo < 0) || couponData?.cupo.length > 9)) return false;
    if ((couponData?.duracion) && ((couponData?.duracion < 0) || couponData?.duracion.length > 9)) return false;
    if ((couponData?.monto_absoluto) && (couponData?.monto_absoluto < 0 || couponData?.monto_absoluto.length > 9)) return false;
    return true;
  };

  useEffect(() => {
    if(idCoupon && open){
      dataProvider.getOne('descuentos', {id: idCoupon})
        .then(({data}) => {
          setCouponData(data);
        }).catch(e => {
          throw new Error(e);
        });
    }
    return(
      setCouponData(null)
    );
  }, [open]);

  const handleClose = () => {
    setCouponData(null);
    setOpen(false);
  };

  return (
    <Box>
      <ModalCustom
        open={open}
        onClose={handleClose}
        title={'Cupon'}
      >
        <Box component='form' onSubmit={idCoupon ? handlerEditCoupon : handlerAddCoupon}>
          <Box>
            {idCoupon ? 
              (
                couponData ? 
                  <ModalGrid setCouponData={setCouponData} couponData={couponData} isEdit/> 
                  : (
                    <Box style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0'}}
                    >
                      <CircularProgress/>
                    </Box>
                  )
              ) 
              : <ModalGrid setCouponData={setCouponData} couponData={couponData}/>
            }
            {
              ((idCoupon && couponData) || (!idCoupon)) && (
                <Box style={{
                  width: '100%', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  gap: '20px',
                  marginTop: '25px'
                }}>
                  {idCoupon && 
                      <Button variant='outlined' 
                        onClick={handlerDelete} color='error'
                        startIcon={loadingDel ?
                          <CircularProgress style={{width: '18px', height: '18px'}}/> :
                          <DeleteOutlineOutlinedIcon/>
                        }
                        disabled={loading || loadingDel}
                      >
                        Borrar
                      </Button>
                  }
                  <Button 
                    variant='contained'
                    type='submit'
                    startIcon={loading ? 
                      <CircularProgress style={{width: '18px', height: '18px'}}/> :
                      idCoupon ? <SaveOutlinedIcon/> : <AddOutlinedIcon/>
                    }
                    disabled={loading || loadingDel}
                  >
                    {idCoupon ? 'Guardar' : 'Agregar'}
                  </Button>
                </Box>
              )
            }
          </Box>

        </Box>
      </ModalCustom>
    </Box>
  );
};

const ModalGrid = ({setCouponData, couponData, isEdit}) => {
  const [viewPorcentaje, setViewPorcentaje] = useState(true);
  const [couponError, setCouponError] = useState(false);
  const validateConcept = () => {
    return (couponData?.concepto?.length >= 25 || couponData && couponData.concepto == '' );
  };
  const validatePorcentaje= () => {
    return (couponData?.porcentaje > 100 || couponData?.porcentaje < 0 );
  };

  const checkCodigoName = useCheckField('descuentos', 'codigo', couponData?.codigo);

  const handleblur = () => {
    checkCodigoName().then(({data}) => {
      if(data?.data?.data?.length > 0) return setCouponError(true);
      setCouponError(false);
    });
  };

  const checkCodigo = (e) => {
    setCouponData({...couponData, codigo: e.target.value});
  };
  console.log(couponError);
  return(
    <Box style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <TextField label='Código' variant='outlined' size='small'
        onChange={(e) => checkCodigo(e)}
        onBlur={handleblur}
        error={couponError}
        helperText={couponError ? 'El código ya existe' : null}
        defaultValue={isEdit ? (couponData?.codigo ? couponData.codigo : '') : ''}
      />
      <TextField label='Concepto' variant='outlined' size='small'
        error={validateConcept()}
        required
        onChange={(e) => setCouponData({...couponData, concepto: e.target.value})}
        defaultValue={isEdit ? (couponData?.concepto ? couponData.concepto : '') : ''}
      />
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <TextField 
          type='number' 
          label='Aplicaciones' 
          variant='outlined'
          size='small'
          min={0}
          InputProps={{inputProps:{ min: 0 }}}
          onChange={(e) => {
            setCouponData({...couponData, duracion: e.target.value >= 0 ? e.target.value : 0, cantidad_de_aplicaciones: e.target.value >= 0 ? e.target.value : 0});
          }}
          value={couponData?.duracion}
          style={{width: '45%'}}
        />
        <TextField 
          type='number' 
          label='Cupo' 
          variant='outlined'
          size='small'
          InputProps={{inputProps:{ min: 0 }}}
          onChange={(e) => setCouponData({...couponData, cupo: e.target.value >= 0 ? e.target.value : 0})}
          validate={[minValue(0)]} 
          value={couponData?.cupo}
          style={{width: '45%'}}
        />
      </Box>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        {
          viewPorcentaje ? 
            <TextField type='number' label='Porcentaje' variant='outlined' size='small'
              error={validatePorcentaje()}
              InputProps={{inputProps:{ min: 0 , max: 100}}}
              onChange={(e) => {
                const value = e.target.value > 100 ? couponData.porcentaje : e.target.value < 0 ? 0 : e.target.value;
                setCouponData({...couponData, porcentaje: value, monto_absoluto: 0});
              }}
              validate={[minValue(0), maxValue(100)]}
              value={ couponData?.porcentaje ?? 0 }
              style={{width: '45%'}}
            /> :
            <TextField type='number' label='Monto absoluto' variant='outlined' size='small'
              onChange={(e) => setCouponData({...couponData, monto_absoluto: e.target.value, porcentaje: 0})}
              validate={[minValue(0)]} 
              value={couponData?.monto_absoluto ?? 0}
              style={{width: '45%'}}
            />
        }
        <TextField type='date' label='Vigencia'  size='small'
          variant='outlined' InputLabelProps={{ shrink: true }}
          min="2022-12-12"
          onChange={(e) => setCouponData({...couponData, fecha_vigencia: e.target.value})}
          defaultValue={isEdit ? couponData?.fecha_vigencia?.slice(0,10) : ''}
          style={{width: '45%'}}
        />
      </Box>
      <ToggleButtonGroup
        color="secondary"
        value={viewPorcentaje ? 'porcentaje' : 'monto'}
        exclusive
        onChange={() => setViewPorcentaje(!viewPorcentaje)}
        aria-label="Platform"
        size='small'
      >
        <ToggleButton size='small' value="porcentaje">Porcentaje</ToggleButton>
        <ToggleButton size='small' value="monto">monto</ToggleButton>
      </ToggleButtonGroup>
      {/* <FormControlLabel
        control={
          <Switch 
            checked={viewPorcentaje} 
            label='Ingresar Monto' 
            onChange={() => setViewPorcentaje(!viewPorcentaje)} 
          />
        }
        label={viewPorcentaje ? 'Porcentaje' : 'Monto'}
      /> */}
    </Box>
  );
};

export default ModalCoupons;