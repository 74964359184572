import React, { useEffect, useState } from 'react';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { useDataProvider, useRecordContext } from 'react-admin';
import { emptyTextStyle, styleChip } from '../../../Theme/useStyle';
import activityAmenities from '../SectionsEdit/ActivityAmenities';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { getPermissions } from '../../helpers/helpFunctions';


const fontTitle = {
  color: '#6470B1',
  fontSize: '13px',
  fontWeight: '600'
};

const contentChips = {
  display: 'flex',
  gap: '7px 10px',
  flexWrap: 'wrap',
};

const styleBoxAmenities = {
  display:'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '15px'
};

const AmntsServsCard = () => {
  const [activities, setActivities] = useState(null);
  const [amenities, setAmenities] = useState(null);
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const permis = getPermissions()

  useEffect(()=>{
    dataProvider.getMany('actividades', {ids: record.actividades}).then(items => setActivities(items.data));
    dataProvider.getMany('servicios', {ids: record.amenities}).then(items => setAmenities(items.data));
  },[]);

  const editComponent = {
    component: activityAmenities(),
    label: 'Servicios y Actividades',
    icon: <DirectionsRunIcon/>
  }


  return (
    <CardBasic title='Servicios y Actividades' route={'sedes'} editComponent={permis !== 'operador' && editComponent} record={record}>
      <Box style={styleBoxAmenities}>
        <Typography style={{...fontTitle, display:'flex', justifyContent: 'center'}}>
        Servicios
        </Typography>
        <Box style={{width:'80%', display: 'flex', justifyContent: 'flex-start'}}>
          {
            (amenities !== null && amenities.length > 0) ? (
              <Box style={contentChips}>
                {
                  amenities.map(item => (
                    <Chip
                      key={item.id}
                      variant='outlined' 
                      label={item.name} 
                      style={styleChip}
                    />
                  ))
                }
              </Box>
            ) : amenities == null ? (
              <Box>
                <Skeleton animation='wave' style={{width: '400px'}}/>
                <Skeleton animation='wave'/>
              </Box>
            ) : (
              <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Typography style={emptyTextStyle}>No hay servicios para esta sede</Typography>
              </Box>
            )
          }
        </Box>
      </Box>
      <Box style={{display:'flex', justifyContent: 'space-between', marginTop: '15px', alignItems:'center'}}>
        <Typography style={{...fontTitle, display:'flex', justifyContent: 'center'}}>
        Actividades
        </Typography>
        <Box style={{width:'80%', display: 'flex', justifyContent: 'flex-start'}}>
          {
            (activities !== null && activities.length > 0) ? (
              <Box style={contentChips}>
                {
                  activities.map(item => (
                    <Chip
                      key={item.id}
                      variant='outlined' 
                      label={item.name} 
                      style={styleChip}
                    />
                  ))
                }
              </Box>
            ) : activities == null ? (
              <Box >
                <Skeleton animation='wave' style={{width: '400px'}}/>
                <Skeleton animation='wave'/>
              </Box>
            ) : (
              <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Typography style={emptyTextStyle}>No hay actividades para esta sede</Typography>
              </Box>
            )
          }
        </Box>
      </Box>
    </CardBasic>
  );
};

export default AmntsServsCard;