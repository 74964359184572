import { Box, Grid, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { List, Datagrid, TextField, CreateButton,
  TopToolbar, ExportButton, TextInput, useRedirect, FunctionField, usePermissions
} from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import { snippetBeacon } from '../../Components/Snippets/Snippets';
import TextInputIcon from '../../Components/TextInputIcon/TextInputIcon';
import EmptyLayout from '../../Components/UI/Layout/EmptyLayout';
import { merchantContext, merchantSelected } from '../../DataProvider';
import { createNotification, dateFormat } from '../helpers/helpFunctions';
import logoDecidir from '../../Assets/Images/paywayLogo.png';
import logoMP from '../../Assets/Images/mpLogoCrop.png';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Typography } from '@mui/material';



const clientFilters = [
  <TextInputIcon
    source="q" 
    label=""
    placeholder='Buscar'
    alwaysOn 
    variant='outlined' 
    resettable 
    icon='search'
    color='default'
    minLetters={3}
    style={{width: '100%'}}
  />,
];

const MerchantsList = (props) => {
  const redirect = useRedirect();
  const url = window.location.href.split('?')[1];
  const handleClickCreate = () => {
    redirect('/merchants/create');
  };
  const {setMerchant} = useContext(merchantContext);

  useEffect(() => {
    if(url && url.includes('code=')){
      let urlCode = window.location.href.split('?')[1];
      let merchantSelect = localStorage.getItem('merchant');
      if(merchantSelect){
        merchantSelect = merchantSelect?.split('-.-');
        // const newMerch = {id: merchantSelect[0], name: merchantSelect[1]}
        // console.log("MerchantsList", newMerch)
        // setMerchant(newMerch);
        createNotification('warning', 'Es necesario guardar los cambios para finalizar la habilitacion del negocio');
        merchantSelect[0] && redirect(`/merchants/${merchantSelect[0]}/show/?${urlCode}`);
      }
    }

  },[]);
  useEffect(()=> {
    let urlCode = window.location.href.split('?')[1];
    if(merchantSelected.id !== '0' && merchantSelected.id !== ''){
      if(urlCode && urlCode.includes('code=')) {
        redirect(`/merchants/${merchantSelected.id}/show/?${urlCode}`);
      } else {
        redirect(`/merchants/${merchantSelected.id}/show`);
      }
    }
  }, [merchantSelected]);
  
  return (
    <>
      <List 
        bulkActionButtons={false} 
        filters={clientFilters} 
        actions={<MerchantListActions permissions={props.permissions}/>} 
        perPage={25} 
        {...props}
        exporter={false}
        empty={
          <EmptyLayout
            handleClickCreate={handleClickCreate}
            title='No se encuentran negocios cargados'
            subtitle='Puede crear el primero'
            canCreate
          />}
      >
        <DatagridCustom rowClick='show'>
          <TextField source="name" label='Nombre'/>
          <FunctionField label='Procesador' render={record => {
            const activeDecidir = record.credentials?.decidir?.active;
            const activeMP = record.credentials?.mercadopago?.active;
            return(
              <Grid container>
                <Grid item xs={3} alignItems="center" justifyContent="center" style={{display: 'flex', filter: !activeMP && 'opacity(25%) grayscale(1)'}}>
                  
                  <Tooltip title={activeMP ? 'MercadoPago' : 'MercadoPago (Deshabilitado)'}>
                    <img src={logoMP} style={{display: 'flex', alignItem: 'center', width: '24px', objectFit: 'cover'}}/>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} alignItems="center" justifyContent="center" style={{display: 'flex', filter: !activeDecidir && 'grayscale(1) opacity(25%)'}}>
                  
                  <Tooltip title={activeDecidir ? 'Decidir' : 'Decidir (Deshabilitado)'}>
                    <img src={logoDecidir} style={{width: '22px', display: 'flex', alignItem: 'center'}} />
                  </Tooltip>
                </Grid>
                
              </Grid>
            );
          }}/>
          <FunctionField label='Facturacion' 
            render={record => {
              const activeFacturacion = record.afip?.activate_facturacion;
              const dateFacturacion = record.afip?.date_init_facturacion;
              return(
                <Box>
                  {
                    activeFacturacion ? (
                      <Box style={{display: 'flex', gap: '9px'}}>
                        <TaskAltIcon style={{color: '#2e7d32'}}/>
                        <Typography variant='subtitle2'>Desde {dateFormat(dateFacturacion)}</Typography>
                      </Box>
                    ) : (
                      <CancelOutlinedIcon style={{color: '#d32f2f'}}/>
                    )
                  }
                </Box>
              );
            }}
          />
        </DatagridCustom>
      </List>
    </>
  );
};

export default MerchantsList;

export const MerchantListActions = ({ resource }) => {

  const {permissions} = usePermissions();
  console.log(permissions);
  return (
    <TopToolbar>
      {
        (permissions == 'superadmin' || permissions == 'owner') && (
          <CreateButton />
        )
      }
    </TopToolbar>
  );
};
