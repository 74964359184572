import { Box, IconButton, Typography, Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import ChartPie from './ChartPie';
import { MdOutlineFileDownload } from 'react-icons/md';
import { grey } from '@mui/material/colors';
import { Spinner } from '../Spinner/Spinner';
import {MdImageNotSupported} from 'react-icons/md';


const COLORS = [
  '#7FBCD2',
  '#A5F1E9',
  '#E1FFEE',
  '#FFEEAF',
  '#2dc2bd',
];

const CardContentSmall = ({planes, data, title, helpText, isFetching}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '400px'}}>
      <Box 
        sx={{
          padding: '8px 9px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#f4f6f8',
          borderRadius: '6px'
        }}
      >
        <Box style={{width: '18px'}}></Box>
       
        <Typography style={{letterSpacing: '0.1px', fontSize: '0.8em', textAlign: 'center', fontWeight: '500'}} color={grey[700]}>{data?.title || title}</Typography>
        {/* <IconButton aria-label="download">
          <MdOutlineFileDownload fontSize={'18px'} color={grey[700]}/>
        </IconButton> */}
        { 
          helpText && <>
            <IconButton aria-label="delete" color='secondary' onClick={handleClickMenu}>
              <HelpOutlineIcon sx={{fontSize: '21px'}} />
            </IconButton>
            <Popover
              open={openMenu}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <Box sx={{
                maxWidth: '320px',
                backgroundColor: '#fff',
                padding: '15px 18px'
              }}>
                <Typography variant='subtitle2' fontWeight={400} color={grey[600]}>{helpText}</Typography>
              </Box>
            </Popover>
          </>
        }
      </Box>
      {
        isFetching ? (
          <Box sx={{display: 'flex', flexDirection: 'column', width: '330px'}}>
            <Spinner />
          </Box>
        ) : data?.datasets?.length <= 0  ? (
          <>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: '100%', minWidth: '330px', margin: '40px 0 40px 0'}}>
              <MdImageNotSupported size={'100px'} color={grey[400]} />
              <Typography color={grey[400]} variant="h7" fontWeight="600">No hay Data</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
              <Box sx={{marginBottom: '10px'}}>
                <ChartPie planes={planes} datasets={data?.datasets}/>
              </Box>
              <Box sx={{display: 'flex',gap: '10px', justifyContent: 'center', flexWrap: 'wrap'}}>
                {
                  data?.datasets?.map((label, index) => (
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap:'5px'}}>
                      <Box 
                        sx={{
                          width: '15px',
                          height:'6px',
                          backgroundColor: COLORS[index], 
                          marginBottom: '2px',
                          borderRadius: '6px'
                        }}
                      />
                      <Typography variant='subtitle2' fontSize={'12px'} color={grey[600]}>{label.key}</Typography>
                    </Box>
                  )).slice(0,5)
                }
              </Box>
              
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default CardContentSmall;