import axios from 'axios';
import decodeJwt from 'jwt-decode';
import { useDataProvider } from 'react-admin';
import { ToastContainer, toast } from 'react-toastify';
import { apiUrl, merchantContext, merchantSelected } from '../../DataProvider';

export const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const merchantSC = ['6178652dcfb117ad58a2cd3d', '61bca9245d58720defaaf1cc'];

//                         0           1        2         3           4           5
export const roles = ['superadmin', 'owner', 'admin', 'operador', 'accesos', 'molinetes'];

export const brand = ['sportclub'];

export const hasAuth = (permissions, arr) => {
  return arr.includes(permissions);
};

export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getMerchantId = () => {
  const authToken = localStorage.getItem('auth');
  return(decodeJwt(authToken).merchant);
};

export const getToken = () => {
  const authToken = localStorage.getItem('auth');
  if(authToken){
    return(decodeJwt(authToken));
  }
};

export const getPermissions = () => {
  return localStorage.getItem('permissions');
};

export const getListProvider = (dataProvider, url, success, failed, pagination, sort, filter) => {
  let objectDefault = {
    pagination: pagination ? pagination : {page: 1, perPage: 25},
    sort: sort ? sort : {field: 'id', order: 'DESC'},
    filter: filter ? filter : {}
  };

  dataProvider.getList(url, objectDefault)
    .then(({data, total}) => {
      if(!total){
        getTotal(dataProvider, url, sort, filter, success, data);
      }else{
        success(data, total);
      }
    }).catch(e => {
      failed ? 
        failed(e) :
        console.log(e);
    });
};

const getTotal = (dataProvider, url, sort, filter, success, dataList) => {
  dataProvider.getList(url, {
    pagination: {page: 1, perPage: 9999},
    sort: sort ? sort : {field: 'id', order: 'DESC'},
    filter: filter ? filter : {}
  })
    .then(({data}) => {
      success(dataList, data?.length);
    }).catch(e => {
      console.log(e);
      return 0;
    });
};

export const base64toImage = (path) => {
  return 'data:image/png;base64,' + window.btoa(unescape(encodeURIComponent(path)));
};

export const getListAxios = async (filter, endpoint) => {
  const token = localStorage.getItem('auth');
  const tokenSend = await JSON.parse(token)?.token;
  return (await axios({
    url: `${apiUrl}/${endpoint}?filter=${JSON.stringify(filter)}&range=[0,999]&sort=["id","ASC"]`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Tokens': tokenSend,
      'context-merchant': '',
      'access-control-allow-origin': '*'
    },
  }));
};

export const getLocations = (map) => {
  let iframe = `${map}`;
  let map2 = iframe.split('2d');
  let latitud = '';
  let longitud = '';
  if (map2.length > 1) {
    map2 = map2[1].split('!');
    let map2_2d = map2[0];
    let map2_3d = map2[1].split('3d')[1];
    latitud = map2_3d;
    longitud = map2_2d;
  }
  return {latitud, longitud};
};

export const isUserSC = (permissions) => {
  if(permissions === 'superadmin' || permissions === 'owner'){
    return true;
  }else{
    const merchants = getToken()?.merchant;
    for (const m of merchantSC){
      if(merchants?.includes(m)){
        return true;
      }
    }
    return false;
  }
};

export const isUserCentral = (permissions) => {
  if(permissions === 'superadmin' || permissions === 'owner') return true;
  if(permissions === 'admin' && getToken()?.negocio_central) return true;
  return false;
};

export const isUserSede = (permissions) => {
  if(permissions === 'superadmin' || permissions === 'owner') return true;
  if((permissions === 'admin' || permissions === 'operador') && !getToken()?.negocio_central) return true;
  return false;
};

export function strCapitalize(str) {
  if(!str || str.length === 0) return 'Error';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const uppercaseString = (string) => {
  let strArr = string.split(' ');
  return strArr.reduce((a, b, index) => {
    if(index === 0) return [...a, b.charAt(0).toUpperCase() + b.slice(1).toLowerCase()];
    return [...a, b.charAt(0) + b.slice(1).toLowerCase()];
  },[]).join(' ');
  
};

export const createNotification = (type, msg, callback, title) => {
  return(
    type == 'success' ? (
      toast.success(strCapitalize(msg), {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    ) : type == 'error' ? (
      toast.error(strCapitalize(msg), {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    ) : type == 'info' ? (
      toast.info(strCapitalize(msg), {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    ) : (
      toast.warn(strCapitalize(msg), {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    )
  );
};

export const handleClickCopy = (data) => {
  navigator.clipboard.writeText(JSON.stringify(data));
};

export function csvToArray(str, delimiter = ',') {
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });
  
  return arr;
}

export const dateFormat = (date, showYear) => {
  const vreplace = date?.replace(/[/]/g, '-');
  let match = /(\d{4})-(\d{2})-(\d{2})/.exec(vreplace);
  let mm, dd, yyyy;
  if (match === null) {
    match = /(\d{2})-(\d{2})-(\d{4})/.exec(vreplace);
    if(match === null) return date;
    mm = match[2];
    dd = match[1];
    yyyy =  match[3];
  }else{
    mm = match[2];
    dd = match[3];
    yyyy =  match[1];
  }
  const mmInt = parseInt(mm);
  if(!isNaN(mmInt) && mmInt >= 1  && mmInt <= 12){
    if(showYear == null || showYear){
      return `${dd} de ${meses[mmInt - 1]}, ${yyyy}`;
    }
    return `${dd} de ${meses[mmInt - 1]}`;
  }else{
    return date;
  }
};


export function dateToString(date) {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return `${date.getFullYear()}-${(mm>9 ? '' : '0') + mm}-${(dd>9 ? '' : '0') + dd}`;
};


export const getBrand = async () => {
  if(await getToken()?.id) {
    const brand = await getToken()?.brand_id;
    const role = await getToken()?.role;
    try {
      if(role === 'superadmin') {
        return true;
      } else {
        if(brand !== '6328afb359d052e6817c2200') {
          return false;
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  
  return true;
};


export const handleClientAccess = (client) => {
  let merchants = getToken().merchant;
  let permis = getPermissions();
  if(client?.externo === true) return false;
  if(permis === 'superadmin' || permis === 'owner') return true;
  for (let merchant of merchants) {
    if(client?.seller_merchant_id && merchant === client?.seller_merchant_id) return true;
    if(client?.plan_details?.merchant_id && client?.plan_details?.merchant_id === merchant) return true;
  }
  return false;
};