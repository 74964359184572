import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import ButtonAws from '../../CustomFields/ButtonAws';
import ModalCustom from '../ModalCustom';

const ModalUploadImage = ({open, setOpen, socioName, setUrl, finishSuccess }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handleOnClose = () => {
    setOpen(false);
    setImgSrc(null);
  };

  return(
    <ModalCustom 
      open={open} 
      setOpen={setOpen} 
      onClose={handleOnClose}
      title='Foto de perfil'
    >
      <Box style={{width: '100%'}}>
        {
          imgSrc ? (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <img src={imgSrc}/>
              <Box style={{display: 'flex',justifyContent: 'space-between', width: '100%', marginTop: '10px'}}>
                <Button onClick={() => setImgSrc(null)} 
                  style={{width: '80%'}} 
                  variant='outlined'>
                  Tomar otra
                </Button>
                <ButtonAws 
                  setUrl={setUrl} 
                  text='Subir' 
                  variant='contained' 
                  style={{width: '80%', marginLeft: '20%', textAlign: 'center'}} 
                  fileNameCustom={socioName}
                  fileUpload={imgSrc}
                  finish={finishSuccess}
                />
              </Box>
            </Box>
          ) : (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
              <Button onClick={capture}>Tomar Foto</Button>
            </Box>
          )
        }
      </Box>
    </ModalCustom>
  );
};

export default ModalUploadImage;