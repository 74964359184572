import { Box, Button, Skeleton, Table, TableCell, TableHead, TableRow, TableBody, Tooltip, Collapse, Typography } from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import TableBasic from '../../../Components/UI/Show/TableBasic';
import AddIcon from '@mui/icons-material/Add';
import ModalSocio from '../../../Components/UI/ModalSocio';
import { useRecordContext, useRefresh, useUpdate } from 'react-admin';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import { styleTabbleData, styleTableHead } from '../../../Theme/useStyle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styleButtonSection } from '../../../Theme/useStyle';
import { createNotification, handleClickCopy } from '../../helpers/helpFunctions';
import { MenuActionsDiv } from '../../../Components/UI/Options/ActionsOptions';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import { MdOutlineLocalOffer } from 'react-icons/md';
import descuentosSocio from '../SectionsEdit/DescuentosSocio';
import { merchantCadena, merchantContext } from '../../../DataProvider';

const cols = [
  {name: 'Concepto', key: 'concepto'},
  {name: 'Descuento', key: 'porcentaje'},
  {name: 'Aplicaciones', key: 'cantidad_de_aplicaciones'},
  {name: 'Aplicaciones Restantes', key: 'aplicaciones_restantes'},
  {name: 'Monto Absoluto', key: 'monto_absoluto'},
  {name: 'Fecha de creación', key: 'date_created'},
  // {name: 'Fecha de Vigencia', key: 'fecha_vigencia'},
  {name: 'Estado', key: 'estado'},
  {name: '', key: 'action'},
];

const DiscountsCard = ({plan}) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [canCreate, setCanCreate] = useState(false);
  const [arrDisc, setArrDisc] = useState([]);
  const [update, {loading, loaded, error}] = useUpdate();
  const {getMerchant} = useContext(merchantContext);

  useEffect(() => {
    setCanCreate((plan?.merchant_id === merchantCadena && (getMerchant().id === merchantCadena) || (getMerchant().id === '')) || (plan?.merchant_id === getMerchant()?.id));
  },[plan]);
  
  const refresh = useRefresh();
  useEffect(()=> {
    if(record?.discounts?.length > 0) {
      const arr = record?.discounts?.map((item, i) => {
        if(item.aplicaciones_restantes !== 0) {
          return {...item, estado: 'Activo', id: item?.id ?? Math.floor(new Date()?.getTime() + i)};
        } else {
          return {...item, estado: 'Expirado', id: item?.id ?? Math.floor(new Date()?.getTime() + i)};
        }
      });
      setArrDisc(arr.slice((page - 1) * 5, page * 5));
    }
    if(error){
      createNotification('error', 'No se pudo eliminar el descuento');
    }
  }, [page, setPage, loaded, error]);

  return (
    <CardBasic
      title='Descuentos' 
      asideAction={
        canCreate ? (
          <Button 
            variant='text' size='small'
            style={styleButtonSection}
            startIcon={<AddIcon style={{fontSize: '20px', margin: '0 0 2px 0'}}/>}
            onClick={()=> setOpen(true)}
          >
           Crear Descuento
          </Button> 
        ) : null
      }
    >
      {
        loading ? (
          <Box style={{width: '100%'}}>
            <Skeleton animation="wave" style={{width: '100%', margin: '5px'}}/>
            <Skeleton animation="wave" style={{width: '100%', margin: '5px'}}/>
            <Skeleton animation="wave" style={{width: '100%', margin: '5px'}}/>
          </Box>
        ) : (
          <Box>
            <ModalSocio refresh={refresh} open={open} setOpen={setOpen} record={record}/>
            {
              arrDisc && arrDisc.length > 0 ? (
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{...styleTableHead, maxWidth: '80px', padding: '10px 0 10px 10px'}}>Concepto</TableCell>
                      <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Descuento</TableCell>
                      <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Aplicaciones Restantes</TableCell>
                      <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}>Monto Absoluto</TableCell>
                      <TableCell style={{...styleTableHead, padding: '10px 0 10px 10px'}}></TableCell>
                    </TableRow>
                  </TableHead>
              
                  <TableBody >
                    {arrDisc.map((row, index) => (
                      <Row setArrDisc={setArrDisc} row={row} index={index} arrDisc={arrDisc} />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Box style={{width: '100%',display: 'flex', aligntItems: 'center', justifyContent: 'center'}}>
                  <Typography style={{fontSize: '0.875rem', fontWeight: 500, lineHeight: 1.57}}>No se encuentran descuentos para este socio</Typography>
                </Box>
              )
            }
          </Box>
        )
      }
    </CardBasic>
  );
};

export default DiscountsCard;

export const Row = ({row, index, arrDisc, setArrDisc}) => {

  const [open, setOpen] = useState(false);
  const ref = useRef(false);
  const record = useRecordContext();
  const [createdBy, setCreatedBy] = useState([]);
  const [update] = useUpdate();
  
  useEffect(() => {
    
    record?.version_control?.map((item, i) => {
      if(item?.previous_status?.discounts) {
        let discount = item?.previous_status?.discounts;
        if(discount.length < arrDisc.length) {
          createdBy <= discount.length && setCreatedBy((f) => ([...f, item.modified_by]));
        }
      }
    });
  },[]);
  function handleOpen (e) {
    e.preventDefault();
    setOpen(!open);
  }

  const handleDelete = (row) => {
    const deleteDisc = arrDisc.filter((item) => {
      return item.id !== row.id;
    });
    update('socios',{ id: record.id, data: {discounts: deleteDisc}, previousData: record});
    setArrDisc(deleteDisc);
    record.discounts.length == 0 && setArrDisc([]);
    createNotification('success', 'Descuento eliminado con éxito');
  };

  return (
    <>
      { arrDisc && 
    <>

      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderRadius: 'inherit', backgroundColor: '#fff',  '&:hover': {
        background: '#efefef',
        cursor: 'pointer'
      }, }} onClick={handleOpen}>
        <>
          <TableCell component="th" scope="row" style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.concepto}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.porcentaje && row.porcentaje}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.aplicaciones_restantes}</TableCell>
          <TableCell style={{...styleTabbleData, padding: '0 0 0 10px'}}>{row.monto_absoluto && row.monto_absoluto}</TableCell>
          <TableCell 
            // disabled={row.concepto === 'descuento masivo'}
            style={{ 
              ...styleTabbleData,
              display: 'flex',
              flexDirection: 'column',
              height: '42px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 0 0 10px'
            }}
          >
            <DeleteForeverIcon
            //  disabled={row.concepto === 'descuento masivo'} 
              sx={{borderRadius: '4px', cursor: 'pointer', '&:hover':{ backgroundColor: '#cfd8dc'}}} 
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(row);
              }}/>
          
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell style={{...styleTabbleData, padding: '0px'}}colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{margin: '10px 0'}}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Estado</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Cantidad de aplicaciones inicial</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Fecha de creación</TableCell>
                    <TableCell style={{...styleTableHead,  fontSize: '10px',}}>Creado por: </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  <TableRow>
                    <TableCell style={styleTabbleData}>{row.estado}</TableCell>
                    <TableCell style={styleTabbleData}>{row.cantidad_de_aplicaciones}</TableCell>
                    <TableCell style={styleTabbleData}>{row.date_created && new Date(row?.date_created).toISOString().slice(0,10)}</TableCell>
                    <TableCell style={styleTabbleData}>{createdBy[index]}</TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>

      }
    </>
  );

};