import { TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, Checkbox, Icon, IconButton, InputAdornment, useTheme } from '@mui/material';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import { useDebounce } from '../../Hooks/useDebounce';

const SplitComponent = ({params}) => {
  const [valueSplit, setValueSplit] = useState(null);

  const handleSplit = (e) => {
    let value = e.target.value;
    if (value >= 0) { 
      setValueSplit(value);
      params.row.edit = true;
      params.row.application_fee = value ? parseInt(value) : 0;
    }
  };

  const handleBlur = (e) => {
    let val = parseInt(e.target.value);
    if(!val){
      setValueSplit(0);
    }
  };

  useEffect(() => {
    setValueSplit(params.row?.application_fee);
  },[params]);

  return (
    <>
      <TextField
        variant='outlined'
        disabled={!params.row.hasKeys || params.row.disabled}
        fullWidth
        onBlur={handleBlur}
        onInput={(e)=>{ 
          handleSplit(e);
        }}
        value={valueSplit}
        sx={{
          '&  .Mui-focused': {
            border: 'none'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderColor: 'none',
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: '0'
          }
        }}
        InputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          startAdornment: (params.row.hasKeys || params.row.disabled ) && 
          <InputAdornment position="start">
            $
          </InputAdornment>,

        }}
      />
      {
        !params.row.hasKeys && (
          <Tooltip
            title="Este negocio no fue habilitado para la venta de planes Cadena.
             Sólo podrá vender planes cadena sin comisión (0 $)"
            placement="top"
            style={{margin: '0 10px'}}
          >
            <WarningRoundedIcon color='warning'/>
          </Tooltip>
        )
      }
    </>
  );
};

export default SplitComponent;