import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';
import { createNotification } from '../../Resources/helpers/helpFunctions';
import { CustomBajaButton } from '../UI/ButtonsToolbar';

function RevertirBajaButton(props) {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  let tday = new Date().toISOString();
  const noAccess = props.record?.fecha_vigencia < tday;
  
  const handleRevert = () => {
    setLoading(true);
    dataProvider.delete('socios', {id: `${props.record?.id}?arrepentimiento=true`}).then(item => {
      refresh();
      createNotification('success', item.data.msg,); 
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      createNotification('error', err.mensaje);
      throw new Error(err);

    });
  };

  return (
    <Tooltip  title='Revierte el proceso de la baja' placement='left'>
      <Box>
        <CustomBajaButton onClick={handleRevert} noAccess={noAccess} loading={loading} {...props}/>
      </Box>
    </Tooltip>
  );
}

export default RevertirBajaButton;