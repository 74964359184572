import React from 'react';
import { regex, required, useRecordContext } from 'react-admin';
import SectionForm from '../../../Components/Form/SectionForm';

const contactoEdit = () => {
  const record = useRecordContext();
  const checkNumber = regex(/^[0-9/ -()]+$/, 'No puede contener letras');
  const checkEmail = regex(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'No es un email valido');

  const data = [
    {
      type: 'email',
      source: 'contact-email',
      label: 'Email de Contacto',
      resettable: true,
      validate: {
        required: 'Falta el campo Email de Contacto en Contacto',
        pattern: {
          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          message: 'No es un email valido'
        }
      },
      xs: 12
    },
    {
      source: 'telefono',
      type: 'tel',
      label: 'Telefono',
      resettable: true,
      validate: {
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'No puede contener letras'
        }
      },
      xs: 12
    },
    {
      source: 'whatsapp',
      type: 'tel',
      label: 'WhatsApp',
      resettable: true,
      validate: {
        pattern: {
          value: /^[0-9/ -()]+$/,
          message: 'No puede contener letras'
        }
      },
      xs: 12
    }
  ];

  return data;

  // return (
  //   <SectionForm title='Contacto' data={data}/>
  // );
};

export default contactoEdit;