import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { BooleanInput, TextInput } from 'react-admin';
import { removeAccents } from '../helpers/helpFunctions';
import SectionForm from '../../Components/Form/SectionForm';
import { BsCardText } from 'react-icons/bs';
import CustomTabbedForm from '../../Components/Form/CustomTabbedForm';
import CardAws from '../../Components/CustomFields/CardAws';
import { BsWifi } from 'react-icons/bs';
import CreateForm from '../../Components/Form/CreateForm';


const ActividadesCreateEdit = ({isEdit, ...props}) => {

  const tabs = [
    {
      component: actividadesCustom(),
      label: 'Información',
      icon: <BsCardText/>
    },
    // {
    //   component: <IconActividades/>,
    //   label: 'Icono',
    //   icon: <BsWifi/>
    // }
  ];

  return (
    <CreateForm tabs={tabs} route={"actividades"} />
  )

  // return ( 
  //   <CustomTabbedForm tabs={tabs} deleteEnabled={isEdit} title={isEdit ? `Actividad ${props.record?.name}` : 'Crear Actividad'}  noShow {...props}/>
  // );
};

const actividadesCustom = () => {
  const [slug, setSlug] = useState('');

  const handlerName = (e) => {
    setSlug(removeAccents(e.target.value.toLowerCase().replace(/ /g, '-')));
  };

  const data = [
    {
      source: 'name',
      label: 'Nombre',
      onChange: (e)=> handlerName(e),
      xs: 12
    },
    {
      source: 'slug',
      label: 'Slug',
      initialValue: slug,
      value: slug,
      disabled: true,
      xs: 12
    },
    // {
    //   custom: true,
    //   component: <BooleanInput source="aparece-en-home" label='Aparece en home'/>,
    //   xs: 6
    // }
    {
      source: 'aparece-en-home',
      title: 'Aparece en Home',
      type: 'boolean',
      xs: 6
    }
  ];
  
  return data
};

const IconActividades = () => {

  const data = [
    {
      custom: true,
      component: <Box>
        <CardAws source='icon.url' text='Subir Icono'/>
      </Box>,
      xs: 12
    }
  ];
  return(
    <SectionForm data={data}/>
    
  );
};

export default ActividadesCreateEdit;
