import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import AccesosCreate from './AccesosCreate';
import AccesosList from './AccesosList';
import AccesosShow from './AccesosShow';
import { MdQrCode } from 'react-icons/md';

export default{
  icon: MdQrCode,
  list: AccesosList,
  create: AccesosCreate,
  show: AccesosShow
};