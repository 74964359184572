import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { emailAuth } from '../../Authentication/helpers';
import { useLogin, useRedirect } from 'react-admin';
import { Alert, Button, TextField } from '@mui/material';
import { useContext } from 'react';
import { merchantContext } from '../../DataProvider';

const invalidMessage = {
  data: {msg: 'Este link ya fue usado con anterioridad, por favor intenta con otro.', status: 'error'},
  res: {ok: true}
};


export default function SignInSide(props) {
  const [toggleButton, setToggleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const [noShow, setNoShow] = useState(true);
  const isInvalidLink = window.location.href.includes('token_url');
  const { setMerchant } = useContext(merchantContext);
  const redirect = useRedirect();
  const login = useLogin();
  
  const AuthIfWithToken = () => {
    // const apiUrlParams = new URLSearchParams(props.location.search);
    const token_url = document.location.hash.split('login')[1].slice(11);
    const sendTokenUrl = decodeURIComponent(token_url.replace(/\+/g,  ' '));
    console.log(sendTokenUrl);
    if (sendTokenUrl == null){
      return;
    }
    login(sendTokenUrl).then((d) => {
      redirectCallback(d?.role);
      //setMerchant(d.merchant[0] ? {id: d.merchant[0], name: ''} : {id:'0', name: ''});
      setLoading(false);
    }).catch(e => {
      setShow(invalidMessage);
      setLoading(false);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setToggleButton(true);
    const data = new FormData(event.currentTarget);
    const r = await emailAuth({email:data.get('email')});
    setShow(r);
    setToggleButton(false);
  };

  
  React.useEffect(() => {
    if(isInvalidLink){
      setLoading(true);
      AuthIfWithToken();
    }else{
      localStorage.getItem('auth') && redirect('/socios');
    }
  }, [isInvalidLink]);

  const redirectCallback = React.useCallback((rol) => {
    if(rol === 'accesos') return redirect('/accesos');
  },[]);

  return (
    <Box>
      {loading ? <Box sx={{width: '100%', height: '100vh', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress size='55px' variant="indeterminate"/>
      </Box> : (
        <Grid container component="main" sx={{ height: '100vh' }} display={loading && 'none'}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(https://s3.sa-east-1.amazonaws.com/m.miclub/login-op2.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {!noShow && 
            <Box sx={{position: 'absolute', width:'100vw', height: '100vh', backgroundColor: '#fff'}}>
            </Box>
              }
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
              Ingresar a BuenClub
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} style={{ marginTop: '10px', width:'300px'}}>
                <TextField
                  disabled={toggleButton}
                  margin="normal"
                  variant='outlined'
                  required
                  fullWidth
                  size='medium'
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                { 
                  show? 
                    <Alert variant="filled" severity={show.data.status}>
                      {show.res.ok? show.data.msg : 'Dirección de correo no encontrada'}
                    </Alert>
                    : null
                }
                <Button
                  disabled={toggleButton}
                  fullWidth
                  variant="contained"
                  type='submit'
                  color='primary'
                  style={{marginTop: '20px', marginBottom: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center'}}
                >
                  {toggleButton ? 
                    <CircularProgress size={24}/> :
                    <Typography style={{textAlign: 'center', width: '100%'}} variant='subtitle2'>Ingresar</Typography>
                  }
                </Button>

                <Grid container justifyContent='flex-end'>
                  <Grid item display="flex" flexDirection="column">
                    <Link href="#/" variant="body2" textAlign="end" onClick={()=>{setToggleButton(null);}}>
                      {'Volver a iniciar sesión'}
                    </Link>
                    <Link href="#" variant="body2">
                      {'No tenes cuenta? Habla con Soporte'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}