import React from 'react';
import { required } from 'react-admin';
import { choicesProvinciasExpand } from '../../helpers/choicesSelects';
import SectionForm from '../../../Components/Form/SectionForm';


const domicilioSocio = (record) => {
  const data = [
    {
      source: 'domicilio.provincia', 
      type: 'select', 
      label: 'Provincia', 
      choices: choicesProvinciasExpand,
      optionLabel: 'name',
      validate: {required: 'Falta el campo Provincia en Domicilio'},
      defaultValue: record?.domicilio?.provincia,
      xs: 12
    },
    {
      source: 'domicilio.localidad', 
      label: 'Localidad',
      validate: {required: 'Falta el campo Localidad en Domicilio'}, 
      defaultValue: record?.domicilio?.localidad,
      xs: 12
    },
    {
      source: 'domicilio.calle', 
      label: 'Calle', 
      validate: {required: 'Falta el campo Calle en Domicilio'},
      defaultValue: record?.domicilio?.calle,

      xs: 3
    },
    {
      source: 'domicilio.altura', 
      label: 'Altura', 
      validate: {required: 'Falta el campo Altura en Domicilio'},
      defaultValue: record?.domicilio?.altura,

      xs: 3
    },
    {
      source: 'domicilio.código postal', 
      label: 'Código Postal', 
      validate: {required: 'Falta el campo Código Postal en Domicilio'},
      defaultValue: record?.domicilio?.['código postal'],

      xs: 3
    },
    {
      source: 'domicilio.apto_lote', 
      label: 'Apto Lote',
      defaultValue: record?.domicilio?.apto_lote,
      xs: 3
    },
  ];


  // <SectionForm title='Domicilio' data={data}/>

  return data;
};

export default domicilioSocio;