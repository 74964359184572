import { Box } from '@mui/material';
import { Show, useRecordContext } from 'react-admin';
import { ShowTransparent } from '../../Components/CustomFields/FormTransparent';
import CardBasic from '../../Components/UI/Show/CardBasic';
import { TitleEdit } from '../../Components/CustomFields/TitleEdit';

import { bannerStyle } from '../../Theme/useStyle';
import HeaderCardShow from '../../Components/UI/Show/HeaderCardShow';
import { BsCardText, BsWifi } from 'react-icons/bs';
import { useState } from 'react';
import { removeAccents } from '../helpers/helpFunctions';
import AsideActionsShow from '../../Components/UI/Show/AsideActionsShow';
import ImageComponent from '../../Components/UI/Show/ImageComponent';
import cardAws from '../../Components/CustomFields/CardAws';


const AmenitiesShow = (props) => {

  
  return (
    <Show 
      {...props} 
      title={<TitleEdit title='Servicio: ' source='name'/>} 
      actions={null}
      aside={<AsideShow />}
    >
      <ShowTransparent>
        <Box style={bannerStyle}/>
        <AmenitiesInfo />
        <IconsInfo />
      </ShowTransparent>
    </Show>
  );
};

export default AmenitiesShow;

export const AmenitiesInfo = () => {
  const [slug, setSlug] = useState('');
  const record = useRecordContext();
  const handlerName = (e) => {
    setSlug(removeAccents(e.target.value.toLowerCase().replace(/ /g, '-')));
  };
  const editComponent = {
    component: [{source: 'name', label:'Nombre', onChange: handlerName, xs: 12}, {source: 'slug', label:'Slug', disabled: true, initialValue: slug, value: slug, xs:12},],
    label: 'Informacion',
    icon: <BsCardText />
  };
  return (
    <CardBasic route={'servicios'} record={record} editComponent={editComponent}>
      <HeaderCardShow
        title={record.name}
        subtitle={`Slug: ${record.slug}`}
      />
    </CardBasic>
  );
};

export const IconsInfo = () => {
  const [slug, setSlug] = useState('');
  const record = useRecordContext();

  const editComponent =  {
    component: cardAws('Subir Icono','icon.url'),
    label: 'Icono',
    icon: <BsWifi/>
  };

  return (
    <CardBasic title={'Imagenes'} route={'servicios'} record={record} editComponent={editComponent}>
      <Box style={{display: 'flex', gap: '20px'}}>
        <ImageComponent item={record.icon}/>
      </Box>
    </CardBasic>
  );
};


const AsideShow = ({basePath, resource, permis, ...rest}) => {
  const record = useRecordContext();

  const data = record ? [
   
    {
      rest: rest,
      record: record,
      disabled: false,
      label: 'Eliminar Servicio',
      confirmTitle: `¿Eliminar ${record?.name}?`,
      confirmContent: '¿Seguro quieres eliminar este Servicio?',
      basePath: basePath,
    },
    { divider: true },

  ] : [];

  return(
    <AsideActionsShow data={data}/>
  );
};
